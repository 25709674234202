import {History} from './NavigationHistoryUtils';

export const setUserInfoToLocalStorage = (user) => {
    if (user === null) {
        localStorage.removeItem('user');
        return;
    }

    const enabledFactoring = user.factoring ? Object.keys(user.factoring).filter(key => user.factoring[key] === true) : ['email'];

    const userInfo = {
        userName: user.userName,
        userToken: user.userToken,
        userId: user.userId,
        commId: user.commItem ? user.commItem._id : user.commId,
        type: user.commItem ? user.commItem.type : null,
        img: user.commItem ? user.commItem.img : null,
        chats: user.chats,
        factoring: enabledFactoring
    };

    localStorage.setItem('user', JSON.stringify(userInfo));
};


export const getActiveLanguageFromLS = () => {
    return localStorage.getItem('language') || 'eng';
};

export const setActiveLanguageToLS = (language) => {
    localStorage.setItem('language', language);
};

export const getFactoringList = () => {
    const userJSON = localStorage.getItem('user');
    const user = userJSON ? JSON.parse(userJSON) : {};

    if (user.factoring && typeof user.factoring === 'object') {
        return Array.from(user.factoring.values());
    } else {
        return [];
    }
};

export const setFactoringList = (item) => {
    const userJSON = localStorage.getItem('user');
    let user = userJSON ? JSON.parse(userJSON) : {};
    let factoringSet = new Set(user.factoring);

    if (factoringSet.has(item)) {
        factoringSet.delete(item);
    } else {
        factoringSet.add(item);
    }

    user.factoring = Array.from(factoringSet);
    localStorage.setItem('user', JSON.stringify(user));
};

export const getUserInfoFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
};

export const getUserToken = () => {
    const user = getUserInfoFromLocalStorage();
    return user ? user.userToken : null;
};

export const getUserId = () => {
    const user = getUserInfoFromLocalStorage();
    return user ? user.userId : null;
};

export const checkRoles = (roles = [], initRoles = []) => {
    return roles.some((role) => initRoles.includes(role));
};

export const checkIsSpecialist = (
    roles = [],
    initRoles = [
        'Appraiser',
        'Architect',
        'Broker',
        'Buyer',
        'Developer',
        'Investor',
        'Notary',
        'Seller'
    ]
) => {
    return roles.some((role) => initRoles.includes(role));
};

export const getBase64FromFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const getBase64FromURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
        if (this.status === 200) {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        } else {
            callback("");
        }
    };
    xhr.onerror = () => {
        callback("");
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

export const convertImageToBase64 = async (url) => {
    try {
        // Загрузить изображение по ссылке
        const response = await fetch(url,
            {
                headers: {
                    'x-www-access': getUserToken()
                }
            }
        );
        const blob = await response.blob();

        // Прочитать содержимое Blob и преобразовать в base64
        const reader = new FileReader();
        const base64Promise = new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
        });
        reader.readAsDataURL(blob);
        const base64 = await base64Promise;

        return base64;
    } catch (error) {
        console.error("Ошибка при конвертации изображения в base64:", error);
        throw error;
    }
}

export const navigateToSecondaryAuth = () => {
    History.navigate('/my/profile/secondary');
};

export const isCommunity = (user) => {
    return user?.commItem && user?.commId !== 'pending';
};

export const maximizerPhoto = (url = '') => {
    url = Array.isArray(url) ? url[0] : url;
    const splittedUrl = url.split('.');
    if (splittedUrl.length === 1) {
        return url;
    }

    const imageName = splittedUrl[splittedUrl.length - 2].concat('_max');
    splittedUrl[splittedUrl.length - 2] = imageName;
    return splittedUrl.join('.');
};

export const removeEmptyPropertiesFromObject = (object) => {
    return Object.fromEntries(
        Object.entries(object).filter(([_, v]) => v !== '' && (Array.isArray(v) ? v.length > 0 : true) && v !== 0)
    );
};

export const numberFormatter = (number, locale = 'en-US') => {
    if (number === 0) return 0;
    if (!number) return null;

    const formattedNumber = new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2
    }).format(number);

    return formattedNumber;
};

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSupportStatusColor = (status) => {
    if (status === 'open') {
        return '#57ab5a';
    } else if (status === 'fail' || status === 'cancel') {
        return 'red';
    } else if (status === 'wait') {
        return 'orange';
    } else {
        return 'gray';
    }
}

export const getStatusColor = (type, status) => {
    status = status.toLowerCase();
    type = type.toLowerCase();

    switch (type) {
        case 'support':
            return getSupportStatusColor(status);
    }
}
