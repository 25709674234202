import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";
import Header from "./components/Header/Header";
import DrawerMenu from "./components/DrawerMenu/DrawerMenu";
import { StoreContext } from "../../Providers";
import { GremService } from "../../services/index";
import { getUserInfoFromLocalStorage } from "../../utils/index";
import Footer from "./components/Footer/Footer";
import ScrollTop from "../../components/ScrollTop/ScrollTop";
import { Waypoint } from "react-waypoint";

const drawerWidth = 240;

const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: "40px",
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

function CabinetLayout() {
    const [open, setOpen] = useState(true);
    const { auth } = useContext(StoreContext);
    const [user, setUser] = useState(getUserInfoFromLocalStorage());
    const gremService = new GremService();

    useEffect(() => {
        async function fetchUserInfo() {
            if (!auth.user && user) {
                const result = await gremService.getUserInfo(user.userId);
                if (result.data) {
                    auth.setUser(result.data);
                }
            }
        }
        fetchUserInfo();
    }, [auth, user, gremService]);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleWaypointEnter = () => {
        const widgetBtn = document.getElementById('widget-btn');
        if (widgetBtn) widgetBtn.style.display = 'block';
    };

    const handleWaypointLeave = () => {
        const widgetBtn = document.getElementById('widget-btn');
        if (widgetBtn) widgetBtn.style.display = 'none';
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Header handleDrawerOpen={handleDrawerOpen} open={open} drawerWidth={drawerWidth} user={user} />
            <DrawerMenu handleDrawerClose={handleDrawerClose} open={open} drawerWidth={drawerWidth} />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%", minHeight: "100vh", flexGrow: 1 }}>
                <Main open={open}>
                    <Outlet />
                    <Waypoint topOffset={"-50%"} bottomOffset={"100%"} onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
                    <ScrollTop />
                </Main>
                <Footer />
            </Box>
        </Box>
    );
}

export default CabinetLayout;
