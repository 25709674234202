import {getBase64FromFile} from "utils";

class AddData {
    constructor(data, setData, dataError, setDataError) {
        this.data = data;
        this.dataError = dataError;
        this.setData = setData;
        this.setDataError = setDataError;
    }

    handleChangeInput = (event) => {

        const { name, value } = event.target;
        const nameParts = name.split('.');

        if(name === 'mobile_number' || name === 'phone'){
            this.handlePhoneNumberInput(event)
        } else if(name === 'email'){
            this.handleEmailInput(event)
        } else {
            const updateNestedField = (data, parts, value) => {
                if (parts.length === 1) {
                    return {
                        ...data,
                        [parts[0]]: value
                    };
                }

                const sectionName = parts[0];
                if (data[sectionName]) {
                    const updatedSection = updateNestedField(data[sectionName], parts.slice(1), value);

                    return {
                        ...data,
                        [sectionName]: updatedSection
                    };
                }

                return data;
            };

            const updatedData = updateNestedField(this.data, nameParts, value);
            this.data = updatedData;
            this.setData(updatedData);
        }
    };

    handleChangeDate = (date, name) => {
        const updatedData = {
            ...this.data,
            [name]: date,
        };
        this.data = updatedData;
        this.setData(updatedData);
    };

    isValidPhoneNumber = (value) => {
        const pattern = /^[+][0-9]{1,3}[0-9]{10,14}$/;
        return pattern.test(value);
    };

    handlePhoneNumberInput = (event) => {
        const { name, value } = event.target;
        const isValid = this.isValidPhoneNumber(value);

        this.setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        this.setDataError((prevError) => ({
            ...prevError,
            mobile_number: !isValid,
        }));
    };

    isValidEmail = (value) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(value);
    };

    handleEmailInput = (event) => {
        const { name, value } = event.target;
        const isValid = this.isValidEmail(value);

        this.setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        this.setDataError((prevError) => ({
            ...prevError,
            email_address: !isValid,
        }));
    };

    doValidateResponse = (response) => {
        const { errors = [] } = response;
        if (errors.length > 0) {
            this.setDataError({});
            errors.forEach((error) => {
                const field = error.key.toString();

                if (this.dataError.hasOwnProperty(field)) {
                    this.setDataError((prevState) => ({
                        ...prevState,
                        [field]: true,
                    }));
                }
            });
        }
    };

    addImage = async (file, name) => {
        const photo = await getBase64FromFile(file);
        const updatedData = {
            ...this.data,
            [name]: photo,
        };
        this.data = updatedData;
        this.setData(updatedData);
    };

    clearImage = (name) => {
        const updatedData = {
            ...this.data,
            [name]: null,
        };
        this.data = updatedData;
        this.setData(updatedData);
    };
}

export default AddData;
