import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import globalDictionary from "assets/translations/globalDictionary";
import { FileUploader } from "react-drag-drop-files";
import { getBase64FromFile } from "utils/index";
import CloseIcon from "@mui/icons-material/Close";
import Image from "mui-image";
import { snackActions } from "../../../../../utils/SnackBarUtils";
import CompactComponent from "../../ComplexAdd/components/CompactComponent";
import { getActiveLanguageFromLS } from "../../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { GremService } from "../../../../../services";
import { LoadingButton } from "@mui/lab";
import {ImageUploader} from "../../../../../components";

const ApartsComplex = () => {
  const language = getActiveLanguageFromLS();
  const [apartment, setApartment] = useState(
    {
      condition: "",
      area: "",
      floor: "",
      price: "",
      roomsNumber: "",
      currency: "₼",
      type: "Studio",
      description: "",
      images: [],
      number: "",
      utilitySystems: [],
    },
  );
  const [complexId, setComplexId] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();
  const gremService = new GremService();
  useEffect(() => {
    const path = location.pathname;
    const segments = path.split("/");
    const complexIndex = segments.findIndex((segment) => segment === "complex");

    if (complexIndex >= 0 && complexIndex < segments.length - 1) {
      const newComplexId = segments[complexIndex + 1];
      setComplexId(newComplexId);
    }
  }, [location]);

  const [isLoading, setIsLoading] = useState(false);

  const [apartsFormErrors, setApartsFormErrors] = useState([
    {
      condition: false,
      area: false,
      floor: false,
      price: false,
      roomsNumber: false,
      currency: false,
      type: false,
      description: false,
      images: false,
      number: false,
      utilitySystems: false,
    },
  ]);

  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = (apartment) => {
    const {
      type,
      roomsNumber,
      condition,
      area,
      floor,
      price,
      currency,
      description,
    } = apartment;

    const areRequiredFieldsFilled =
        type.trim() !== "" &&
        roomsNumber.trim() !== "" &&
        condition.trim() !== "" &&
        area.trim() !== "" &&
        floor.trim() !== "" &&
        price.trim() !== "" &&
        currency.trim() !== "" &&
        description.trim() !== "";

    return areRequiredFieldsFilled;
  };

  const handleApartmentInputChange = async (event) => {
    const {name, value} = event.target;
    await setApartment({...apartment, [name]: value})
    const isApartmentFormValid = checkFormValidity(apartment);
    setIsFormValid(isApartmentFormValid);
  };
  const handleUtilityInputChange = async (event) => {
    const {name} = event.target;
    const selectedValues = event.target.value;
    await setApartment({...apartment, utilitySystems:selectedValues});
    const isApartmentFormValid = checkFormValidity(apartment);
    setIsFormValid(isApartmentFormValid);
  };

  const handleNumApartmentInputChange = async (event) => {
    const {name, value} = event.target;
    const newValue = value.replace(/[+-]/g, "");
    event.target.value = newValue;

    const tempValue = newValue.replace(/[^0-9.,]/g, "");
    const isValidNumber = /^(\d+([.,]?\d*)?)$/.test(tempValue);
    const sanitizedValue = isValidNumber ? tempValue : "";
    await setApartment({...apartment, [name]: sanitizedValue})
    const isApartmentFormValid = checkFormValidity(apartment);
    setIsFormValid(isApartmentFormValid);
  };

  const handleSubmit = async () => {
    const updatedErrors = {
      condition: !apartment.condition,
      area: !apartment.area,
      floor: !apartment.floor,
      price: !apartment.price,
      roomsNumber: !apartment.roomsNumber,
      currency: !apartment.currency,
      type: !apartment.type,
      description: !apartment.description,
      images: !apartment.images || apartment.images.length === 0,
      number: !apartment.number,
      utilitySystems:
          !apartment.utilitySystems || apartment.utilitySystems.length === 0,
    };

    setApartsFormErrors(updatedErrors);
    const hasErrors = Object.values(updatedErrors).includes(true)

    if (!hasErrors) {
      setIsLoading(true);
      const apartmentData = {
        number: Number(apartment.number),
        utilitySystems: apartment.utilitySystems,
        description: apartment.description || "Описание не указано",
        images: apartment.images,
        type: apartment.type.toLowerCase(),
        area: Number(apartment.area),
        condition: apartment.condition || "new",
        currency: apartment.currency,
        floor: Number(apartment.floor),
        price: Number(apartment.price),
        roomsNumber: Number(apartment.roomsNumber),
      };

      try {
        const res = await gremService.addApartmentToComplex(
            complexId,
            apartmentData
        );
        await setIsLoading(false);
        if (res.code === 200) {
          setIsLoading(false);
          await snackActions.success(
              "You have successfully added new apartment"
          );
        } else {
          setIsLoading(false);
          snackActions.error("Error adding apartment");
        }
      } catch (error) {

        setIsLoading(false);
        snackActions.error("Error adding apartment");
      }
      navigate(-1);
    } else {
      setIsLoading(false);
      snackActions.error("Error adding apartment")
    }
  };

  return(
      <Container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant={"h6"}> {globalDictionary.get("addApartment_translate", language)}</Typography>
      </Grid>
  <Grid container spacing={2} p={2}>
        <Grid container spacing={2} sx={{ marginTop: "15px" }}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id={`apartment-type-label`}>
                {globalDictionary.get("typeOfApartment_translate", language)}
              </InputLabel>
              <Select
                  variant="outlined"
                  labelId={`apartment-type-label`}
                  label={globalDictionary.get(
                      "typeOfApartment_translate",
                      language
                  )}
                  required
                  defaultValue="Studio"
                  value={apartment.type}
                  name={"type"}
                  error={apartsFormErrors.type}
                  onChange={(event) => handleApartmentInputChange(event)}
              >
                <MenuItem
                    key={globalDictionary.get("studio_translate", language)}
                    value={globalDictionary.get("studio_translate", language)}
                >
                  {globalDictionary.get("studio_translate", language)}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get(
                        "convertibleStudio_translate",
                        language
                    )}
                    value={globalDictionary.get(
                        "convertibleStudio_translate",
                        language
                    )}
                >
                  {globalDictionary.get(
                      "convertibleStudio_translate",
                      language
                  )}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get("apartament_translate", language)}
                    value={globalDictionary.get(
                        "apartament_translate",
                        language
                    )}
                >
                  {globalDictionary.get("apartament_translate", language)}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get("oneBedroom_translate", language)}
                    value={globalDictionary.get(
                        "oneBedroom_translate",
                        language
                    )}
                >
                  {globalDictionary.get("oneBedroom_translate", language)}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get("twoBedroom_translate", language)}
                    value={globalDictionary.get(
                        "twoBedroom_translate",
                        language
                    )}
                >
                  {globalDictionary.get("twoBedroom_translate", language)}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get(
                        "threeBedroom_translate",
                        language
                    )}
                    value={globalDictionary.get(
                        "threeBedroom_translate",
                        language
                    )}
                >
                  {globalDictionary.get("threeBedroom_translate", language)}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get(
                        "fourBedroom_translate",
                        language
                    )}
                    value={globalDictionary.get(
                        "fourBedroom_translate",
                        language
                    )}
                >
                  {globalDictionary.get("fourBedroom_translate", language)}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get(
                        "fiveBedroom_translate",
                        language
                    )}
                    value={globalDictionary.get(
                        "fiveBedroom_translate",
                        language
                    )}
                >
                  {globalDictionary.get("fiveBedroom_translate", language)}
                </MenuItem>
                <MenuItem
                    key={globalDictionary.get("penthouse_translate", language)}
                    value={globalDictionary.get(
                        "penthouse_translate",
                        language
                    )}
                >
                  {globalDictionary.get("penthouse_translate", language)}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-currency-label">
                {globalDictionary.get(
                    "property_add_financial_currency",
                    language
                )}
              </InputLabel>
              <Select
                  variant="outlined"
                  labelId="select-currency-label"
                  id="select-currency"
                  label={globalDictionary.get(
                      "property_add_financial_currency",
                      language
                  )}
                  required
                  value={apartment.currency}
                  name={"currency"}
                  error={apartsFormErrors.currency}
                  onChange={(event) => handleApartmentInputChange(event)}
                  helperText={
                    apartment.currency
                        ? globalDictionary.get(
                            "property_add_financial_currency_help",
                            language
                        )
                        : ""
                  }
              >
                <MenuItem key={"₼"} value={"₼"}>
                  ₼
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="utility-systems-label">
                {globalDictionary.get("utilitySystems_translate", language)}
              </InputLabel>
              <Select
                  labelId="utility-systems-label"
                  label={globalDictionary.get(
                      "utilitySystems_translate",
                      language
                  )}
                  multiple
                  name="utilitySystems"
                  error={apartsFormErrors.utilitySystems}
                  value={apartment.utilitySystems || []}
                  renderValue={(selected) => (
                      <div
                          style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
                      >
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                      </div>
                  )}
                  onChange={(event) => handleUtilityInputChange(event)}
              >
                {[
                  globalDictionary.get("electricity_translate", language),
                  globalDictionary.get("water_translate", language),
                  globalDictionary.get("gas_translate", language),
                  globalDictionary.get("heating_translate", language),
                  globalDictionary.get("internet_translate", language),
                ].map((system) => (
                    <MenuItem key={system} value={system}>
                      {system.charAt(0).toUpperCase() + system.slice(1)}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
                type="text"
                placeholder={globalDictionary.get(
                    "projects_add_technical_number_rooms",
                    language
                )}
                label={globalDictionary.get(
                    "projects_add_technical_number_rooms",
                    language
                )}
                variant="outlined"
                fullWidth
                required
                minValue={0}
                error={apartsFormErrors.roomsNumber}
                value={apartment.roomsNumber}
                name={"roomsNumber"}
                onChange={(event) =>
                    handleNumApartmentInputChange(event)
                }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
                placeholder={globalDictionary.get(
                    "conditionOfApartment_translate",
                    language
                )}
                label={globalDictionary.get(
                    "conditionOfApartment_translate",
                    language
                )}
                variant="outlined"
                fullWidth
                required
                value={apartment.condition}
                error={apartsFormErrors.condition}
                name={"condition"}
                type={"text"}
                onChange={(event) => handleApartmentInputChange(event)}
                // error={apartsFormErrors.apartmentPrices.condition}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
                placeholder={globalDictionary.get(
                    "property_add_financial_price_placeholder",
                    language
                )}
                label={globalDictionary.get(
                    "property_add_financial_price",
                    language
                )}
                variant="outlined"
                fullWidth
                required
                value={apartment.price}
                name={"price"}
                error={apartsFormErrors.price}
                type="text"
                onChange={(event) =>
                    handleNumApartmentInputChange(event)
                }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
                placeholder={globalDictionary.get("area_translate", language)}
                label={globalDictionary.get("area_translate", language)}
                variant="outlined"
                fullWidth
                required
                value={apartment.area}
                name={"area"}
                type="text"
                error={apartsFormErrors.area}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                onChange={(event) =>
                    handleNumApartmentInputChange(event)
                }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
                placeholder={globalDictionary.get(
                    "property_item_technical_floor",
                    language
                )}
                label={globalDictionary.get(
                    "property_item_technical_floor",
                    language
                )}
                variant="outlined"
                fullWidth
                required
                value={apartment.floor}
                name={"floor"}
                type="text"
                onChange={(event) =>
                    handleNumApartmentInputChange(event)
                }
                error={apartsFormErrors.floor}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
                placeholder={globalDictionary.get("number_translate", language)}
                label={globalDictionary.get("number_translate", language)}
                variant="outlined"
                fullWidth
                required
                value={apartment.number}
                name={"number"}
                type="text"
                onChange={(event) =>
                    handleNumApartmentInputChange(event)
                }
                error={apartsFormErrors.price}
            />
          </Grid>
          <ImageUploader
              _data={apartment}
              _setData={setApartment}
              isError={apartsFormErrors.images}
              config={{field: 'images',
                titleKey: 'image_uploader_title',
                confName:'apartConfigs'
              }}
          />
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
                placeholder={globalDictionary.get(
                    "property_add_common_description_help",
                    language
                )}
                label={globalDictionary.get(
                    "property_add_common_description",
                    language
                )}
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                value={apartment.description}
                error={apartsFormErrors.description}
                name={"description"}
                onChange={(event) => {
                  handleApartmentInputChange(event);
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={isLoading}
                  sx={{ width: { xs: "100", md: "25%" } }}
                  onClick={handleSubmit}
              >
                {globalDictionary.get('add_translate', language)}
              </LoadingButton>
            </Box>
          </Grid>

          <Divider />
        </Grid>
      </Grid>
      </Container>
  )
};

export default ApartsComplex;
