import {observer} from "mobx-react-lite";
import {StoreContext, useStore} from "../../../../Providers";
import React, {createContext, useEffect, useState} from "react";
import {GremService} from "services";
import {Grid, Typography} from "@mui/material";
import Kanban from "./kanban-view";
import {TabsList} from "@mui/base/TabsList";
import {Tab} from "@mui/base/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {Tabs} from "@mui/base/Tabs";
import TabContext from "@mui/lab/TabContext";
import {Container, styled} from "@mui/system";
import {useTheme} from "@mui/material/styles";

const KanbanBoard = observer(() => {
    const {auth} = useStore(StoreContext);
    const [board, setBoard] = useState({
        "columns": {
            "new": {
                "id": "new",
                "name": "New",
                "taskIds": []
            },
            "in progress": {
                "id": "in progress",
                "name": "In Progress",
                "taskIds": []
            },
            "processing": {
                "id": "processing",
                "name": "Processing",
                "taskIds": []
            },
            "agreement": {
                "id": "agreement",
                "name": "Agreement",
                "taskIds": []
            },
            "closed": {
                "id": "closed",
                "name": "Success",
                "taskIds": []
            },
            "not target": {
                "id": "not target",
                "name": "Not target",
                "taskIds": []
            },
            "failed": {
                "id": "failed",
                "name": "Failed",
                "taskIds": []
            }
        },
        "tasks": {},
        "ordered": [
            "new",
            "in progress",
            "processing",
            "agreement",
            "closed",
            "not target",
            "failed",
        ]
    });
    const gremService = new GremService();
    const [loading, setLoading] = useState(false);
    const isTaskMove = true
    const [activePropertyTab, setActivePropertyTab] = useState(0);
    const handlePropertyTabChange = (event, newActiveTab) => {
        setActivePropertyTab(newActiveTab);
    };
    useEffect(() => {
        getContractLeads();
    }, []);

    const theme = useTheme();
    const NewTab = styled(Tab)`
    font-family: IBM Plex Sans, sans-serif;
    color: #fff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    background-color: ${theme.palette.secondary.main};
    padding: 10px 12px;
    margin: 1px;
    border: none;
    border-radius: 14px 7px 7px 0px;
    display: flex;
    justify-content: center;
    transition: 0.1s;

      &:hover {
        background-color: ${theme.palette.primary.main};
      }
    `;

    const NewTabPanel = styled(TabPanel)(({theme}) => `width: 100%;`);

    const NewTabsList = styled(TabsList)(
        ({theme}) => `
  background-color: '#fff';
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-content: space-between;
  box-shadow: 0px 4px 30px '#fff';
  `
    );

    const getContractLeads = async () => {
        const response = await gremService.getLeads();
        if (response.code !== 200) {
            setLoading(false);
            return;
        }

        const leads = response.data.data;
        const updatedBoard = {...board};
        updatedBoard.tasks = {};
        for (let prop in updatedBoard.columns) {
            updatedBoard.columns[prop].taskIds = [];
        }
        leads.forEach(lead => {
            updatedBoard.columns[lead.status].taskIds.push(lead._id);
            updatedBoard.tasks[lead._id.toString()] = {
                id: lead._id,
                status: lead.status,
                reporter: {
                    "id": lead.broker.userId,
                    "name": `${lead.broker.fname} ${lead.broker.lname}`,
                    "avatarUrl": lead.broker.img
                },
                contractId:lead.contract,
                customerName: lead.customerName,
                customerContact: lead.customerContact,
                customerComments: lead.customerComments,
                brokerComments: lead.brokerComments,
                proposedTerms: lead.proposedTerms,
                createdAt: lead.createdAt,
                updatedAt: lead.updatedAt,
            };
        });

        setBoard(updatedBoard);
        setLoading(false);
    }

    const updateContractLead = async (leadId, status) => {
        const response = await gremService.updateContractLead(board.tasks[leadId].contractId, leadId, {status});
        return response;
    }

    return (
        <Container sx={{
            overflow: 'scroll'
        }}>
            <Grid container>
                <Grid
                    item
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <Typography
                        variant={"h5"}
                        component={"h2"}
                        sx={{
                            padding: "10px 0px",
                        }}
                    >
                        CRM
                    </Typography>
                </Grid>
                <Grid container>
                    <TabContext value={activePropertyTab.toString()}>
                        <Tabs value={activePropertyTab.toString()}>
                            <NewTabsList
                                value={activePropertyTab}
                                onChange={handlePropertyTabChange}
                                scrollButtons="auto"
                                textColor="inherit"
                                indicatorColor="secondary"
                                allowScrollButtonsMobile={true}
                                variant="scrollable"
                            >
                                <NewTab value={0}>Leads
                                </NewTab>
                            </NewTabsList>

                            <NewTabPanel value="0">
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                                    marginTop: 1,
                                }}>
                                    <Kanban board={board} boardLoading={loading} boardEmpty={board.length} setBoard={setBoard} updateContractLead={updateContractLead} isTaskMove={isTaskMove}/>
                                </Grid>
                            </NewTabPanel>
                        </Tabs>
                    </TabContext>
                </Grid>
            </Grid>
        </Container>
    );
});

export default KanbanBoard;
