import React from "react";
import {ComingSoon, Error} from "../pages";

const commonRoutes = [
    {
        path: "comingsoon",
        element: (<ComingSoon/>),
    },
    {
        path: "*",
        element: (<Error/>),
    },
];

export default commonRoutes;
