import React, { useContext } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import style from "./PropertyListItem.module.css";
import { Link } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import countries from "assets/translations/countries";
import noImage from "assets/images/no-image.png";
import m2cImage from "assets/images/m2cTokenLogo.png";
import {
  navigateToSecondaryAuth,
  isCommunity,
  getActiveLanguageFromLS,
  numberFormatter,
} from "utils/index";
import { StoreContext } from "../../Providers";
import globalDictionary from "assets/translations/globalDictionary";
import { snackActions } from "../../utils/SnackBarUtils";
import { ImageChecker } from "components";

function PropertyItem({ property = {}, isOwn = false }) {
  const language = getActiveLanguageFromLS();
  const navigate = useNavigate();
  const { auth } = useContext(StoreContext);
  const isCommunityUser = isCommunity(auth.user);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    name = "",
    price = "",
      mainPhoto = "",
    photos = "",
    currency = "",
    date = null,
    country = "",
    city = "",
    type = "",
    spaceAll = "",
    floor = "",
    _id: id = "",
  } = property;

  const menuItems = [
    {
      id: 1,
      to: `/properties/${property._id}`,
      label: globalDictionary.get("property_list_item_menu_view", language),
      showCondition: true,
      active: true,
    },
    {
      id: 2,
      to: `/properties/edit/${property._id}`,
      label: globalDictionary.get("properties_list_item_edit", language),
      showCondition: isOwn,
      active: true,
    },
    {
      id: 3,
      onClick: () => {
        copyToClipboard();
        handleClose();
      },
      label: globalDictionary.get("properties_menu_share", language),
      showCondition: true,
      active: true,
    },
    {
      id: 4,
      label: globalDictionary.get("property_menu_sold", language),
      showCondition: isOwn,
      active: false,
    },
    {
      id: 5,
      label: globalDictionary.get("property_menu_available", language),
      showCondition: isOwn,
      active: false,
    },
  ];

  const propertyImage = photos && photos.length > 0 ? mainPhoto !== "" ? mainPhoto : photos[0] : noImage;
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const copyToClipboard = () => {
    const link = `https://${process.env.REACT_APP_CABINET_URL}/properties/${property._id}`;

    navigator.clipboard.writeText(link).then((r) => {
      snackActions.success(
        globalDictionary.get("properties_share_success", language)
      );
    });
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Card
        sx={{
          flex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          "&:hover": {
            boxShadow: "0 0 10px 1px #dedede",
          },
        }}
      >
        <Stack
          direction="column"
          sx={{
            position: "absolute",
            top: 5,
            left: 3,
            zIndex: 100,
          }}
          spacing={1}
        >
          {date && (
            <Chip
              label={new Date(+property.date).toLocaleDateString()}
              variant={"filled"}
              size={"small"}
              color={"info"}
            />
          )}
          {property.isSold && (
            <Chip
              label={globalDictionary.get(
                "properties_list_item_selled",
                language
              )}
              variant={"filled"}
              size={"small"}
              color={"error"}
            />
          )}
        </Stack>
        <IconButton
          onClick={handleClick}
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
            zIndex: 100,
          }}
          size="large"
        >
          <MoreVert
            sx={{
              color: "rgba(255,255,255,0.8)",
            }}
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuItems.map(
            (menuItem) =>
              menuItem.showCondition && (
                <MenuItem
                  key={menuItem.id}
                  onClick={menuItem.onClick || handleClose}
                  component={menuItem.active ? Link : undefined}
                  to={menuItem.to}
                  style={{ width: "100%" }}
                >
                  <Typography color={menuItem.active ? "#0288d1" : "#888888"}>
                    {menuItem.label}
                  </Typography>
                </MenuItem>
              )
          )}
        </Menu>

        <Link
          to={`/properties/${property._id}`}
          style={{
            overflow: "hidden",
          }}
          onClick={(event) => {
            if (!isCommunityUser) {
              event.preventDefault();
              navigateToSecondaryAuth();
            }
          }}
        >
          <ImageChecker
            src={propertyImage}
            alt={name}
            variant={"rounded"}
            sx={{
              width: "100%",
              height: 200,
            }}
            imgProps={{
              objectfit: "cover",
              transition: ".5s",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            text={name}
          />
        </Link>
        <CardContent
          sx={{
            paddingTop: "5px",
            paddingBottom: 0,
            flex: 1,
            width: "100%",
          }}
        >
          <Typography
            gutterBottom
            fontSize={"1.2em"}
            variant="h6"
            component="h3"
            sx={{
              marginBottom: 0,
            }}
          >
            {name}
          </Typography>
          {price && currency && (
            <div className={style.priceInfo}>
              <Typography
                fontSize={"1.1em"}
                fontWeight={600}
                variant="h5"
                color={"secondary"}
              >
                {`${numberFormatter(price)} ₼`}
              </Typography>
            </div>
          )}
          {countries.get(country, "eng") && city ? (
            <Typography>
              {`${countries.get(country, "eng")}, ${city}`}
            </Typography>
          ) : (
            <Typography>- ,{city}</Typography>
          )}
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginTop: 1,
            }}
          >
            {type && (
              <Chip
                label={`${globalDictionary.get(
                  "properties_list_item_type",
                  language
                )}: ${type}`}
                variant="outlined"
              />
            )}
            {!!spaceAll && (
              <Chip
                label={`${spaceAll} ${globalDictionary.get(
                  "properties_list_item_sqm",
                  language
                )}`}
                variant="outlined"
              />
            )}
            {!!floor && (
              <Chip
                label={`${globalDictionary.get(
                  "properties_list_item_floor",
                  language
                )}: ${floor}`}
                variant="outlined"
              />
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              if (isCommunityUser) {
                navigate(`/properties/${property._id}`);
              } else {
                navigateToSecondaryAuth();
              }
            }}
          >
            {globalDictionary.get("properties_list_item_more", language)}
          </Button>
          {isOwn && (
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                if (isCommunityUser) {
                  navigate(`/properties/edit/${property._id}`);
                  //window.location.href = `https://cabinet.remaz.az/#/property/edit/${property._id}`;
                } else {
                  navigateToSecondaryAuth();
                }
              }}
            >
              {globalDictionary.get("properties_list_item_edit", language)}
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}

export default PropertyItem;
