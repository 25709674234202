import io from 'socket.io-client';
import {wsServer} from '../config';

const NotificationSocketService = {
    socket: null,

    async init(token) {
        return new Promise((resolve, reject) => {
            if (token) {
                this.socket = io(`${wsServer}/notification`, {
                    transports: ['websocket'],
                    query: {
                        token,
                    },
                });

                this.socket.on('connect', () => {
                    resolve(this);
                });

                this.socket.on('error', (error) => {
                    console.error(error);
                    reject(error);
                });

                this.socket.on('connect_error', (error) => {
                    console.error(error);
                    reject(error);
                });
            }
        });
    },
};

export default NotificationSocketService;
