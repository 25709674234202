import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { getUserToken } from './index';
import {useContext} from "react";
import {StoreContext} from "../Providers";
import {History} from './NavigationHistoryUtils';
export const ProtectedRoute = ({ children }) => {
    const userToken = getUserToken();
    if (!userToken) return <Navigate to="/auth/login" replace />;
    return children;
};

export const CommunityProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const {auth} = useContext(StoreContext);
    const commId = auth.user ? auth.user.commId : null;
    if (commId === null) {
        History.navigate("/profile");
    }
    if (commId === "pending" && location.pathname!=='/my/profile/secondary/progress') {
        History.navigate("/my/profile/secondary/progress");
    }
    return children;
};

export const NoProtectedRoute = ({ children }) => {

    const userToken = getUserToken();
    if (userToken) return <Navigate to="/my/profile" replace />;
    return children;
};
