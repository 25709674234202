import globalDictionary from "assets/translations/globalDictionary";
import {Alert, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import React from "react";
import {getActiveLanguageFromLS} from "utils/index";

async function RequestsValidator(result, dictionaryKey, callback ,errorCallback) {
    try {
        const language = getActiveLanguageFromLS();
        if (result.code === 200) {
            if (callback) {
                callback();
            }
        }
        if (result.code === 200 && isEmpty(result.data)) {
            if (dictionaryKey !== "" && dictionaryKey) {
                result.message = globalDictionary.get(dictionaryKey, language);
            } else {
                result.message = (
                    <Typography variant={'h6'} align={'center'}>
                        {globalDictionary.get("information_not_found", language)}
                    </Typography>
                )
            }

        }
        if (result.code !== 200) {
            if (dictionaryKey !== "" && dictionaryKey) {
                result.message = globalDictionary.get(dictionaryKey, language);
            } else {
                result.message = (
                    <Alert severity="info" sx={{marginTop: 3, marginBottom: 1}}>
                        {globalDictionary.get("contact_support", language)}
                        <Link to={"/support/tickets"} style={{textDecoration: "underline" , marginLeft:'5px'} }>

                            {globalDictionary.get("support_service", language)}
                        </Link>
                    </Alert>
                );
            }
            if(errorCallback){
                result.callback = errorCallback;
            }
        }

        return result;
    } catch (error) {
        console.error("Error in RequestsValidator:", error);
    }
}

export default RequestsValidator;
