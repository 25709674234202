import React, { useState } from "react";
import gremLogo from "assets/images/logo.webp";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Toolbar } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { observer } from "mobx-react-lite";
import { getActiveLanguageFromLS } from "utils/index";
import globalDictionary from "assets/translations/globalDictionary";
import HeaderNotificationMenu from "./components/HeaderNotificationMenu/HeaderNotificationMenu";
import HeaderProfileMenu from "./components/HeaderProfileMenu/HeaderProfileMenu";
import HeaderLanguageMenu from "./components/HeaderLanguageMenu/HeaderLanguageMenu";
import { findFlagUrlByCountryName } from "country-flags-svg";

const Header = observer(
  ({ handleDrawerOpen, open = false, drawerWidth = 240 }) => {
    const [language, setLanguage] = useState(getActiveLanguageFromLS());
    const ukr = findFlagUrlByCountryName("Ukraine");
    const rus = findFlagUrlByCountryName("Russia");
    const eng = findFlagUrlByCountryName("United Kingdom");
    const deu = findFlagUrlByCountryName("Germany");
    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));

    return (
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "white",
          boxShadow: "0 0 5px 1px #f3f3f3",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={() => {
                handleDrawerOpen();
              }}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <a href={"https://remaz.az"} target={"_blank"}>
              <img
                src={gremLogo}
                height={50}
                alt={globalDictionary.get(
                  "layout_cabinet_header_logo",
                  language
                )}
              />
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <HeaderLanguageMenu />
            <HeaderNotificationMenu />
            <HeaderProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
);

export default Header;
