import React, {useContext, useEffect, useState} from "react";
import {Badge, Box, CircularProgress, IconButton, List, ListItem, Menu, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {styled} from '@mui/material/styles';
import {GremService} from "services";
import {StoreContext} from "Providers";
import {Pagination} from "@mui/lab";
import {getUserToken} from "utils/index";
import globalDictionary from "../../../../../../assets/translations/globalDictionary";
import {getActiveLanguageFromLS} from 'utils/index';
const HeaderNotificationMenu = observer(() => {
    const language = getActiveLanguageFromLS();
    const gremService = new GremService();
    const { auth, websocket } = useContext(StoreContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [notifications, setNotifications] = useState({
        loading: true,
        data: [],
        unreadCount: 0,
        currentPage: 1,
        totalPages: null,
        pageLimit: 10,
    });

    useEffect(() => {
        if (auth?.user) {
            getNotifications();
        }

        if (websocket?.notification?.socket) {
            const notificationSocket = websocket.notification.socket;

            const handleNotification = async () => {
                if (getUserToken()) {
                    await getNotifications();
                }
            };

            notificationSocket.on('notification', handleNotification);

            return () => {
                notificationSocket.off('notification', handleNotification);
            };
        }
    }, [auth?.user, websocket?.notification]);

    const getNotifications = async ({
                                        currentPage = notifications.currentPage,
                                        pageLimit = notifications.pageLimit,
                                    } = {}) => {
        setNotifications({ loading: true, data: [] });

        if (!auth?.user._id) {
            return;
        }

        const userId = auth.user._id;
        const result = await gremService.getUserNotifications(userId, currentPage, pageLimit);

        setTimeout(() => {
            if (result?.code !== 200) {
                setNotifications({ loading: false, data: [] });
                return;
            }

            const notificationList = result?.data?.notifications?.map((notification) => notification?.notifications[0]) || [];
            const unreadCount = notificationList?.filter((notification) => !notification?.read?.read)?.length || 0;
            const totalPages = result?.data?.totalPages || 1;

            setNotifications({ loading: false, data: notificationList, unreadCount, currentPage, totalPages });
        }, 500);
    };

    const HoverableListItem = styled(ListItem)(({theme}) => ({
        marginTop: 5,
        marginBottom: 5,
        paddingTop: 5,
        paddingBottom: 5,
        cursor: 'pointer',
        transition: '.1s',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const setAllNotificationsRead = async () => {
        const userId = auth?.user?._id;
        const result = await gremService.setAllUserNotificationsRead(userId);
        if (result?.code !== 200) return;
        await getNotifications();
    }

    const getNotificationsPage = async (event, value) => {
        await getNotifications(value);
    }

    const NotificationItem = (
        {
            notification = {}
        }
    ) => {
        const date = new Date(notification?.received?.receivedTime) || new Date();
        const isRead = notification?.read?.read || false;
        const {title = '-', description = '-'} = notification?.content || '-';
        const priorityStatusColor = notification?.priority === 'high' ? 'error.light' : (notification?.priority === 'medium' ? 'warning.light' : 'info.light');

        return (<HoverableListItem sx={{
            display: 'flex', flexDirection: 'column', gap: 1, padding: '5px 25px', boxSizing: 'border-box'
        }}>
            <Box sx={{
                width: '100%', display: 'flex', justifyContent: 'space-between',
            }}>
                <Typography variant={'body2'} component={'span'} color={'text.secondary'} fontSize={'0.8em'}>
                    {date.toLocaleString()}
                </Typography>
                {
                    !isRead && (
                        <IconButton size={'small'}>
                            <Box sx={{
                                width: 7, height: 7, backgroundColor: priorityStatusColor, borderRadius: '50%',
                            }}></Box>
                        </IconButton>
                    )
                }
            </Box>
            <Box sx={{
                width: '100%'
            }}>
                <Typography fontSize={'0.85em'} fontWeight={500} sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{
                width: '100%'
            }}>
                <Typography fontSize={'0.95em'} sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                }}>
                    {description}
                </Typography>
            </Box>
        </HoverableListItem>);
    }

    const handleOpen = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                size="small"
                sx={{ml: 2}}
            >
                <Badge badgeContent={notifications.unreadCount} color="error">
                    <NotificationsIcon/>
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="notification-menu"
                open={openMenu}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        right: 10,
                    }, elevation: 0, sx: {
                        height: '100vh',
                        maxHeight: 600,
                        width: 450,
                        top: "50px !important",
                        overflow: "hidden",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 2.5,
                        display: 'flex',
                        justifyContent: 'stretch',
                        alignItems: 'center',
                    },
                }}
            >
                {
                    notifications?.loading ? (
                        <Box sx={{
                            width: 450,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <CircularProgress color={'secondary'}/>
                        </Box>
                    ) : (
                        <Box sx={{
                            height: '100vh',
                            maxHeight: 600,
                            width: '100%',
                            overflowY: 'auto',
                            pb: 2
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 1,
                                p: 2,
                            }}>
                                <Typography variant={"h6"}>
                                   {globalDictionary.get('profile_settings_notifications_title',language)}
                                </Typography>
                                <IconButton onClick={setAllNotificationsRead}>
                                    <Typography variant={'body2'} component={'span'} color={'text.secondary'}
                                                fontSize={'0.55em'}>
                                          {globalDictionary.get('mark_all_as_read_translation',language)}
                                    </Typography>
                                </IconButton>
                            </Box>
                            {
                                notifications?.data?.length === 0 ? (
                                    <Box sx={{
                                        width: '100%',
                                        height: '25%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <Typography variant={'body2'} component={'span'} color={'text.secondary'}>
                                            No notifications
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                        <List sx={{
                                            width: '100%',
                                        }}>
                                            {notifications?.data?.map( (notification , index) => <NotificationItem
                                            key={index}
                                                notification={notification}/>)}
                                        </List>
                                        <Pagination count={notifications.totalPages} page={notifications.currentPage}
                                                    onChange={getNotificationsPage} size="small"/>
                                    </Box>
                                )
                            }
                        </Box>
                    )
                }
            </Menu>
        </>
    );
});

export default HeaderNotificationMenu;
