import PropTypes from 'prop-types';
import {Draggable, Droppable} from '@hello-pangea/dnd';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {alpha} from '@mui/material/styles';

/*import {
  createTask,
  updateTask,
  deleteTask,
  clearColumn,
  updateColumn,
  deleteColumn,
} from 'src/api/kanban';*/

import KanbanTaskItem from './kanban-task-item';
import KanbanColumnToolBar from './kanban-column-tool-bar';

// ----------------------------------------------------------------------

export default function KanbanColumn({column, tasks, isTaskMove, index}) {
    return (
        <Draggable draggableId={column.id} index={index} isDragDisabled={true}>
            {(provided, snapshot) => (
                <Paper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{
                        px: 2,
                        borderRadius: 2,
                        bgcolor: 'background.neutral',
                        ...(snapshot.isDragging && {
                            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.24),
                        }),
                    }}
                >
                    <Stack {...provided.dragHandleProps}>
                        <KanbanColumnToolBar
                            columnName={column.name}
                        />

                        <Droppable droppableId={column.id} type="TASK">
                            {(dropProvided) => (
                                <Stack
                                    ref={dropProvided.innerRef}
                                    {...dropProvided.droppableProps}
                                    spacing={2}
                                    sx={{
                                        py: 3,
                                        width: 280,
                                    }}
                                >
                                    {column.taskIds.map((taskId, taskIndex) => (
                                        <KanbanTaskItem
                                            key={taskId}
                                            index={taskIndex}
                                            task={tasks[taskId]}
                                            isTaskMove={isTaskMove}
                                        />
                                    ))}
                                    {dropProvided.placeholder}
                                </Stack>
                            )}
                        </Droppable>
                    </Stack>
                </Paper>
            )}
        </Draggable>
    );
}

KanbanColumn.propTypes = {
    column: PropTypes.object,
    index: PropTypes.number,
    tasks: PropTypes.object,
    isTaskMove: PropTypes.bool,
};
