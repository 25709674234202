import {History} from '../utils/NavigationHistoryUtils';
import {snackActions} from '../utils/SnackBarUtils';
import {setUserInfoToLocalStorage} from '../utils/index';

export const validateResponseData = (responseData) => {
    const {errors = []} = responseData;
    if (errors.length === 0) return responseData;

    const authError = errors.some((error) => error.errorCode === 404003);

    if (authError) {
        setUserInfoToLocalStorage(null);
        snackActions.error('Your session has expired. Please log in again.');
        History.navigate('/auth/logout');
    } else {
        if(errors[0].message){
        snackActions.error(errors[0].message);
        }
        if(errors[0].errorMessage){
        snackActions.error(errors[0].errorMessage);
        }
    }

    return responseData;
}
