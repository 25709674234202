import React, {useState} from "react";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import globalDictionary from "assets/translations/globalDictionary";
import {getActiveLanguageFromLS, setActiveLanguageToLS,} from "utils";
import {findFlagUrlByCountryName} from "country-flags-svg";

const HeaderLanguageMenu = observer(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [language, setLanguage] = useState(getActiveLanguageFromLS());
  const flags = {
    ukr: findFlagUrlByCountryName("Ukraine"),
    rus: findFlagUrlByCountryName("Russia"),
    eng: findFlagUrlByCountryName("United Kingdom"),
    deu: findFlagUrlByCountryName("Germany"),
  };
  
  const setNewLanguage = (language) => {
    setActiveLanguageToLS(language);
    setLanguage(language);
    window.location.reload();
  };

  const handleMenuOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <img
        style={{ marginLeft: "10px", padding:'1px' , background:'grey' }}
        src={flags[language]}
        height={15}
        alt={globalDictionary.get("layout_cabinet_header_logo", language)}
      />
      <IconButton
        onClick={handleMenuOpen}
        size="small"
        sx={{ ml: 2, marginLeft: "5px" }}
        aria-controls={openMenu ? "lang-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
      >
        <Typography>
          {globalDictionary.get(`layout_lang_menu_${language}`, language)}
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="lang-menu"
        open={openMenu}
        onMouseLeave={handleMenuClose}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        left={0}
        PaperProps={{
          style: {
            right: 140,
          },
          elevation: 0,
          sx: {
            left: "initial !important",
            top: "50px !important",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem
          sx={{ display: "flex", justifyContent: "flex-start" }}
          onClick={() => setNewLanguage("eng")}
        >
          <img
            style={{
              marginRight: "5px",
              padding: "1px",
              background: "grey",
            }}
            src={flags.eng}
            height={15}
            alt={globalDictionary.get("layout_cabinet_header_logo", language)}
          />
          {globalDictionary.get("layout_lang_menu_eng", language)}
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", justifyContent: "flex-start" }}
          onClick={() => setNewLanguage("ukr")}
        >
          <img
            style={{
              marginRight: "5px",
              padding: "1px",
              background: "grey",
            }}
            src={flags.ukr}
            width={25}
            height={15}
            alt={globalDictionary.get("layout_cabinet_header_logo", language)}
          />
          {globalDictionary.get("layout_lang_menu_ukr", language)}
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", justifyContent: "flex-start" }}
          onClick={() => setNewLanguage("rus")}
        >
          <img
            style={{
              marginRight: "5px",
              padding: "0.8px",
              background: "grey",
            }}
            src={flags.rus}
            height={17}
            alt={globalDictionary.get("layout_cabinet_header_logo", language)}
          />
          {globalDictionary.get("layout_lang_menu_rus", language)}
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", justifyContent: "flex-start" }}
          onClick={() => setNewLanguage("deu")}
        >
          <img
            style={{
              marginRight: "5px",
              padding: "1px",
              background: "grey",
            }}
            src={flags.deu}
            height={15}
            alt={globalDictionary.get("layout_cabinet_header_logo", language)}
          />
          {globalDictionary.get("layout_lang_menu_deu", language)}
        </MenuItem>
      </Menu>
    </>
  );
});

export default HeaderLanguageMenu;
