import React from 'react';
import { Typography, Card, CardContent, CardMedia,Button } from '@mui/material';
import styled from '@mui/system/styled';


const CardWrapper = styled(Card)`
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Media = styled(CardMedia)`
margin:10px;
height: 100px; 
width: 100px;

`;
const Content = styled(CardContent)`
display: flex;
  flex-grow: 1; 
  flex-direction:row;
`;
const Typogapf = styled(Typography)`
align-items: center;
display: flex;
margin:5px;
max-width: '20ch'
`;
const useStyles = styled((theme) => ({
  cardContent: {

    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));


const CompactComponent = ({ data , onRemove }) => {
  const classes = useStyles();
  return (

    <CardWrapper>
    {data.images   && (
      <Media
        component="img"
        alt={data.type}
        image={data.images }
      />
    )}
        { data.apartmentPlan  && (
      <Media
        component="img"
        alt={data.type}
        image={ data.apartmentPlan}
      />
    )}
    <Content>
      {data.type && (
        <Typogapf variant="h4">{`Type: ${data.type}`}</Typogapf>
      )}
      {data.roomsNumber && (
        <Typogapf variant="h7">{`Number of rooms: ${data.roomsNumber}`}</Typogapf>
      )}
      {data.condition && (
        <Typogapf variant="h7">{`Condition: ${data.condition}`}</Typogapf>
      )}
      {data.area && (
        <Typogapf variant="h7">{`Area: ${data.area}`}</Typogapf>
      )}
      {data.floor && (
        <Typogapf variant="h7">{`Floor: ${data.floor}`}</Typogapf>
      )}
           {data.number && (
        <Typogapf variant="h4">{`Floor: ${data.number}`}</Typogapf>
      )}

      {data.price && data.currency && (
        <Typogapf variant="h7">{`Price: ${data.price} ${data.currency}`}</Typogapf>
      )}
        {data.text  && (
        <Typogapf variant="h4"   style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "20ch",
        }}>{`News: ${data.news}`}</Typogapf>
      )}
        {data.date && (
        <Typogapf variant="h7">{`Date: ${data.date}`}</Typogapf>
      )}
    </Content>
    <Button sx={{marginRight:'10px', }} color='error' variant='outlined' onClick={onRemove}>remove</Button>
   </CardWrapper>

  );
};

export default CompactComponent;
