import React from 'react';
import {Box, Typography, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {getActiveLanguageFromLS} from 'utils/index';
import globalDictionary from 'assets/translations/globalDictionary';

function KYCWarning(){
    const language = getActiveLanguageFromLS();

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1
        }}>
            <Typography variant={'h6'}>{globalDictionary.get('profile_kyc_warning_text', language)}</Typography>
            <Link to={'/my/profile/kyc/add'}>
                <Button variant={'contained'}>{globalDictionary.get('profile_kyc_warning_button', language)}</Button>
            </Link>
        </Box>
    );
}

export default KYCWarning;
