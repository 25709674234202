import React, {useContext, useState} from "react";
import {Avatar, CircularProgress, Divider, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import globalDictionary from "assets/translations/globalDictionary";
import {getActiveLanguageFromLS, isCommunity} from "utils";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import {StoreContext} from "Providers";

const HeaderProfileMenu = observer(
    () => {
        const language = getActiveLanguageFromLS();
        const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = Boolean(anchorEl);
        const {auth} = useContext(StoreContext);
        const isCommunityUser = isCommunity(auth.user);

        const handleMenuOpen = (event) => {
            if (anchorEl !== event.currentTarget) {
                setAnchorEl(event.currentTarget);
            }
        }

        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <Tooltip
                    title={auth.user ? `${auth.user.fname} ${auth.user.lname}` : ""}
                >
                    <IconButton
                        onClick={handleMenuOpen}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={openMenu ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                    >
                        {!auth?.user ? (
                            <CircularProgress/>
                        ) : (
                            <Avatar
                                sx={
                                    {
                                        width: 32,
                                        height: 32,
                                        '& img': {
                                            objectFit: 'contain'
                                        }
                                    }
                                }
                                src={
                                    isCommunityUser && auth.user.commItem
                                        ? auth.user.commItem.img
                                        : null
                                }
                            />
                        )}
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    left={0}
                    PaperProps={{
                        style: {
                            right: 26,
                        },
                        elevation: 0,
                        sx: {
                            left: "initial !important",
                            top: "50px !important",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                >
                    <MenuItem
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0px",
                            minHeight: "32px",
                            width: "100%",
                        }}
                    >
                        <Link
                            to={"/"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "inherit",
                                width: "100%",
                                justifyContent: "space-around",
                                padding: "5px",
                            }}
                        >
                            {globalDictionary.get(
                                "layout_cabinet_header_profile_menu_my",
                                language
                            )}
                        </Link>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{minHeight: "32px", width: "100%"}}>
                        <Link
                            to={"/auth/logout"}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "inherit",
                            }}
                        >
                            <ListItemIcon>
                                <Logout fontSize="small"/>
                            </ListItemIcon>
                            {globalDictionary.get(
                                "layout_cabinet_header_profile_menu_exit",
                                language
                            )}
                        </Link>
                    </MenuItem>
                </Menu>
            </>
        );
    }
);

export default HeaderProfileMenu;
