const ImageConfigs = {
    defaultConfigs:{field:'photo',titleKey:'image_uploader_title',minCount:0,count:1,size:5},
    complexConfigs:{
        mainPhoto:{
            minCount: 1,
            maxCount: 1,
            size: 15,
        },
        photos:{
            minCount: 6,
            maxCount: 25,
            size: 10,
        },
        documentsLand:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        documentsConstruction:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        constructionParticipants:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        images:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        }
    },
    apartConfigs:{
        images:{
            minCount:1,
            maxCount:20,
            size:10
        }
    },
    progressConfigs:{
        images:{
            minCount:0,
            maxCount:20,
            size:10
        }
    },
    projectsConfig:{
        mainPhoto:{
            minCount: 1,
            maxCount: 1,
            size: 15,
        },
        photos:{
            minCount: 6,
            maxCount: 25,
            size: 10,
        },
        ownershipDocuments:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        projectDocumentation:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        buildingPermit:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        businessPlan:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        dueDiligence:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        restrictions:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        }
    },
    propertiesConfigs:{
        mainPhoto:{
            minCount: 1,
            maxCount: 1,
            size: 15,
        },
        photos:{
            minCount: 6,
            maxCount: 25,
            size: 10,
        },
        documentsPhoto:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        exclusiveAgreements:{
            minCount: 1,
            maxCount: 20,
            size: 10,
        }
    },
    get: function (name, field) {
        let config = this[name];
        const fieldParts = field.split('.');
        config = config.length === 0 ? config[fieldParts[0]] :config[fieldParts[fieldParts.length-1]];
        return config;
    }
}

export default ImageConfigs;