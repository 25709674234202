import React, { Fragment } from 'react';
import {Alert, AlertTitle, Box, Button, Grid, IconButton, Modal, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GlobalDictionary from '../../assets/translations/globalDictionary';
import { getActiveLanguageFromLS } from 'utils';

const ModalError = ({ closeModal, title = 'error_main', message }) => {
    const globalDictionary = GlobalDictionary;
    const language = getActiveLanguageFromLS();

    const modalStyles = {
        modal: {
            outline: 'none',
            p: 2,
            bgcolor: 'white',
            boxShadow: 'none',
            width: '360px',
            height: 'auto',
            borderRadius: '10px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        message: {
            textAlign: 'center',
        },
    };

    return (
        <Modal
            open={true}
            onClose={closeModal}
            style={{
                backdropFilter: 'blur(2px)',
                backgroundColor: 'rgba(255, 255, 255, 0.35)',
            }}
        >
            <Box sx={modalStyles.modal}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant={'h6'}>

                        {globalDictionary.get(title, language)}
                    </Typography>
                </Grid>
                <IconButton
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </Grid>

                    <Grid item xs={12}>
                        <Alert severity={'error'}>
                            {message}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={'contained'} onClick={closeModal} fullWidth>
                            <Typography variant={'body1'}>
                                {globalDictionary.get('refresh', language)}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalError;
