const countries = {
  alpha2Standard: [
    {
      code: "All countries",
      value: "",
      labelEn: "All countries",
      labelRu: "Все страны",
      labelCh: "所有國家",
      labelFr: "Tous les pays",
      labelSp: "Todos los países",
      labelUa: "Всі країни",
    },
    {
      code: "AF",
      value: "AF",
      labelEn: "Afghanistan",
      labelRu: "Афганистан",
      labelCh: "阿富汗",
      labelFr: "Afghanistan",
      labelSp: "Afganistán",
      labelUa: "Афганістан",
    },
    {
      code: "AX",
      value: "AX",
      labelEn: "Åland Islands",
      labelRu: "Аландские острова",
      labelCh: "奥兰",
      labelFr: "Åland",
      labelSp: "Islas Aland",
      labelUa: "Аландські острови",
    },
    {
      code: "AL",
      value: "AL",
      labelEn: "Albania",
      labelRu: "Албания",
      labelCh: "阿尔巴尼亚",
      labelFr: "Albanie",
      labelSp: "Albania",
      labelUa: "Албанія",
    },
    {
      code: "DZ",
      value: "DZ",
      labelEn: "Algeria",
      labelRu: "Алжир",
      labelCh: "阿尔及利亚",
      labelFr: "Algérie",
      labelSp: "Argelia",
      labelUa: "Алжир",
    },
    {
      code: "AS",
      value: "AS",
      labelEn: "American Samoa",
      labelRu: "Американское Самоа",
      labelCh: "美属萨摩亚",
      labelFr: "Samoa américaines",
      labelSp: "Samoa Americana",
      labelUa: "Американське Самоа",
    },
    {
      code: "AD",
      value: "AD",
      labelEn: "Andorra",
      labelRu: "Андорра",
      labelCh: "安道尔",
      labelFr: "Andorre",
      labelSp: "Andorra",
      labelUa: "Андорра",
    },
    {
      code: "AO",
      value: "AO",
      labelEn: "Angola",
      labelRu: "Ангола",
      labelCh: "安哥拉",
      labelFr: "Angola",
      labelSp: "Angola",
      labelUa: "Ангола",
    },
    {
      code: "AI",
      value: "AI",
      labelEn: "Anguilla",
      labelRu: "Ангилья",
      labelCh: "安圭拉",
      labelFr: "Anguilla",
      labelSp: "Anguila",
      labelUa: "Ангілья",
    },
    {
      code: "AQ",
      value: "AQ",
      labelEn: "Antarctica",
      labelRu: "Антарктика",
      labelCh: "南极洲",
      labelFr: "Antarctique",
      labelSp: "Antártida",
      labelUa: "Антарктида",
    },
    {
      code: "AG",
      value: "AG",
      labelEn: "Antigua and Barbuda",
      labelRu: "Антигуа и Барбуда",
      labelCh: "安提瓜和巴布达",
      labelFr: "Antigua-et-Barbuda",
      labelSp: "Antigua y Barbuda",
      labelUa: "Антигуа і Барбуда",
    },
    {
      code: "AR",
      value: "AR",
      labelEn: "Argentina",
      labelRu: "Аргентина",
      labelCh: "阿根廷 ",
      labelFr: "Argentine",
      labelSp: "Argentina",
      labelUa: "Аргентина",
    },
    {
      code: "AM",
      value: "AM",
      labelEn: "Armenia",
      labelRu: "Армения",
      labelCh: "亚美尼亚",
      labelFr: "Arménie",
      labelSp: "Armenia",
      labelUa: "Вірменія",
    },
    {
      code: "AW",
      value: "AW",
      labelEn: "Aruba",
      labelRu: "Аруба",
      labelCh: "阿鲁巴",
      labelFr: "Aruba",
      labelSp: "Aruba",
      labelUa: "Аруба",
    },
    {
      code: "AU",
      value: "AU",
      labelEn: "Australia",
      labelRu: "Австралия",
      labelCh: "澳大利亚",
      labelFr: "Australie",
      labelSp: "Australia",
      labelUa: "Австралія",
    },
    {
      code: "AT",
      value: "AT",
      labelEn: "Austria",
      labelRu: "Австрия",
      labelCh: "奥地利",
      labelFr: "Autriche",
      labelSp: "Austria",
      labelUa: "Австрія",
    },
    {
      code: "AZ",
      value: "AZ",
      labelEn: "Azerbaijan",
      labelRu: "Азербайджан",
      labelCh: "阿塞拜疆",
      labelFr: "Azerbaïdjan",
      labelSp: "Azerbaiyán",
      labelUa: "Азербайджан",
    },
    {
      code: "BS",
      value: "BS",
      labelEn: "Bahamas",
      labelRu: "Багамы",
      labelCh: "巴哈马",
      labelFr: "Bahamas",
      labelSp: "Bahamas",
      labelUa: "Багамські острови",
    },
    {
      code: "BH",
      value: "BH",
      labelEn: "Bahrain",
      labelRu: "Бахрейн",
      labelCh: "巴林",
      labelFr: "Bahreïn",
      labelSp: "Bahrein",
      labelUa: "Бахрейн",
    },
    {
      code: "BD",
      value: "BD",
      labelEn: "Bangladesh",
      labelRu: "Бангладеш",
      labelCh: "孟加拉国",
      labelFr: "Bangladesh",
      labelSp: "Bangladesh",
      labelUa: "Бангладеш",
    },
    {
      code: "BB",
      value: "BB",
      labelEn: "Barbados",
      labelRu: "Барбадос",
      labelCh: "巴巴多斯",
      labelFr: "Barbade",
      labelSp: "Barbados",
      labelUa: "Барбадос",
    },
    {
      code: "BY",
      value: "BY",
      labelEn: "Belarus",
      labelRu: "Беларусь",
      labelCh: "白罗斯",
      labelFr: "Biélorussie",
      labelSp: "Bielorrusia",
      labelUa: "Білорусь",
    },
    {
      code: "BE",
      value: "BE",
      labelEn: "Belgium",
      labelRu: "Бельгия",
      labelCh: "比利时",
      labelFr: "Belgique",
      labelSp: "Bélgica",
      labelUa: "Бельгія",
    },
    {
      code: "BZ",
      value: "BZ",
      labelEn: "Belize",
      labelRu: "Белиз",
      labelCh: "伯利兹",
      labelFr: "Belize",
      labelSp: "Belice",
      labelUa: "Беліз",
    },
    {
      code: "BJ",
      value: "BJ",
      labelEn: "Benin",
      labelRu: "Бенин",
      labelCh: "贝宁",
      labelFr: "Bénin",
      labelSp: "Benin",
      labelUa: "Бенін",
    },
    {
      code: "BM",
      value: "BM",
      labelEn: "Bermuda",
      labelRu: "Бермуды",
      labelCh: "百慕大群岛",
      labelFr: "Bermudes",
      labelSp: "Bermuda",
      labelUa: "Бермудські острови",
    },
    {
      code: "BT",
      value: "BT",
      labelEn: "Bhutan",
      labelRu: "Бутан",
      labelCh: "不丹",
      labelFr: "Bhoutan",
      labelSp: "Bután",
      labelUa: "Бутан",
    },
    {
      code: "BO",
      value: "BO",
      labelEn: "Bolivia, plurinational state of",
      labelRu: "Боливия, Многонациональное Государство",
      labelCh: "玻利维亚",
      labelFr: "Bolivie ,  l'État plurinational de",
      labelSp: "Estado plurinacional de Bolivia",
      labelUa: "Болівія, Багатонаціональна Держава",
    },
    {
      code: "BA",
      value: "BA",
      labelEn: "Bosnia and Herzegovina",
      labelRu: "Босния и Герцеговина",
      labelCh: "波斯尼亚和黑塞哥维那",
      labelFr: "Bosnie-Herzégovine",
      labelSp: "Bosnia y Herzegovina",
      labelUa: "Боснія і Герцеговина",
    },
    {
      code: "BW",
      value: "BW",
      labelEn: "Botswana",
      labelRu: "Ботсвана",
      labelCh: "博茨瓦纳",
      labelFr: "Botswana",
      labelSp: "Botsuana",
      labelUa: "Ботсвана",
    },
    {
      code: "BV",
      value: "BV",
      labelEn: "Bouvet Island",
      labelRu: "Остров Буве",
      labelCh: "布韦岛",
      labelFr: "île Bouvet",
      labelSp: "Isla Bouvet",
      labelUa: "Блошиний острів",
    },
    {
      code: "BR",
      value: "BR",
      labelEn: "Brazil",
      labelRu: "Бразилия",
      labelCh: "巴西",
      labelFr: "Brésil",
      labelSp: "Brasil",
      labelUa: "Бразилія",
    },
    {
      code: "IO",
      value: "IO",
      labelEn: "British Indian Ocean Territory",
      labelRu: "Британская территория в Индийском океане",
      labelCh: "英属印度洋领土",
      labelFr: "Territoire britannique de l'océan Indien",
      labelSp: "Territorio Británico del Océano Índico",
      labelUa: "Британська територія в Індійському океані",
    },
    {
      code: "BN",
      value: "BN",
      labelEn: "Brunei Darussalam",
      labelRu: "Бруней-Даруссалам",
      labelCh: "文莱达鲁萨兰国",
      labelFr: "Brunei Darussalam",
      labelSp: "Brunei Darussalam",
      labelUa: "Бруней-Даруссалам",
    },
    {
      code: "BG",
      value: "BG",
      labelEn: "Bulgaria",
      labelRu: "Болгария",
      labelCh: "保加利亚",
      labelFr: "Bulgarie",
      labelSp: "Bulgaria",
      labelUa: "Болгарія",
    },
    {
      code: "BF",
      value: "BF",
      labelEn: "Burkina Faso",
      labelRu: "Буркина-Фасо",
      labelCh: "布基纳法索",
      labelFr: "Burkina Faso",
      labelSp: "Burkina Faso",
      labelUa: "Буркіна-Фасо",
    },
    {
      code: "BI",
      value: "BI",
      labelEn: "Burundi",
      labelRu: "Бурунди",
      labelCh: "布隆迪",
      labelFr: "Burundi",
      labelSp: "Burundi",
      labelUa: "Бурунді",
    },
    {
      code: "KH",
      value: "KH",
      labelEn: "Cambodia",
      labelRu: "Камбоджа",
      labelCh: "柬埔寨",
      labelFr: "Cambodge",
      labelSp: "Camboya",
      labelUa: "Камбоджа",
    },
    {
      code: "CM",
      value: "CM",
      labelEn: "Cameroon",
      labelRu: "Камерун",
      labelCh: "喀麦隆",
      labelFr: "Cameroun",
      labelSp: "Camerún",
      labelUa: "Камерун",
    },
    {
      code: "CA",
      value: "CA",
      labelEn: "Canada",
      labelRu: "Канада",
      labelCh: "加拿大",
      labelFr: "Canada",
      labelSp: "Canadá",
      labelUa: "Канада",
    },
    {
      code: "CV",
      value: "CV",
      labelEn: "Cape Verde",
      labelRu: "Кабо-Верде",
      labelCh: "佛得角",
      labelFr: "Cap-Vert",
      labelSp: "Cabo Verde",
      labelUa: "Кабо-Верде",
    },
    {
      code: "KY",
      value: "KY",
      labelEn: "Cayman Islands",
      labelRu: "Острова Кайман",
      labelCh: "开曼群岛",
      labelFr: "îles Caïmans",
      labelSp: "Islas Caimán",
      labelUa: "Кайманові острови",
    },
    {
      code: "CF",
      value: "CF",
      labelEn: "Central African Republic",
      labelRu: "Центрально-Африканская Республика",
      labelCh: "中非共和国",
      labelFr: "République centrafricaine",
      labelSp: "República Centroafricana",
      labelUa: "Центрально-Африканська Республіка",
    },
    {
      code: "TD",
      value: "TD",
      labelEn: "Chad",
      labelRu: "Чад",
      labelCh: "乍得",
      labelFr: "Tchad",
      labelSp: "Chad",
      labelUa: "Чад",
    },
    {
      code: "CL",
      value: "CL",
      labelEn: "Chile",
      labelRu: "Чили",
      labelCh: "智利",
      labelFr: "Chili",
      labelSp: "Chile",
      labelUa: "Чилі",
    },
    {
      code: "CN",
      value: "CN",
      labelEn: "China",
      labelRu: "Китай",
      labelCh: "中国",
      labelFr: "Chine",
      labelSp: "China",
      labelUa: "Китай",
    },
    {
      code: "CX",
      value: "CX",
      labelEn: "Christmas Island",
      labelRu: "Остров Рождества",
      labelCh: "圣诞岛",
      labelFr: "île Christmas",
      labelSp: "Isla de Navidad",
      labelUa: "Острів Різдва",
    },
    {
      code: "CC",
      value: "CC",
      labelEn: "Cocos (Keeling) Islands",
      labelRu: "Кокосовые (Килинг) острова",
      labelCh: "科科斯群岛",
      labelFr: "îles Cocos",
      labelSp: "Islas Cocos (Keeling)",
      labelUa: "Кокосові (Кілінг) острови.",
    },
    {
      code: "CO",
      value: "CO",
      labelEn: "Colombia",
      labelRu: "Колумбия",
      labelCh: "哥伦比亚",
      labelFr: "Colombie",
      labelSp: "Colombia",
      labelUa: "Колумбія",
    },
    {
      code: "KM",
      value: "KM",
      labelEn: "Comoros",
      labelRu: "Коморы",
      labelCh: "科摩罗",
      labelFr: "Comores",
      labelSp: "Comoras",
      labelUa: "Комори",
    },
    {
      code: "CG",
      value: "CG",
      labelEn: "Congo",
      labelRu: "Конго",
      labelCh: "刚果金",
      labelFr: "Congo",
      labelSp: "Congo",
      labelUa: "Конго",
    },
    {
      code: "CD",
      value: "CD",
      labelEn: "Congo, Democratic Republic of the",
      labelRu: "Конго, Демократическая Республика",
      labelCh: "刚果布",
      labelFr: "République démocratique du Congo",
      labelSp: "República Democrática del Congo",
      labelUa: "Конго, Демократична Республіка",
    },
    {
      code: "CDR",
      value: "CDR",
      labelEn: "Congo, Democratic Republic of the",
      labelRu: "Конго, Демократическая Республика",
      labelCh: "刚果布",
      labelFr: "République démocratique du Congo",
      labelSp: "República Democrática del Congo",
      labelUa: "Конго, Демократична Республіка",
    },
    {
      code: "CK",
      value: "CK",
      labelEn: "Cook Islands",
      labelRu: "Острова Кука",
      labelCh: "库克群岛",
      labelFr: "Îles Cook",
      labelSp: "Islas Cook",
      labelUa: "Острови Хук",
    },
    {
      code: "CR",
      value: "CR",
      labelEn: "Costa Rica",
      labelRu: "Коста-Рика",
      labelCh: "哥斯达黎加",
      labelFr: "Costa Rica",
      labelSp: "Costa Rica",
      labelUa: "Коста-Ріка",
    },
    {
      code: "CI",
      value: "CI",
      labelEn: "Cote d'Ivoire",
      labelRu: "Кот д'Ивуар",
      labelCh: "科特迪瓦",
      labelFr: "Côte d’Ivoire",
      labelSp: "Costa de Marfil",
      labelUa: "Кіт д'Івуар",
    },
    {
      code: "HR",
      value: "HR",
      labelEn: "Croatia",
      labelRu: "Хорватия",
      labelCh: "克罗地亚",
      labelFr: "Croatie",
      labelSp: "Croacia",
      labelUa: "Хорватія",
    },
    {
      code: "CU",
      value: "CU",
      labelEn: "Cuba",
      labelRu: "Куба",
      labelCh: "古巴",
      labelFr: "Cuba",
      labelSp: "Cuba",
      labelUa: "Куба",
    },
    {
      code: "CY",
      value: "CY",
      labelEn: "Cyprus",
      labelRu: "Кипр",
      labelCh: "塞浦路斯",
      labelFr: "Chypre",
      labelSp: "Chipre",
      labelUa: "Кіпр",
    },
    {
      code: "CZ",
      value: "CZ",
      labelEn: "Czech Republic",
      labelRu: "Чешская Республика",
      labelCh: "捷克共和国",
      labelFr: "République tchèque",
      labelSp: "Republica checa",
      labelUa: "Чеська Республіка",
    },
    {
      code: "DK",
      value: "DK",
      labelEn: "Denmark",
      labelRu: "Дания",
      labelCh: "丹麦",
      labelFr: "Danemark",
      labelSp: "Dinamarca",
      labelUa: "Данія",
    },
    {
      code: "DJ",
      value: "DJ",
      labelEn: "Djibouti",
      labelRu: "Джибути",
      labelCh: "吉布提",
      labelFr: "Djibouti",
      labelSp: "Djibouti",
      labelUa: "Джибуты",
    },
    {
      code: "DM",
      value: "DM",
      labelEn: "Dominica",
      labelRu: "Доминика",
      labelCh: "多米尼克国",
      labelFr: "Dominique",
      labelSp: "Dominica",
      labelUa: "Домініка",
    },
    {
      code: "DO",
      value: "DO",
      labelEn: "Dominican Republic",
      labelRu: "Доминиканская Республика",
      labelCh: "多米尼加共和国",
      labelFr: "République dominicaine",
      labelSp: "República Dominicana",
      labelUa: "Домініканська республіка",
    },
    {
      code: "EC",
      value: "EC",
      labelEn: "Ecuador",
      labelRu: "Эквадор",
      labelCh: "厄瓜多尔",
      labelFr: "Équateur",
      labelSp: "Ecuador",
      labelUa: "Еквадор",
    },
    {
      code: "EG",
      value: "EG",
      labelEn: "Egypt",
      labelRu: "Египет",
      labelCh: "埃及",
      labelFr: "Égypte",
      labelSp: "Egipto",
      labelUa: "Єгипет",
    },
    {
      code: "SV",
      value: "SV",
      labelEn: "El Salvador",
      labelRu: "Эль-Сальвадор",
      labelCh: "萨尔瓦多共和国",
      labelFr: "Salvador",
      labelSp: "El Salvador",
      labelUa: "Сальвадор",
    },
    {
      code: "GQ",
      value: "GQ",
      labelEn: "Equatorial Guinea",
      labelRu: "Экваториальная Гвинея",
      labelCh: "赤道几内亚",
      labelFr: "Guinée équatoriale",
      labelSp: "Guinea Ecuatorial",
      labelUa: "Екваторіальна Гвінея",
    },
    {
      code: "ER",
      value: "ER",
      labelEn: "Eritrea",
      labelRu: "Эритрея",
      labelCh: "厄立特里亚",
      labelFr: "Érythrée",
      labelSp: "Eritrea",
      labelUa: "Еритрея",
    },
    {
      code: "EE",
      value: "EE",
      labelEn: "Estonia",
      labelRu: "Эстония",
      labelCh: "爱沙尼亚",
      labelFr: "Estonie",
      labelSp: "Estonia",
      labelUa: "Естонія",
    },
    {
      code: "ET",
      value: "ET",
      labelEn: "Ethiopia",
      labelRu: "Эфиопия",
      labelCh: "埃塞俄比亚",
      labelFr: "Éthiopie",
      labelSp: "Etiopía",
      labelUa: "Ефіопія",
    },
    {
      code: "FK",
      value: "FK",
      labelEn: "Falkland Islands (Malvinas)",
      labelRu: "Фолклендские острова (Мальвинские)",
      labelCh: "马尔维纳斯群岛",
      labelFr: "îles Falkland (îles Malouines)",
      labelSp: "Islas Malvinas (Islas Malvinas)",
      labelUa: "Фолклендські острови (Мальвінські)",
    },
    {
      code: "FO",
      value: "FO",
      labelEn: "Faroe Islands",
      labelRu: "Фарерские острова",
      labelCh: "福克兰群岛",
      labelFr: "Îles Féroé",
      labelSp: "Islas Faroe",
      labelUa: "Фарерські острови",
    },
    {
      code: "FJ",
      value: "FJ",
      labelEn: "Fiji",
      labelRu: "Фиджи",
      labelCh: "斐济",
      labelFr: "Fidji",
      labelSp: "Fiji",
      labelUa: "Фіджі",
    },
    {
      code: "FI",
      value: "FI",
      labelEn: "Finland",
      labelRu: "Финляндия",
      labelCh: "芬兰",
      labelFr: "Finlande",
      labelSp: "Finlandia",
      labelUa: "Фінляндія",
    },
    {
      code: "FR",
      value: "FR",
      labelEn: "France",
      labelRu: "Франция",
      labelCh: "法国",
      labelFr: "France",
      labelSp: "Francia",
      labelUa: "Франція",
    },
    {
      code: "GF",
      value: "GF",
      labelEn: "French Guiana",
      labelRu: "Французская Гвиана",
      labelCh: "法属圭亚那",
      labelFr: "Guyane française",
      labelSp: "Guayana Francesa",
      labelUa: "Французька Гвіана",
    },
    {
      code: "PF",
      value: "PF",
      labelEn: "French Polynesia",
      labelRu: "Французская Полинезия",
      labelCh: "法属波利尼西亚",
      labelFr: "Polynésie française",
      labelSp: "Polinesia francesa",
      labelUa: "Французька Полінезія",
    },
    {
      code: "TF",
      value: "TF",
      labelEn: "French Southern Territories",
      labelRu: "Французские Южные территории",
      labelCh: "法属南部和南极土地",
      labelFr: "Terres australes et antarctiques françaises",
      labelSp: "Territorios Franceses del Sur",
      labelUa: "Французькі Південні території",
    },
    {
      code: "GA",
      value: "GA",
      labelEn: "Gabon",
      labelRu: "Габон",
      labelCh: "加蓬",
      labelFr: "Gabon",
      labelSp: "Gabón",
      labelUa: "Габон",
    },
    {
      code: "GM",
      value: "GM",
      labelEn: "Gambia",
      labelRu: "Гамбия",
      labelCh: "冈比亚",
      labelFr: "Gambie",
      labelSp: "Gambia",
      labelUa: "Гамбія",
    },
    {
      code: "GE",
      value: "GE",
      labelEn: "Georgia",
      labelRu: "Грузия",
      labelCh: "格鲁吉亚",
      labelFr: "Géorgie",
      labelSp: "Georgia",
      labelUa: "Грузія",
    },
    {
      code: "DE",
      value: "DE",
      labelEn: "Germany",
      labelRu: "Германия",
      labelCh: "德国",
      labelFr: "Allemagne",
      labelSp: "Alemania",
      labelUa: "Німеччина",
    },
    {
      code: "GH",
      value: "GH",
      labelEn: "Ghana",
      labelRu: "Гана",
      labelCh: "加纳",
      labelFr: "Ghana",
      labelSp: "Ghana",
      labelUa: "Гана",
    },
    {
      code: "GI",
      value: "GI",
      labelEn: "Gibraltar",
      labelRu: "Гибралтар",
      labelCh: "直布罗陀",
      labelFr: "Gibraltar",
      labelSp: "Gibraltar",
      labelUa: "Гібралтар",
    },
    {
      code: "GR",
      value: "GR",
      labelEn: "Greece",
      labelRu: "Греция",
      labelCh: "希腊",
      labelFr: "Grèce",
      labelSp: "Grecia",
      labelUa: "Греція",
    },
    {
      code: "GL",
      value: "GL",
      labelEn: "Greenland",
      labelRu: "Гренландия",
      labelCh: "格陵兰岛",
      labelFr: "Groenland",
      labelSp: "Groenlandia",
      labelUa: "Гренландія",
    },
    {
      code: "GD",
      value: "GD",
      labelEn: "Grenada",
      labelRu: "Гренада",
      labelCh: "格林纳达",
      labelFr: "Grenade",
      labelSp: "Granada",
      labelUa: "Гренада",
    },
    {
      code: "GP",
      value: "GP",
      labelEn: "Guadeloupe",
      labelRu: "Гваделупа",
      labelCh: "瓜德罗普",
      labelFr: "Guadeloupe",
      labelSp: "Guadalupe",
      labelUa: "Гваделупа",
    },
    {
      code: "GU",
      value: "GU",
      labelEn: "Guam",
      labelRu: "Гуам",
      labelCh: "关岛",
      labelFr: "Guam",
      labelSp: "Guam",
      labelUa: "Гуам",
    },
    {
      code: "GT",
      value: "GT",
      labelEn: "Guatemala",
      labelRu: "Гватемала",
      labelCh: "危地马拉",
      labelFr: "Guatemala",
      labelSp: "Guatemala",
      labelUa: "Гватемала",
    },
    {
      code: "GG",
      value: "GG",
      labelEn: "Guernsey",
      labelRu: "Гернси",
      labelCh: "根西岛",
      labelFr: "Bailliage de Guernesey",
      labelSp: "Guernsey",
      labelUa: "Гернссі",
    },
    {
      code: "GN",
      value: "GN",
      labelEn: "Guinea",
      labelRu: "Гвинея",
      labelCh: "几内亚",
      labelFr: "Guinée",
      labelSp: "Guinea",
      labelUa: "Гвінея",
    },
    {
      code: "GW",
      value: "GW",
      labelEn: "Guinea-Bissau",
      labelRu: "Гвинея-Бисау",
      labelCh: "几内亚比绍",
      labelFr: "Guinée-Bissau",
      labelSp: "Guinea-Bissau",
      labelUa: "Гвінея-Бісау",
    },
    {
      code: "GY",
      value: "GY",
      labelEn: "Guyana",
      labelRu: "Гайана",
      labelCh: "圭亚那",
      labelFr: "Guyana",
      labelSp: "Guayana",
      labelUa: "Гайана",
    },
    {
      code: "HT",
      value: "HT",
      labelEn: "Haiti",
      labelRu: "Гаити",
      labelCh: "海地",
      labelFr: "Haïti",
      labelSp: "Haití",
      labelUa: "Гаїті",
    },
    {
      code: "HM",
      value: "HM",
      labelEn: "Heard Island and McDonald Islands",
      labelRu: "Остров Херд и острова Макдональд",
      labelCh: "赫德岛和麦克唐纳群岛",
      labelFr: "îles Heard-et-MacDonald",
      labelSp: "Islas Heard y McDonald",
      labelUa: "Острів стада та острів Макдональд",
    },
    {
      code: "VA",
      value: "VA",
      labelEn: "Holy See (Vatican City State)",
      labelRu: "Папский Престол (Государство — город Ватикан)",
      labelCh: "梵蒂冈城国",
      labelFr: "État de la Cité du Vatican",
      labelSp: "Santa Sede (Estado de la Ciudad del Vaticano)",
      labelUa: "Папський престол (Держава - місто Ватикан)",
    },
    {
      code: "HN",
      value: "HN",
      labelEn: "Honduras",
      labelRu: "Гондурас",
      labelCh: "洪都拉斯",
      labelFr: "Honduras",
      labelSp: "Honduras",
      labelUa: "Гондурас",
    },
    {
      code: "HK",
      value: "HK",
      labelEn: "Hong Kong",
      labelRu: "Гонконг",
      labelCh: "香港",
      labelFr: "Hong Kong",
      labelSp: "Hong Kong",
      labelUa: "Гонконг",
    },
    {
      code: "HU",
      value: "HU",
      labelEn: "Hungary",
      labelRu: "Венгрия",
      labelCh: "匈牙利",
      labelFr: "Hongrie",
      labelSp: "Hungría",
      labelUa: "Угорщина",
    },
    {
      code: "IS",
      value: "IS",
      labelEn: "Iceland",
      labelRu: "Исландия",
      labelCh: "冰岛",
      labelFr: "Islande",
      labelSp: "Islandia",
      labelUa: "Ісландія",
    },
    {
      code: "IN",
      value: "IN",
      labelEn: "India",
      labelRu: "Индия",
      labelCh: "印度",
      labelFr: "Inde",
      labelSp: "India",
      labelUa: "Індія",
    },
    {
      code: "ID",
      value: "ID",
      labelEn: "Indonesia",
      labelRu: "Индонезия",
      labelCh: "印度尼西亚",
      labelFr: "Indonésie",
      labelSp: "Indonesia",
      labelUa: "Індонезія",
    },
    {
      code: "IR",
      value: "IR",
      labelEn: "Iran, Islamic Republic of",
      labelRu: "Иран, Исламская Республика",
      labelCh: "伊朗伊斯兰共和国",
      labelFr: "République islamique d'Iran",
      labelSp: "República Islámica de Irán",
      labelUa: "Іран, Ісламська Республіка",
    },
    {
      code: "IQ",
      value: "IQ",
      labelEn: "Iraq",
      labelRu: "Ирак",
      labelCh: "伊拉克",
      labelFr: "Irak",
      labelSp: "Irak",
      labelUa: "Ірак",
    },
    {
      code: "IE",
      value: "IE",
      labelEn: "Ireland",
      labelRu: "Ирландия",
      labelCh: "爱尔兰",
      labelFr: "Irlande",
      labelSp: "Irlanda",
      labelUa: "Ірландія",
    },
    {
      code: "IM",
      value: "IM",
      labelEn: "Isle of Man",
      labelRu: "Остров Мэн",
      labelCh: "马恩岛",
      labelFr: "île de Man",
      labelSp: "Isla de Man",
      labelUa: "Острів Мен",
    },
    {
      code: "IL",
      value: "IL",
      labelEn: "Israel",
      labelRu: "Израиль",
      labelCh: "以色列",
      labelFr: "Israël",
      labelSp: "Israel",
      labelUa: "Ізраїль",
    },
    {
      code: "IT",
      value: "IT",
      labelEn: "Italy",
      labelRu: "Италия",
      labelCh: "意大利",
      labelFr: "Italie",
      labelSp: "Italia",
      labelUa: "Італія",
    },
    {
      code: "JM",
      value: "JM",
      labelEn: "Jamaica",
      labelRu: "Ямайка",
      labelCh: "牙买加",
      labelFr: "Jamaïque",
      labelSp: "Jamaica",
      labelUa: "Ямайка",
    },
    {
      code: "JP",
      value: "JP",
      labelEn: "Japan",
      labelRu: "Япония",
      labelCh: "日本",
      labelFr: "Japon",
      labelSp: "Japón",
      labelUa: "Японія",
    },
    {
      code: "JE",
      value: "JE",
      labelEn: "Jersey",
      labelRu: "Джерси",
      labelCh: "泽西岛",
      labelFr: "Jersey",
      labelSp: "Jersey",
      labelUa: "Джерсі",
    },
    {
      code: "JO",
      value: "JO",
      labelEn: "Jordan",
      labelRu: "Иордания",
      labelCh: "约旦",
      labelFr: "Jordanie",
      labelSp: "Jordán",
      labelUa: "Йордан",
    },
    {
      code: "KZ",
      value: "KZ",
      labelEn: "Kazakhstan",
      labelRu: "Казахстан",
      labelCh: "哈萨克斯坦",
      labelFr: "Kazakhstan",
      labelSp: "Kazajstán",
      labelUa: "Казахстан",
    },
    {
      code: "KE",
      value: "KE",
      labelEn: "Kenya",
      labelRu: "Кения",
      labelCh: "肯尼亚",
      labelFr: "Kenya",
      labelSp: "Kenia",
      labelUa: "Кенія",
    },
    {
      code: "KI",
      value: "KI",
      labelEn: "Kiribati",
      labelRu: "Кирибати",
      labelCh: "基里巴斯",
      labelFr: "Kiribati",
      labelSp: "Kiribati",
      labelUa: "Кірібаті",
    },
    {
      code: "KP",
      value: "KP",
      labelEn: "Korea, Democratic People's republic of",
      labelRu: "Корея, Народно-Демократическая Республика",
      labelCh: "朝鲜民主主义人民共和国",
      labelFr: "République populaire démocratique de Corée",
      labelSp: "República Popular Democrática de Corea",
      labelUa: "Корея, Народно-Демократична Республіка",
    },
    {
      code: "KR",
      value: "KR",
      labelEn: "Korea, Republic of",
      labelRu: "Корея, Республика",
      labelCh: "韩国",
      labelFr: "République de Corée",
      labelSp: "República de Corea",
      labelUa: "Корея, Республіка",
    },
    {
      code: "KW",
      value: "KW",
      labelEn: "Kuwait",
      labelRu: "Кувейт",
      labelCh: "科威特",
      labelFr: "Koweït",
      labelSp: "Kuwait",
      labelUa: "Кувейт",
    },
    {
      code: "KG",
      value: "KG",
      labelEn: "Kyrgyzstan",
      labelRu: "Киргизия",
      labelCh: "吉尔吉斯",
      labelFr: "Kirghizistan",
      labelSp: "Kirguizstán",
      labelUa: "Киргизстан",
    },
    {
      code: "LA",
      value: "LA",
      labelEn: "Lao People's Democratic Republic",
      labelRu: "Лаос",
      labelCh: "老挝",
      labelFr: "République démocratique populaire du Laos",
      labelSp: "República Democrática Popular Lao",
      labelUa: "Лаос",
    },
    {
      code: "LV",
      value: "LV",
      labelEn: "Latvia",
      labelRu: "Латвия",
      labelCh: "拉脱维亚",
      labelFr: "République de Lettonie",
      labelSp: "Letonia",
      labelUa: "Латвія",
    },
    {
      code: "LB",
      value: "LB",
      labelEn: "Lebanon",
      labelRu: "Ливан",
      labelCh: "黎巴嫩",
      labelFr: "Liban",
      labelSp: "Líbano",
      labelUa: "Ліван",
    },
    {
      code: "LS",
      value: "LS",
      labelEn: "Lesotho",
      labelRu: "Лесото",
      labelCh: "莱索托 ",
      labelFr: "Lesotho",
      labelSp: "Lesoto",
      labelUa: "Лесото",
    },
    {
      code: "LR",
      value: "LR",
      labelEn: "Liberia",
      labelRu: "Либерия",
      labelCh: "利比里亚",
      labelFr: "Liberia",
      labelSp: "Liberia",
      labelUa: "Ліберія",
    },
    {
      code: "LY",
      value: "LY",
      labelEn: "Libyan Arab Jamahiriya",
      labelRu: "Ливийская Арабская Джамахирия",
      labelCh: "阿拉伯利比亚民众国",
      labelFr: "Jamahiriya arabe libyenne",
      labelSp: "Jamahiriya Árabe Libia",
      labelUa: "Лівійська Арабська Джамахірія",
    },
    {
      code: "LI",
      value: "LI",
      labelEn: "Liechtenstein",
      labelRu: "Лихтенштейн",
      labelCh: "列支敦士登 ",
      labelFr: "Liechtenstein",
      labelSp: "Liechtenstein",
      labelUa: "Ліхтенштейн",
    },
    {
      code: "LT",
      value: "LT",
      labelEn: "Lithuania",
      labelRu: "Литва",
      labelCh: "立陶宛",
      labelFr: "Lituanie",
      labelSp: "Lituania",
      labelUa: "Литва",
    },
    {
      code: "LU",
      value: "LU",
      labelEn: "Luxembourg",
      labelRu: "Люксембург",
      labelCh: "卢森堡",
      labelFr: "Luxembourg",
      labelSp: "Luxemburgo",
      labelUa: "Люксембург",
    },
    {
      code: "MO",
      value: "MO",
      labelEn: "Macao",
      labelRu: "Макао",
      labelCh: "澳门",
      labelFr: "Macao",
      labelSp: "Macao",
      labelUa: "Макао",
    },
    {
      code: "MK",
      value: "MK",
      labelEn: "Macedonia, The Former Yugoslav Republic Of",
      labelRu: "Республика Македония",
      labelCh: "马其顿共和国",
      labelFr: "République de Macédoine du Nord",
      labelSp: "República de macedonia",
      labelUa: "Республіка Македонія",
    },
    {
      code: "MG",
      value: "MG",
      labelEn: "Madagascar",
      labelRu: "Мадагаскар",
      labelCh: "马达加斯加",
      labelFr: "Madagascar",
      labelSp: "Madagascar",
      labelUa: "Мадагаскар",
    },
    {
      code: "MW",
      value: "MW",
      labelEn: "Malawi",
      labelRu: "Малави",
      labelCh: "马拉维",
      labelFr: "Malawi",
      labelSp: "Malawi",
      labelUa: "Малаві",
    },
    {
      code: "MY",
      value: "MY",
      labelEn: "Malaysia",
      labelRu: "Малайзия",
      labelCh: "马来西亚",
      labelFr: "Malaisie",
      labelSp: "Malasia",
      labelUa: "Малайзія",
    },
    {
      code: "MV",
      value: "MV",
      labelEn: "Maldives",
      labelRu: "Мальдивы",
      labelCh: "马尔代夫",
      labelFr: "Maldives",
      labelSp: "Maldivas",
      labelUa: "Мальдіви",
    },
    {
      code: "ML",
      value: "ML",
      labelEn: "Mali",
      labelRu: "Мали",
      labelCh: "马里",
      labelFr: "Mali",
      labelSp: "Mali",
      labelUa: "Малі",
    },
    {
      code: "MT",
      value: "MT",
      labelEn: "Malta",
      labelRu: "Мальта",
      labelCh: "马耳他",
      labelFr: "Malte",
      labelSp: "Malta",
      labelUa: "Мальта",
    },
    {
      code: "MH",
      value: "MH",
      labelEn: "Marshall Islands",
      labelRu: "Маршалловы острова",
      labelCh: "马绍尔群岛",
      labelFr: "Îles Marshall",
      labelSp: "Islas Marshall",
      labelUa: "Маршаллські острови",
    },
    {
      code: "MQ",
      value: "MQ",
      labelEn: "Martinique",
      labelRu: "Мартиника",
      labelCh: "马提尼克岛 ",
      labelFr: "Martinique",
      labelSp: "Martinica",
      labelUa: "Мартінік",
    },
    {
      code: "MR",
      value: "MR",
      labelEn: "Mauritania",
      labelRu: "Мавритания",
      labelCh: "毛里塔尼亚",
      labelFr: "Mauritanie",
      labelSp: "Mauritania",
      labelUa: "Мавританія",
    },
    {
      code: "MU",
      value: "MU",
      labelEn: "Mauritius",
      labelRu: "Маврикий",
      labelCh: "毛里求斯",
      labelFr: "Maurice",
      labelSp: "Mauricio",
      labelUa: "Маврикій",
    },
    {
      code: "YT",
      value: "YT",
      labelEn: "Mayotte",
      labelRu: "Майотта",
      labelCh: "马约特",
      labelFr: "Mayotte",
      labelSp: "Mayotte",
      labelUa: "Мейотта",
    },
    {
      code: "MX",
      value: "MX",
      labelEn: "Mexico",
      labelRu: "Мексика",
      labelCh: "墨西哥",
      labelFr: "Mexique",
      labelSp: "Mexico",
      labelUa: "Мексика",
    },
    {
      code: "FM",
      value: "FM",
      labelEn: "Micronesia, Federated States of",
      labelRu: "Микронезия, Федеративные Штаты",
      labelCh: "密克罗尼西亚联邦",
      labelFr: "États fédérés de Micronésie",
      labelSp: "Micronesia, estados federados",
      labelUa: "Федеративні Штати Мікронезії ",
    },
    {
      code: "MD",
      value: "MD",
      labelEn: "Moldova",
      labelRu: "Молдова, Республика",
      labelCh: "摩尔多瓦共和国",
      labelFr: "Moldavie",
      labelSp: "Moldavia",
      labelUa: "Молдова, республіка",
    },
    {
      code: "MC",
      value: "MC",
      labelEn: "Monaco",
      labelRu: "Монако",
      labelCh: "摩纳哥",
      labelFr: "Monaco",
      labelSp: "Mónaco",
      labelUa: "Монако",
    },
    {
      code: "MN",
      value: "MN",
      labelEn: "Mongolia",
      labelRu: "Монголия",
      labelCh: "蒙古",
      labelFr: "Mongolie",
      labelSp: "Mongolia",
      labelUa: "Монголія",
    },
    {
      code: "MS",
      value: "MS",
      labelEn: "Montserrat",
      labelRu: "Монтсеррат",
      labelCh: "蒙特塞拉特",
      labelFr: "Montserrat",
      labelSp: "Montserrat",
      labelUa: "Монтсеррат",
    },
    {
      code: "MA",
      value: "MA",
      labelEn: "Morocco",
      labelRu: "Марокко",
      labelCh: "摩洛哥",
      labelFr: "Maroc",
      labelSp: "Marruecos",
      labelUa: "Марокко",
    },
    {
      code: "MZ",
      value: "MZ",
      labelEn: "Mozambique",
      labelRu: "Мозамбик",
      labelCh: "莫三比克",
      labelFr: "Mozambique",
      labelSp: "Mozambique",
      labelUa: "Мозамбік",
    },
    {
      code: "MM",
      value: "MM",
      labelEn: "Myanmar",
      labelRu: "Мьянма",
      labelCh: "缅甸",
      labelFr: "Myanmar",
      labelSp: "Myanmar",
      labelUa: "М'янма",
    },
    {
      code: "NA",
      value: "NA",
      labelEn: "Namibia",
      labelRu: "Намибия",
      labelCh: "纳米比亚",
      labelFr: "Namibie",
      labelSp: "Namibia",
      labelUa: "Намібія",
    },
    {
      code: "NR",
      value: "NR",
      labelEn: "Nauru",
      labelRu: "Науру",
      labelCh: "瑙鲁",
      labelFr: "Nauru",
      labelSp: "Nauru",
      labelUa: "Науру",
    },
    {
      code: "NP",
      value: "NP",
      labelEn: "Nepal",
      labelRu: "Непал",
      labelCh: "尼泊尔",
      labelFr: "Népal",
      labelSp: "Nepal",
      labelUa: "Непал",
    },
    {
      code: "NL",
      value: "NL",
      labelEn: "Netherlands",
      labelRu: "Нидерланды",
      labelCh: "荷兰",
      labelFr: "Pays-Bas",
      labelSp: "Países Bajos",
      labelUa: "Нідерланди",
    },
    {
      code: "NC",
      value: "NC",
      labelEn: "New Caledonia",
      labelRu: "Новая Каледония",
      labelCh: "新喀里多尼亚",
      labelFr: "Nouvelle-Calédonie",
      labelSp: "Nueva Caledonia",
      labelUa: "Нова Каледонія",
    },
    {
      code: "NZ",
      value: "NZ",
      labelEn: "New Zealand",
      labelRu: "Новая Зеландия",
      labelCh: "新西兰",
      labelFr: "Nouvelle-Zélande",
      labelSp: "Nueva Zelanda",
      labelUa: "Нова Зеландія",
    },
    {
      code: "NI",
      value: "NI",
      labelEn: "Nicaragua",
      labelRu: "Никарагуа",
      labelCh: "尼加拉瓜",
      labelFr: "Nicaragua",
      labelSp: "Nicaragua",
      labelUa: "Нікарагуа",
    },
    {
      code: "NE",
      value: "NE",
      labelEn: "Niger",
      labelRu: "Нигер",
      labelCh: "尼日尔",
      labelFr: "Niger",
      labelSp: "Níger",
      labelUa: "Нігер",
    },
    {
      code: "NG",
      value: "NG",
      labelEn: "Nigeria",
      labelRu: "Нигерия",
      labelCh: "尼日利亚",
      labelFr: "Nigeria",
      labelSp: "Nigeria",
      labelUa: "Нігерія",
    },
    {
      code: "NU",
      value: "NU",
      labelEn: "Niue",
      labelRu: "Ниуэ",
      labelCh: "纽埃",
      labelFr: "Niue",
      labelSp: "Niue",
      labelUa: "Ніуе",
    },
    {
      code: "NF",
      value: "NF",
      labelEn: "Norfolk Island",
      labelRu: "Остров Норфолк",
      labelCh: "诺福克岛",
      labelFr: "île Norfolk",
      labelSp: "Isla Norfolk",
      labelUa: "Острів Норфолк",
    },
    {
      code: "MP",
      value: "MP",
      labelEn: "Northern Mariana Islands",
      labelRu: "Северные Марианские острова",
      labelCh: "北马里亚纳群岛",
      labelFr: "îles Mariannes du Nord",
      labelSp: "Islas Marianas del Norte",
      labelUa: "Північні Маріанські острови",
    },
    {
      code: "NO",
      value: "NO",
      labelEn: "Norway",
      labelRu: "Норвегия",
      labelCh: "挪威",
      labelFr: "Norvège",
      labelSp: "Noruega",
      labelUa: "Норвегія",
    },
    {
      code: "OM",
      value: "OM",
      labelEn: "Oman",
      labelRu: "Оман",
      labelCh: "阿曼",
      labelFr: "Oman",
      labelSp: "Omán",
      labelUa: "Оман",
    },
    {
      code: "PK",
      value: "PK",
      labelEn: "Pakistan",
      labelRu: "Пакистан",
      labelCh: "巴基斯坦",
      labelFr: "Pakistan",
      labelSp: "Pakistán",
      labelUa: "Пакистан",
    },
    {
      code: "PW",
      value: "PW",
      labelEn: "Palau",
      labelRu: "Палау",
      labelCh: "帕劳",
      labelFr: "Palaos",
      labelSp: "Palau",
      labelUa: "Палау",
    },
    {
      code: "PS",
      value: "PS",
      labelEn: "Palestinian Territory, Occupied",
      labelRu: "Палестинская территория, оккупированная",
      labelCh: "巴勒斯坦",
      labelFr: "État de Palestine , occupés",
      labelSp: "Territorio Palestino, Ocupado",
      labelUa: "Палестинська територія зайнята",
    },
    {
      code: "PA",
      value: "PA",
      labelEn: "Panama",
      labelRu: "Панама",
      labelCh: "巴拿马",
      labelFr: "Panama",
      labelSp: "Panamá",
      labelUa: "Панама",
    },
    {
      code: "PG",
      value: "PG",
      labelEn: "Papua New Guinea",
      labelRu: "Папуа-Новая Гвинея",
      labelCh: "巴布亚新几内亚 ",
      labelFr: "Papouasie-Nouvelle-Guinée",
      labelSp: "Papúa Nueva Guinea",
      labelUa: "Папуа Нова Гвінея",
    },
    {
      code: "PY",
      value: "PY",
      labelEn: "Paraguay",
      labelRu: "Парагвай",
      labelCh: "巴拉圭",
      labelFr: "Paraguay",
      labelSp: "Paraguay",
      labelUa: "Парагвай",
    },
    {
      code: "PE",
      value: "PE",
      labelEn: "Peru",
      labelRu: "Перу",
      labelCh: "秘鲁",
      labelFr: "Pérou",
      labelSp: "Perú",
      labelUa: "Перу",
    },
    {
      code: "PH",
      value: "PH",
      labelEn: "Philippines",
      labelRu: "Филиппины",
      labelCh: "菲律宾",
      labelFr: "Philippines",
      labelSp: "Filipinas",
      labelUa: "Філіппіни",
    },
    {
      code: "PN",
      value: "PN",
      labelEn: "Pitcairn",
      labelRu: "Питкерн",
      labelCh: "皮特克恩岛",
      labelFr: "Pitcairn",
      labelSp: "Pitcairn",
      labelUa: "Піткерн",
    },
    {
      code: "PL",
      value: "PL",
      labelEn: "Poland",
      labelRu: "Польша",
      labelCh: "波兰",
      labelFr: "Pologne",
      labelSp: "Polonia",
      labelUa: "Польща",
    },
    {
      code: "PT",
      value: "PT",
      labelEn: "Portugal",
      labelRu: "Португалия",
      labelCh: "葡萄牙",
      labelFr: "Portugal",
      labelSp: "Portugal",
      labelUa: "Португалія",
    },
    {
      code: "PR",
      value: "PR",
      labelEn: "Puerto Rico",
      labelRu: "Пуэрто-Рико",
      labelCh: "波多黎各",
      labelFr: "Porto Rico",
      labelSp: "Puerto Rico",
      labelUa: "Пуерто-Рико",
    },
    {
      code: "QA",
      value: "QA",
      labelEn: "Qatar",
      labelRu: "Катар",
      labelCh: "卡塔尔",
      labelFr: "Qatar",
      labelSp: "Katar",
      labelUa: "Катар",
    },
    {
      code: "RE",
      value: "RE",
      labelEn: "Reunion",
      labelRu: "Реюньон",
      labelCh: "留尼汪岛",
      labelFr: "Réunion",
      labelSp: "Reunion",
      labelUa: "Реюньйон",
    },
    {
      code: "RO",
      value: "RO",
      labelEn: "Romania",
      labelRu: "Румыния",
      labelCh: "罗马尼亚",
      labelFr: "Roumanie",
      labelSp: "Rumania",
      labelUa: "Румунія",
    },
    {
      code: "RU",
      value: "RU",
      labelEn: "Russian Federation",
      labelRu: "Россия",
      labelCh: "俄罗斯",
      labelFr: "Russie",
      labelSp: "Federación Rusa",
      labelUa: "Росія",
    },
    {
      code: "RW",
      value: "RW",
      labelEn: "Rwanda",
      labelRu: "Руанда",
      labelCh: "卢旺达",
      labelFr: "Rwanda",
      labelSp: "Ruanda",
      labelUa: "Руанда",
    },
    {
      code: "SH",
      value: "SH",
      labelEn: "Saint Helena, Ascension And Tristan Da Cunha",
      labelRu: "Святая Елена, Остров вознесения, Тристан-да-Кунья",
      labelCh: "圣赫勒拿岛",
      labelFr: "Sainte-Hélène, Ascension et Tristan da Cunha",
      labelSp: "Santa Helena, Ascensión Y Tristán Da Cunha",
      labelUa: "Свята Олена, острів Вознесіння, Трістан-Да-Куня",
    },
    {
      code: "KN",
      value: "KN",
      labelEn: "Saint Kitts and Nevis",
      labelRu: "Сент-Китс и Невис",
      labelCh: "圣基茨和尼维斯",
      labelFr: "Saint-Christophe-et-Niévès",
      labelSp: "San Cristóbal y Nieves",
      labelUa: "Сен-Кітс і Невіс",
    },
    {
      code: "LC",
      value: "LC",
      labelEn: "Saint Lucia",
      labelRu: "Сент-Люсия",
      labelCh: "圣路西亚",
      labelFr: "Sainte-Lucie",
      labelSp: "Santa Lucia",
      labelUa: "Сент-Лусія",
    },
    {
      code: "PM",
      value: "PM",
      labelEn: "Saint Pierre and Miquelon",
      labelRu: "Сент-Пьер и Микелон",
      labelCh: "圣皮埃尔密克隆群岛",
      labelFr: "Saint-Pierre-et-Miquelon",
      labelSp: "San Pedro y Miquelón",
      labelUa: "Сен-П'єр та Мішелон",
    },
    {
      code: "VC",
      value: "VC",
      labelEn: "Saint Vincent and the Grenadines",
      labelRu: "Сент-Винсент и Гренадины",
      labelCh: "圣文森蒂格林纳丁斯",
      labelFr: "Saint-Vincent-et-les-Grenadines",
      labelSp: "San Vicente y las Granadinas",
      labelUa: "Сен-Вінсент та Гренадини",
    },
    {
      code: "WS",
      value: "WS",
      labelEn: "Samoa",
      labelRu: "Самоа",
      labelCh: "萨摩亚",
      labelFr: "Samoa",
      labelSp: "Samoa",
      labelUa: "Самоа",
    },
    {
      code: "SM",
      value: "SM",
      labelEn: "San Marino",
      labelRu: "Сан-Марино",
      labelCh: "圣马力诺",
      labelFr: "Saint-Marin",
      labelSp: "San Marino",
      labelUa: "Сан -Маріно",
    },
    {
      code: "ST",
      value: "ST",
      labelEn: "Sao Tome and Principe",
      labelRu: "Сан-Томе и Принсипи",
      labelCh: "圣多美岛和普林西比岛",
      labelFr: "Sao Tomé-et-Principe",
      labelSp: "Santo Tomé y Príncipe",
      labelUa: "Сан -Том і Принци",
    },
    {
      code: "SA",
      value: "SA",
      labelEn: "Saudi Arabia",
      labelRu: "Саудовская Аравия",
      labelCh: "沙特阿拉伯",
      labelFr: "Arabie saoudite",
      labelSp: "Arabia Saudita",
      labelUa: "Саудівська Аравія",
    },
    {
      code: "SN",
      value: "SN",
      labelEn: "Senegal",
      labelRu: "Сенегал",
      labelCh: "塞内加尔",
      labelFr: "Sénégal",
      labelSp: "Senegal",
      labelUa: "Сенегал",
    },
    {
      code: "SC",
      value: "SC",
      labelEn: "Seychelles",
      labelRu: "Сейшелы",
      labelCh: "塞舌尔群岛",
      labelFr: "Seychelles",
      labelSp: "Seychelles",
      labelUa: "Сейше́ли ",
    },
    {
      code: "SL",
      value: "SL",
      labelEn: "Sierra Leone",
      labelRu: "Сьерра-Леоне",
      labelCh: "塞拉利昂",
      labelFr: "Sierra Leone",
      labelSp: "Sierra Leone",
      labelUa: "Сьєрра-Леоне",
    },
    {
      code: "SG",
      value: "SG",
      labelEn: "Singapore",
      labelRu: "Сингапур",
      labelCh: "新加坡",
      labelFr: "Singapour",
      labelSp: "Singapur",
      labelUa: "Сінгапур",
    },
    {
      code: "SK",
      value: "SK",
      labelEn: "Slovakia",
      labelRu: "Словакия",
      labelCh: "斯洛伐克",
      labelFr: "Slovaquie",
      labelSp: "Eslovaquia",
      labelUa: "Словаччина",
    },
    {
      code: "SI",
      value: "SI",
      labelEn: "Slovenia",
      labelRu: "Словения",
      labelCh: "斯洛文尼亚",
      labelFr: "Slovénie",
      labelSp: "Eslovenia",
      labelUa: "Словенія",
    },
    {
      code: "SB",
      value: "SB",
      labelEn: "Solomon Islands",
      labelRu: "Соломоновы острова",
      labelCh: "所罗门群岛",
      labelFr: "Îles Salomon",
      labelSp: "Islas Salomón",
      labelUa: "Соломонські острови",
    },
    {
      code: "SO",
      value: "SO",
      labelEn: "Somalia",
      labelRu: "Сомали",
      labelCh: "索马里",
      labelFr: "Somalie",
      labelSp: "Somalia",
      labelUa: "Сомалі",
    },
    {
      code: "ZA",
      value: "ZA",
      labelEn: "South Africa",
      labelRu: "Южная Африка",
      labelCh: "南非",
      labelFr: "Afrique du Sud",
      labelSp: "Sudáfrica",
      labelUa: "Південна Африка",
    },
    {
      code: "GS",
      value: "GS",
      labelEn: "South Georgia and the South Sandwich Islands",
      labelRu: "Южная Джорджия и Южные Сандвичевы острова",
      labelCh: "南非",
      labelFr: "Géorgie du Sud-et-les îles Sandwich du Sud",
      labelSp: "Georgia del sur y las islas Sandwich del sur",
      labelUa: "Острови Південної Джорджії та Південний Сендвічев",
    },
    {
      code: "ES",
      value: "ES",
      labelEn: "Spain",
      labelRu: "Испания",
      labelCh: "西班牙",
      labelFr: "Espagne",
      labelSp: "España",
      labelUa: "Іспанія",
    },
    {
      code: "LK",
      value: "LK",
      labelEn: "Sri Lanka",
      labelRu: "Шри-Ланка",
      labelCh: "斯里兰卡",
      labelFr: "Sri Lanka",
      labelSp: "Sri Lanka",
      labelUa: "Шрі Ланка",
    },
    {
      code: "SD",
      value: "SD",
      labelEn: "Sudan",
      labelRu: "Судан",
      labelCh: "苏丹",
      labelFr: "Soudan",
      labelSp: "Sudán",
      labelUa: "Судан",
    },
    {
      code: "SR",
      value: "SR",
      labelEn: "Suriname",
      labelRu: "Суринам",
      labelCh: "苏里南",
      labelFr: "Suriname",
      labelSp: "Surinam",
      labelUa: "Сурінам",
    },
    {
      code: "SJ",
      value: "SJ",
      labelEn: "Svalbard and Jan Mayen",
      labelRu: "Шпицберген и Ян Майен",
      labelCh: "斯瓦尔巴群岛和扬马延岛",
      labelFr: "Svalbard et Jan Mayen",
      labelSp: "Svalbardn y Jan Mayen",
      labelUa: "Шпіцберген та Ян Майєн",
    },
    {
      code: "SZ",
      value: "SZ",
      labelEn: "Swaziland",
      labelRu: "Свазиленд",
      labelCh: "斯威士兰",
      labelFr: "Swaziland",
      labelSp: "Suazilandia",
      labelUa: "Свазіленд",
    },
    {
      code: "SE",
      value: "SE",
      labelEn: "Sweden",
      labelRu: "Швеция",
      labelCh: "瑞典",
      labelFr: "Suède",
      labelSp: "Suecia",
      labelUa: "Швеція",
    },
    {
      code: "CH",
      value: "CH",
      labelEn: "Switzerland",
      labelRu: "Швейцария",
      labelCh: "瑞士",
      labelFr: "Suisse",
      labelSp: "Suiza",
      labelUa: "Швейцарія",
    },
    {
      code: "SY",
      value: "SY",
      labelEn: "Syrian Arab Republic",
      labelRu: "Сирийская Арабская Республика",
      labelCh: "阿拉伯叙利亚共和国",
      labelFr: "République arabe syrienne",
      labelSp: "República Árabe Siria",
      labelUa: "Сирійська Арабська Республіка",
    },
    {
      code: "TW",
      value: "TW",
      labelEn: "Taiwan, Province of China",
      labelRu: "Тайвань (Китай)",
      labelCh: "台湾",
      labelFr: "province de Taïwan, république populaire de Chine",
      labelSp: "Taiwan, provincia de China",
      labelUa: "Тайвань (Китай)",
    },
    {
      code: "TJ",
      value: "TJ",
      labelEn: "Tajikistan",
      labelRu: "Таджикистан",
      labelCh: "塔吉克斯坦",
      labelFr: "Tadjikistan",
      labelSp: "Tayikistán",
      labelUa: "Таджикистан",
    },
    {
      code: "TZ",
      value: "TZ",
      labelEn: "Tanzania, United Republic Of",
      labelRu: "Танзания, Объединенная Республика",
      labelCh: "坦桑尼亚",
      labelFr: "République unie de Tanzanie",
      labelSp: "República Unida de Tanzania",
      labelUa: "Танзанія, Об'єднана Республіка",
    },
    {
      code: "TH",
      value: "TH",
      labelEn: "Thailand",
      labelRu: "Таиланд",
      labelCh: "泰国",
      labelFr: "Thaïlande",
      labelSp: "Tailandia",
      labelUa: "Таїланд",
    },
    {
      code: "TL",
      value: "TL",
      labelEn: "Timor-Leste",
      labelRu: "Тимор-Лесте",
      labelCh: "东帝汶",
      labelFr: "Timor oriental",
      labelSp: "Timor Oriental",
      labelUa: "Тимор-Лесте",
    },
    {
      code: "TG",
      value: "TG",
      labelEn: "Togo",
      labelRu: "Того",
      labelCh: "多哥",
      labelFr: "Togo",
      labelSp: "Togo",
      labelUa: "Того",
    },
    {
      code: "TK",
      value: "TK",
      labelEn: "Tokelau",
      labelRu: "Токелау",
      labelCh: "托克劳",
      labelFr: "Tokelau",
      labelSp: "Tokelau",
      labelUa: "Токелау",
    },
    {
      code: "TO",
      value: "TO",
      labelEn: "Tonga",
      labelRu: "Тонга",
      labelCh: "汤加",
      labelFr: "Tonga",
      labelSp: "Tonga",
      labelUa: "Тонга",
    },
    {
      code: "TT",
      value: "TT",
      labelEn: "Trinidad and Tobago",
      labelRu: "Тринидад и Тобаго",
      labelCh: "特立尼达和多巴哥",
      labelFr: "Trinité-et-Tobago",
      labelSp: "Trinidad y Tobago",
      labelUa: "Трінідад і Тобаго",
    },
    {
      code: "TN",
      value: "TN",
      labelEn: "Tunisia",
      labelRu: "Тунис",
      labelCh: "突尼斯",
      labelFr: "Tunisie",
      labelSp: "Túnez",
      labelUa: "Туніс",
    },
    {
      code: "TR",
      value: "TR",
      labelEn: "Turkey",
      labelRu: "Турция",
      labelCh: "土耳其",
      labelFr: "Turquie",
      labelSp: "Turquía",
      labelUa: "Туреччина",
    },
    {
      code: "TM",
      value: "TM",
      labelEn: "Turkmenistan",
      labelRu: "Туркмения",
      labelCh: "土库曼",
      labelFr: "Turkménistan",
      labelSp: "Turkmenistán",
      labelUa: "Туркменія",
    },
    {
      code: "TC",
      value: "TC",
      labelEn: "Turks and Caicos Islands",
      labelRu: "Острова Теркс и Кайкос",
      labelCh: "土克凯可群岛",
      labelFr: "îles Turques-et-Caïques",
      labelSp: "Islas Turcas y Caicos",
      labelUa: "Острова Теркс та Кайкос",
    },
    {
      code: "TV",
      value: "TV",
      labelEn: "Tuvalu",
      labelRu: "Тувалу",
      labelCh: "图瓦卢",
      labelFr: "Tuvalu",
      labelSp: "Tuvalu",
      labelUa: "Тувалу",
    },
    {
      code: "UG",
      value: "UG",
      labelEn: "Uganda",
      labelRu: "Уганда",
      labelCh: "乌干达",
      labelFr: "Ouganda",
      labelSp: "Uganda",
      labelUa: "Уганда",
    },
    {
      code: "UA",
      value: "UA",
      labelEn: "Ukraine",
      labelRu: "Украина",
      labelCh: "乌克兰",
      labelFr: "Ukraine",
      labelSp: "Ucrania",
      labelUa: "Україна",
    },
    {
      code: "AE",
      value: "AE",
      labelEn: "United Arab Emirates",
      labelRu: "Объединенные Арабские Эмираты",
      labelCh: "阿拉伯联合酋长国",
      labelFr: "Émirats arabes unis",
      labelSp: "Emiratos Árabes Unidos",
      labelUa: "Об'єднані Арабські Емірати",
    },
    {
      code: "UK",
      value: "UK",
      labelEn: "United Kingdom",
      labelRu: "Соединенное Королевство",
      labelCh: "英国",
      labelFr: "Royaume-Uni",
      labelSp: "Reino Unido",
      labelUa: "Сполучене Королівство",
    },
    {
      code: "GB",
      value: "GB",
      labelEn: "United Kingdom",
      labelRu: "Соединенное Королевство",
      labelCh: "英国",
      labelFr: "Royaume-Uni",
      labelSp: "Reino Unido",
      labelUa: "Сполучене Королівство",
    },
    {
      code: "US",
      value: "US",
      labelEn: "United States",
      labelRu: "Соединенные Штаты",
      labelCh: "美国",
      labelFr: "États-Unis",
      labelSp: "Estados Unidos",
      labelUa: "Сполучені Штати",
    },
    {
      code: "UM",
      value: "UM",
      labelEn: "United States Minor Outlying Islands",
      labelRu: "Малые Тихоокеанские отдаленные острова Соединенных Штатов",
      labelCh: "美属维尔京群岛",
      labelFr: "îles mineures éloignées des États-Unis",
      labelSp: "Islas menores alejadas de los Estados Unidos",
      labelUa: "Малі Тихоокеанські віддалені острови Сполучених Штатів",
    },
    {
      code: "UY",
      value: "UY",
      labelEn: "Uruguay",
      labelRu: "Уругвай",
      labelCh: "乌拉圭",
      labelFr: "Uruguay",
      labelSp: "Uruguay",
      labelUa: "Уругвай",
    },
    {
      code: "UZ",
      value: "UZ",
      labelEn: "Uzbekistan",
      labelRu: "Узбекистан",
      labelCh: "乌茲别克斯坦",
      labelFr: "Ouzbékistan",
      labelSp: "Uzbekistán",
      labelUa: "",
    },
    {
      code: "VU",
      value: "VU",
      labelEn: "Vanuatu",
      labelRu: "Вануату",
      labelCh: "瓦努阿图",
      labelFr: "Vanuatu",
      labelSp: "Vanuatu",
      labelUa: "",
    },
    {
      code: "VE",
      value: "VE",
      labelEn: "Venezuela",
      labelRu: "Венесуэла Боливарианская Республика",
      labelCh: "委内瑞拉玻利瓦尔共和国",
      labelFr: "République bolivarienne du Venezuela",
      labelSp: "La República Bolivariana de Venezuela",
      labelUa: "",
    },
    {
      code: "VN",
      value: "VN",
      labelEn: "Vietnam",
      labelRu: "Вьетнам",
      labelCh: "越南",
      labelFr: "Viêt Nam",
      labelSp: "Vietnam",
      labelUa: "В'єтнам",
    },
    {
      code: "VG",
      value: "VG",
      labelEn: "Virgin Islands, British",
      labelRu: "Виргинские острова, Британские",
      labelCh: "维尔京群岛",
      labelFr: "îles Vierges britanniques",
      labelSp: "Islas Vírgenes Británicas",
      labelUa: "Віргінські острови, Британські",
    },
    {
      code: "VI",
      value: "VI",
      labelEn: "Virgin Islands, U.S.",
      labelRu: "Виргинские острова, США",
      labelCh: "美属维尔京群岛",
      labelFr: "îles Vierges des États-Unis",
      labelSp: "Islas Vírgenes, EE.UU.",
      labelUa: "Віргінські острови, США",
    },
    {
      code: "WF",
      value: "WF",
      labelEn: "Wallis and Futuna",
      labelRu: "Уоллис и Футуна",
      labelCh: "瓦利斯和富图纳",
      labelFr: "Wallis-et-Futuna",
      labelSp: "Wallis y Futuna",
      labelUa: "Уолліс та Футуна",
    },
    {
      code: "EH",
      value: "EH",
      labelEn: "Western Sahara",
      labelRu: "Западная Сахара",
      labelCh: "西撒哈拉",
      labelFr: "Sahara occidental",
      labelSp: "Sahara Occidental",
      labelUa: "Західна Сахара",
    },
    {
      code: "YE",
      value: "YE",
      labelEn: "Yemen",
      labelRu: "Йемен",
      labelCh: "也门",
      labelFr: "Yémen",
      labelSp: "Yemen",
      labelUa: "Ємен",
    },
    {
      code: "ZM",
      value: "ZM",
      labelEn: "Zambia",
      labelRu: "Замбия",
      labelCh: "赞比亚",
      labelFr: "Zambie",
      labelSp: "Zambia",
      labelUa: "Замбія",
    },
    {
      code: "ZW",
      value: "ZW",
      labelEn: "Zimbabwe",
      labelRu: "Зимбабве",
      labelCh: "津巴布韦",
      labelFr: "Zimbabwe",
      labelSp: "Zimbabwe",
      labelUa: "Зімбабве",
    },
  ],
  alpha3Standard: [
    {
      code: "All countries",
      value: "",
      labelEn: "All countries",
      labelRu: "Все страны",
      labelCh: "所有國家",
      labelFr: "Tous les pays",
      labelSp: "Todos los países",
      labelUa: "Всі країни",
    },
    {
      code: "AFG",
      value: "AFG",
      labelEn: "Afghanistan",
      labelRu: "Афганистан",
      labelCh: "阿富汗",
      labelFr: "Afghanistan",
      labelSp: "Afganistán",
      labelUa: "Афганістан",
    },
    {
      code: "ALA",
      value: "ALA",
      labelEn: "Åland Islands",
      labelRu: "Аландские острова",
      labelCh: "奥兰",
      labelFr: "Åland",
      labelSp: "Islas Aland",
      labelUa: "Аландські острови",
    },
    {
      code: "ALB",
      value: "ALB",
      labelEn: "Albania",
      labelRu: "Албания",
      labelCh: "阿尔巴尼亚",
      labelFr: "Albanie",
      labelSp: "Albania",
      labelUa: "Албанія",
    },
    {
      code: "DZA",
      value: "DZA",
      labelEn: "Algeria",
      labelRu: "Алжир",
      labelCh: "阿尔及利亚",
      labelFr: "Algérie",
      labelSp: "Argelia",
      labelUa: "Алжир",
    },
    {
      code: "ASM",
      value: "ASM",
      labelEn: "American Samoa",
      labelRu: "Американское Самоа",
      labelCh: "美属萨摩亚",
      labelFr: "Samoa américaines",
      labelSp: "Samoa Americana",
      labelUa: "Американське Самоа",
    },
    {
      code: "AND",
      value: "AND",
      labelEn: "Andorra",
      labelRu: "Андорра",
      labelCh: "安道尔",
      labelFr: "Andorre",
      labelSp: "Andorra",
      labelUa: "Андорра",
    },
    {
      code: "AGO",
      value: "AGO",
      labelEn: "Angola",
      labelRu: "Ангола",
      labelCh: "安哥拉",
      labelFr: "Angola",
      labelSp: "Angola",
      labelUa: "Ангола",
    },
    {
      code: "AIA",
      value: "AIA",
      labelEn: "Anguilla",
      labelRu: "Ангилья",
      labelCh: "安圭拉",
      labelFr: "Anguilla",
      labelSp: "Anguila",
      labelUa: "Ангілья",
    },
    {
      code: "ATA",
      value: "ATA",
      labelEn: "Antarctica",
      labelRu: "Антарктика",
      labelCh: "南极洲",
      labelFr: "Antarctique",
      labelSp: "Antártida",
      labelUa: "Антарктида",
    },
    {
      code: "ATG",
      value: "ATG",
      labelEn: "Antigua and Barbuda",
      labelRu: "Антигуа и Барбуда",
      labelCh: "安提瓜和巴布达",
      labelFr: "Antigua-et-Barbuda",
      labelSp: "Antigua y Barbuda",
      labelUa: "Антигуа і Барбуда",
    },
    {
      code: "ARG",
      value: "ARG",
      labelEn: "Argentina",
      labelRu: "Аргентина",
      labelCh: "阿根廷 ",
      labelFr: "Argentine",
      labelSp: "Argentina",
      labelUa: "Аргентина",
    },
    {
      code: "ARM",
      value: "ARM",
      labelEn: "Armenia",
      labelRu: "Армения",
      labelCh: "亚美尼亚",
      labelFr: "Arménie",
      labelSp: "Armenia",
      labelUa: "Вірменія",
    },
    {
      code: "ABW",
      value: "ABW",
      labelEn: "Aruba",
      labelRu: "Аруба",
      labelCh: "阿鲁巴",
      labelFr: "Aruba",
      labelSp: "Aruba",
      labelUa: "Аруба",
    },
    {
      code: "AUS",
      value: "AUS",
      labelEn: "Australia",
      labelRu: "Австралия",
      labelCh: "澳大利亚",
      labelFr: "Australie",
      labelSp: "Australia",
      labelUa: "Австралія",
    },
    {
      code: "AUT",
      value: "AUT",
      labelEn: "Austria",
      labelRu: "Австрия",
      labelCh: "奥地利",
      labelFr: "Autriche",
      labelSp: "Austria",
      labelUa: "Австрія",
    },
    {
      code: "AZE",
      value: "AZE",
      labelEn: "Azerbaijan",
      labelRu: "Азербайджан",
      labelCh: "阿塞拜疆",
      labelFr: "Azerbaïdjan",
      labelSp: "Azerbaiyán",
      labelUa: "Азербайджан",
    },
    {
      code: "BS",
      value: "BS",
      labelEn: "Bahamas",
      labelRu: "Багамы",
      labelCh: "巴哈马",
      labelFr: "Bahamas",
      labelSp: "Bahamas",
      labelUa: "Багамські острови",
    },
    {
      code: "BHR",
      value: "BHR",
      labelEn: "Bahrain",
      labelRu: "Бахрейн",
      labelCh: "巴林",
      labelFr: "Bahreïn",
      labelSp: "Bahrein",
      labelUa: "Бахрейн",
    },
    {
      code: "BGD",
      value: "BGD",
      labelEn: "Bangladesh",
      labelRu: "Бангладеш",
      labelCh: "孟加拉国",
      labelFr: "Bangladesh",
      labelSp: "Bangladesh",
      labelUa: "Бангладеш",
    },
    {
      code: "BRB",
      value: "BRB",
      labelEn: "Barbados",
      labelRu: "Барбадос",
      labelCh: "巴巴多斯",
      labelFr: "Barbade",
      labelSp: "Barbados",
      labelUa: "Барбадос",
    },
    {
      code: "BLR",
      value: "BLR",
      labelEn: "Belarus",
      labelRu: "Беларусь",
      labelCh: "白罗斯",
      labelFr: "Biélorussie",
      labelSp: "Bielorrusia",
      labelUa: "Білорусь",
    },
    {
      code: "BEL",
      value: "BEL",
      labelEn: "Belgium",
      labelRu: "Бельгия",
      labelCh: "比利时",
      labelFr: "Belgique",
      labelSp: "Bélgica",
      labelUa: "Бельгія",
    },
    {
      code: "BLZ",
      value: "BLZ",
      labelEn: "Belize",
      labelRu: "Белиз",
      labelCh: "伯利兹",
      labelFr: "Belize",
      labelSp: "Belice",
      labelUa: "Беліз",
    },
    {
      code: "BEN",
      value: "BEN",
      labelEn: "Benin",
      labelRu: "Бенин",
      labelCh: "贝宁",
      labelFr: "Bénin",
      labelSp: "Benin",
      labelUa: "Бенін",
    },
    {
      code: "BMU",
      value: "BMU",
      labelEn: "Bermuda",
      labelRu: "Бермуды",
      labelCh: "百慕大群岛",
      labelFr: "Bermudes",
      labelSp: "Bermuda",
      labelUa: "Бермудські острови",
    },
    {
      code: "BTN",
      value: "BTN",
      labelEn: "Bhutan",
      labelRu: "Бутан",
      labelCh: "不丹",
      labelFr: "Bhoutan",
      labelSp: "Bután",
      labelUa: "Бутан",
    },
    {
      code: "BO",
      value: "BO",
      labelEn: "Bolivia, plurinational state of",
      labelRu: "Боливия, Многонациональное Государство",
      labelCh: "玻利维亚",
      labelFr: "Bolivie ,  l'État plurinational de",
      labelSp: "Estado plurinacional de Bolivia",
      labelUa: "Болівія, Багатонаціональна Держава",
    },
    {
      code: "BIH",
      value: "BIH",
      labelEn: "Bosnia and Herzegovina",
      labelRu: "Босния и Герцеговина",
      labelCh: "波斯尼亚和黑塞哥维那",
      labelFr: "Bosnie-Herzégovine",
      labelSp: "Bosnia y Herzegovina",
      labelUa: "Боснія і Герцеговина",
    },
    {
      code: "BWA",
      value: "BWA",
      labelEn: "Botswana",
      labelRu: "Ботсвана",
      labelCh: "博茨瓦纳",
      labelFr: "Botswana",
      labelSp: "Botsuana",
      labelUa: "Ботсвана",
    },
    {
      code: "BVT",
      value: "BVT",
      labelEn: "Bouvet Island",
      labelRu: "Остров Буве",
      labelCh: "布韦岛",
      labelFr: "île Bouvet",
      labelSp: "Isla Bouvet",
      labelUa: "Блошиний острів",
    },
    {
      code: "BRA",
      value: "BRA",
      labelEn: "Brazil",
      labelRu: "Бразилия",
      labelCh: "巴西",
      labelFr: "Brésil",
      labelSp: "Brasil",
      labelUa: "Бразилія",
    },
    {
      code: "IO",
      value: "IO",
      labelEn: "British Indian Ocean Territory",
      labelRu: "Британская территория в Индийском океане",
      labelCh: "英属印度洋领土",
      labelFr: "Territoire britannique de l'océan Indien",
      labelSp: "Territorio Británico del Océano Índico",
      labelUa: "Британська територія в Індійському океані",
    },
    {
      code: "BRN",
      value: "BRN",
      labelEn: "Brunei Darussalam",
      labelRu: "Бруней-Даруссалам",
      labelCh: "文莱达鲁萨兰国",
      labelFr: "Brunei Darussalam",
      labelSp: "Brunei Darussalam",
      labelUa: "Бруней-Даруссалам",
    },
    {
      code: "BGR",
      value: "BGR",
      labelEn: "Bulgaria",
      labelRu: "Болгария",
      labelCh: "保加利亚",
      labelFr: "Bulgarie",
      labelSp: "Bulgaria",
      labelUa: "Болгарія",
    },
    {
      code: "BFA",
      value: "BFA",
      labelEn: "Burkina Faso",
      labelRu: "Буркина-Фасо",
      labelCh: "布基纳法索",
      labelFr: "Burkina Faso",
      labelSp: "Burkina Faso",
      labelUa: "Буркіна-Фасо",
    },
    {
      code: "BDI",
      value: "BDI",
      labelEn: "Burundi",
      labelRu: "Бурунди",
      labelCh: "布隆迪",
      labelFr: "Burundi",
      labelSp: "Burundi",
      labelUa: "Бурунді",
    },
    {
      code: "KHM",
      value: "KHM",
      labelEn: "Cambodia",
      labelRu: "Камбоджа",
      labelCh: "柬埔寨",
      labelFr: "Cambodge",
      labelSp: "Camboya",
      labelUa: "Камбоджа",
    },
    {
      code: "CMR",
      value: "CMR",
      labelEn: "Cameroon",
      labelRu: "Камерун",
      labelCh: "喀麦隆",
      labelFr: "Cameroun",
      labelSp: "Camerún",
      labelUa: "Камерун",
    },
    {
      code: "CAN",
      value: "CAN",
      labelEn: "Canada",
      labelRu: "Канада",
      labelCh: "加拿大",
      labelFr: "Canada",
      labelSp: "Canadá",
      labelUa: "Канада",
    },
    {
      code: "CV",
      value: "CV",
      labelEn: "Cape Verde",
      labelRu: "Кабо-Верде",
      labelCh: "佛得角",
      labelFr: "Cap-Vert",
      labelSp: "Cabo Verde",
      labelUa: "Кабо-Верде",
    },
    {
      code: "KY",
      value: "KY",
      labelEn: "Cayman Islands",
      labelRu: "Острова Кайман",
      labelCh: "开曼群岛",
      labelFr: "îles Caïmans",
      labelSp: "Islas Caimán",
      labelUa: "Кайманові острови",
    },
    {
      code: "CF",
      value: "CF",
      labelEn: "Central African Republic",
      labelRu: "Центрально-Африканская Республика",
      labelCh: "中非共和国",
      labelFr: "République centrafricaine",
      labelSp: "República Centroafricana",
      labelUa: "Центрально-Африканська Республіка",
    },
    {
      code: "TCD",
      value: "TCD",
      labelEn: "Chad",
      labelRu: "Чад",
      labelCh: "乍得",
      labelFr: "Tchad",
      labelSp: "Chad",
      labelUa: "Чад",
    },
    {
      code: "CHL",
      value: "CHL",
      labelEn: "Chile",
      labelRu: "Чили",
      labelCh: "智利",
      labelFr: "Chili",
      labelSp: "Chile",
      labelUa: "Чилі",
    },
    {
      code: "CHN",
      value: "CHN",
      labelEn: "China",
      labelRu: "Китай",
      labelCh: "中国",
      labelFr: "Chine",
      labelSp: "China",
      labelUa: "Китай",
    },
    {
      code: "CXR",
      value: "CXR",
      labelEn: "Christmas Island",
      labelRu: "Остров Рождества",
      labelCh: "圣诞岛",
      labelFr: "île Christmas",
      labelSp: "Isla de Navidad",
      labelUa: "Острів Різдва",
    },
    {
      code: "CC",
      value: "CC",
      labelEn: "Cocos (Keeling) Islands",
      labelRu: "Кокосовые (Килинг) острова",
      labelCh: "科科斯群岛",
      labelFr: "îles Cocos",
      labelSp: "Islas Cocos (Keeling)",
      labelUa: "Кокосові (Кілінг) острови.",
    },
    {
      code: "COL",
      value: "COL",
      labelEn: "Colombia",
      labelRu: "Колумбия",
      labelCh: "哥伦比亚",
      labelFr: "Colombie",
      labelSp: "Colombia",
      labelUa: "Колумбія",
    },
    {
      code: "KM",
      value: "KM",
      labelEn: "Comoros",
      labelRu: "Коморы",
      labelCh: "科摩罗",
      labelFr: "Comores",
      labelSp: "Comoras",
      labelUa: "Комори",
    },
    {
      code: "CG",
      value: "CG",
      labelEn: "Congo",
      labelRu: "Конго",
      labelCh: "刚果金",
      labelFr: "Congo",
      labelSp: "Congo",
      labelUa: "Конго",
    },
    {
      code: "CD",
      value: "CD",
      labelEn: "Congo, Democratic Republic of the",
      labelRu: "Конго, Демократическая Республика",
      labelCh: "刚果布",
      labelFr: "République démocratique du Congo",
      labelSp: "República Democrática del Congo",
      labelUa: "Конго, Демократична Республіка",
    },
    {
      code: "CDR",
      value: "CDR",
      labelEn: "Congo, Democratic Republic of the",
      labelRu: "Конго, Демократическая Республика",
      labelCh: "刚果布",
      labelFr: "République démocratique du Congo",
      labelSp: "República Democrática del Congo",
      labelUa: "Конго, Демократична Республіка",
    },
    {
      code: "CK",
      value: "CK",
      labelEn: "Cook Islands",
      labelRu: "Острова Кука",
      labelCh: "库克群岛",
      labelFr: "Îles Cook",
      labelSp: "Islas Cook",
      labelUa: "Острови Хук",
    },
    {
      code: "CRI",
      value: "CRI",
      labelEn: "Costa Rica",
      labelRu: "Коста-Рика",
      labelCh: "哥斯达黎加",
      labelFr: "Costa Rica",
      labelSp: "Costa Rica",
      labelUa: "Коста-Ріка",
    },
    {
      code: "CI",
      value: "CI",
      labelEn: "Cote d'Ivoire",
      labelRu: "Кот д'Ивуар",
      labelCh: "科特迪瓦",
      labelFr: "Côte d’Ivoire",
      labelSp: "Costa de Marfil",
      labelUa: "Кіт д'Івуар",
    },
    {
      code: "HRV",
      value: "HRV",
      labelEn: "Croatia",
      labelRu: "Хорватия",
      labelCh: "克罗地亚",
      labelFr: "Croatie",
      labelSp: "Croacia",
      labelUa: "Хорватія",
    },
    {
      code: "CUB",
      value: "CUB",
      labelEn: "Cuba",
      labelRu: "Куба",
      labelCh: "古巴",
      labelFr: "Cuba",
      labelSp: "Cuba",
      labelUa: "Куба",
    },
    {
      code: "CYP",
      value: "CYP",
      labelEn: "Cyprus",
      labelRu: "Кипр",
      labelCh: "塞浦路斯",
      labelFr: "Chypre",
      labelSp: "Chipre",
      labelUa: "Кіпр",
    },
    {
      code: "CZ",
      value: "CZ",
      labelEn: "Czech Republic",
      labelRu: "Чешская Республика",
      labelCh: "捷克共和国",
      labelFr: "République tchèque",
      labelSp: "Republica checa",
      labelUa: "Чеська Республіка",
    },
    {
      code: "DNK",
      value: "DNK",
      labelEn: "Denmark",
      labelRu: "Дания",
      labelCh: "丹麦",
      labelFr: "Danemark",
      labelSp: "Dinamarca",
      labelUa: "Данія",
    },
    {
      code: "DJI",
      value: "DJI",
      labelEn: "Djibouti",
      labelRu: "Джибути",
      labelCh: "吉布提",
      labelFr: "Djibouti",
      labelSp: "Djibouti",
      labelUa: "Джибуты",
    },
    {
      code: "DMA",
      value: "DMA",
      labelEn: "Dominica",
      labelRu: "Доминика",
      labelCh: "多米尼克国",
      labelFr: "Dominique",
      labelSp: "Dominica",
      labelUa: "Домініка",
    },
    {
      code: "DO",
      value: "DO",
      labelEn: "Dominican Republic",
      labelRu: "Доминиканская Республика",
      labelCh: "多米尼加共和国",
      labelFr: "République dominicaine",
      labelSp: "República Dominicana",
      labelUa: "Домініканська республіка",
    },
    {
      code: "ECU",
      value: "ECU",
      labelEn: "Ecuador",
      labelRu: "Эквадор",
      labelCh: "厄瓜多尔",
      labelFr: "Équateur",
      labelSp: "Ecuador",
      labelUa: "Еквадор",
    },
    {
      code: "EGY",
      value: "EGY",
      labelEn: "Egypt",
      labelRu: "Египет",
      labelCh: "埃及",
      labelFr: "Égypte",
      labelSp: "Egipto",
      labelUa: "Єгипет",
    },
    {
      code: "SLV",
      value: "SLV",
      labelEn: "El Salvador",
      labelRu: "Эль-Сальвадор",
      labelCh: "萨尔瓦多共和国",
      labelFr: "Salvador",
      labelSp: "El Salvador",
      labelUa: "Сальвадор",
    },
    {
      code: "GNQ",
      value: "GNQ",
      labelEn: "Equatorial Guinea",
      labelRu: "Экваториальная Гвинея",
      labelCh: "赤道几内亚",
      labelFr: "Guinée équatoriale",
      labelSp: "Guinea Ecuatorial",
      labelUa: "Екваторіальна Гвінея",
    },
    {
      code: "ERI",
      value: "ERI",
      labelEn: "Eritrea",
      labelRu: "Эритрея",
      labelCh: "厄立特里亚",
      labelFr: "Érythrée",
      labelSp: "Eritrea",
      labelUa: "Еритрея",
    },
    {
      code: "EST",
      value: "EST",
      labelEn: "Estonia",
      labelRu: "Эстония",
      labelCh: "爱沙尼亚",
      labelFr: "Estonie",
      labelSp: "Estonia",
      labelUa: "Естонія",
    },
    {
      code: "ETH",
      value: "ETH",
      labelEn: "Ethiopia",
      labelRu: "Эфиопия",
      labelCh: "埃塞俄比亚",
      labelFr: "Éthiopie",
      labelSp: "Etiopía",
      labelUa: "Ефіопія",
    },
    {
      code: "FK",
      value: "FK",
      labelEn: "Falkland Islands (Malvinas)",
      labelRu: "Фолклендские острова (Мальвинские)",
      labelCh: "马尔维纳斯群岛",
      labelFr: "îles Falkland (îles Malouines)",
      labelSp: "Islas Malvinas (Islas Malvinas)",
      labelUa: "Фолклендські острови (Мальвінські)",
    },
    {
      code: "FO",
      value: "FO",
      labelEn: "Faroe Islands",
      labelRu: "Фарерские острова",
      labelCh: "福克兰群岛",
      labelFr: "Îles Féroé",
      labelSp: "Islas Faroe",
      labelUa: "Фарерські острови",
    },
    {
      code: "FJI",
      value: "FJI",
      labelEn: "Fiji",
      labelRu: "Фиджи",
      labelCh: "斐济",
      labelFr: "Fidji",
      labelSp: "Fiji",
      labelUa: "Фіджі",
    },
    {
      code: "FIN",
      value: "FIN",
      labelEn: "Finland",
      labelRu: "Финляндия",
      labelCh: "芬兰",
      labelFr: "Finlande",
      labelSp: "Finlandia",
      labelUa: "Фінляндія",
    },
    {
      code: "FRA",
      value: "FRA",
      labelEn: "France",
      labelRu: "Франция",
      labelCh: "法国",
      labelFr: "France",
      labelSp: "Francia",
      labelUa: "Франція",
    },

    {
      code: "GUF",
      value: "GUF",
      labelEn: "French Guiana",
      labelRu: "Французская Гвиана",
      labelCh: "法属圭亚那",
      labelFr: "Guyane française",
      labelSp: "Guayana Francesa",
      labelUa: "Французька Гвіана",
    },
    {
      code: "PYF",
      value: "PYF",
      labelEn: "French Polynesia",
      labelRu: "Французская Полинезия",
      labelCh: "法属波利尼西亚",
      labelFr: "Polynésie française",
      labelSp: "Polinesia francesa",
      labelUa: "Французька Полінезія",
    },
    {
      code: "TF",
      value: "TF",
      labelEn: "French Southern Territories",
      labelRu: "Французские Южные территории",
      labelCh: "法属南部和南极土地",
      labelFr: "Terres australes et antarctiques françaises",
      labelSp: "Territorios Franceses del Sur",
      labelUa: "Французькі Південні території",
    },
    {
      code: "GAB",
      value: "GAB",
      labelEn: "Gabon",
      labelRu: "Габон",
      labelCh: "加蓬",
      labelFr: "Gabon",
      labelSp: "Gabón",
      labelUa: "Габон",
    },
    {
      code: "GM",
      value: "GM",
      labelEn: "Gambia",
      labelRu: "Гамбия",
      labelCh: "冈比亚",
      labelFr: "Gambie",
      labelSp: "Gambia",
      labelUa: "Гамбія",
    },
    {
      code: "GEO",
      value: "GEO",
      labelEn: "Georgia",
      labelRu: "Грузия",
      labelCh: "格鲁吉亚",
      labelFr: "Géorgie",
      labelSp: "Georgia",
      labelUa: "Грузія",
    },
    {
      code: "DEU",
      value: "DEU",
      labelEn: "Germany",
      labelRu: "Германия",
      labelCh: "德国",
      labelFr: "Allemagne",
      labelSp: "Alemania",
      labelUa: "Німеччина",
    },
    {
      code: "GHA",
      value: "GHA",
      labelEn: "Ghana",
      labelRu: "Гана",
      labelCh: "加纳",
      labelFr: "Ghana",
      labelSp: "Ghana",
      labelUa: "Гана",
    },
    {
      code: "GIB",
      value: "GIB",
      labelEn: "Gibraltar",
      labelRu: "Гибралтар",
      labelCh: "直布罗陀",
      labelFr: "Gibraltar",
      labelSp: "Gibraltar",
      labelUa: "Гібралтар",
    },
    {
      code: "GRC",
      value: "GRC",
      labelEn: "Greece",
      labelRu: "Греция",
      labelCh: "希腊",
      labelFr: "Grèce",
      labelSp: "Grecia",
      labelUa: "Греція",
    },
    {
      code: "GRL",
      value: "GRL",
      labelEn: "Greenland",
      labelRu: "Гренландия",
      labelCh: "格陵兰岛",
      labelFr: "Groenland",
      labelSp: "Groenlandia",
      labelUa: "Гренландія",
    },
    {
      code: "GRD",
      value: "GRD",
      labelEn: "Grenada",
      labelRu: "Гренада",
      labelCh: "格林纳达",
      labelFr: "Grenade",
      labelSp: "Granada",
      labelUa: "Гренада",
    },
    {
      code: "GLP",
      value: "GLP",
      labelEn: "Guadeloupe",
      labelRu: "Гваделупа",
      labelCh: "瓜德罗普",
      labelFr: "Guadeloupe",
      labelSp: "Guadalupe",
      labelUa: "Гваделупа",
    },
    {
      code: "GUM",
      value: "GUM",
      labelEn: "Guam",
      labelRu: "Гуам",
      labelCh: "关岛",
      labelFr: "Guam",
      labelSp: "Guam",
      labelUa: "Гуам",
    },
    {
      code: "GTM",
      value: "GTM",
      labelEn: "Guatemala",
      labelRu: "Гватемала",
      labelCh: "危地马拉",
      labelFr: "Guatemala",
      labelSp: "Guatemala",
      labelUa: "Гватемала",
    },
    {
      code: "GGY",
      value: "GGY",
      labelEn: "Guernsey",
      labelRu: "Гернси",
      labelCh: "根西岛",
      labelFr: "Bailliage de Guernesey",
      labelSp: "Guernsey",
      labelUa: "Гернссі",
    },
    {
      code: "GIN",
      value: "GIN",
      labelEn: "Guinea",
      labelRu: "Гвинея",
      labelCh: "几内亚",
      labelFr: "Guinée",
      labelSp: "Guinea",
      labelUa: "Гвінея",
    },
    {
      code: "GNB",
      value: "GNB",
      labelEn: "Guinea-Bissau",
      labelRu: "Гвинея-Бисау",
      labelCh: "几内亚比绍",
      labelFr: "Guinée-Bissau",
      labelSp: "Guinea-Bissau",
      labelUa: "Гвінея-Бісау",
    },
    {
      code: "GUY",
      value: "GUY",
      labelEn: "Guyana",
      labelRu: "Гайана",
      labelCh: "圭亚那",
      labelFr: "Guyana",
      labelSp: "Guayana",
      labelUa: "Гайана",
    },
    {
      code: "HTI",
      value: "HTI",
      labelEn: "Haiti",
      labelRu: "Гаити",
      labelCh: "海地",
      labelFr: "Haïti",
      labelSp: "Haití",
      labelUa: "Гаїті",
    },
    {
      code: "HMD",
      value: "HMD",
      labelEn: "Heard Island and McDonald Islands",
      labelRu: "Остров Херд и острова Макдональд",
      labelCh: "赫德岛和麦克唐纳群岛",
      labelFr: "îles Heard-et-MacDonald",
      labelSp: "Islas Heard y McDonald",
      labelUa: "Острів стада та острів Макдональд",
    },
    {
      code: "VA",
      value: "VA",
      labelEn: "Holy See (Vatican City State)",
      labelRu: "Папский Престол (Государство — город Ватикан)",
      labelCh: "梵蒂冈城国",
      labelFr: "État de la Cité du Vatican",
      labelSp: "Santa Sede (Estado de la Ciudad del Vaticano)",
      labelUa: "Папський престол (Держава - місто Ватикан)",
    },
    {
      code: "HND",
      value: "HND",
      labelEn: "Honduras",
      labelRu: "Гондурас",
      labelCh: "洪都拉斯",
      labelFr: "Honduras",
      labelSp: "Honduras",
      labelUa: "Гондурас",
    },
    {
      code: "HKG",
      value: "HKG",
      labelEn: "Hong Kong",
      labelRu: "Гонконг",
      labelCh: "香港",
      labelFr: "Hong Kong",
      labelSp: "Hong Kong",
      labelUa: "Гонконг",
    },
    {
      code: "HUN",
      value: "HUN",
      labelEn: "Hungary",
      labelRu: "Венгрия",
      labelCh: "匈牙利",
      labelFr: "Hongrie",
      labelSp: "Hungría",
      labelUa: "Угорщина",
    },
    {
      code: "ISL",
      value: "ISL",
      labelEn: "Iceland",
      labelRu: "Исландия",
      labelCh: "冰岛",
      labelFr: "Islande",
      labelSp: "Islandia",
      labelUa: "Ісландія",
    },
    {
      code: "IND",
      value: "IND",
      labelEn: "India",
      labelRu: "Индия",
      labelCh: "印度",
      labelFr: "Inde",
      labelSp: "India",
      labelUa: "Індія",
    },
    {
      code: "IDN",
      value: "IDN",
      labelEn: "Indonesia",
      labelRu: "Индонезия",
      labelCh: "印度尼西亚",
      labelFr: "Indonésie",
      labelSp: "Indonesia",
      labelUa: "Індонезія",
    },
    {
      code: "IR",
      value: "IR",
      labelEn: "Iran, Islamic Republic of",
      labelRu: "Иран, Исламская Республика",
      labelCh: "伊朗伊斯兰共和国",
      labelFr: "République islamique d'Iran",
      labelSp: "República Islámica de Irán",
      labelUa: "Іран, Ісламська Республіка",
    },
    {
      code: "IRQ",
      value: "IRQ",
      labelEn: "Iraq",
      labelRu: "Ирак",
      labelCh: "伊拉克",
      labelFr: "Irak",
      labelSp: "Irak",
      labelUa: "Ірак",
    },
    {
      code: "IRL",
      value: "IRL",
      labelEn: "Ireland",
      labelRu: "Ирландия",
      labelCh: "爱尔兰",
      labelFr: "Irlande",
      labelSp: "Irlanda",
      labelUa: "Ірландія",
    },
    {
      code: "IMN",
      value: "IMN",
      labelEn: "Isle of Man",
      labelRu: "Остров Мэн",
      labelCh: "马恩岛",
      labelFr: "île de Man",
      labelSp: "Isla de Man",
      labelUa: "Острів Мен",
    },
    {
      code: "ISR",
      value: "ISR",
      labelEn: "Israel",
      labelRu: "Израиль",
      labelCh: "以色列",
      labelFr: "Israël",
      labelSp: "Israel",
      labelUa: "Ізраїль",
    },
    {
      code: "ITA",
      value: "ITA",
      labelEn: "Italy",
      labelRu: "Италия",
      labelCh: "意大利",
      labelFr: "Italie",
      labelSp: "Italia",
      labelUa: "Італія",
    },
    {
      code: "JAM",
      value: "JAM",
      labelEn: "Jamaica",
      labelRu: "Ямайка",
      labelCh: "牙买加",
      labelFr: "Jamaïque",
      labelSp: "Jamaica",
      labelUa: "Ямайка",
    },
    {
      code: "JPN",
      value: "JPN",
      labelEn: "Japan",
      labelRu: "Япония",
      labelCh: "日本",
      labelFr: "Japon",
      labelSp: "Japón",
      labelUa: "Японія",
    },
    {
      code: "JEY",
      value: "JEY",
      labelEn: "Jersey",
      labelRu: "Джерси",
      labelCh: "泽西岛",
      labelFr: "Jersey",
      labelSp: "Jersey",
      labelUa: "Джерсі",
    },
    {
      code: "JOR",
      value: "JOR",
      labelEn: "Jordan",
      labelRu: "Иордания",
      labelCh: "约旦",
      labelFr: "Jordanie",
      labelSp: "Jordán",
      labelUa: "Йордан",
    },
    {
      code: "KAZ",
      value: "KAZ",
      labelEn: "Kazakhstan",
      labelRu: "Казахстан",
      labelCh: "哈萨克斯坦",
      labelFr: "Kazakhstan",
      labelSp: "Kazajstán",
      labelUa: "Казахстан",
    },
    {
      code: "KEN",
      value: "KEN",
      labelEn: "Kenya",
      labelRu: "Кения",
      labelCh: "肯尼亚",
      labelFr: "Kenya",
      labelSp: "Kenia",
      labelUa: "Кенія",
    },
    {
      code: "KIR",
      value: "KIR",
      labelEn: "Kiribati",
      labelRu: "Кирибати",
      labelCh: "基里巴斯",
      labelFr: "Kiribati",
      labelSp: "Kiribati",
      labelUa: "Кірібаті",
    },
    {
      code: "KP",
      value: "KP",
      labelEn: "Korea, Democratic People's republic of",
      labelRu: "Корея, Народно-Демократическая Республика",
      labelCh: "朝鲜民主主义人民共和国",
      labelFr: "République populaire démocratique de Corée",
      labelSp: "República Popular Democrática de Corea",
      labelUa: "Корея, Народно-Демократична Республіка",
    },
    {
      code: "KR",
      value: "KR",
      labelEn: "Korea, Republic of",
      labelRu: "Корея, Республика",
      labelCh: "韩国",
      labelFr: "République de Corée",
      labelSp: "República de Corea",
      labelUa: "Корея, Республіка",
    },
    {
      code: "KWT",
      value: "KWT",
      labelEn: "Kuwait",
      labelRu: "Кувейт",
      labelCh: "科威特",
      labelFr: "Koweït",
      labelSp: "Kuwait",
      labelUa: "Кувейт",
    },
    {
      code: "KGZ",
      value: "KGZ",
      labelEn: "Kyrgyzstan",
      labelRu: "Киргизия",
      labelCh: "吉尔吉斯",
      labelFr: "Kirghizistan",
      labelSp: "Kirguizstán",
      labelUa: "Киргизстан",
    },
    {
      code: "LA",
      value: "LA",
      labelEn: "Lao People's Democratic Republic",
      labelRu: "Лаос",
      labelCh: "老挝",
      labelFr: "République démocratique populaire du Laos",
      labelSp: "República Democrática Popular Lao",
      labelUa: "Лаос",
    },
    {
      code: "LVA",
      value: "LVA",
      labelEn: "Latvia",
      labelRu: "Латвия",
      labelCh: "拉脱维亚",
      labelFr: "République de Lettonie",
      labelSp: "Letonia",
      labelUa: "Латвія",
    },
    {
      code: "LBN",
      value: "LBN",
      labelEn: "Lebanon",
      labelRu: "Ливан",
      labelCh: "黎巴嫩",
      labelFr: "Liban",
      labelSp: "Líbano",
      labelUa: "Ліван",
    },
    {
      code: "LSO",
      value: "LSO",
      labelEn: "Lesotho",
      labelRu: "Лесото",
      labelCh: "莱索托 ",
      labelFr: "Lesotho",
      labelSp: "Lesoto",
      labelUa: "Лесото",
    },
    {
      code: "LBR",
      value: "LBR",
      labelEn: "Liberia",
      labelRu: "Либерия",
      labelCh: "利比里亚",
      labelFr: "Liberia",
      labelSp: "Liberia",
      labelUa: "Ліберія",
    },
    {
      code: "LY",
      value: "LY",
      labelEn: "Libyan Arab Jamahiriya",
      labelRu: "Ливийская Арабская Джамахирия",
      labelCh: "阿拉伯利比亚民众国",
      labelFr: "Jamahiriya arabe libyenne",
      labelSp: "Jamahiriya Árabe Libia",
      labelUa: "Лівійська Арабська Джамахірія",
    },
    {
      code: "LIE",
      value: "LIE",
      labelEn: "Liechtenstein",
      labelRu: "Лихтенштейн",
      labelCh: "列支敦士登 ",
      labelFr: "Liechtenstein",
      labelSp: "Liechtenstein",
      labelUa: "Ліхтенштейн",
    },
    {
      code: "LTU",
      value: "LTU",
      labelEn: "Lithuania",
      labelRu: "Литва",
      labelCh: "立陶宛",
      labelFr: "Lituanie",
      labelSp: "Lituania",
      labelUa: "Литва",
    },
    {
      code: "LUX",
      value: "LUX",
      labelEn: "Luxembourg",
      labelRu: "Люксембург",
      labelCh: "卢森堡",
      labelFr: "Luxembourg",
      labelSp: "Luxemburgo",
      labelUa: "Люксембург",
    },
    {
      code: "MAC",
      value: "MAC",
      labelEn: "Macao",
      labelRu: "Макао",
      labelCh: "澳门",
      labelFr: "Macao",
      labelSp: "Macao",
      labelUa: "Макао",
    },
    {
      code: "MK",
      value: "MK",
      labelEn: "Macedonia, The Former Yugoslav Republic Of",
      labelRu: "Республика Македония",
      labelCh: "马其顿共和国",
      labelFr: "République de Macédoine du Nord",
      labelSp: "República de macedonia",
      labelUa: "Республіка Македонія",
    },
    {
      code: "MDG",
      value: "MDG",
      labelEn: "Madagascar",
      labelRu: "Мадагаскар",
      labelCh: "马达加斯加",
      labelFr: "Madagascar",
      labelSp: "Madagascar",
      labelUa: "Мадагаскар",
    },
    {
      code: "MWI",
      value: "MWI",
      labelEn: "Malawi",
      labelRu: "Малави",
      labelCh: "马拉维",
      labelFr: "Malawi",
      labelSp: "Malawi",
      labelUa: "Малаві",
    },
    {
      code: "MYS",
      value: "MYS",
      labelEn: "Malaysia",
      labelRu: "Малайзия",
      labelCh: "马来西亚",
      labelFr: "Malaisie",
      labelSp: "Malasia",
      labelUa: "Малайзія",
    },
    {
      code: "MDV",
      value: "MDV",
      labelEn: "Maldives",
      labelRu: "Мальдивы",
      labelCh: "马尔代夫",
      labelFr: "Maldives",
      labelSp: "Maldivas",
      labelUa: "Мальдіви",
    },
    {
      code: "MLI",
      value: "MLI",
      labelEn: "Mali",
      labelRu: "Мали",
      labelCh: "马里",
      labelFr: "Mali",
      labelSp: "Mali",
      labelUa: "Малі",
    },
    {
      code: "MLT",
      value: "MLT",
      labelEn: "Malta",
      labelRu: "Мальта",
      labelCh: "马耳他",
      labelFr: "Malte",
      labelSp: "Malta",
      labelUa: "Мальта",
    },
    {
      code: "MH",
      value: "MH",
      labelEn: "Marshall Islands",
      labelRu: "Маршалловы острова",
      labelCh: "马绍尔群岛",
      labelFr: "Îles Marshall",
      labelSp: "Islas Marshall",
      labelUa: "Маршаллські острови",
    },
    {
      code: "MTQ",
      value: "MTQ",
      labelEn: "Martinique",
      labelRu: "Мартиника",
      labelCh: "马提尼克岛 ",
      labelFr: "Martinique",
      labelSp: "Martinica",
      labelUa: "Мартінік",
    },
    {
      code: "MRT",
      value: "MRT",
      labelEn: "Mauritania",
      labelRu: "Мавритания",
      labelCh: "毛里塔尼亚",
      labelFr: "Mauritanie",
      labelSp: "Mauritania",
      labelUa: "Мавританія",
    },
    {
      code: "MUS",
      value: "MUS",
      labelEn: "Mauritius",
      labelRu: "Маврикий",
      labelCh: "毛里求斯",
      labelFr: "Maurice",
      labelSp: "Mauricio",
      labelUa: "Маврикій",
    },
    {
      code: "MYT",
      value: "MYT",
      labelEn: "Mayotte",
      labelRu: "Майотта",
      labelCh: "马约特",
      labelFr: "Mayotte",
      labelSp: "Mayotte",
      labelUa: "Мейотта",
    },
    {
      code: "MEX",
      value: "MEX",
      labelEn: "Mexico",
      labelRu: "Мексика",
      labelCh: "墨西哥",
      labelFr: "Mexique",
      labelSp: "Mexico",
      labelUa: "Мексика",
    },
    {
      code: "FM",
      value: "FM",
      labelEn: "Micronesia, Federated States of",
      labelRu: "Микронезия, Федеративные Штаты",
      labelCh: "密克罗尼西亚联邦",
      labelFr: "États fédérés de Micronésie",
      labelSp: "Micronesia, estados federados",
      labelUa: "Федеративні Штати Мікронезії ",
    },
    {
      code: "MD",
      value: "MD",
      labelEn: "Moldova",
      labelRu: "Молдова, Республика",
      labelCh: "摩尔多瓦共和国",
      labelFr: "Moldavie",
      labelSp: "Moldavia",
      labelUa: "Молдова, республіка",
    },
    {
      code: "MCO",
      value: "MCO",
      labelEn: "Monaco",
      labelRu: "Монако",
      labelCh: "摩纳哥",
      labelFr: "Monaco",
      labelSp: "Mónaco",
      labelUa: "Монако",
    },
    {
      code: "MNG",
      value: "MNG",
      labelEn: "Mongolia",
      labelRu: "Монголия",
      labelCh: "蒙古",
      labelFr: "Mongolie",
      labelSp: "Mongolia",
      labelUa: "Монголія",
    },
    {
      code: "MSR",
      value: "MSR",
      labelEn: "Montserrat",
      labelRu: "Монтсеррат",
      labelCh: "蒙特塞拉特",
      labelFr: "Montserrat",
      labelSp: "Montserrat",
      labelUa: "Монтсеррат",
    },
    {
      code: "MAR",
      value: "MAR",
      labelEn: "Morocco",
      labelRu: "Марокко",
      labelCh: "摩洛哥",
      labelFr: "Maroc",
      labelSp: "Marruecos",
      labelUa: "Марокко",
    },
    {
      code: "MOZ",
      value: "MOZ",
      labelEn: "Mozambique",
      labelRu: "Мозамбик",
      labelCh: "莫三比克",
      labelFr: "Mozambique",
      labelSp: "Mozambique",
      labelUa: "Мозамбік",
    },
    {
      code: "MMR",
      value: "MMR",
      labelEn: "Myanmar",
      labelRu: "Мьянма",
      labelCh: "缅甸",
      labelFr: "Myanmar",
      labelSp: "Myanmar",
      labelUa: "М'янма",
    },
    {
      code: "NAM",
      value: "NAM",
      labelEn: "Namibia",
      labelRu: "Намибия",
      labelCh: "纳米比亚",
      labelFr: "Namibie",
      labelSp: "Namibia",
      labelUa: "Намібія",
    },
    {
      code: "NRU",
      value: "NRU",
      labelEn: "Nauru",
      labelRu: "Науру",
      labelCh: "瑙鲁",
      labelFr: "Nauru",
      labelSp: "Nauru",
      labelUa: "Науру",
    },
    {
      code: "NPL",
      value: "NPL",
      labelEn: "Nepal",
      labelRu: "Непал",
      labelCh: "尼泊尔",
      labelFr: "Népal",
      labelSp: "Nepal",
      labelUa: "Непал",
    },
    {
      code: "NL",
      value: "NL",
      labelEn: "Netherlands",
      labelRu: "Нидерланды",
      labelCh: "荷兰",
      labelFr: "Pays-Bas",
      labelSp: "Países Bajos",
      labelUa: "Нідерланди",
    },
    {
      code: "NCL",
      value: "NCL",
      labelEn: "New Caledonia",
      labelRu: "Новая Каледония",
      labelCh: "新喀里多尼亚",
      labelFr: "Nouvelle-Calédonie",
      labelSp: "Nueva Caledonia",
      labelUa: "Нова Каледонія",
    },
    {
      code: "NZL",
      value: "NZL",
      labelEn: "New Zealand",
      labelRu: "Новая Зеландия",
      labelCh: "新西兰",
      labelFr: "Nouvelle-Zélande",
      labelSp: "Nueva Zelanda",
      labelUa: "Нова Зеландія",
    },
    {
      code: "NIC",
      value: "NIC",
      labelEn: "Nicaragua",
      labelRu: "Никарагуа",
      labelCh: "尼加拉瓜",
      labelFr: "Nicaragua",
      labelSp: "Nicaragua",
      labelUa: "Нікарагуа",
    },
    {
      code: "NE",
      value: "NE",
      labelEn: "Niger",
      labelRu: "Нигер",
      labelCh: "尼日尔",
      labelFr: "Niger",
      labelSp: "Níger",
      labelUa: "Нігер",
    },
    {
      code: "NGA",
      value: "NGA",
      labelEn: "Nigeria",
      labelRu: "Нигерия",
      labelCh: "尼日利亚",
      labelFr: "Nigeria",
      labelSp: "Nigeria",
      labelUa: "Нігерія",
    },
    {
      code: "NIU",
      value: "NIU",
      labelEn: "Niue",
      labelRu: "Ниуэ",
      labelCh: "纽埃",
      labelFr: "Niue",
      labelSp: "Niue",
      labelUa: "Ніуе",
    },
    {
      code: "NFK",
      value: "NFK",
      labelEn: "Norfolk Island",
      labelRu: "Остров Норфолк",
      labelCh: "诺福克岛",
      labelFr: "île Norfolk",
      labelSp: "Isla Norfolk",
      labelUa: "Острів Норфолк",
    },
    {
      code: "MP",
      value: "MP",
      labelEn: "Northern Mariana Islands",
      labelRu: "Северные Марианские острова",
      labelCh: "北马里亚纳群岛",
      labelFr: "îles Mariannes du Nord",
      labelSp: "Islas Marianas del Norte",
      labelUa: "Північні Маріанські острови",
    },
    {
      code: "NOR",
      value: "NOR",
      labelEn: "Norway",
      labelRu: "Норвегия",
      labelCh: "挪威",
      labelFr: "Norvège",
      labelSp: "Noruega",
      labelUa: "Норвегія",
    },
    {
      code: "OMN",
      value: "OMN",
      labelEn: "Oman",
      labelRu: "Оман",
      labelCh: "阿曼",
      labelFr: "Oman",
      labelSp: "Omán",
      labelUa: "Оман",
    },
    {
      code: "PAK",
      value: "PAK",
      labelEn: "Pakistan",
      labelRu: "Пакистан",
      labelCh: "巴基斯坦",
      labelFr: "Pakistan",
      labelSp: "Pakistán",
      labelUa: "Пакистан",
    },
    {
      code: "PLW",
      value: "PLW",
      labelEn: "Palau",
      labelRu: "Палау",
      labelCh: "帕劳",
      labelFr: "Palaos",
      labelSp: "Palau",
      labelUa: "Палау",
    },
    {
      code: "PS",
      value: "PS",
      labelEn: "Palestinian Territory, Occupied",
      labelRu: "Палестинская территория, оккупированная",
      labelCh: "巴勒斯坦",
      labelFr: "État de Palestine , occupés",
      labelSp: "Territorio Palestino, Ocupado",
      labelUa: "Палестинська територія зайнята",
    },
    {
      code: "PAN",
      value: "PAN",
      labelEn: "Panama",
      labelRu: "Панама",
      labelCh: "巴拿马",
      labelFr: "Panama",
      labelSp: "Panamá",
      labelUa: "Панама",
    },
    {
      code: "PNG",
      value: "PNG",
      labelEn: "Papua New Guinea",
      labelRu: "Папуа-Новая Гвинея",
      labelCh: "巴布亚新几内亚 ",
      labelFr: "Papouasie-Nouvelle-Guinée",
      labelSp: "Papúa Nueva Guinea",
      labelUa: "Папуа Нова Гвінея",
    },
    {
      code: "PRY",
      value: "PRY",
      labelEn: "Paraguay",
      labelRu: "Парагвай",
      labelCh: "巴拉圭",
      labelFr: "Paraguay",
      labelSp: "Paraguay",
      labelUa: "Парагвай",
    },
    {
      code: "PER",
      value: "PER",
      labelEn: "Peru",
      labelRu: "Перу",
      labelCh: "秘鲁",
      labelFr: "Pérou",
      labelSp: "Perú",
      labelUa: "Перу",
    },
    {
      code: "PH",
      value: "PH",
      labelEn: "Philippines",
      labelRu: "Филиппины",
      labelCh: "菲律宾",
      labelFr: "Philippines",
      labelSp: "Filipinas",
      labelUa: "Філіппіни",
    },
    {
      code: "PCN",
      value: "PCN",
      labelEn: "Pitcairn",
      labelRu: "Питкерн",
      labelCh: "皮特克恩岛",
      labelFr: "Pitcairn",
      labelSp: "Pitcairn",
      labelUa: "Піткерн",
    },
    {
      code: "POL",
      value: "POL",
      labelEn: "Poland",
      labelRu: "Польша",
      labelCh: "波兰",
      labelFr: "Pologne",
      labelSp: "Polonia",
      labelUa: "Польща",
    },
    {
      code: "PRT",
      value: "PRT",
      labelEn: "Portugal",
      labelRu: "Португалия",
      labelCh: "葡萄牙",
      labelFr: "Portugal",
      labelSp: "Portugal",
      labelUa: "Португалія",
    },
    {
      code: "PRI",
      value: "PRI",
      labelEn: "Puerto Rico",
      labelRu: "Пуэрто-Рико",
      labelCh: "波多黎各",
      labelFr: "Porto Rico",
      labelSp: "Puerto Rico",
      labelUa: "Пуерто-Рико",
    },
    {
      code: "QAT",
      value: "QAT",
      labelEn: "Qatar",
      labelRu: "Катар",
      labelCh: "卡塔尔",
      labelFr: "Qatar",
      labelSp: "Katar",
      labelUa: "Катар",
    },
    {
      code: "RE",
      value: "RE",
      labelEn: "Reunion",
      labelRu: "Реюньон",
      labelCh: "留尼汪岛",
      labelFr: "Réunion",
      labelSp: "Reunion",
      labelUa: "Реюньйон",
    },
    {
      code: "ROU",
      value: "ROU",
      labelEn: "Romania",
      labelRu: "Румыния",
      labelCh: "罗马尼亚",
      labelFr: "Roumanie",
      labelSp: "Rumania",
      labelUa: "Румунія",
    },
    {
      code: "RU",
      value: "RU",
      labelEn: "Russian Federation",
      labelRu: "Россия",
      labelCh: "俄罗斯",
      labelFr: "Russie",
      labelSp: "Federación Rusa",
      labelUa: "Росія",
    },
    {
      code: "RWA",
      value: "RWA",
      labelEn: "Rwanda",
      labelRu: "Руанда",
      labelCh: "卢旺达",
      labelFr: "Rwanda",
      labelSp: "Ruanda",
      labelUa: "Руанда",
    },
    {
      code: "SH",
      value: "SH",
      labelEn: "Saint Helena, Ascension And Tristan Da Cunha",
      labelRu: "Святая Елена, Остров вознесения, Тристан-да-Кунья",
      labelCh: "圣赫勒拿岛",
      labelFr: "Sainte-Hélène, Ascension et Tristan da Cunha",
      labelSp: "Santa Helena, Ascensión Y Tristán Da Cunha",
      labelUa: "Свята Олена, острів Вознесіння, Трістан-Да-Куня",
    },
    {
      code: "KNA",
      value: "KNA",
      labelEn: "Saint Kitts and Nevis",
      labelRu: "Сент-Китс и Невис",
      labelCh: "圣基茨和尼维斯",
      labelFr: "Saint-Christophe-et-Niévès",
      labelSp: "San Cristóbal y Nieves",
      labelUa: "Сен-Кітс і Невіс",
    },
    {
      code: "LCA",
      value: "LCA",
      labelEn: "Saint Lucia",
      labelRu: "Сент-Люсия",
      labelCh: "圣路西亚",
      labelFr: "Sainte-Lucie",
      labelSp: "Santa Lucia",
      labelUa: "Сент-Лусія",
    },
    {
      code: "SPM",
      value: "SPM",
      labelEn: "Saint Pierre and Miquelon",
      labelRu: "Сент-Пьер и Микелон",
      labelCh: "圣皮埃尔密克隆群岛",
      labelFr: "Saint-Pierre-et-Miquelon",
      labelSp: "San Pedro y Miquelón",
      labelUa: "Сен-П'єр та Мішелон",
    },
    {
      code: "VCT",
      value: "VCT",
      labelEn: "Saint Vincent and the Grenadines",
      labelRu: "Сент-Винсент и Гренадины",
      labelCh: "圣文森蒂格林纳丁斯",
      labelFr: "Saint-Vincent-et-les-Grenadines",
      labelSp: "San Vicente y las Granadinas",
      labelUa: "Сен-Вінсент та Гренадини",
    },
    {
      code: "WSM",
      value: "WSM",
      labelEn: "Samoa",
      labelRu: "Самоа",
      labelCh: "萨摩亚",
      labelFr: "Samoa",
      labelSp: "Samoa",
      labelUa: "Самоа",
    },
    {
      code: "SMR",
      value: "SMR",
      labelEn: "San Marino",
      labelRu: "Сан-Марино",
      labelCh: "圣马力诺",
      labelFr: "Saint-Marin",
      labelSp: "San Marino",
      labelUa: "Сан -Маріно",
    },
    {
      code: "STP",
      value: "STP",
      labelEn: "Sao Tome and Principe",
      labelRu: "Сан-Томе и Принсипи",
      labelCh: "圣多美岛和普林西比岛",
      labelFr: "Sao Tomé-et-Principe",
      labelSp: "Santo Tomé y Príncipe",
      labelUa: "Сан -Том і Принци",
    },
    {
      code: "SAU",
      value: "SAU",
      labelEn: "Saudi Arabia",
      labelRu: "Саудовская Аравия",
      labelCh: "沙特阿拉伯",
      labelFr: "Arabie saoudite",
      labelSp: "Arabia Saudita",
      labelUa: "Саудівська Аравія",
    },
    {
      code: "SEN",
      value: "SEN",
      labelEn: "Senegal",
      labelRu: "Сенегал",
      labelCh: "塞内加尔",
      labelFr: "Sénégal",
      labelSp: "Senegal",
      labelUa: "Сенегал",
    },
    {
      code: "SYC",
      value: "SYC",
      labelEn: "Seychelles",
      labelRu: "Сейшелы",
      labelCh: "塞舌尔群岛",
      labelFr: "Seychelles",
      labelSp: "Seychelles",
      labelUa: "Сейше́ли ",
    },
    {
      code: "SLE",
      value: "SLE",
      labelEn: "Sierra Leone",
      labelRu: "Сьерра-Леоне",
      labelCh: "塞拉利昂",
      labelFr: "Sierra Leone",
      labelSp: "Sierra Leone",
      labelUa: "Сьєрра-Леоне",
    },
    {
      code: "SGP",
      value: "SGP",
      labelEn: "Singapore",
      labelRu: "Сингапур",
      labelCh: "新加坡",
      labelFr: "Singapour",
      labelSp: "Singapur",
      labelUa: "Сінгапур",
    },
    {
      code: "SVK",
      value: "SVK",
      labelEn: "Slovakia",
      labelRu: "Словакия",
      labelCh: "斯洛伐克",
      labelFr: "Slovaquie",
      labelSp: "Eslovaquia",
      labelUa: "Словаччина",
    },
    {
      code: "SVN",
      value: "SVN",
      labelEn: "Slovenia",
      labelRu: "Словения",
      labelCh: "斯洛文尼亚",
      labelFr: "Slovénie",
      labelSp: "Eslovenia",
      labelUa: "Словенія",
    },
    {
      code: "SLB",
      value: "SLB",
      labelEn: "Solomon Islands",
      labelRu: "Соломоновы острова",
      labelCh: "所罗门群岛",
      labelFr: "Îles Salomon",
      labelSp: "Islas Salomón",
      labelUa: "Соломонські острови",
    },
    {
      code: "SOM",
      value: "SOM",
      labelEn: "Somalia",
      labelRu: "Сомали",
      labelCh: "索马里",
      labelFr: "Somalie",
      labelSp: "Somalia",
      labelUa: "Сомалі",
    },
    {
      code: "ZAF",
      value: "ZAF",
      labelEn: "South Africa",
      labelRu: "Южная Африка",
      labelCh: "南非",
      labelFr: "Afrique du Sud",
      labelSp: "Sudáfrica",
      labelUa: "Південна Африка",
    },
    {
      code: "SGS",
      value: "SGS",
      labelEn: "South Georgia and the South Sandwich Islands",
      labelRu: "Южная Джорджия и Южные Сандвичевы острова",
      labelCh: "南非",
      labelFr: "Géorgie du Sud-et-les îles Sandwich du Sud",
      labelSp: "Georgia del sur y las islas Sandwich del sur",
      labelUa: "Острови Південної Джорджії та Південний Сендвічев",
    },
    {
      code: "ESP",
      value: "ESP",
      labelEn: "Spain",
      labelRu: "Испания",
      labelCh: "西班牙",
      labelFr: "Espagne",
      labelSp: "España",
      labelUa: "Іспанія",
    },
    {
      code: "LKA",
      value: "LKA",
      labelEn: "Sri Lanka",
      labelRu: "Шри-Ланка",
      labelCh: "斯里兰卡",
      labelFr: "Sri Lanka",
      labelSp: "Sri Lanka",
      labelUa: "Шрі Ланка",
    },
    {
      code: "SD",
      value: "SD",
      labelEn: "Sudan",
      labelRu: "Судан",
      labelCh: "苏丹",
      labelFr: "Soudan",
      labelSp: "Sudán",
      labelUa: "Судан",
    },
    {
      code: "SUR",
      value: "SUR",
      labelEn: "Suriname",
      labelRu: "Суринам",
      labelCh: "苏里南",
      labelFr: "Suriname",
      labelSp: "Surinam",
      labelUa: "Сурінам",
    },
    {
      code: "SJM",
      value: "SJM",
      labelEn: "Svalbard and Jan Mayen",
      labelRu: "Шпицберген и Ян Майен",
      labelCh: "斯瓦尔巴群岛和扬马延岛",
      labelFr: "Svalbard et Jan Mayen",
      labelSp: "Svalbardn y Jan Mayen",
      labelUa: "Шпіцберген та Ян Майєн",
    },
    {
      code: "SZ",
      value: "SZ",
      labelEn: "Swaziland",
      labelRu: "Свазиленд",
      labelCh: "斯威士兰",
      labelFr: "Swaziland",
      labelSp: "Suazilandia",
      labelUa: "Свазіленд",
    },
    {
      code: "SWE",
      value: "SWE",
      labelEn: "Sweden",
      labelRu: "Швеция",
      labelCh: "瑞典",
      labelFr: "Suède",
      labelSp: "Suecia",
      labelUa: "Швеція",
    },
    {
      code: "CHE",
      value: "CHE",
      labelEn: "Switzerland",
      labelRu: "Швейцария",
      labelCh: "瑞士",
      labelFr: "Suisse",
      labelSp: "Suiza",
      labelUa: "Швейцарія",
    },
    {
      code: "SYR",
      value: "SYR",
      labelEn: "Syrian Arab Republic",
      labelRu: "Сирийская Арабская Республика",
      labelCh: "阿拉伯叙利亚共和国",
      labelFr: "République arabe syrienne",
      labelSp: "República Árabe Siria",
      labelUa: "Сирійська Арабська Республіка",
    },
    {
      code: "TW",
      value: "TW",
      labelEn: "Taiwan, Province of China",
      labelRu: "Тайвань (Китай)",
      labelCh: "台湾",
      labelFr: "province de Taïwan, république populaire de Chine",
      labelSp: "Taiwan, provincia de China",
      labelUa: "Тайвань (Китай)",
    },
    {
      code: "TJK",
      value: "TJK",
      labelEn: "Tajikistan",
      labelRu: "Таджикистан",
      labelCh: "塔吉克斯坦",
      labelFr: "Tadjikistan",
      labelSp: "Tayikistán",
      labelUa: "Таджикистан",
    },
    {
      code: "TZ",
      value: "TZ",
      labelEn: "Tanzania, United Republic Of",
      labelRu: "Танзания, Объединенная Республика",
      labelCh: "坦桑尼亚",
      labelFr: "République unie de Tanzanie",
      labelSp: "República Unida de Tanzania",
      labelUa: "Танзанія, Об'єднана Республіка",
    },
    {
      code: "THA",
      value: "THA",
      labelEn: "Thailand",
      labelRu: "Таиланд",
      labelCh: "泰国",
      labelFr: "Thaïlande",
      labelSp: "Tailandia",
      labelUa: "Таїланд",
    },
    {
      code: "TLS",
      value: "TLS",
      labelEn: "Timor-Leste",
      labelRu: "Тимор-Лесте",
      labelCh: "东帝汶",
      labelFr: "Timor oriental",
      labelSp: "Timor Oriental",
      labelUa: "Тимор-Лесте",
    },
    {
      code: "TGO",
      value: "TGO",
      labelEn: "Togo",
      labelRu: "Того",
      labelCh: "多哥",
      labelFr: "Togo",
      labelSp: "Togo",
      labelUa: "Того",
    },
    {
      code: "TKL",
      value: "TKL",
      labelEn: "Tokelau",
      labelRu: "Токелау",
      labelCh: "托克劳",
      labelFr: "Tokelau",
      labelSp: "Tokelau",
      labelUa: "Токелау",
    },
    {
      code: "TON",
      value: "TON",
      labelEn: "Tonga",
      labelRu: "Тонга",
      labelCh: "汤加",
      labelFr: "Tonga",
      labelSp: "Tonga",
      labelUa: "Тонга",
    },
    {
      code: "TTO",
      value: "TTO",
      labelEn: "Trinidad and Tobago",
      labelRu: "Тринидад и Тобаго",
      labelCh: "特立尼达和多巴哥",
      labelFr: "Trinité-et-Tobago",
      labelSp: "Trinidad y Tobago",
      labelUa: "Трінідад і Тобаго",
    },
    {
      code: "TUN",
      value: "TUN",
      labelEn: "Tunisia",
      labelRu: "Тунис",
      labelCh: "突尼斯",
      labelFr: "Tunisie",
      labelSp: "Túnez",
      labelUa: "Туніс",
    },
    {
      code: "TUR",
      value: "TUR",
      labelEn: "Turkey",
      labelRu: "Турция",
      labelCh: "土耳其",
      labelFr: "Turquie",
      labelSp: "Turquía",
      labelUa: "Туреччина",
    },
    {
      code: "TKM",
      value: "TKM",
      labelEn: "Turkmenistan",
      labelRu: "Туркмения",
      labelCh: "土库曼",
      labelFr: "Turkménistan",
      labelSp: "Turkmenistán",
      labelUa: "Туркменія",
    },
    {
      code: "TC",
      value: "TC",
      labelEn: "Turks and Caicos Islands",
      labelRu: "Острова Теркс и Кайкос",
      labelCh: "土克凯可群岛",
      labelFr: "îles Turques-et-Caïques",
      labelSp: "Islas Turcas y Caicos",
      labelUa: "Острова Теркс та Кайкос",
    },
    {
      code: "TUV",
      value: "TUV",
      labelEn: "Tuvalu",
      labelRu: "Тувалу",
      labelCh: "图瓦卢",
      labelFr: "Tuvalu",
      labelSp: "Tuvalu",
      labelUa: "Тувалу",
    },
    {
      code: "UGA",
      value: "UGA",
      labelEn: "Uganda",
      labelRu: "Уганда",
      labelCh: "乌干达",
      labelFr: "Ouganda",
      labelSp: "Uganda",
      labelUa: "Уганда",
    },
    {
      code: "UKR",
      value: "UKR",
      labelEn: "Ukraine",
      labelRu: "Украина",
      labelCh: "乌克兰",
      labelFr: "Ukraine",
      labelSp: "Ucrania",
      labelUa: "Україна",
    },
    {
      code: "AE",
      value: "AE",
      labelEn: "United Arab Emirates",
      labelRu: "Объединенные Арабские Эмираты",
      labelCh: "阿拉伯联合酋长国",
      labelFr: "Émirats arabes unis",
      labelSp: "Emiratos Árabes Unidos",
      labelUa: "Об'єднані Арабські Емірати",
    },
    {
      code: "UK",
      value: "UK",
      labelEn: "United Kingdom",
      labelRu: "Соединенное Королевство",
      labelCh: "英国",
      labelFr: "Royaume-Uni",
      labelSp: "Reino Unido",
      labelUa: "Сполучене Королівство",
    },
    {
      code: "USA",
      value: "USA",
      labelEn: "United States",
      labelRu: "Соединенные Штаты",
      labelCh: "美国",
      labelFr: "États-Unis",
      labelSp: "Estados Unidos",
      labelUa: "Сполучені Штати",
    },
    {
      code: "UM",
      value: "UM",
      labelEn: "United States Minor Outlying Islands",
      labelRu: "Малые Тихоокеанские отдаленные острова Соединенных Штатов",
      labelCh: "美属维尔京群岛",
      labelFr: "îles mineures éloignées des États-Unis",
      labelSp: "Islas menores alejadas de los Estados Unidos",
      labelUa: "Малі Тихоокеанські віддалені острови Сполучених Штатів",
    },
    {
      code: "URY",
      value: "URY",
      labelEn: "Uruguay",
      labelRu: "Уругвай",
      labelCh: "乌拉圭",
      labelFr: "Uruguay",
      labelSp: "Uruguay",
      labelUa: "Уругвай",
    },
    {
      code: "UZB",
      value: "UZB",
      labelEn: "Uzbekistan",
      labelRu: "Узбекистан",
      labelCh: "乌茲别克斯坦",
      labelFr: "Ouzbékistan",
      labelSp: "Uzbekistán",
      labelUa: "",
    },
    {
      code: "VUT",
      value: "VUT",
      labelEn: "Vanuatu",
      labelRu: "Вануату",
      labelCh: "瓦努阿图",
      labelFr: "Vanuatu",
      labelSp: "Vanuatu",
      labelUa: "",
    },
    {
      code: "VE",
      value: "VE",
      labelEn: "Venezuela",
      labelRu: "Венесуэла Боливарианская Республика",
      labelCh: "委内瑞拉玻利瓦尔共和国",
      labelFr: "République bolivarienne du Venezuela",
      labelSp: "La República Bolivariana de Venezuela",
      labelUa: "",
    },
    {
      code: "VN",
      value: "VN",
      labelEn: "Vietnam",
      labelRu: "Вьетнам",
      labelCh: "越南",
      labelFr: "Viêt Nam",
      labelSp: "Vietnam",
      labelUa: "В'єтнам",
    },
    {
      code: "VG",
      value: "VG",
      labelEn: "Virgin Islands, British",
      labelRu: "Виргинские острова, Британские",
      labelCh: "维尔京群岛",
      labelFr: "îles Vierges britanniques",
      labelSp: "Islas Vírgenes Británicas",
      labelUa: "Віргінські острови, Британські",
    },
    {
      code: "VI",
      value: "VI",
      labelEn: "Virgin Islands, U.S.",
      labelRu: "Виргинские острова, США",
      labelCh: "美属维尔京群岛",
      labelFr: "îles Vierges des États-Unis",
      labelSp: "Islas Vírgenes, EE.UU.",
      labelUa: "Віргінські острови, США",
    },
    {
      code: "WLF",
      value: "WLF",
      labelEn: "Wallis and Futuna",
      labelRu: "Уоллис и Футуна",
      labelCh: "瓦利斯和富图纳",
      labelFr: "Wallis-et-Futuna",
      labelSp: "Wallis y Futuna",
      labelUa: "Уолліс та Футуна",
    },
    {
      code: "ESH",
      value: "ESH",
      labelEn: "Western Sahara",
      labelRu: "Западная Сахара",
      labelCh: "西撒哈拉",
      labelFr: "Sahara occidental",
      labelSp: "Sahara Occidental",
      labelUa: "Західна Сахара",
    },
    {
      code: "YEM",
      value: "YEM",
      labelEn: "Yemen",
      labelRu: "Йемен",
      labelCh: "也门",
      labelFr: "Yémen",
      labelSp: "Yemen",
      labelUa: "Ємен",
    },
    {
      code: "ZMB",
      value: "ZMB",
      labelEn: "Zambia",
      labelRu: "Замбия",
      labelCh: "赞比亚",
      labelFr: "Zambie",
      labelSp: "Zambia",
      labelUa: "Замбія",
    },
    {
      code: "ZWE",
      value: "ZWE",
      labelEn: "Zimbabwe",
      labelRu: "Зимбабве",
      labelCh: "津巴布韦",
      labelFr: "Zimbabwe",
      labelSp: "Zimbabwe",
      labelUa: "Зімбабве",
    },
  ],
  // TODO format this code
  get: function (key, lang) {
    const ar = this.alpha2Standard;

    if (lang === "eng") {
      var a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelEn;
      }
    } else if (lang === "rus") {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelRu;
      }
    } else if (lang === "ch") {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelCh;
      }
    } else if (lang === "fre") {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelFr;
      }
    } else if (lang === "spa") {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelSp;
      }
    } else if (lang === "ukr") {
      a = ar.find((x) => {
        return x.code === key || x.labelEn === key;
      });
      if (a) {
        return a.labelUa;
      }
    } else {
      return "";
    }
  },

  getAllValueLabel: function (lang) {
    if (!lang) lang = "eng";

    const functor = function (elem, language) {
      switch (language) {
        case "eng":
          return { value: elem.value, label: elem.labelEn };
        case "rus":
          return { value: elem.value, label: elem.labelRu };
        case "ch":
          return { value: elem.value, label: elem.labelCh };
        case "fre":
          return { value: elem.value, label: elem.labelFr };
        case "spa":
          return { value: elem.value, label: elem.labelSp };
        case "ukr":
          return { value: elem.value, label: elem.labelUa };
        default:
          return { value: elem.value, label: elem.labelEn };
      }
    };

    const countries = this.alpha2Standard.map((item) => functor(item, lang));
    return countries;
  },
};

export default countries;
