import {makeAutoObservable, reaction} from 'mobx';
import {ChatSocketService, NotificationSocketService} from "services";
import auth from "./auth";

class Websocket {
    chat = null;
    notification = null;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => auth.user,
            (user) => {
                const {token} = user;
                if (token && !this.chat && !this.notification) {
                    this.init(token);
                }
            }
        );
    }

    async init(token) {
        this.chat = await ChatSocketService.init(token);
        this.notification = await NotificationSocketService.init(token);
    }

    stopAll() {
        this.chat.socket.close();
        this.notification.socket.close();

        this.chat = null;
        this.notification = null;
    }
}

export default new Websocket();
