import { getBase64FromFile } from "utils/index";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import CloseIcon from "@mui/icons-material/Close";
import { Image } from "mui-image";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { GremService } from "../../../../../services";
import { snackActions } from "../../../../../utils/SnackBarUtils";
import { LoadingButton } from "@mui/lab";
import globalDictionary from "../../../../../assets/translations/globalDictionary";
import { getActiveLanguageFromLS } from "../../../../../utils";
import {ImageUploader} from "../../../../../components";

const ProgressComplexAdd = () => {
  const language = getActiveLanguageFromLS();
  const gremService = new GremService();
  const currentDate = Date.now();
  const [progress, setProgress] = useState(
    { images: [], date: currentDate, text: "" },
  );
  const [loading, setLoading] = useState(false);
  const [complexId, setComplexId] = useState(null);
  const [errors, setErrors] = useState({
    text: false,
    date: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const segments = location.pathname.split("/");
    const complexIndex = segments.findIndex((segment) => segment === "complex");
    if (complexIndex >= 0 && complexIndex < segments.length - 1) {
      setComplexId(segments[complexIndex + 1]);
    }
  }, [location]);

  const validateForm = () => {
    const newErrors = {
      text: !progress.text.trim(),
      date: progress.date === currentDate,
    };

    setErrors(newErrors);
    return !Object.values(newErrors).includes(true)
  };

  const handleAddProgress = async () => {
    setLoading(true);
    if (!validateForm()) {
      snackActions.error("Please fill in all the fields correctly");
      setLoading(false);
      return;
    }

    try {
      const firstProgressItem = progress;
      const res = await gremService.updateComplexProgress(
        complexId,
        firstProgressItem
      );
      if (res.code === 200) {
        snackActions.success("You have successfully added new progress");
        setLoading(false);
        navigate(-1);
      } else {
        snackActions.error("Error adding new progress");
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      snackActions.error("Error adding new progress");
    }
  };

  const handleProgressInputChange = (event, ) => {
    const { name, value } = event.target;
    setProgress({...progress,[name]:value});
  };

  const handleOtherDateChange = (newValue, ) => {
    try {
      if (!dayjs(newValue).isValid()) {
        console.error("Недопустимое значение даты:", newValue);
        return;
      }
      const formattedDate = newValue.getTime();

      setProgress({ ...progress, date: formattedDate });

      console.log("Дата успешно обработана:", formattedDate);
    } catch (error) {
      console.error("Ошибка при обработке даты:", error);
    }
  };
  return (
    <Container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant={"h6"}>{globalDictionary.get('constructionProgress_translate',language)}</Typography>
      </Grid>
      
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              placeholder={globalDictionary.get('constructionNews_translate',language)}
              label={globalDictionary.get('constructionNews_translate',language)}
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              error={errors.text}
              value={progress.text}
              name={"text"}
              type={"text"}
              onChange={(event) => handleProgressInputChange(event)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs().toDate()}
                label={globalDictionary.get('profile_rating_table_date',language)}
                value={dayjs(progress.date)}
                maxDate={dayjs(progress.date)}
                onChange={(newValue) => handleOtherDateChange(newValue)}
                error={errors.date}
              />
            </LocalizationProvider>
          </Grid>
          <ImageUploader
              _data={progress}
              _setData={setProgress}
              config={{field: 'images',
                titleKey: 'image_uploader_title',
                confName:'progressConfigs'
              }}
          />

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={loading}
                sx={{ width: { xs: "100", md: "25%" } }}
                onClick={handleAddProgress}
              >
                {globalDictionary.get('add_translate',language)}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
    </Container>
  );
};

export default ProgressComplexAdd;
