import {useCallback} from 'react';
import {DragDropContext, Droppable} from '@hello-pangea/dnd';

import Stack from '@mui/material/Stack';
import Scrollbar from 'components/v2/scrollbar';
import EmptyContent from 'components/v2/empty-content';

import KanbanColumn from '../kanban-column';
import {KanbanColumnSkeleton} from '../kanban-skeleton';
import {createContext} from "react";

// ----------------------------------------------------------------------

export const BoardContext = createContext();
export default function KanbanView({board, boardLoading, boardEmpty, setBoard, updateContractLead, isTaskMove}) {
    const moveTask = async ({destination, source, draggableId, type, board}) => {
        let updateColumns = null;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const sourceColumn = {...board.columns[source.droppableId]};
        const destinationColumn = {...board.columns[destination.droppableId]};

        // Moving task to same list
        if (sourceColumn.id === destinationColumn.id) {
            const newTaskIds = [...sourceColumn.taskIds];
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);
            updateColumns = {
                ...board?.columns,
                [sourceColumn.id]: {
                    ...sourceColumn,
                    taskIds: newTaskIds,
                },
            };
        }else {
            const sourceTaskIds = [...sourceColumn.taskIds];
            const destinationTaskIds = [...destinationColumn.taskIds];
            const response = await updateContractLead(draggableId, destination.droppableId);
            if(response.code !== 200) return;

            sourceTaskIds.splice(source.index, 1);
            destinationTaskIds.splice(destination.index, 0, draggableId);
            updateColumns = {
                ...board?.columns,
                [sourceColumn.id]: {
                    ...sourceColumn,
                    taskIds: sourceTaskIds,
                },
                [destinationColumn.id]: {
                    ...destinationColumn,
                    taskIds: destinationTaskIds,
                },
            };
        }

        const updatedBoard = {
            ...board,
            columns: updateColumns,
        };

        setBoard(updatedBoard);
    }

    const onDragEnd = useCallback(
        async (data) => {
            try {
                await moveTask({...data, board});
            } catch (error) {
                console.error(error);
            }
        },
        [board, board?.columns, board?.ordered]
    );

    const renderSkeleton = (
        <Stack direction="row" alignItems="flex-start" spacing={3}>
            {[...Array(4)].map((_, index) => (
                <KanbanColumnSkeleton key={index} index={index}/>
            ))}
        </Stack>
    );

    if (boardLoading) return renderSkeleton;
    if (boardEmpty) return (
        <EmptyContent
            filled
            title="No Data"
            sx={{
                py: 10,
                maxHeight: {md: 480},
            }}
        />
    );

    return (
        <BoardContext.Provider value={{moveTask:moveTask,board:board}}>
        <>
            {!!board?.ordered.length && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="board" type="COLUMN" direction="horizontal" isDropDisabled={true}>
                        {(provided) => (
                            <Scrollbar
                                sx={{
                                    height: 1,
                                    minHeight: {
                                        xs: '80vh',
                                        md: 'unset',
                                    },
                                }}
                            >
                                <Stack
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    spacing={3}
                                    direction="row"
                                    alignItems="flex-start"
                                    sx={{
                                        p: 0.25,
                                        height: 1,
                                    }}
                                >
                                    {board?.ordered.map((columnId, index) => (
                                        <KanbanColumn
                                            index={index}
                                            key={columnId}
                                            column={board?.columns[columnId]}
                                            tasks={board?.tasks}
                                            isTaskMove={isTaskMove}
                                        />
                                    ))}

                                    {provided.placeholder}

                                </Stack>
                            </Scrollbar>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </>
        </BoardContext.Provider>
    );
}
