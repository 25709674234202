import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import globalDictionary from "assets/translations/globalDictionary";
import { getActiveLanguageFromLS } from "utils";
import countries from "assets/translations/countries";

const ProjectFilter = ({ filterForm, setFilterForm, getAndSetToState }) => {
  const language = getActiveLanguageFromLS();

  const handleChangeFilterInput = (e) => {
    const newState = { ...filterForm };
    newState[e.target.name] = e.target.value;
    setFilterForm(newState);
  };
  return (
    <Grid
      container
      spacing={1}
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        paddingBottom: 3,
        marginTop: "15px",
      }}
    >
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <FormControl variant="outlined" fullWidth size={"small"}>
          <InputLabel id="select-country-label">
            {globalDictionary.get("filter_country", language)}
          </InputLabel>
          <Select
            variant="outlined"
            labelId="select-country-label"
            id="select-country"
            label={globalDictionary.get("filter_country", language)}
            value={filterForm.country}
            name={"country"}
            onChange={handleChangeFilterInput}
          >
            {countries.getAllValueLabel(language).map((country) => (
              <MenuItem
                key={country.value}
                value={countries.get(country.value, "eng")}
              >
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <TextField
          placeholder={globalDictionary.get("filter_input_name", language)}
          label={globalDictionary.get("filter_name", language)}
          variant="outlined"
          fullWidth
          size={"small"}
          value={filterForm.name}
          name={"name"}
          onChange={handleChangeFilterInput}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          justifyContent: "space-between",
        }}
      >
        <Button
          sx={{height:'40px'}}
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={async () => {
            await getAndSetToState();
          }}
        >
          {globalDictionary.get("filter_search", language)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectFilter;
