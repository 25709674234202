import React from 'react';
import {Help} from '@mui/icons-material';
import {Link} from 'react-router-dom';

function GuideButton({href = ''}){

    // Temp (need to create new docs for this)
    return null;

    return (
        <Link to={'/comingSoon'} style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <Help color={'secondary'}/>
        </Link>
    );
}

export default GuideButton;
