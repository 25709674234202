import GoogleTranslator from './google-translator';
import { CacheProvider } from '../types';

export default class CacheTranslator extends GoogleTranslator {
  async translate(value) {
    let translation = await this.cacheProvider.get(this.to, value);

    if (!translation) {
      translation = await this.tryGetGoogleTranslationAndCache(value);
    }

    return translation;
  }

  async tryGetGoogleTranslationAndCache(value) {
    const translation = await this.tryGetGoogleTranslation(value);

    this.setCachedTranslationForValue(value, translation);
    return translation;
  }

  setCachedTranslationForValue(value, translation) {
    if (translation) {
      this.cacheProvider.set(this.to, value, translation);
    }
  }
}
