import React from "react";
import { Avatar } from "@mui/material";
import LazyLoad from "react-lazyload";

// Define the default style
const defaultStyle = {
  textAlign: "center",
};

function ImageChecker({ src, variant, alt, imgProps, sx, text }) {
  return (
    <LazyLoad once>
      <Avatar
        src={src?.toString()}
        variant={variant}
        alt={alt ? alt : ""}
        sx={{
          ...defaultStyle,
          ...sx,
        }}
        imgProps={imgProps ? imgProps : {}}
      >
        {text ? text : ""}
      </Avatar>
    </LazyLoad>
  );
}

export default ImageChecker;
