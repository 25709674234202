import {server} from 'config';
import {getUserToken} from 'utils/index';
import {GremService} from "services";

class ProjectService{

    constructor() {
        this._apiBase = server;
        this.service = new GremService();
    }

    async addUnit(unit) {
        const res = await this.service.sendRequest(
            '/projects/unit/add',
            'POST',
            unit,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
}

export default ProjectService;