import React, {Fragment, useState,useContext} from 'react';
import GlobalDictionary from "../../assets/translations/globalDictionary";
import { getActiveLanguageFromLS } from "utils";
import { Alert, Box, Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GoogleCode from './googleCode';
import SelectMethod from "./selectMethod";
import GoogleQrCode from "./googleQrCode";
import {StoreContext} from "../../Providers";

const Verification = ({ closeModal}) => {
    const globalDictionary = GlobalDictionary;
    const language = getActiveLanguageFromLS();
    const [error,setError] = useState(null);
    const {auth} = useContext(StoreContext);
    const [availableMethods, setAvailableMethods] = useState(auth.user.factoring);
    const [method,setMethod] = useState('select')

    const MethodComponents = {
        'select': <SelectMethod methods={Object.keys(availableMethods)} setMethod={setMethod}/>,
        'google': <GoogleQrCode setMethod={setMethod} setError={setError} />,
        'googleVerify': <GoogleCode closeModal={closeModal} />,
    };

    const modalStyles = {
        modal: {
            outline: 'none',
            p: 2,
            bgcolor: 'white',
            boxShadow: 'none',
            width: '440px',
            height: 'auto',
            borderRadius: '10px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        message: {
            textAlign: 'center',
        },
    };

    return (
        <Modal
            open={true}
            onClose={closeModal}
            style={{
                backdropFilter: 'blur(2px)',
                backgroundColor: 'rgba(255, 255, 255, 0.35)',
            }}
        >
            <Box sx={modalStyles.modal}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant={'h6'}>
                                {method ==='select' ?(
                                globalDictionary.get('select_method', language))
                                    :
                                    (globalDictionary.get('verification', language))}
                            </Typography>
                        </Grid>
                        <IconButton
                            onClick={closeModal}
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    {error && (
                    <Grid item xs={12}>
                        <Alert severity={'error'}>
                            <Typography>
                                {error.message}
                            </Typography>
                        </Alert>
                    </Grid>)}
                    <Grid item xs={12}>
                        {MethodComponents[method]} {/* Render the selected method component */}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default Verification;
