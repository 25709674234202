import {getUserToken} from 'utils/index';
import {validateResponseData} from 'utils/ProcessResponseUtils';
import {server} from 'config';
import {format} from "date-fns";
import {random} from "lodash";

class ExchangeService{
    constructor() {
        this._apiBase = server;
    }

    getCurrentPrice(){
        const newDataPoint = {
            time: Date.now() / 1000,
            open: 30690 + Math.random() + 0.05,
            high: 30690 + Math.random() + 0.01,
            low: 30690 + Math.random() + 0.01,
            close: 30690 + Math.random() + 0.05,
        };
        return newDataPoint;
    }

    getCurrency(){
        const subCurrency = '₼';
        return subCurrency;
}

    getProjectsList(key){
        const projects = [{
                key:'aloha',
                name: 'Aloha',
                url: 'https://aloha.remaz.az',
            },
            {
                key:'hayat',
                name: 'Hayat',
                url: 'https://hayat.remaz.az',
            },
            {
                key:'m2c',
                name: 'm2c',
                url: 'https://remaz.az',
            }
            ];

        const res = projects.find((project) => project.key === key);
        return res;
    }

    getCoinList(){
        const coinData = [
            { key: 'm2c', price: 0.2,change: '-1.2%', subPrice:0.003 },
            { key: 'aloha', price: 0.3,change: '-1.2%', subPrice:1 },
            { key: 'hayat', price: 0.5,change: '-1.2%', subPrice:1.1 },
        ];
        return coinData
    }

    getSellBook(key){
        const currentTime = format(Date.now(), 'HH:mm:ss');
        const sellBook = [{
            key:'m2c',
            value:[
                {type:'sell', price: 1.2, amount: 2, total: 0.03, time: currentTime },
                {type:'sell', price: 1.3, amount: 3, total: 0.02, time: currentTime },
                {type:'sell', price: 1.5, amount: 1, total: 0.01, time: currentTime },
            ]
        },
            {
                key:'aloha',
                value:[
                    {type:'sell', price: 2.2, amount: 2, total: 0.03, time: currentTime },
                    {type:'sell', price: 2.3, amount: 3, total: 0.02, time: currentTime },
                    {type:'sell', price: 2.5, amount: 1, total: 0.01, time: currentTime },
                ]
            },
            {
                key:'hayat',
                value:[
                    {type:'sell', price: 3.2, amount: 2, total: 0.03, time: currentTime },
                    {type:'sell', price: 3.3, amount: 3, total: 0.02, time: currentTime },
                    {type:'sell', price: 3.5, amount: 1, total: 0.01, time: currentTime },
                ]
            }

        ];

        return sellBook.find((book)=>book.key === key)

    }

    getBuyBook(key){
        const currentTime = format(Date.now(), 'HH:mm:ss');
        const buyBook = [{
            key:'m2c',
            value:[
                {type:'buy', price: 1.2, amount: 2, total: 0.03, time: currentTime },
                {type:'buy', price: 1.3, amount: 3, total: 0.02, time: currentTime },
                {type:'buy', price: 1.5, amount: 1, total: 0.01, time: currentTime },
            ]
            },
            {
                key:'aloha',
                value:[
                    {type:'buy', price: 2.2, amount: 2, total: 0.03, time: currentTime },
                    {type:'buy', price: 2.3, amount: 3, total: 0.02, time: currentTime },
                    {type:'buy', price: 2.5, amount: 1, total: 0.01, time: currentTime },
                ]
            },
            {
                key:'hayat',
                value:[
                    {type:'buy', price: 3.2, amount: 2, total: 0.03, time: currentTime },
                    {type:'buy', price: 3.3, amount: 3, total: 0.02, time: currentTime },
                    {type:'buy', price: 3.5, amount: 1, total: 0.01, time: currentTime },
                ]
            }

        ];

        return buyBook.find((book)=>book.key === key)
    }
    getUserOrders(userID){
        const openOrders = [
            { id: 1, symbol: 'BTC/USD', type: 'Buy', price: 50000, amount: 1 },
            { id: 2, symbol: 'ETH/USD', type: 'Sell', price: 3000, amount: 5 },
            { id: 3, symbol: 'XRP/USD', type: 'Buy', price: 0.7, amount: 100 },
            { id: 4, symbol: 'XRP/USD', type: 'Buy', price: 0.7, amount: 100 },
            { id: 5, symbol: 'ETH/USD', type: 'Sell', price: 3000, amount: 5 },
        ];

        return openOrders;
    }
    getUserHistory(userID){

        const historyOrders = [
            { id: 1, symbol: 'BTC/USD', type: 'Sell', price: 55000, amount: 1 },
            { id: 2, symbol: 'ETH/USD', type: 'Buy', price: 3500, amount: 3 },
            { id: 3, symbol: 'XRP/USD', type: 'Sell', price: 0.6, amount: 50 },
        ];
        return historyOrders;
    }

    sendOrderQuery(formType,spot){
        console.log(`Submitting ${formType} form:`, spot);
    }

}

export default ExchangeService;
