import React from "react";
import {
    Forgot,
    ForgotChangePassword,
    ForgotMessage,
    Login,
    Logout,
    Register,
    RegisterConfirm,
    RegisterSuccess
} from "../pages";
import {NoProtectedRoute} from 'utils/RoutesUtils';
import {LinkedInCallback} from "react-linkedin-login-oauth2";

const authRoutes = [
    {
        path: "login",
        element: (
            <NoProtectedRoute>
                <Login />
            </NoProtectedRoute>
        ),
        index: false,
    },
    {
        path: "register",
        element: (
            <NoProtectedRoute>
                <Register />
            </NoProtectedRoute>
        ),
    },
    {
        path: "register-success",
        element: (
            <NoProtectedRoute>
                <RegisterSuccess />
            </NoProtectedRoute>
        ),
    },
    {
        path: "register-confirm/:userId/:registrationToken",
        element: (
            <NoProtectedRoute>
                <RegisterConfirm />
            </NoProtectedRoute>
        ),
    },
    {
        path: "forgot",
        element: (
            <NoProtectedRoute>
                <Forgot />
            </NoProtectedRoute>
        ),
    },
    {
        path: "forgot-message",
        element: (
            <NoProtectedRoute>
                <ForgotMessage />
            </NoProtectedRoute>
        ),
    },
    {
        path: "change-password/:recoveryToken",
        element: (
            <NoProtectedRoute>
                <ForgotChangePassword />
            </NoProtectedRoute>
        ),
    },
    {
        path: "linkedin",
        element: (
            <NoProtectedRoute>
                <LinkedInCallback />
            </NoProtectedRoute>
        ),
    },
    {
        path: "logout",
        element: (<Logout/>),
    },
];

export default authRoutes;
