import React, {createContext, useContext} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {SnackbarProvider} from 'notistack';
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {getActiveLanguageFromLS, setActiveLanguageToLS} from "./utils";
import ThemeProvider from "./theme";
import {Translator} from "./utils/react-auto-translate";
import auth from "./store/auth";
import {messenger} from "./store/messenger";
import websocket from "./store/websocket";
import {Error} from "./pages";
import {SnackbarUtilsConfigurator} from "./utils/SnackBarUtils";

const {REACT_APP_RECAPTCHA_SITE_KEY} = process.env;
const queryClient = new QueryClient();
export const StoreContext = createContext();

const userLanguage = getActiveLanguageFromLS();
if (!userLanguage) setActiveLanguageToLS("eng");

const store = {
    auth,
    messenger,
    websocket,
};

export function useStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error("useStore must be used within StoreContext.Provider");
    }

    return context;
}

const Providers = ({children}) => (
    <StoreContext.Provider value={store}>
        <ThemeProvider>
            <Translator
                from=""
                to={userLanguage.substr(0, 2)}
                googleApiKey={process.env.REACT_APP_GOOGLE_TRANSLATOR_API_KEY}
            >
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider maxSnack={3}>
                        <SnackbarUtilsConfigurator/>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
                                {children}
                            </GoogleReCaptchaProvider>
                        </LocalizationProvider>
                    </SnackbarProvider>
                </QueryClientProvider>
            </Translator>
        </ThemeProvider>
    </StoreContext.Provider>
);

export default Providers;
