import PropTypes from 'prop-types';
import {useState} from 'react';
import Stack from '@mui/material/Stack';

import KanbanInputName from './kanban-input-name';

// ----------------------------------------------------------------------

export default function KanbanColumnToolBar(
    {
        columnName,
    }
) {
    const [name, setName] = useState(columnName);

    return (
        <>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{pt: 3}}
            >
                <KanbanInputName
                    placeholder="Section name"
                    value={name}
                />
            </Stack>
        </>
    );
}

KanbanColumnToolBar.propTypes = {
    columnName: PropTypes.string,
    onClearColumn: PropTypes.func,
    onDeleteColumn: PropTypes.func,
    onUpdateColumn: PropTypes.func,
};
