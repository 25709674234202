import {getUserToken} from 'utils/index';
import {validateResponseData} from 'utils/ProcessResponseUtils';
import {server} from 'config';

class GremService {
    constructor(apiBase = server) {
        this._apiBase = apiBase;
        this.defaultHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    async sendRequest(route, method = 'GET', data = {}, additionalHeaders = {}, isBlob = false) {
        const headers = { ...this.defaultHeaders, ...additionalHeaders };
        if (getUserToken()) {
            headers['x-www-access'] = getUserToken();
        }

        const payload = method === 'GET' ? { method, headers } : { method, body: JSON.stringify(data), headers };

        try {
            const response = await fetch(`${this._apiBase}${route}`, payload);
            const parsedResponseData = isBlob ? await response.blob() : await response.json();
            return validateResponseData(parsedResponseData);
        } catch (error) {
            return this.handleRequestError(error);
        }
    }

    handleRequestError(error) {
        console.error(error);
        return {
            code: 404,
            message: "Errors",
            errors: [{ "errorCode": 409172, "errorMessage": "Internal Server Error" }],
        };
    }

    async doLogin({email = '', pass = ''}) {
        const res = await this.sendRequest(
            '/users/login',
            'POST',
            {
                email,
                pass
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async doRegister({
                         email = '',
                         pass = '',
                         firstName: fname = '',
                         lastName: lname = '',
                         lang = 'en',
                         referralCode = ''
                     }) {
        const res = await this.sendRequest(
            '/users/register',
            'POST',
            {
                email,
                pass,
                fname,
                lname,
                lang,
                referralCode
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async resendConfirmMail(email = '') {
        const res = await this.sendRequest(
            '/users/resend-confirm-email',
            'POST',
            {
                email
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guyest'
            }
        );
        return res;
    }

    async doConfirmMail({userId = '', registrationToken = ''}) {
        const res = await this.sendRequest(
            `/users/register-confirm/${userId}/${registrationToken}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async sendResetPasswordRequest(email = '') {
        const res = await this.sendRequest(
            '/users/reset-password',
            'POST',
            {
                email
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guyest'
            }
        );
        return res;
    }

    async sendNewPassword({recoveryToken = '', password: newpass = ''}) {
        const res = await this.sendRequest(
            `/users/reset-password-confirm/${recoveryToken}`,
            'POST',
            {
                newpass
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guyest'
            }
        );
        return res;
    }

    async authGoogle(data = {}) {
        const res = await this.sendRequest(
            '/users/googleAuth',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async authFacebook(data = {}) {
        const res = await this.sendRequest(
            '/users/facebookAuth',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async authLinkedin(code = '') {
        const res = await this.sendRequest(
            '/users/linkedinAuth',
            'POST',
            {
                code
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async doLogout() {
        const res = await this.sendRequest(
            '/users/logout',
            'POST',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getUserSessions() {
        const res = await this.sendRequest(
            '/users/session',
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async deleteUserSession(token) {
        const res = await this.sendRequest(
            '/users/session',
            'DELETE',
            {
                token
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getCryptoPrices(data = {}) {
        const res = await this.sendRequest(
            '/serviceRequest/cryptoPrices',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async getUserInfo(userId = '') {
        const res = await this.sendRequest(
            '/users/item',
            'POST',
            {userId},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async sendPartnerInviteByEmail(email = '') {
        const res = await this.sendRequest(
            '/users/invite',
            'POST',
            {email},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async sendSupportForm(data = {}) {
        const res = await this.sendRequest(
            '/common/grem/feedback',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async sendUserUnavailabilityDates(data = {}) {
        const res = await this.sendRequest(
            '/community/setUnavailability',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getProperties(data = {}) {
        const res = await this.sendRequest(
            '/properties/list',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    async getComplex(data = {}) {
        const res = await this.sendRequest(
            `/complex/?${data}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    async getComplexById(complexId) {
        const res = await this.sendRequest(
            `/complex/${complexId}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    async getComplexNewsById(complexId,page) {
        const res = await this.sendRequest(
            `/complex/${complexId}/progress/?limit=5&page=${page}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
            );
            return res;
        }

    async getComplexApartInfo(complexId,page) {
        const res = await this.sendRequest(
            `/complex/${complexId}/apart/?limit=5&page=${page}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getProjects(data = {}) {
        const res = await this.sendRequest(
            '/projects/list',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    async getAIDescription(data = {text:""}) {
        const res = await this.sendRequest(
            '/openai/gpt/description',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    async getCommunity(data = {}) {
        const res = await this.sendRequest(
            '/community/list',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getReferralParent(referralCode = '') {
        const res = await this.sendRequest(
            '/referrals/parent',
            'POST',
            {referralCode},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async getPropertiesMapMarkers() {
        const res = await this.sendRequest(
            '/properties/markers',
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async getProjectsMapMarkers() {
        const res = await this.sendRequest(
            '/projects/markers',
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async doSecondaryAuth(data) {
        const res = await this.sendRequest(
            '/community/add',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getNews(data = {}) {
        const res = await this.sendRequest(
            '/news/list',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getBlogs(data = {}) {
        const res = await this.sendRequest(
            '/blog/list',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async changePassword(data = {}) {
        const res = await this.sendRequest(
            '/users/reset-password-direct',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getNewsArticle(newsId) {
        const res = await this.sendRequest(
            '/news/item',
            'POST',
            {newsId},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getBlogArticle(blogId) {
        const res = await this.sendRequest(
            '/blog/item',
            'POST',
            {blogId},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getProperty(propId) {
        const res = await this.sendRequest(
            '/properties/item',
            'POST',
            {propId},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getRequests(data = {}) {
        const res = await this.sendRequest(
            '/serviceRequest/list',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addRequestProperty(data = {}) {
        const res = await this.sendRequest(
            '/serviceRequest/addProperty',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addRequestSpecialist(data = {}) {
        const res = await this.sendRequest(
            '/serviceRequest/addSpecialist',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async deleteRequest(requestId) {
        const res = await this.sendRequest(
            `/serviceRequest/${requestId}`,
            'DELETE',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getRequest(requestId) {
        const res = await this.sendRequest(
            '/serviceRequest/item',
            'POST',
            {requestId},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async updateRequest(requestID, data = {}) {
        const res = await this.sendRequest(
            `/serviceRequest/${requestID}`,
            'PUT',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async updateCommunity(data = {}) {
        const res = await this.sendRequest(
            '/community/update',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addProperty(data = {}) {
        const res = await this.sendRequest(
            '/properties/add',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

        async addComplexProperty(data = {}) {
        const res = await this.sendRequest(
            '/complex',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    async updateComplexProgress(id, data = {}) {
        const res = await this.sendRequest(
            `/complex/${id}/progress`,
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addApartmentToComplex(complexId, data = {}) {
        const res = await this.sendRequest(
            `/complex/${complexId}/apart`,
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getProject(projId) {
        const res = await this.sendRequest(
            '/projects/item',
            'POST',
            {projId},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addRequestProject(data = {}) {
        const res = await this.sendRequest(
            '/serviceRequest/addProject',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addProject(data = {}) {
        const res = await this.sendRequest(
            '/projects/add',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async initializeExpertise(projId) {
        const res = await this.sendRequest(
            '/projects/initializeExpertise',
            'POST',
            {projId},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async updateProperty(data = {}) {
        const res = await this.sendRequest(
            '/properties/update',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    async updateComplex(data = {}) {
        const res = await this.sendRequest(
            `/complex/${data._id}`,
            'PUT',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getWalletInfo() {
        const res = await this.sendRequest(
            '/users/wallet',
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async createCryptoWallet() {
        const res = await this.sendRequest(
            '/users/wallet/generate',
            'POST',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getAssets({typeCode = ''} = {}) {
        const res = await this.sendRequest(
            `/asset/asset?typeCode=${typeCode}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getAssetIssuers({assetId, issuerId, type}) {
        const res = await this.sendRequest(
            `/asset/issuer?assetId=${assetId}&issuerId=${issuerId}&type=${type}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getDepositWallet({issuerId}) {
        const res = await this.sendRequest(
            `/payment/deposit/${issuerId}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async generateDepositWallet({issuer, issuerId}) {
        const res = await this.sendRequest(
            '/payment/deposit',
            'POST',
            {
                issuer,
                issuerId
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async doWithdraw(payload) {
        const res = await this.sendRequest(
            '/payment/withdraw',
            'POST',
            payload,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async doManualWithdraw(payload) {
        const res = await this.sendRequest(
            '/wallet/withdraw',
            'POST',
            payload,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getWalletTransactions({type = 'deposit', assetType = 'crypto', currentPage = 1, pageSize = 10}) {
        const res = await this.sendRequest(
            `/users/wallet/transaction/${type}/${assetType}?currentPage=${currentPage}&pageSize=${pageSize}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async exportWalletTransactions({type = 'deposit', assetType = 'crypto'}) {
        const res = await this.sendRequest(
            `/users/wallet/transaction/${type}/${assetType}/export`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            },
            true
        );
        return res;
    }

    async getUserNotifications(userId = '', page = 1, limit = 10) {
        const res = await this.sendRequest(
            `/notifications/${userId}/${page}/${limit}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async setAllUserNotificationsRead(userId = '') {
        const res = await this.sendRequest(
            `/notifications/${userId}`,
            'PUT',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getChats() {
        const res = await this.sendRequest(
            `/chat`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async createNewChat(data) {
        const res = await this.sendRequest(
            `/chat`,
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async createSplitComissionContract(data) {
        const res = await this.sendRequest(
            `/contract/division`,
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getContract(contractId) {
        const res = await this.sendRequest(
            `/contract/division/${contractId}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async getContractLeads(contractId) {
        const res = await this.sendRequest(
            `/contract/division/${contractId}/lead`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
    async getLeads() {
        const res = await this.sendRequest(
            `/contract/division/lead`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async updateContractLead(contractId, leadId, data) {
        const res = await this.sendRequest(
            `/contract/division/${contractId}/lead/${leadId}`,
            'PUT',
            {
                status: data.status,
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }

    async createTargetLanding(data) {
        const res = await this.sendRequest(
            `/landing`,
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken(),
            }
        );
        return res;
    }
}

export default GremService;
