import React, {useEffect} from 'react';
import style from './AuthLayout.module.css';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Header from './components/Header/Header';
import imageBG from '../../assets/images/auth/layout_bg.png';
import {getUserToken} from "utils/index";

const AuthLayout = () => {
    const navigate = useNavigate();
    const userToken = getUserToken();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname !== '/auth/logout' && userToken) {
            navigate('/my/profile');
        }
    }, [navigate, pathname, userToken]);

    const backgroundStyle = {
        position: 'absolute',
        backgroundImage: `url(${imageBG})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '130px center'
    };

    return (
        <div className={style.layout} style={backgroundStyle}>
            <Header />
            <div className={style.content}>
                <Outlet />
            </div>
        </div>
    );
}

export default AuthLayout;
