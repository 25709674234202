import React, {Fragment, useState,useContext} from 'react'
import {Button, CircularProgress, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import GlobalDictionary from "../../assets/translations/globalDictionary";
import {getActiveLanguageFromLS} from "utils";
import {AddData, RequestsValidator} from "../index";
import {FactoringService} from "services/index";
import {snackActions} from "../../utils/SnackBarUtils";
import {StoreContext} from "../../Providers";
import LoadingButton from "@mui/lab/LoadingButton";

const googleCode = ({closeModal}) => {
    const globalDictionary = GlobalDictionary;
    const language = getActiveLanguageFromLS();

    const {auth} = useContext(StoreContext);
    const [data,setData] = useState({loading:false});
    const [dataError,setDataError] = useState({});
    const addData = new AddData(data,setData,dataError,setDataError)
    const handleInputChange = addData.handleChangeInput
    const service = new FactoringService();

    const sendCode = async () => {
        setData({...data,loading: true})
        const code = data.code;
        const result = await RequestsValidator(await service.sendCode(code))
        if(result.code===200){

            !auth.user.factoring.google ? snackActions.success('Two Factoring is enabled.') : snackActions.warning('Two Factoring is disabled.')
            const user = {...auth.user,factoring: {google: !auth.user.factoring.google}}
            await auth.setUser(user)
            setData({...data,loading: false})
            closeModal();

        } else {
            setData({...data,code:'', loading: false})
            setDataError({...dataError, code: true})
        }
    }

    return(
        <Fragment>
            <Grid container spacing={2} p={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography fontSize={14} fontWeight={500}>
                        {globalDictionary.get('authenticator', language)}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={data?.code}
                        name={'code'}
                        type={'number'}
                        onChange={(event)=>{
                            setDataError({})
                            handleInputChange(event)
                            if (data.code && data?.code.length === 6){
                                sendCode()
                            }
                        }}
                        error={dataError?.code}
                        helperText={dataError?.code && 'Code is wrong'}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && event.target.value.length === 6) {
                                sendCode();
                            }}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button variant={'text'} onClick={async () => {
                                        const code = await navigator.clipboard.readText()
                                        setData({...data,code:code})
                                        if (data.code && data.code.length === 6){
                                            sendCode()
                                        }
                                    }}>
                                        {globalDictionary.get('paste', language)}
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography fontSize={14} fontWeight={500} color={"grey"}>
                        {globalDictionary.get('enter_code', language)}
                    </Typography>

                    <LoadingButton variant={'contained'}
                            style={{marginTop:10}}
                            onClick={sendCode}
                                   loading={data.loading}
                            disabled={data.loading}
                            fullWidth>
                        <Typography variant={'body1'}>
                            {globalDictionary.get('submit', language)}
                        </Typography>
                    </LoadingButton>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default googleCode;
