import React from "react";
import style from "./Header.module.css";
import { Link, useLocation } from "react-router-dom";
import { getActiveLanguageFromLS, setActiveLanguageToLS } from "../../../../utils/index";
import globalDictionary from "../../../../assets/translations/globalDictionary";
import logo from "../../../../assets/images/grem-capital-og.png";


function Header() {
  const location = useLocation();
  const language = getActiveLanguageFromLS();

  const setNewLanguage = (language) => {
    setActiveLanguageToLS(language);
    window.location.reload();
  };

  return (
    <header className={style.header}>
      <nav>
        <div className={style.header__logo}>
          <a href={"https://remaz.az"} target={"_blank"}>
            <img src={logo} alt={"Remaz.az"} />
          </a>
        </div>
        <div className={style.headerNavigation}>
          <ul className={style.authList}>
            {
              location.pathname.indexOf("register") !== -1 ? (
                <li>
                  <Link
                    to={"/auth/login"}
                  >
                    {globalDictionary.get("layout_auth_header_signin", language)}
                  </Link>
                </li>
              ) : (
                <li>
                  <Link
                    to={"/auth/register"}
                  >
                    {globalDictionary.get("layout_auth_header_signup", language)}
                  </Link>
                </li>
              )
            }
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;

