const {REACT_APP_NODE_ENV: env} = process.env;
const baseProdServer = 'api.remaz.az';
const baseDevServer = 'api.remaz.az';
const baseLocalServer = '127.0.0.1:5001';

const config = {
    development: {
        apiServer: `https://${baseDevServer}/v1`,
        wsServer: `ws://46.4.103.79:4001`,
    },
    production: {
        apiServer: `https://${baseProdServer}/v1`,
        wsServer: `ws://188.40.140.21:4001`,
    },
    local: {
        apiServer: `http://${baseLocalServer}/v1`,
        wsServer: `ws://127.0.0.1:4001`,
    },
};

const getEnvironmentConfig = (env) => {
    return config[env] || config.local;
};

const { apiServer: server, wsServer } = getEnvironmentConfig(env);

export { server, wsServer, env };
