import React from 'react';

const _ = require('lodash');
const {compose, withProps, lifecycle} = require('recompose');
const {withScriptjs, withGoogleMap, GoogleMap, Marker} = require('react-google-maps');
const {StandaloneSearchBox} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const MapWithASearchBox = compose(
    withProps({
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{width: '100%', height: `400px`}}/>,
        mapElement: <div style={{height: `100%`}}/>
    }),
    lifecycle({
        componentWillMount() {
            const refs = {};
            let lat = parseFloat(this.props.lat);
            let lng = parseFloat(this.props.lng);

            this.setState({
                bounds: null,
                center: {
                    lat: lat,
                    lng: lng
                },
                markers: [
                    {
                        position: {
                            lat: lat,
                            lng: lng
                        }
                    }
                ],
                places: [],
                onMapMounted: (ref) => {
                    refs.map = ref;
                },
                onSearchBoxMounted: (ref) => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();

                    const nextMarkers = places.map((place) => ({
                        position: place.geometry.location
                    }));
                    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                    let locationObject = {
                        lat: nextCenter.lat(),
                        lng: nextCenter.lng(),
                        country: '',
                        countryCode: '',
                        district: '',
                        street: '',
                        streetNumber: '',
                        city: '',
                        postalCode: '',
                        place_id: ''
                    };

                    let address_components = places[0].address_components;
                    for (let i = 0; i < address_components.length; i++) {
                        for (let j = 0; j < address_components[i].types.length; j++) {
                            if (address_components[i].types[j] === 'country') {
                                locationObject.country = address_components[i].long_name;
                                locationObject.countryCode = address_components[i].short_name;
                            }
                            if (address_components[i].types[j] === 'sublocality_level_1') {
                                locationObject.district = address_components[i].long_name;
                            }
                            if (address_components[i].types[j] === 'route') {
                                locationObject.street = address_components[i].long_name;
                            }
                            if (address_components[i].types[j] === 'locality') {
                                locationObject.city = address_components[i].long_name;
                            } else if (address_components[i].types[j] === 'postal_town') {
                                locationObject.city = address_components[i].long_name;
                            } else if (address_components[i].types[j] === 'administrative_area_level_1') {
                                if (locationObject.city === '') {
                                    locationObject.city = address_components[i].long_name;
                                } else {
                                    if (locationObject.district === '') {
                                        locationObject.district = address_components[i].long_name;
                                    }
                                }
                            }
                            if (address_components[i].types[j] === 'street_number') {
                                locationObject.streetNumber = address_components[i].long_name;
                            }
                            if (address_components[i].types[j] === 'postal_code') {
                                locationObject.postalCode = address_components[i].long_name;
                            }
                        }
                    }
                    locationObject.place_id = places[0].place_id;

                    this.props.funcSetLocation(locationObject);
                    this.setState({
                        center: nextCenter,
                        markers: nextMarkers,
                        places: places
                    });
                }
            });
        },
        componentWillReceiveProps(nextProps) {
            let lat = parseFloat(nextProps.lat);
            let lng = parseFloat(nextProps.lng);
            if (nextProps.lat != this.props.lat || nextProps.lng != this.props.lng) {
                this.setState({
                    center: {
                        lat: lat,
                        lng: lng
                    }
                });
            }
        }
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={15}
        center={props.center}
        onBoundsChanged={props.onBoundsChanged}
    >
        <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder={'Please select an address in this field'}
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `100%`,
                    height: `32px`,
                    padding: `0 12px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: 'relative',
                    top: '-340px'
                }}
            />
        </StandaloneSearchBox>
        {props.markers.map(
            (marker, index) => (
                <Marker key={index} position={marker.position}/>
            )
        )}
    </GoogleMap>
));

export default MapWithASearchBox;
