import React from 'react';
import { Box } from '@mui/system';
import { IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ContentCopy } from '@mui/icons-material';
import { QRCodeCanvas } from 'qrcode.react';
import GlobalDictionary from "../../assets/translations/globalDictionary";
import copy from "clipboard-copy";
import { getActiveLanguageFromLS } from "utils/index";
import { snackActions } from "../../utils/SnackBarUtils";

function ModalWindow({ closeModal, stellarAddress ,dictionaryKey}) {
    const globalDictionary = GlobalDictionary;
    const language = getActiveLanguageFromLS();

    const copyMyAddressToClipboard = () => {
        copy(stellarAddress);
        snackActions.success(globalDictionary.get('modal_copy_success', language));
    };

    return (
        <Modal
            open={true}
            onClose={closeModal}
            style={{
                backdropFilter: 'blur(2px)',
                backgroundColor: 'rgba(255, 255, 255, 0.35)'
            }}
        >
            <Box
                sx={{
                    outline: 'none',
                    p: 2,
                    bgcolor: 'white',
                    boxShadow: 'none',
                    width: '-webkit-fill-available',
                    height: '75%',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        width: '80%',
                        marginTop: '5%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center'
                    }}
                >
                    {globalDictionary.get(dictionaryKey, language)}
                </Typography>
                <IconButton
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        marginTop: '5%',
                        position: 'relative',
                        zIndex: '2',
                        width: '80%',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <TextField
                        variant="outlined"
                        value={stellarAddress}
                        onFocus={(event) => event.target.select()}
                        fullWidth={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={copyMyAddressToClipboard}>
                                    <IconButton edge="end" color="primary" size="large">
                                        <ContentCopy />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Typography>
                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                        position: 'absolute',
                        zIndex: '1',
                        top: '60%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        alignItems: 'center'
                    }}
                >
                    <QRCodeCanvas
                        value={stellarAddress}
                        includeMargin={true}
                        size={350}
                        style={{ position: 'relative', zIndex: '1', maxWidth: '100%', height: 'auto' }}
                    />
                </Typography>
            </Box>
        </Modal>
    );
}

export default ModalWindow;
