import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import './fonts.css';
import "./App.css";
import "react-image-crop/dist/ReactCrop.css";

import {AuthLayout, CabinetLayout} from "./layouts/index";
import {CommunityProtectedRoute, ProtectedRoute} from 'utils/RoutesUtils';
import {NavigateSetter} from "./utils/NavigationHistoryUtils";
import {authRoutes, cabinetRoutes, commonRoutes} from "./routes";
import Providers from "./Providers";

process.env.CI = false;

export const App = () => {
    return (
        <Router>
            <Providers>
                <NavigateSetter/>
                <Routes>
                    <Route path="/auth" element={<AuthLayout/>}>
                        {authRoutes.map((route, index) => {
                            return route.index ? (
                                <>
                                    <Route key={`guest-routes-${index}`}
                                           index={route.index}
                                           element={route.element}/>
                                    <Route key={`guest-routes-${index}`}
                                           path={route.path}
                                           element={route.element}/>
                                </>
                            ) : (
                                <Route key={`guest-routes-${index}`}
                                       path={route.path}
                                       element={route.element}/>
                            )
                        })}
                    </Route>

                    <Route path="/" element={<ProtectedRoute><CabinetLayout/></ProtectedRoute>}>
                        {cabinetRoutes.map((route, index) => (
                            <Route key={`cabinet-routes-${index}`} index={route.index}
                                   path={route.index ? null : route.path} element={
                                route.communityProtected
                                    ? <CommunityProtectedRoute>{route.element}</CommunityProtectedRoute>
                                    : <ProtectedRoute>{route.element}</ProtectedRoute>
                            }/>
                        ))}
                    </Route>

                    {
                        commonRoutes.map((route, index) => (
                            <Route key={`common-routes-${index}`} index={route.index}
                                   path={route.index ? null : route.path} element={route.element}/>
                        ))
                    }
                </Routes>
            </Providers>
        </Router>
    );
};

console.log(
    "%c REM capital",
    "font-weight: bold; font-size: 72px;color: #00ffff; text-shadow: 3px 3px 0 #00ECEC, 6px 6px 0 #80FFFF, 9px 9px 0 #070649, 12px 12px 0 #070649, 15px 15px 0 #070649, 18px 18px 0 #070649, 21px 21px 0 #070649"
);
