import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import GroupsIcon from "@mui/icons-material/Groups";
import CottageIcon from "@mui/icons-material/Cottage";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import FeedIcon from "@mui/icons-material/Feed";
import ContactSupport from "@mui/icons-material/ContactSupport";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuseumIcon from "@mui/icons-material/Museum";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getActiveLanguageFromLS } from "utils/index";
import globalDictionary from "assets/translations/globalDictionary";

function DrawerMenu({ handleDrawerClose, open = false, drawerWidth = 240 }) {
  const language = getActiveLanguageFromLS();
  const navigate = useNavigate();
  const theme = useTheme();
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }));

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden"
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`
    }
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
      }),
      ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
      })
    })
  );

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose} size="large">
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {[
          {
            text: globalDictionary.get(
              "layout_cabinet_drawer_menu_projects",
              language
            ),
            icon: <MuseumIcon />,
            link: "/projects"
          },
          {
            text: globalDictionary.get(
              "layout_cabinet_drawer_menu_properties",
              language
            ),
            icon: <CottageIcon />,
            link: "/properties"
          },
          {
            text: globalDictionary.get(
              "complexs_translation",
              language
            ),
            icon: <MapsHomeWorkIcon />,
            link: `/complex`
          }
        ].map((listItem, index) => (
          <ListItem
            key={listItem.text}
            disablePadding
            sx={{
              display: "block",
              color: listItem.important
                ? theme.palette.primary.main
                : "initial"
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5
              }}
              onClick={() => {
                if (listItem.external)
                  return (window.location = listItem.link);
                navigate(listItem.link);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center"
                }}
              >
                {listItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={listItem.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          {
            text: globalDictionary.get(
              "layout_cabinet_drawer_menu_community",
              language
            ),
            icon: <GroupsIcon />,
            link: "/community"
          },
          {
            text: globalDictionary.get(
              "layout_cabinet_drawer_menu_news",
              language
            ),
            icon: <FeedIcon />,
            link: "/news"
          },
          {
            text: globalDictionary.get(
              "layout_cabinet_drawer_menu_support",
              language
            ),
            icon: <SupportAgentIcon />,
            link: "/support/tickets"
          },
          {
            text: globalDictionary.get(
              "layout_cabinet_drawer_menu_contact",
              language
            ),
            icon: <ContactSupport />,
            link: "/contact"
          }
        ].map((listItem, index) => (
          <ListItem
            key={listItem.text}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5
              }}
              onClick={() => {
                if (listItem.external)
                  return (window.location = listItem.link);
                navigate(listItem.link);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center"
                }}
              >
                {listItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={listItem.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default DrawerMenu;
