const globalDictionary = {
  dictionary: {
    layout_cabinet_drawer_menu_wallet: {
      eng: "Wallet",
      rus: "Кошелёк",
      ukr: "Гаманець",
      deu: "Geldbörse",
    },
    projects_list_item_view: {
      eng: "View",
      rus: "Обзор",
      ukr: "Огляд",
      deu: "Mehr sehen",
    },
    projects_list_item_share: {
      eng: "Share",
      rus: "Поделиться",
      ukr: "Поділиться",
      deu: "Teilen",
    },
    projects_list_item_link_clipboard: {
      eng: "Link copied to clipboard",
      rus: "Ссылка скопирована в буфер обмена",
      ukr: "Посилання скопійовано в буфер обміну",
      deu: "Link wurde in die Zwischenablage kopiert",
    },
    profile_wallet_button_staking: {
      eng: "Staking",
      rus: "Стейкинг",
      ukr: "Стейкінг",
      deu: "Staking",
    },
    created_at: {
      eng: "Created at",
      rus: "Создано",
      ukr: "Створено",
      deu: "Erstellt am",
    },
    type: {
      eng: "Type",
      rus: "Тип",
      ukr: "Тип",
      deu: "Typ",
    },
    area_translate: {
      eng: "Area",
      rus: "Площадь",
      ukr: "Площа",
      deu: "Fläche",
    },

    asset: {
      eng: "Asset",
      rus: "Актив",
      ukr: "Актив",
      deu: "Vermögenswert",
    },
    deposit_method_translation: {
      eng: "Deposit method",
      rus: "Способ внесения",
      ukr: "Спосіб внесення",
      deu: "Einzahlungsmethode",
    },
    bank_transfer_translation: {
      eng: "Bank Transfer",
      rus: "Банковский Перевод",
      ukr: "Банківський Переказ",
      deu: "Banküberweisung",
    },
    min_deposit_amount_translation: {
      eng: "Min. deposit amount",
      rus: "Мин. сумма депозита",
      ukr: "Мін. сума депозиту",
      deu: "Mindesteinzahlungsbetrag",
    },
    but_no_less_than_translation: {
      eng: "but no less than",
      rus: "но не менее чем",
      ukr: "але не менше ніж",
      deu: "aber nicht weniger als",
    },transaction_fee_translation: {
      eng: "Transaction fee:",
      rus: "Комиссия за транзакцию:",
      ukr: "Комісія за транзакцію:",
      deu: "Transaktionsgebühr:"
    }
,    to_pay_translation: {
  eng: "To pay:",
  rus: "К оплате:",
  ukr: "До сплати:",
  deu: "Zu zahlen:"
}
,
min_withdraw_amount_translation: {
  eng: "Min. withdraw amount",
  rus: "Мин. сумма для вывода",
  ukr: "Мін. сума для виведення",
  deu: "Mindestabhebungsbetrag"
}
,add_new_ticket_translation: {
  eng: "Add a new ticket",
  rus: "Добавить новый тикет",
  ukr: "Додати новий тiкет",
  deu: "Ein neues Ticket hinzufügen"
}
,
create_new_ticket_form_translation: {
  eng: "Please fill out the form below to create a new ticket. We will get back to you as soon as possible",
  rus: "Пожалуйста, заполните форму ниже, чтобы создать новый билет. Мы свяжемся с вами как можно скорее",
  ukr: "Будь ласка, заповніть форму нижче, щоб створити новий квиток. Ми зв'яжемося з вами якнайшвидше",
  deu: "Bitte füllen Sie das untenstehende Formular aus, um ein neues Ticket zu erstellen. Wir werden uns so schnell wie möglich bei Ihnen melden"
}
,category_translation: {
  eng: "Category",
  rus: "Категория",
  ukr: "Категорія",
  deu: "Kategorie"
}
,general_translation: {
  eng: "General",
  rus: "Общие",
  ukr: "Загальні",
  deu: "Allgemein"
},

billing_payments_translation: {
  eng: "Billing & Payments",
  rus: "Выставление счетов & Оплаты",
  ukr: "Виставлення рахунків & Оплати",
  deu: "Abrechnung & Zahlungen"
},

technical_issues_translation: {
  eng: "Technical Issues",
  rus: "Технические проблемы",
  ukr: "Технічні проблеми",
  deu: "Technische Probleme"
},

account_management_translation: {
  eng: "Account Management",
  rus: "Управление аккаунтом",
  ukr: "Управління акаунтом",
  deu: "Kontoverwaltung"
},

product_feedback_translation: {
  eng: "Product Feedback",
  rus: "Отзывы о продукте",
  ukr: "Відгуки про продукт",
  deu: "Produkt-Feedback"
},

feature_request_translation: {
  eng: "Feature Request",
  rus: "Запрос функционала",
  ukr: "Запит функціоналу",
  deu: "Funktionsanfrage"
},

report_bug_translation: {
  eng: "Report a Bug",
  rus: "Сообщить об ошибке",
  ukr: "Повідомити про помилку",
  deu: "Einen Fehler melden"
},

installation_setup_translation: {
  eng: "Installation & Setup",
  rus: "Установка & Настройка",
  ukr: "Встановлення & Налаштування",
  deu: "Installation & Einrichtung"
},

account_recovery_translation: {
  eng: "Account Recovery",
  rus: "Восстановление аккаунта",
  ukr: "Відновлення акаунту",
  deu: "Kontowiederherstellung"
},

security_concerns_translation: {
  eng: "Security Concerns",
  rus: "Вопросы безопасности",
  ukr: "Питання безпеки",
  deu: "Sicherheitsbedenken"
},

cancellation_refunds_translation: {
  eng: "Cancellation & Refunds",
  rus: "Отмена & Возвраты",
  ukr: "Скасування & Повернення коштів",
  deu: "Stornierung & Rückerstattungen"
},

login_issues_translation: {
  eng: "Login Issues",
  rus: "Проблемы входа",
  ukr: "Проблеми входу",
  deu: "Login-Probleme"
},

usage_tutorials_translation: {
  eng: "Usage & Tutorials",
  rus: "Использование & Уроки",
  ukr: "Використання & Навчання",
  deu: "Nutzung & Anleitungen"
},

shipping_delivery_translation: {
  eng: "Shipping & Delivery",
  rus: "Доставка & Отправка",
  ukr: "Доставка & Відправлення",
  deu: "Versand & Lieferung"
},

subscription_management_translation: {
  eng: "Subscription Management",
  rus: "Управление подписками",
  ukr: "Управління підписками",
  deu: "Abonnementverwaltung"
},

integration_apis_translation: {
  eng: "Integration & APIs",
  rus: "Интеграция & API",
  ukr: "Інтеграція & API",
  deu: "Integration & APIs"
},

mobile_app_issues_translation: {
  eng: "Mobile App Issues",
  rus: "Проблемы мобильного приложения",
  ukr: "Проблеми мобільного додатку",
  deu: "Probleme mit mobilen Apps"
},

licensing_activation_translation: {
  eng: "Licensing & Activation",
  rus: "Лицензирование & Активация",
  ukr: "Ліцензування & Активація",
  deu: "Lizenzierung & Aktivierung"
},

accessibility_concerns_translation: {
  eng: "Accessibility Concerns",
  rus: "Проблемы доступности",
  ukr: "Проблеми доступності",
  deu: "Barrierefreiheitsbedenken"
},

partnership_affiliates_translation: {
  eng: "Partnership & Affiliates",
  rus: "Партнерство & Филиалы",
  ukr: "Партнерство & Філії",
  deu: "Partnerschaft & Partnerprogramme"
},

general_inquiry_translation: {
  eng: "General Inquiry",
  rus: "Общий запрос",
  ukr: "Загальний запит",
  deu: "Allgemeine Anfrage"
}
,
send_ticket_translation: {
  eng: "Send Ticket",
  rus: "Отправить Заявку",
  ukr: "Відправити Заявку",
  deu: "Ticket Senden"
}
,content_translation: {
  eng: "Content",
  rus: "Содержание",
  ukr: "Зміст",
  deu: "Inhalt"
}
,title_translation: {
  eng: "Title",
  rus: "Заголовок",
  ukr: "Заголовок",
  deu: "Titel"
}
,
    not_yet_registered_translation: {
      eng: "You are not yet registered",
      rus: "Вы еще не зарегистрированы",
      ukr: "Ви ще не зареєстровані",
      deu: "Sie sind noch nicht registriert",
    },
    not_registered_with_provider_translation: {
      eng: "You are not yet registered with this provider. Please confirm the agreement to continue",
      rus: "Вы еще не зарегистрированы у этого провайдера. Пожалуйста, подтвердите соглашение, чтобы продолжить",
      ukr: "Ви ще не зареєстровані у цього провайдера. Будь ласка, підтвердіть угоду, щоб продовжити",
      deu: "Sie sind bei diesem Anbieter noch nicht registriert. Bitte bestätigen Sie die Vereinbarung, um fortzufahren",
    },
    agree_terms_conditions_translation: {
      eng: "I agree with the Terms and Conditions",
      rus: "Я согласен с Условиями и Положениями",
      ukr: "Я згоден з Умовами та Положеннями",
      deu: "Ich stimme den Allgemeinen Geschäftsbedingungen zu",
    },
    beneficiary_translation: {
      eng: "Beneficiary",
      rus: "Бенефициар",
      ukr: "Бенефіціар",
      deu: "Begünstigter",
    },
    amount: {
      eng: "Amount",
      rus: "Сумма",
      ukr: "Сума",
      deu: "Betrag",
    },
    coming_soon: {
      eng: "Coming soon",
      rus: "Скоро будет доступно",
      ukr: "Скоро буде доступно",
      deu: "Demnächst verfügbar",
    },
    exchange_rate: {
      eng: "Exchange rate",
      rus: "Обменный курс",
      ukr: "Обмінний курс",
      deu: "Wechselkurs",
    },
    m2c: {
      eng: "M2C",
      rus: "M2C",
      ukr: "M2C",
      deu: "M2C",
    },
    history: {
      eng: "History",
      rus: "История",
      ukr: "Історія",
      deu: "Geschichte",
    },
    status: {
      eng: "Status",
      rus: "Статус",
      ukr: "Статус",
      deu: "Status",
    },
    completed_at: {
      eng: "Completed at",
      rus: "Завершено",
      ukr: "Завершено",
      deu: "Abgeschlossen am",
    },
    transaction_history: {
      eng: "Transaction History",
      rus: "История транзакций",
      ukr: "Історія транзакцій",
      deu: "Transaktionsverlauf",
    },
    export_transactions_history_success: {
      eng: "Transactions history exported successfully",
      rus: "История транзакций успешно экспортирована",
      ukr: "Історія транзакцій успішно експортована",
      deu: "Transaktionsverlauf erfolgreich exportiert",
    },
    contracts_history: {
      eng: "Contracts History",
      rus: "История контрактов",
      ukr: "Історія контрактів",
      deu: "Vertragsverlauf",
    },
    blocked: {
      eng: "Blocked",
      rus: "Заблокировано",
      ukr: "Заблоковано",
      deu: "Gesperrt",
    },
    maximum_transaction_amount: {
      eng: "Maximum Transaction Amount",
      rus: "Максимальная сумма транзакции",
      ukr: "Максимальна сума транзакції",
      deu: "Maximaler Transaktionsbetrag",
    },

    create_crypto_wallet: {
      eng: "Create Crypto Wallet",
      rus: "Создать криптовалютный кошелек",
      ukr: "Створити криптовалютний гаманець",
      deu: "Krypto-Wallet erstellen",
    },

    export_transactions_history: {
      eng: "Export transactions history",
      rus: "Экспортировать историю транзакций",
      ukr: "Експортувати історію транзакцій",
      deu: "Transaktionsverlauf exportieren",
    },
    profile_wallet_button_deposit: {
      eng: "Deposit",
      rus: "Депозит",
      ukr: "Депозит",
      deu: "Einzahlung",
    },
    profile_wallet_button_withdraw: {
      eng: "Withdraw",
      rus: "Вывести",
      ukr: "Вивести",
      deu: "Ausgabe",
    },
    profile_wallet_stellar_address: {
      eng: "Your wallet address",
      rus: "Адрес вашего кошелька",
      ukr: "Адреса вашого гаманця",
      deu: "Ihre Brieftaschenadresse",
    },
    profile_wallet_stellar_address_explorer: {
      eng: "Show my wallet in stellar explorer",
      rus: "Показать кошелек в stellar explorer",
      ukr: "Показати гаманець у stellar explorer",
      deu: "Brieftasche im Stellar-Explorer anzeigen",
    },
    layout_cabinet_header_wallet_deposit: {
      eng: "Deposit",
      rus: "Депозит",
      ukr: "Депозит",
      deu: "Einzahlung",
    },
    layout_cabinet_header_wallet: {
      eng: "Wallet",
      rus: "Кошелек",
      ukr: "Гаманець",
      deu: "Brieftasche",
    },
    wallet_tab_wallet: {
      eng: "Wallet",
      rus: "Кошелек",
      ukr: "Гаманець",
      deu: "Brieftasche",
    },
    layout_cabinet_header_wallet_overview: {
      eng: "Wallet",
      rus: "Кошелек",
      ukr: "Гаманець",
      deu: "Brieftasche",
    },
    layout_cabinet_header_wallet_history: {
      eng: "History",
      rus: "История",
      ukr: "Історія",
      deu: "Geschichte",
    },
    requests_edit_title: {
      eng: "Edit request",
      rus: "Редактировать запрос",
      ukr: "Редагувати запит",
      deu: "Bearbeiten einer Anfrage",
    },
    button_to_old_menu: {
      eng: "Old cabinet",
      rus: "Старый кабинет",
      ukr: "Старий кабінет",
      deu: "alter Schrank",
    },
    profile_advertising_support: {
      eng: "Feedback form",
      rus: "Форма обратной связи",
      ukr: "Форма зворотного зв'язку",
      deu: "Feedback-Formular",
    },
    profile_advertising_info: {
      eng: "The REM platform has its own marketing agency. You can order the promotion of your properties by writing to us via the feedback form",
      rus: "Платформа REM имеет собственное маркетинговое агентство. Вы можете заказать  продвижение ваших объектов недвижимости, написав нам через форму обратной связи",
      ukr: "Платформа REM має власне маркетингове агентство. Ви можете замовити просування ваших об'єктів нерухомості, написавши нам через форму зворотного зв'язку",
      deu: "Die REM-Plattform verfügt über eine eigene Marketing-Agentur. Sie können die Werbung für Ihre Immobilien bestellen, indem Sie uns über das Feedback-Formular schreiben",
    },
    properties_list_button_complex_add: {
      eng: "Add residential complex",
      rus: "Добавить жилой комплекс",
      ukr: "Додати житловий комплекс",
      deu: "Dodate die Wohnsiedlung",
    },
    properties_menu_view: {
      eng: "Owerview",
      rus: "Обзор",
      ukr: "Огляд",
      deu: "Übersicht",
    },
    property_list_item_menu_view: {
      eng: "View",
      rus: "Обзор",
      ukr: "Огляд",
      deu: "Mehr sehen",
    },
    requests_add_success: {
      eng: "Request has been successfully added",
      rus: "Запрос успешно добавлен",
      ukr: "Запит успішно додано",
      deu: "Anfrage wurde erfolgreich hinzugefügt",
    },
    properties_share_success: {
      eng: "Link copied to clipboard",
      rus: "Ссылка скопирована в буфер обмена",
      ukr: "Посилання скопійовано в буфер обміну",
      deu: "Link in die Zwischenablage kopiert",
    },
    property_add_common_sold_title: {
      eng: "Is sold?",
      rus: "Продано?",
      ukr: "Продано?",
      deu: "Ist verkauft?",
    },
    property_add_common_sold: {
      eng: "Sold",
      rus: "Продано",
      ukr: "Продано",
      deu: "Verkauft",
    },
    property_menu_sold: {
      eng: "Sold",
      rus: "Продано",
      ukr: "Продано",
      deu: "Verkauft",
    },
    property_add_common_not_sold: {
      eng: "Not sold",
      rus: "Не продано",
      ukr: "Не продано",
      deu: "Nicht verkauft",
    },
    property_add_common_available_title: {
      eng: "Is available?",
      rus: "Доступен?",
      ukr: "Доступний?",
      deu: "Ist verfügbar?",
    },
    property_add_common_available: {
      eng: "Available",
      rus: "Доступен",
      ukr: "Доступний",
      deu: "Verfügbar",
    },
    property_menu_available: {
      eng: "Available",
      rus: "Доступен",
      ukr: "Доступний",
      deu: "Verfügbar",
    },
    property_add_common_not_available: {
      eng: "Not available",
      rus: "Не доступен",
      ukr: "Недоступний",
      deu: "Nicht verfügbar",
    },
    property_edit_title: {
      eng: "Edit property",
      rus: "Редактировать объект недвижимости",
      ukr: "Редагувати об'єкт нерухомості",
      deu: "Eigenschaft bearbeiten",
    },
    complex_edit_title: {
      eng: "Edit complex",
      rus: "Редактировать комплекс ",
      ukr: "Редагувати комплекс ",
      deu: "Komplex bearbeiten",
    },
    property_item_author_contact: {
      eng: "Contact",
      rus: "Контакт",
      ukr: "Контакт",
      deu: "Kontakt",
    },
    property_item_tab_overview: {
      eng: "Overview",
      rus: "Обзор",
      ukr: "Огляд",
      deu: "Übersicht",
    },
    property_item_tab_technical: {
      eng: "Technical description",
      rus: "Техническое описание",
      ukr: "Технічний опис",
      deu: "Technische Beschreibung",
    },
    property_item_tab_documents: {
      eng: "Documents",
      rus: "Документы",
      ukr: "Документи",
      deu: "Dokumente",
    },
    property_item_tab_investment: {
      eng: "Investment attractiveness (soon)",
      rus: "Инвестиционная привлекательность (скоро)",
      ukr: "Інвестиційна привабливість (скоро)",
      deu: "Investmentattraktivität (bald)",
    },
    property_item_overview_description: {
      eng: "Description:",
      rus: "Описание:",
      ukr: "Опис:",
      deu: "Beschreibung:",
    },
    property_item_overview_location: {
      eng: "Location",
      rus: "Расположение",
      ukr: "Розташування",
      deu: "Standort",
    },
    property_item_technical_type: {
      eng: "Type of property:",
      rus: "Тип недвижимости:",
      ukr: "Тип нерухомості:",
      deu: "Art der Eigenschaft:",
    },
    complex_item_technical_type: {
      eng: "Type of complex:",
      rus: "Тип комплекса:",
      ukr: "Тип комплексу:",
      deu: "Art des Komplexes:",
    },

    property_item_technical_description: {
      eng: "Apartment description:",
      rus: "Описание квартиры:",
      ukr: "Опис квартири:",
      deu: "Wohnungsbeschreibung:",
    },
    property_item_technical_conditions: {
      eng: "Conditions:",
      rus: "Условия:",
      ukr: "Умови:",
      deu: "Bedingungen:",
    },
    property_item_technical_sm: {
      eng: "(sq.m.)",
      rus: "(кв.м.)",
      ukr: "(кв.м.)",
      deu: "(qm)",
    },
    property_item_technical_total_area: {
      eng: "Total area:",
      rus: "Общая площадь:",
      ukr: "Загальна площа:",
      deu: "Gesamtfläche:",
    },
    property_item_technical_living_space: {
      eng: "Living space:",
      rus: "Жилая площадь:",
      ukr: "Житлова площа:",
      deu: "Wohnfläche:",
    },
    property_item_technical_kitchen_size: {
      eng: "Kitchen size:",
      rus: "Площадь кухни:",
      ukr: "Площа кухні:",
      deu: "Küchengröße:",
    },
    property_item_technical_floor: {
      eng: "Floor:",
      rus: "Этаж:",
      ukr: "Поверх:",
      deu: "Boden:",
    },
    condition_translate: {
      eng: "Condition",
      rus: "Состояние",
      ukr: "Стан",
      deu: "Zustand",
    },
    number_translate: {
      eng: "Number",
      rus: "Номер",
      ukr: "Номер",
      deu: "Nummer",
    },

    property_item_technical_storeys: {
      eng: "Number of storeys:",
      rus: "Этажность:",
      ukr: "Поверховість:",
      deu: "Anzahl der Stockwerke:",
    },
    property_item_technical_rooms: {
      eng: "Rooms:",
      rus: "Комнаты:",
      ukr: "Кімнати:",
      deu: "Zimmer:",
    },
    property_item_technical_balcony: {
      eng: "Balcony:",
      rus: "Балконы:",
      ukr: "Балкони:",
      deu: "Balkon:",
    },
    property_item_technical_ceiling_height: {
      eng: "Ceiling height:",
      rus: "Высота потолка:",
      ukr: "Висота стелі:",
      deu: "Deckenhöhe:",
    },
    property_item_technical_wall_material: {
      eng: "Wall material:",
      rus: "Материал стен:",
      ukr: "Матеріал стін:",
      deu: "Wandmaterial:",
    },
    property_item_technical_window_view: {
      eng: "Utility system:",
      rus: "Коммунальные системы:",
      ukr: "Комунальні системи:",
      deu: "Versorgungssystem:",
    },
    property_item_documents_photo: {
      eng: "Documents:",
      rus: "Документы:",
      ukr: "Документи:",
      deu: "Dokumente:",
    },
    property_item_documents_exclusive_agreements: {
      eng: "Exclusive agreements:",
      rus: "Эксклюзивные соглашения:",
      ukr: "Ексклюзивні угоди:",
      deu: "Exklusivvereinbarungen:",
    },
    property_add_title: {
      eng: "Add property",
      rus: "Добавить объект недвижимости",
      ukr: "Додати об'єкт нерухомості ",
      deu: "Eigenschaft hinzufügen",
    },
    property_add_step_common: {
      eng: "Common",
      rus: "Общее",
      ukr: "Загальні",
      deu: "Gemeinsam",
    },
    property_add_step_technical: {
      eng: "Technical",
      rus: "Технические",
      ukr: "Технічні",
      deu: "Technisch",
    },
    property_add_step_financial: {
      eng: "Financial",
      rus: "Финансовые",
      ukr: "Фінансові",
      deu: "Finanzen",
    },
    property_add_step_photos: {
      eng: "Photos",
      rus: "Фотографии",
      ukr: "Фотографії",
      deu: "Fotos",
    },
    property_add_step_documents: {
      eng: "Documents",
      rus: "Документы",
      ukr: "Документи",
      deu: "Dokumente",
    },
    property_add_send: {
      eng: "Send",
      rus: "Отправить",
      ukr: "Надіслати",
      deu: "Senden",
    },
    property_add_next: {
      eng: "Next",
      rus: "Далее",
      ukr: "Далі",
      deu: "Weiter",
    },

    property_add_back: {
      eng: "Back",
      rus: "Назад",
      ukr: "Назад",
      deu: "Zurück",
    },
    property_add_common_title: {
      eng: "Common properties",
      rus: "Общие свойства",
      ukr: "Загальні властивості",
      deu: "Gemeinsame Eigenschaften",
    },
    property_add_common_number: {
      eng: "Number of the property",
      rus: "Номер объекта",
      ukr: "Номер об'єкта",
      deu: "Nummer der Eigenschaft",
    },
    property_add_common_number_placeholder: {
      eng: "Enter number of the property",
      rus: "Введите номер объекта",
      ukr: "Введіть номер власності",
      deu: "Geben Sie die Nummer der Immobilie ein",
    },
    property_add_common_number_help: {
      eng: "Here you can write a code of the property object. For example, a code in your database or elsewhere",
      rus: "Здесь вы можете написать код объекта свойства. Например, код в вашей базе данных или в другом месте.",
      ukr: "Тут ви можете написати код об'єкта. Наприклад, код у вашій базі даних або деінде",
      deu: "Hier können Sie einen Code des Eigenschaftsobjekts schreiben. Zum Beispiel ein Code in Ihrer Datenbank oder anderswo.",
    },
    property_add_common_name: {
      eng: "Name of the property",
      rus: "Имя объекта",
      ukr: "Назва об'єкта",
      deu: "Name der Eigenschaft",
    },
    complex_add_common_name: {
      eng: "Name of the complex",
      rus: "Имя комплекса",
      ukr: "Назва комплесу",
      deu: "Komplexer Name",
    },
    property_add_common_name_placeholder: {
      eng: "Enter name of the property",
      rus: "Введите имя объекта",
      ukr: "Введіть назву об'єкту",
      deu: "Geben Sie den Namen der Eigenschaft ein",
    },
    property_add_common_type: {
      eng: "Type of property",
      rus: "Тип объекта",
      ukr: "Тип об'єкту",
      deu: "Art der Eigenschaft",
    },
    property_add_common_type_help: {
      eng: "Select property type",
      rus: "Выберите тип объекта",
      ukr: "Виберіть тип об'єкту",
      deu: "Eigenschaftstyp auswählen",
    },
    property_add_common_residential_type: {
      eng: "Residential type",
      rus: "Тип жилья",
      ukr: "Житловий тип",
      deu: "Wohntyp",
    },
    property_add_common_residential_type_help: {
      eng: "Select residential type",
      rus: "Выберите тип жилья",
      ukr: "Виберіть тип житлового приміщення",
      deu: "Wohntyp auswählen",
    },
    property_add_common_commercial_type: {
      eng: "Commercial type",
      rus: "Коммерческий тип",
      ukr: "Комерційний тип",
      deu: "Handelstyp",
    },
    property_add_common_commercial_type_help: {
      eng: "Select commercial type",
      rus: "Выберите коммерческий тип",
      ukr: "Виберіть комерційний тип",
      deu: "Gewerbetyp auswählen",
    },
    property_add_common_industrial_type: {
      eng: "Industrial type",
      rus: "Промышленный тип",
      ukr: "Промисловий тип",
      deu: "Industrietyp",
    },
    property_add_common_industrial_type_help: {
      eng: "Select industrial type",
      rus: "Выберите промышленный тип",
      ukr: "Виберіть промисловий тип",
      deu: "Industrietyp auswählen",
    },
    property_add_common_conditions_type: {
      eng: "Conditions type",
      rus: "Состояние объекта",
      ukr: "Стан об'єкту",
      deu: "Bedingungstyp",
    },
    property_add_common_conditions_type_help: {
      eng: "Select conditions type",
      rus: "Укажите состояние объекта",
      ukr: "Вкажіть стан об'єкту",
      deu: "Bedingungstyp auswählen",
    },
    property_add_common_disposition_rooms: {
      eng: "Disposition of rooms",
      rus: "Расположение комнат",
      ukr: "Планування кімнат",
      deu: "Disposition of rooms",
    },
    property_add_common_conditions_disposition_rooms_help: {
      eng: "Select disposition of rooms",
      rus: "Выберите расположение комнат",
      ukr: "Виберіть розташування кімнат",
      deu: "Raumverteilung wählen",
    },
    property_add_common_disposition_rooms_walkthrough: {
      eng: "Walkthrough room",
      rus: "Комната обхода",
      ukr: "Прохідна кімната",
      deu: "Durchgangsraum",
    },
    property_add_common_disposition_rooms_separate: {
      eng: "Separate rooms",
      rus: "Отдельные комнаты",
      ukr: "Окремі кімнати",
      deu: "Getrennte Räume",
    },
    property_add_common_disposition_rooms_interconnecting: {
      eng: "Interconnecting parallel rooms",
      rus: "Соединяющиеся параллельные комнаты",
      ukr: "З'єднання паралельних кімнат",
      deu: "Verbindung paralleler Räume",
    },
    property_add_common_apartment_description: {
      eng: "Apartment description",
      rus: "Описание квартиры",
      ukr: "Опис квартири",
      deu: "Wohnungsbeschreibung",
    },
    property_add_common_conditions_apartment_description_help: {
      eng: "Select apartment description",
      rus: "Выберите описание квартиры",
      ukr: "Виберіть опис квартири",
      deu: "Wohnungsbeschreibung auswählen",
    },
    property_add_common_description: {
      eng: "Description",
      rus: "Описание",
      ukr: "Опис",
      deu: "Beschreibung",
    },
    property_add_common_description_help: {
      eng: "Enter description",
      rus: "Введите описание",
      ukr: "Введіть опис",
      deu: "Beschreibung eingeben",
    },
    property_add_common_infrastructure_description: {
      eng: "Infrastructure description",
      rus: "Описание инфраструктуры",
      ukr: "Опис інфраструктури",
      deu: "Infrastruktur Beschreibung",
    },
    property_add_common_infrastructure_description_help: {
      eng: "Enter infrastructure description",
      rus: "Введите описание инфраструктуры",
      ukr: "Введіть опис інфраструктури",
      deu: "Geben Sie die Infrastruktur-Beschreibung ein",
    },
    property_add_common_parking_description: {
      eng: "Parking description",
      rus: "Описание парковки",
      ukr: "Опис парковки",
      deu: "Parkplatzbeschreibung",
    },
    property_add_common_parking_description_help: {
      eng: "Enter parking description",
      rus: "Введите описание парковки",
      ukr: "Введіть опис парковки",
      deu: "Geben Sie die Parkplatzbeschreibung ein",
    },
    property_add_common_territory_description: {
      eng: "Territory description",
      rus: "Описание территории",
      ukr: "Опис території",
      deu: "Geländebeschreibung",
    },
    property_add_common_territory_description_help: {
      eng: "Enter territory description",
      rus: "Введите описание территории",
      ukr: "Введіть опис території",
      deu: "Geben Sie die Geländebeschreibung ein",
    },
    property_add_common_landmark: {
      eng: "Landmarks",
      rus: "Ориентиры",
      ukr: "Орієнтири",
      deu: "Sehenswürdigkeiten",
    },
    property_add_common_landmark_placeholder: {
      eng: "Enter Landmarks",
      rus: "Введите ориентиры",
      ukr: "Введіть орієнтири",
      deu: "Orientierungspunkte eingeben",
    },
    property_add_common_landmark_help: {
      eng: "Orienting point, some well-known or easily seen object, that is located nearby",
      rus: "Ориентиры, какой-нибудь хорошо известный или хорошо заметный объект, находящийся поблизости",
      ukr: "Орієнтири, якийсь добре відомий або легко видимий об'єкт, який знаходиться поблизу",
      deu: "Orientierungspunkt, ein bekanntes oder leicht zu sehendes Objekt, das sich in der Nähe befindet",
    },
    property_add_common_location_title: {
      eng: "In order to choose an address of the object, please fill in the address in the map field below",
      rus: "Чтобы выбрать адрес объекта, пожалуйста, заполните адрес в поле карты ниже",
      ukr: "Щоб вибрати адресу об'єкта, будь ласка, заповніть адресу в полі карти нижче",
      deu: "Um eine Adresse des Objekts auszuwählen, geben Sie bitte die Adresse in das Kartenfeld unten ein",
    },
    complex_add_common_location_title: {
      eng: "In order to choose an address of the complex, please fill in the address in the map field below",
      rus: "Чтобы выбрать адрес комплекса, пожалуйста, заполните адрес в поле карты ниже",
      ukr: "Щоб вибрати адресу комплексу, будь ласка, заповніть адресу в полі карти нижче",
      deu: "Um eine Adresse des Komplexes auszuwählen, geben Sie bitte die Adresse in das Kartenfeld unten ein",
    },
    property_add_common_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    property_add_common_country_placeholder: {
      eng: "Enter сountry",
      rus: "Введите страну ",
      ukr: "Введіть країну",
      deu: "Land eingeben",
    },
    property_add_common_city: {
      eng: "City",
      rus: "Город",
      ukr: "місто",
      deu: "Stadt",
    },
    property_add_common_city_placeholder: {
      eng: "Enter city",
      rus: "Введите город",
      ukr: "Введіть місто",
      deu: "Stadt eingeben",
    },
    property_add_common_district: {
      eng: "District",
      rus: "Район",
      ukr: "Район",
      deu: "Bezirk",
    },
    property_add_common_district_placeholder: {
      eng: "Enter district",
      rus: "Введите район",
      ukr: "Введіть район",
      deu: "Bezirk eingeben",
    },
    property_add_common_street: {
      eng: "Street",
      rus: "Улица",
      ukr: "Вулиця",
      deu: "Straße",
    },
    property_add_common_street_placeholder: {
      eng: "Enter street",
      rus: "Введите улицу",
      ukr: "Введіть вулицю",
      deu: "Straße eingeben",
    },
    property_add_common_house: {
      eng: "House number",
      rus: "Номер дома",
      ukr: "номер будинку",
      deu: "Hausnummer",
    },
    property_add_common_house_placeholder: {
      eng: "Enter house number",
      rus: "Введите номер дома",
      ukr: "Введіть номер будинку",
      deu: "Hausnummer eingeben",
    },
    property_add_technical_title: {
      eng: "Technical specifications",
      rus: "Технические характеристики",
      ukr: "Технічні характеристики",
      deu: "Technische Daten",
    },
    property_add_technical_building_type: {
      eng: "Type of the building",
      rus: "Тип здания",
      ukr: "Тип будівлі",
      deu: "Typ des Gebäudes",
    },
    property_add_technical_building_type_placeholder: {
      eng: "Enter type of the building",
      rus: "Введите тип здания",
      ukr: "Введіть тип будівлі",
      deu: "Typ des Gebäudes eingeben",
    },
    property_add_technical_wall: {
      eng: "Wall material",
      rus: "Материал стены",
      ukr: "Матеріал стін",
      deu: "Wandmaterial",
    },
    property_add_technical_wall_placeholder: {
      eng: "Enter wall material",
      rus: "Введите материал стены",
      ukr: "Введіть матеріал стіни",
      deu: "Wandmaterial eingeben",
    },
    property_add_technical_storeys: {
      eng: "Number of storeys",
      rus: "Этажность",
      ukr: "Кількість поверхів",
      deu: "Anzahl der Stockwerke",
    },
    property_add_technical_storeys_placeholder: {
      eng: "Enter number of storeys",
      rus: "Введите количество этажей",
      ukr: "Введіть кількість поверхів",
      deu: "Geschosszahl eingeben",
    },
    property_add_technical_floor: {
      eng: "Floor",
      rus: "Этаж",
      ukr: "Поверх",
      deu: "Boden",
    },
    property_add_technical_floor_placeholder: {
      eng: "Enter floor",
      rus: "Введите этаж",
      ukr: "Введіть поверх",
      deu: "Etage eingeben",
    },
    property_add_technical_year_сonstruction: {
      eng: "Year of construction",
      rus: "Год постройки",
      ukr: "Рік побудови",
      deu: "Baujahr",
    },
    property_add_technical_year_сonstruction_placeholder: {
      eng: "Enter year of construction",
      rus: "Введите год постройки",
      ukr: "Введіть рік будівництва",
      deu: "Baujahr eingeben",
    },
    property_add_technical_designated_use: {
      eng: "Designated use",
      rus: "Использование по назначению",
      ukr: "Використання за призначенням",
      deu: "Verwendungszweck",
    },
    property_add_technical_designated_use_placeholder: {
      eng: "Enter designated use",
      rus: "Введите указанное использование",
      ukr: "Введіть призначення",
      deu: "Verwendungszweck eintragen",
    },
    property_add_technical_space_all: {
      eng: "Total area (sq.m.)",
      rus: "Общая площадь (кв.м.)",
      ukr: "Загальна площа (кв.м.)",
      deu: "Gesamtfläche (qm)",
    },
    property_add_technical_space_all_placeholder: {
      eng: "Enter total area (sq.m.)",
      rus: "Введите общую площадь (кв.м.)",
      ukr: "Введіть загальну площу (кв.м.)",
      deu: "Gesamtfläche eingeben (qm)",
    },
    property_add_technical_space_land: {
      eng: "Land plot area (sq.m.)",
      rus: "Площадь земельного участка (кв.м.)",
      ukr: "Площа земельної ділянки (кв.м.)",
      deu: "Grundstücksfläche (qm)",
    },
    property_add_technical_space_land_placeholder: {
      eng: "Enter land plot area (sq.m.)",
      rus: "Введите площадь земельного участка (кв.м.)",
      ukr: "Введіть площу земельної ділянки (кв.м.)",
      deu: "Grundstücksfläche eingeben (qm)",
    },
    property_add_technical_space_effective: {
      eng: "Effective area (sq.m.)",
      rus: "Эффективная площадь (кв.м.)",
      ukr: "Ефективна площа (кв.м.)",
      deu: "Nutzfläche (qm)",
    },
    property_add_technical_space_effective_placeholder: {
      eng: "Enter effective area (sq.m.)",
      rus: "Введите эффективную площадь (кв.м.)",
      ukr: "Введіть ефективну площу (кв.м.)",
      deu: "Nutzfläche (qm) eingeben",
    },
    property_add_technical_space_auxiliary: {
      eng: "Effective area (sq.m.)",
      rus: "Дополнительная площадь (кв.м.)",
      ukr: "Додаткова площа (кв.м.)",
      deu: "Nutzfläche (qm)",
    },
    property_add_technical_space_auxiliary_placeholder: {
      eng: "Enter effective area (sq.m.)",
      rus: "Введите дополнительную площадь (кв.м.)",
      ukr: "Введіть додаткову площу (кв.м.)",
      deu: "Nutzfläche (qm) eingeben",
    },
    property_add_technical_space_living: {
      eng: "Living space (sq.m.)",
      rus: "Жилая площадь (кв.м.)",
      ukr: "Житлова площа (кв.м.)",
      deu: "Wohnfläche (qm)",
    },
    property_add_technical_space_living_placeholder: {
      eng: "Enter living space (sq.m.)",
      rus: "Введите жилую площадь (кв.м.)",
      ukr: "Введіть житлову площу (кв.м.)",
      deu: "Wohnfläche eingeben (qm)",
    },
    property_add_technical_space_kitchen: {
      eng: "Kitchen size (sq.m.)",
      rus: "Площадь кухни (кв.м.)",
      ukr: "Розмір кухні (кв.м.)",
      deu: "Küchengröße (qm)",
    },
    property_add_technical_space_kitchen_placeholder: {
      eng: "Enter kitchen size (sq.m.)",
      rus: "Введите размер кухни (кв.м.)",
      ukr: "Введіть розмір кухні (кв.м.)",
      deu: "Küchengröße eingeben (qm)",
    },
    property_add_technical_ceiling_height: {
      eng: "Ceiling height (m)",
      rus: "Высота потолка (м)",
      ukr: "Висота стелі (м)",
      deu: "Deckenhöhe (m)",
    },
    property_add_technical_ceiling_height_placeholder: {
      eng: "Enter ceiling height (m)",
      rus: "Введите высоту потолка (м)",
      ukr: "Введіть висоту стелі (м)",
      deu: "Deckenhöhe eingeben (m)",
    },
    property_add_technical_rooms: {
      eng: "Rooms",
      rus: "Комнаты",
      ukr: "Кімнати",
      deu: "Räume",
    },
    property_add_technical_rooms_placeholder: {
      eng: "Enter rooms",
      rus: "Войти в комнаты",
      ukr: "Вхід в кімнати",
      deu: "Räume betreten",
    },
    property_add_technical_balcony: {
      eng: "Balcony",
      rus: "Балкон",
      ukr: "Балкон",
      deu: "Balkon",
    },
    property_add_technical_balcony_placeholder: {
      eng: "Enter balcony",
      rus: "Войти на балкон",
      ukr: "Вхід на балкон",
      deu: "Balkon betreten",
    },
    property_add_technical_windows: {
      eng: "Number of windows",
      rus: "Количество окон",
      ukr: "Кількість вікон",
      deu: "Anzahl der Fenster",
    },
    property_add_technical_windows_placeholder: {
      eng: "Enter number of windows",
      rus: "Введите количество окон",
      ukr: "Введіть кількість вікон",
      deu: "Anzahl der Fenster eingeben",
    },
    property_add_technical_bathrooms: {
      eng: "Number of bathrooms",
      rus: "Количество ванных комнат",
      ukr: "Кількість ванних кімнат",
      deu: "Anzahl der Badezimmer",
    },
    property_add_technical_bathrooms_placeholder: {
      eng: "Enter number of bathrooms",
      rus: "Введите количество ванных комнат",
      ukr: "Введіть кількість ванних кімнат",
      deu: "Anzahl Badezimmer eingeben",
    },
    property_add_technical_windows_view: {
      eng: "Window view",
      rus: "Вид из окна",
      ukr: "Вид з вікна",
      deu: "Fensteransicht",
    },
    property_add_technical_windows_view_placeholder: {
      eng: "Enter window view",
      rus: "Введите вид окна",
      ukr: "Укажіть вид з вікна",
      deu: "Fensteransicht öffnen",
    },
    property_add_technical_interior_features: {
      eng: "Interior features",
      rus: "Внутренние особенности",
      ukr: "Особливості інтер'єру",
      deu: "Innenausstattung",
    },
    property_add_technical_interior_features_placeholder: {
      eng: "Enter interior features",
      rus: "Введите элементы интерьера",
      ukr: "Введіть особливості інтер'єру",
      deu: "Interieurmerkmale eingeben",
    },
    property_add_technical_parking_area: {
      eng: "Parking area",
      rus: "Парковка",
      ukr: "Парковка",
      deu: "Parkplatz",
    },
    property_add_technical_parking_area_placeholder: {
      eng: "Enter parking area",
      rus: "Укажите зону парковки",
      ukr: "Укажіть зону паркування",
      deu: "Parkbereich betreten",
    },
    property_add_technical_utility: {
      eng: "Utility system",
      rus: "Коммунальные системы",
      ukr: "Комунальні системи",
      deu: "Versorgungssystem",
    },
    property_add_technical_utility_help: {
      eng: "Select utility system",
      rus: "Выберите коммунальные системы",
      ukr: "Виберіть комунальні системи",
      deu: "Versorgungssystem wählen",
    },
    property_add_technical_equipment: {
      eng: "Equipment",
      rus: "Оборудование",
      ukr: "Обладнання",
      deu: "Ausrüstung",
    },
    property_add_technical_equipment_placeholder: {
      eng: "Enter equipment",
      rus: "Введите оборудование",
      ukr: "Введіть обладнання",
      deu: "Ausrüstung eingeben",
    },
    property_add_financial_title: {
      eng: "Financial indicators",
      rus: "Финансовые показатели",
      ukr: "Фінансові показники",
      deu: "Finanzkennzahlen",
    },
    property_add_financial_price: {
      eng: "Price",
      rus: "Цена",
      ukr: "Ціна",
      deu: "Preis",
    },
    addNews_translate: {
      eng: "Add News",
      rus: "Добавить Новость",
      ukr: "Додати Новину",
      deu: "Nachrichten Hinzufügen",
    },
    typeOfApartment_translate: {
      eng: "Type of Apartment",
      rus: "Тип Квартиры",
      ukr: "Тип Квартири",
      deu: "Art der Wohnung",
    },
    studio_translate: {
      eng: "Studio",
      rus: "Студия",
      ukr: "Студія",
      deu: "Studio",
    },
    convertibleStudio_translate: {
      eng: "Convertible Studio",
      rus: "Трансформируемая Студия",
      ukr: "Трансформована Студія",
      deu: "Umwandelbares Studio",
    },
    apartament_translate: {
      eng: "Apartment",
      rus: "Апартаменты",
      ukr: "Апартаменти",
      deu: "Apartment",
    },
    oneBedroom_translate: {
      eng: "1-Bedroom",
      rus: "1-Комнатная Квартира",
      ukr: "1-Кімнатна Квартира",
      deu: "1-Zimmer-Wohnung",
    },
    twoBedroom_translate: {
      eng: "2-Bedroom",
      rus: "2-Комнатная Квартира",
      ukr: "2-Кімнатна Квартира",
      deu: "2-Zimmer-Wohnung",
    },
    threeBedroom_translate: {
      eng: "3-Bedroom",
      rus: "3-Комнатная Квартира",
      ukr: "3-Кімнатна Квартира",
      deu: "3-Zimmer-Wohnung",
    },
    fourBedroom_translate: {
      eng: "4-Bedroom",
      rus: "4-Комнатная Квартира",
      ukr: "4-Кімнатна Квартира",
      deu: "4-Zimmer-Wohnung",
    },
    fiveBedroom_translate: {
      eng: "5-Bedroom",
      rus: "5-Комнатная Квартира",
      ukr: "5-Кімнатна Квартира",
      deu: "5-Zimmer-Wohnung",
    },
    penthouse_translate: {
      eng: "Penthouse",
      rus: "Пентхаус",
      ukr: "Пентхаус",
      deu: "Penthouse",
    },
    utilitySystems_translate: {
      eng: "Utility Systems",
      rus: "Коммунальные Системы",
      ukr: "Комунальні Системи",
      deu: "Versorgungssysteme",
    },
    property_add_financial_price_placeholder: {
      eng: "Enter price",
      rus: "Введите цену",
      ukr: "Введіть ціну",
      deu: "Preis eingeben",
    },
    property_add_financial_currency: {
      eng: "Currency",
      rus: "Валюта",
      ukr: "Валюта",
      deu: "Währung",
    },
    property_add_financial_currency_help: {
      eng: "Select currency",
      rus: "Выберите валюту",
      ukr: "Виберіть валюту",
      deu: "Währung wählen",
    },
    property_add_main_photo_title: {
      eng: "Main photo of the property",
      rus: "Главное фото объекта",
      ukr: "Головне фото власності",
      deu: "Hauptfoto des Immobilie",
    },
    property_add_photos_title: {
      eng: "Photos of the property",
      rus: "Фотографии объекта",
      ukr: "Фото власності",
      deu: "Fotos der Immobilie",
    },
    complex_add_photos_title: {
      eng: "Photos of the complex",
      rus: "Фотографии комплекса",
      ukr: "Фото комплексу",
      deu: "Fotos des Komplexes",
    },
    image_uploader_title: {
      eng: "Photos",
      rus: "Фотографии",
      ukr: "Фото",
      deu: "Fotos",
    },
    image_uploader_clear: {
      eng: "Clear all",
      rus: "Очистить все",
      ukr: "Очистити все",
      deu: "Alle löschen",
    },
    image_uploader_drop: {
      eng: "Drop image files to upload (or click)",
      rus: "Перетащите файлы изображений для загрузки (или нажмите)",
      ukr: "Перетягніть файли зображень для завантаження (або натисніть)",
      deu: "Bilddateien zum Hochladen ablegen (oder klicken)",
    },
    image_uploader_formats: {
      eng: "(jpg, jpeg, png, webp)",
      rus: "(jpg, jpeg, png, webp)",
      ukr: "(jpg, jpeg, png, webp)",
      deu: "(jpg, jpeg, png, webp)",
    },
    image_uploader_file_count: {
      eng: "Maximum count of files",
      rus: "Максимальное количество файлов",
      ukr: "Максимальна кількість файлів",
      deu: "Maximale Anzahl an Dateien",
    },
    image_uploader_file_count_alarm: {
      eng: "Maximum count of files reached",
      rus: "Максимальное количество файлов достигнуто",
      ukr: "Максимальна кількість файлів досягнута",
      deu: "Maximale Anzahl an Dateien erreicht",
    },
    image_uploader_file_count_min: {
      eng: "Minimum count of files",
      rus: "Минимальное количество файлов",
      ukr: "Мінімальна кількість файлів",
      deu: "Mindestanzahl an Dateien",
    },
    image_uploader_file_count_min_warning: {
      eng: "Minimum count of files",
      rus: "Минимальное количество файлов",
      ukr: "Мінімальна кількість файлів",
      deu: "Mindestanzahl an Dateien",
    },
    image_uploader_file_size: {
      eng: "Maximum size of each image",
      rus: "Максимальный размер каждого изображения",
      ukr: "Максимальний розмір кожного зображення",
      deu: "Maximale Größe jedes Bildes",
    },
    property_add_loader_clear: {
      eng: "Clear all",
      rus: "Очистить все",
      ukr: "Очистити все",
      deu: "Alle löschen",
    },
    property_add_loader_drop: {
      eng: "Drop image files to upload (or click)",
      rus: "Перетащите файлы изображений для загрузки (или нажмите)",
      ukr: "Перетягніть файли зображень для завантаження (або натисніть)",
      deu: "Bilddateien zum Hochladen ablegen (oder klicken)",
    },
    add_translate: {
      eng: "Add",
      rus: "Добавить",
      ukr: "Додати",
      deu: "Hinzufügen",
    },
    constructionProgress_translate: {
      eng: "Construction Progress",
      rus: "Ход Строительства",
      ukr: "Хід Будівництва",
      deu: "Baufortschritt",
    },
    constructionNews_translate: {
      eng: "Construction News",
      rus: "Новости Строительства",
      ukr: "Новини Будівництва",
      deu: "Bau Nachrichten",
    },
    property_add_loader_formats: {
      eng: "(jpg, jpeg, png, gif)",
      rus: "(jpg, jpeg, png, gif)",
      ukr: "(jpg, jpeg, png, gif)",
      deu: "(jpg, jpeg, png, gif)",
    },
    property_add_loader_file_count: {
      eng: "Maximum number of files",
      rus: "Максимальное количество файлов",
      ukr: "Максимальна кількість файлів",
      deu: "Maximale Anzahl an Dateien",
    },
    property_add_loader_file_count_min: {
      eng: "Minimum number of files",
      rus: "Минимальное количество файлов",
      ukr: "Мінімальна кількість файлів",
      deu: "Mindestanzahl an Dateien",
    },
    property_add_loader_file_size: {
      eng: "Maximum size of each image",
      rus: "Максимальный размер каждого изображения",
      ukr: "Максимальний розмір кожного зображення",
      deu: "Maximale Größe jedes Bildes",
    },
    property_add_documents_title: {
      eng: "Verification documents (property documents)",
      rus: "Проверочные документы (документы о собственности)",
      ukr: "Документи перевірки (документи власності)",
      deu: "Nachweisdokumente (Eigenschaftsdokumente)",
    },
    property_add_documents_subtitle: {
      eng: "Images of documents",
      rus: "Изображения документов",
      ukr: "Зображення документів",
      deu: "Bilder von Dokumenten",
    },
    property_add_documents_agreements_title: {
      eng: "Exclusive agreements",
      rus: "Эксклюзивные соглашения",
      ukr: "Ексклюзивні угоди",
      deu: "Exklusivvereinbarungen",
    },
    requests_add_title: {
      eng: "Add request",
      rus: "Добавить запрос",
      ukr: "Додати запит",
      deu: "Anfrage hinzufügen",
    },
    requests_add_tab_property: {
      eng: "Property",
      rus: "Недвижимость",
      ukr: "Нерухомість",
      deu: "Eigenschaft",
    },
    requests_add_tab_specialist: {
      eng: "Specialist",
      rus: "Специалист",
      ukr: "Фахівець",
      deu: "Spezialist",
    },
    requests_add_property_text: {
      eng: "The user can create an application for choosing property that is of interest to him. This application is sent to all brokers registered on the platform according to the specified search options.",
      rus: "Пользователь может создать заявку на выбор интересующей его недвижимости. Данная заявка рассылается всем брокерам, зарегистрированным на платформе по указанным параметрам поиска.",
      ukr: "Користувач може створити заявку на вибір нерухомості, що його цікавить. Ця заявка надсилається всім брокерам, зареєстрованим на платформі за вказаними параметрами пошуку.",
      deu: "Der Benutzer kann eine Anwendung erstellen, um Immobilien auszuwählen, die ihn interessieren. Dieser Antrag wird an alle auf der Plattform registrierten Makler gemäß den angegebenen Suchoptionen gesendet.",
    },
    requests_add_property_type: {
      eng: "Type of property",
      rus: "Тип недвижимости",
      ukr: "Тип нерухомості",
      deu: "Art der Eigenschaft",
    },
    requests_add_property_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    requests_add_property_city: {
      eng: "City",
      rus: "Город",
      ukr: "Місто",
      deu: "Stadt",
    },
    requests_add_property_district: {
      eng: "District",
      rus: "Округ",
      ukr: "Округ",
      deu: "Bezirk",
    },
    requests_add_property_space_from: {
      eng: "Space (sq.m.) from:",
      rus: "Площадь (кв.м.) от:",
      ukr: "Площа (кв.м.) від:",
      deu: "Fläche (qm) von:",
    },
    requests_add_property_space_to: {
      eng: "Space (sq.m.) to:",
      rus: "Площадь (кв.м.) до:",
      ukr: "Площа (кв.м.) до:",
      deu: "Fläche (qm) bis:",
    },
    requests_add_property_price_from: {
      eng: "Price from (₼):",
      rus: "Цена от (₼):",
      ukr: "Ціна від (₼):",
      deu: "Preis ab (₼):",
    },
    requests_add_property_price_to: {
      eng: "Price to (₼):",
      rus: "Цена до (₼):",
      ukr: "Ціна до (₼):",
      deu: "Preis bis (₼):",
    },
    requests_add_property_submit: {
      eng: "Submit request",
      rus: "Отправить запрос",
      ukr: "Відправити запит",
      deu: "Anfrage senden",
    },
    requests_add_specialist_text: {
      eng: "The user can create an application for choosing necessary specialists. This application is sent to all specialists registered on the platform.",
      rus: "Пользователь может создать заявку на подбор необходимых специалистов. Данная заявка рассылается всем специалистам, зарегистрированным на платформе.",
      ukr: "Користувач може створити заявку на вибір необхідних фахівців. Ця заявка надсилається всім фахівцям, зареєстрованим на платформі.",
      deu: "Der Benutzer kann eine Anwendung zur Auswahl der erforderlichen Spezialisten erstellen. Dieser Antrag wird an alle auf der Plattform registrierten Spezialisten versendet.",
    },
    requests_add_specialist_role: {
      eng: "Role",
      rus: "Роль",
      ukr: "Роль",
      deu: "Rolle",
    },
    requests_add_specialist_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    requests_add_specialist_city: {
      eng: "City",
      rus: "Город",
      ukr: "Місто",
      deu: "Stadt",
    },
    requests_add_specialist_intentions: {
      eng: "Intentions",
      rus: "Намерения",
      ukr: "Наміри",
      deu: "Absichten",
    },
    requests_add_specialist_submit: {
      eng: "Submit request",
      rus: "Отправить запрос",
      ukr: "Відправити запит",
      deu: "Anfrage senden",
    },
    profile_edit_title: {
      eng: "Edit your profile",
      rus: "Редактировать профиль",
      ukr: "Редагувати профіль",
      deu: "Profil bearbeiten",
    },
    complex_overview: {
      eng: "Complex Overview",
      rus: "Обзор Комплексов",
      ukr: "Огляд Комплексів",
      deu: "Komplexer Überblick",
    },
    requests_list_title: {
      eng: "Requests",
      rus: "Заявки",
      ukr: "Заяви",
      deu: "Anfragen",
    },
    requests_list_add_button: {
      eng: "Add request",
      rus: "Добавить заявку",
      ukr: "Додати заяву",
      deu: "Anfrage hinzufügen",
    },
    requests_list_tab_property: {
      eng: "Property",
      rus: "Недвижимость",
      ukr: "Нерухомість",
      deu: "Eigenschaft",
    },
    requests_list_tab_specialist: {
      eng: "Specialist",
      rus: "Специалист",
      ukr: "Фахівець",
      deu: "Spezialist",
    },
    requests_list_not_found: {
      eng: "Nothing was found for your request",
      rus: "По вашему запросу ничего не найдено",
      ukr: "За вашим запитом нічого не знайдено",
      deu: "Für Ihre Anfrage wurde nichts gefunden",
    },
    requests_specialist_description: {
      eng: "Description",
      rus: "Описание",
      ukr: "Опис",
      deu: "Beschreibung",
    },
    profile_secondary_form_education: {
      eng: "Licenses and Educations",
      rus: "Лицензии и образование",
      ukr: "Ліцензії та освіта",
      deu: "Lizenzen und Ausbildungen",
    },
    profile_secondary_form_education_sub_licenses: {
      eng: "Licenses",
      rus: "Лицензии",
      ukr: "Ліцензії",
      deu: "Lizenzen",
    },
    profile_secondary_form_education_sub_education: {
      eng: "Education",
      rus: "Образование",
      ukr: "Освіта",
      deu: "Bildung",
    },
    profile_secondary_form_education_sub_education_number: {
      eng: "Certificate of education serial number",
      rus: "Серийный номер свидетельства об образовании",
      ukr: "Серійний номер свідоцтва про освіту",
      deu: "Seriennummer des Bildungszertifikats",
    },
    profile_secondary_form_education_sub_education_faculty: {
      eng: "University faculty",
      rus: "Университетский факультет",
      ukr: "Університетський факультет",
      deu: "Universitätsfakultät",
    },
    profile_secondary_form_education_sub_education_speciality: {
      eng: "Speciality",
      rus: "Специальность",
      ukr: "Спеціальність",
      deu: "Spezialität",
    },
    profile_secondary_form_education_sub_education_start: {
      eng: "Starting date",
      rus: "Дата начала",
      ukr: "Дата початку",
      deu: "Startdatum",
    },
    profile_secondary_form_education_sub_education_end: {
      eng: "Date of graduation",
      rus: "Дата выпуска",
      ukr: "Дата випуску",
      deu: "Abschlussdatum",
    },
    profile_secondary_form_education_sub_education_scan: {
      eng: "Upload dimploma scan",
      rus: "Загрузить скан диплома",
      ukr: "Завантажити скан диплому",
      deu: "Diplomscan hochladen",
    },
    profile_secondary_form_education_sub_education_add: {
      eng: "Add",
      rus: "Добавить",
      ukr: "Додати",
      deu: "Hinzufügen",
    },
    profile_secondary_form_education_sub_education_remove: {
      eng: "Delete",
      rus: "Удалить",
      ukr: "Видалити",
      deu: "Löschen",
    },
    news_article_message: {
      eng: "Read this article on REM platform",
      rus: "Читать эту статью на платформе REM",
      ukr: "Читати цю статтю на платформі REM",
      deu: "Diesen Artikel auf der REM-Plattform lesen",
    },
    news_article_share: {
      eng: "Share:",
      rus: "Поделиться:",
      ukr: "Поділитися:",
      deu: "Teilen:",
    },
    properties_menu_share: {
      eng: "Share",
      rus: "Поделиться",
      ukr: "Поділитися",
      deu: "Teilen",
    },
    profile_settings_password_changed: {
      eng: "Password changed",
      rus: "Пароль изменен",
      ukr: "Пароль змінено",
      deu: "Passwort geändert",
    },
    profile_settings_password_changed_title: {
      eng: "Change your password",
      rus: "Изменить ваш пароль",
      ukr: "Змінити пароль",
      deu: "Passwort ändern",
    },
    profile_settings_password_changed_button: {
      eng: "Change password",
      rus: "Изменить пароль",
      ukr: "Змінити пароль",
      deu: "Passwort ändern",
    },
    profile_settings_password_current: {
      eng: "Current password",
      rus: "Текущий пароль",
      ukr: "Поточний пароль",
      deu: "Aktuelles Passwort",
    },
    profile_settings_password_new: {
      eng: "New password",
      rus: "Новый пароль",
      ukr: "Новий пароль",
      deu: "Neues Passwort",
    },
    profile_settings_password_new_repeat: {
      eng: "Confirm new password",
      rus: "Подтвердите новый пароль",
      ukr: "Підтвердіть новий пароль",
      deu: "Neues Passwort bestätigen",
    },
    profile_settings_password_change: {
      eng: "Change",
      rus: "Изменить",
      ukr: "Змінити",
      deu: "Ändern",
    },
    profile_settings_security_title: {
      eng: "Security",
      rus: "Безопасность",
      ukr: "Безпека",
      deu: "Sicherheit",
    },
    profile_settings_2fa_title: {
      eng: "Enable two factor authentication",
      rus: "Включить двухфакторную аутентификацию",
      ukr: "Включити двофакторну автентифікацію",
      deu: "Zwei-Faktor-Authentifizierung aktivieren",
    },
    profile_settings_2fa_button_enable: {
      eng: "Enable 2FA",
      rus: "Включить 2FA",
      ukr: "Увімкнути 2FA",
      deu: "2FA aktivieren",
    },
    profile_settings_2fa_button_disable: {
      eng: "Disable 2FA",
      rus: "Отключить 2FA",
      ukr: "Вимкнути 2FA",
      deu: "2FA deaktivieren",
    },
    profile_settings_notifications_title: {
      eng: "Notifications",
      rus: "Уведомления",
      ukr: "Повідомлення",
      deu: "Benachrichtigungen",
    },
    mark_all_as_read_translation: {
      eng: "Mark all as read",
      rus: "Отметить все как прочитанные",
      ukr: "Позначити всі як прочитані",
      deu: "Alle als gelesen markieren",
    },
    type_here_translation: {
      eng: "Type here...",
      rus: "Введите здесь...",
      ukr: "Введіть тут...",
      deu: "Hier eingeben...",
    },
    send_message_translation: {
      eng: "Send message",
      rus: "Отправить сообщение",
      ukr: "Надіслати повідомлення",
      deu: "Nachricht senden",
    },

    profile_settings_notifications_all: {
      eng: "All notifications",
      rus: "Все уведомления",
      ukr: "Всі повідомлення",
      deu: "Alle Benachrichtigungen",
    },
    profile_settings_notifications_actions: {
      eng: "Actions",
      rus: "События",
      ukr: "Події",
      deu: "Aktionen",
    },
    profile_settings_notifications_news: {
      eng: "News",
      rus: "Новости",
      ukr: "Новини",
      deu: "Neuigkeiten",
    },
    layout_error_title: {
      eng: "Something went wrong...",
      rus: "Что-то пошло не так...",
      ukr: "Щось пішло не так...",
      deu: "Etwas ist schief gelaufen...",
    },
    layout_error_reset_button: {
      eng: "Try again",
      rus: "Попробовать снова",
      ukr: "Спробувати знову",
      deu: "Erneut versuchen",
    },
    layout_lang_menu_eng: {
      eng: "English",
      rus: "Английский",
      ukr: "Англійська",
      deu: "Englisch",
    },
    layout_lang_menu_ukr: {
      eng: "Ukrainian",
      rus: "Украинский",
      ukr: "Український",
      deu: "Ukrainisch",
    },
    layout_lang_menu_rus: {
      eng: "Russian",
      rus: "Русский",
      ukr: "Російська",
      deu: "Russisch",
    },
    layout_lang_menu_deu: {
      eng: "Deutsch",
      rus: "Немецкий",
      ukr: "Німецький",
      deu: "Deutsch",
    },
    layout_auth_header_logo: {
      eng: "REM Logo",
      rus: "Логотип REM",
      ukr: "Логотип REM",
      deu: "REM-Logo",
    },
    layout_auth_header_signin: {
      eng: "Login",
      rus: "Войти",
      ukr: "Увійти",
      deu: "Anmelden",
    },
    layout_auth_header_signup: {
      eng: "Create an account",
      rus: "Создать аккаунт",
      ukr: "Створити обліковий запис",
      deu: "Konto erstellen",
    },
    layout_cabinet_header_logo: {
      eng: "REM Logo",
      rus: "Логотип REM",
      ukr: "Логотип REM",
      deu: "REM-Logo",
    },
    layout_cabinet_header_profile_menu_my: {
      eng: "My profile",
      rus: "Мой профиль",
      ukr: "Мій профіль",
      deu: "Mein Profil",
    },
    layout_cabinet_header_profile_menu_exit: {
      eng: "Exit",
      rus: "Выход",
      ukr: "Вихід",
      deu: "Beenden",
    },
    layout_cabinet_footer_services_community: {
      eng: "Community",
      rus: "Сообщество",
      ukr: "Спільнота",
      deu: "Gemeinschaft",
    },
    layout_cabinet_footer_services_properties: {
      eng: "Property",
      rus: "Недвижимость",
      ukr: "Нерухомість",
      deu: "Eigenschaft",
    },
    layout_cabinet_footer_services_projects: {
      eng: "Development projects",
      rus: "Девелоперские проекты",
      ukr: "Девелоперські проекти",
      deu: "Entwicklungsprojekte",
    },
    layout_cabinet_footer_sub_contacts: {
      eng: "Contacts",
      rus: "Контакты",
      ukr: "Контакти",
      deu: "Kontakte",
    },
    layout_cabinet_footer_sub_jobs: {
      eng: "Jobs",
      rus: "Вакансии",
      ukr: "Вакансії",
      deu: "Jobs",
    },
    layout_cabinet_footer_sub_terms: {
      eng: "Terms and Conditions",
      rus: "Положения и Условия",
      ukr: "Положення та Умови",
      deu: "Allgemeine Geschäftsbedingungen",
    },
    layout_cabinet_footer_sub_privacy: {
      eng: "Privacy Policy",
      rus: "Политика Конфиденциальности",
      ukr: "Політика конфіденційності",
      deu: "Datenschutzerklärung",
    },
    layout_cabinet_footer_sub_anti_money: {
      eng: "Anti-Money Laundering",
      rus: "Противодействие отмыванию денег",
      ukr: "Протидія відмиванню грошей",
      deu: "Anti-Geldwäsche",
    },
    layout_cabinet_footer_stay: {
      eng: "Stay connected",
      rus: "Оставайся на связи",
      ukr: "Залишайся на зв'язку",
      deu: "In Verbindung bleiben",
    },
    layout_cabinet_footer_copyright: {
      eng: "© REM Azerbaijan LTD, Company number VOEN: 9900014901, 2023 - ",
      rus: "© REM Azerbaijan LTD, Номер компании VOEN: 9900014901, 2023 - ",
      ukr: "© REM Azerbaijan LTD, Номер компанії: VOEN: 9900014901, 2023 - ",
      deu: "© REM Azerbaijan LTD, Firmennummer VOEN: 9900014901, 2023 - ",
    },
    layout_cabinet_drawer_menu_contact: {
      eng: "Contact",
      rus: "Контакт",
      ukr: "Контакт",
      deu: "Kontakt",
    },
    layout_cabinet_drawer_menu_knowledge: {
      eng: "Knowledge base",
      rus: "База знаний",
      ukr: "База знань",
      deu: "Wissensbasis",
    },
    layout_cabinet_drawer_menu_community: {
      eng: "Community",
      rus: "Сообщество",
      ukr: "Спільнота",
      deu: "Gemeinschaft",
    },
    layout_cabinet_drawer_menu_properties: {
      eng: "Property",
      rus: "Недвижимость",
      ukr: "Нерухомість",
      deu: "Eigenschaft",
    },
    layout_cabinet_drawer_menu_projects: {
      eng: "Projects",
      rus: "Проекты",
      ukr: "Проекти",
      deu: "Projekte",
    },
    layout_cabinet_drawer_menu_contracts: {
      eng: "Contracts",
      rus: "Контракты",
      ukr: "Контракти",
      deu: "Verträge",
    },
    layout_cabinet_drawer_menu_marketplace: {
      eng: "Marketplace",
      rus: "Торговая площадка",
      ukr: "Торговий майданчик",
      deu: "Marktplatz",
    },
    layout_cabinet_drawer_menu_exchange: {
      eng: "Exchange",
      rus: "Обмен",
      ukr: "Обмін",
      deu: "Austausch",
    },
    layout_cabinet_drawer_menu_distribution: {
      eng: "Distribution",
      rus: "Распределение",
      ukr: "Розподіл",
      deu: "Verteilung",
    },layout_cabinet_drawer_menu_transfer: {
      eng: "Transfer",
      rus: "Перевод",
      ukr: "Переказ",
      deu: "Übertragung",
    },
    total_amount_translation: {
      eng: "Total amount",
      rus: "Общая сумма",
      ukr: "Загальна сума",
      deu: "Gesamtbetrag",
    },
    complex_translation: {
      eng: "Complex",
      rus: "Комплекс",
      ukr: "Комплекс",
      deu: "Komplex",
    },
    complexs_translation: {
      eng: "Complex",
      rus: "Комплексы",
      ukr: "Комплекси",
      deu: "Komplex",
    },
    withdraw_types_translation: {
      eng: "Withdraw types",
      rus: "Типы вывода",
      ukr: "Типи виведення",
      deu: "Auszahlungstypen",
    },
    withdraw_fiat_translation: {
      eng: "Withdraw Fiat",
      rus: "Вывести Фиат",
      ukr: "Вивести Фіат",
      deu: "Fiat Abheben",
    },
    withdraw_crypto_translation: {
      eng: "Withdraw Crypto",
      rus: "Вывести Криптовалюту",
      ukr: "Вивести Криптовалюту",
      deu: "Krypto Abheben",
    },
    withdraw_method_translation: {
      eng: "Withdraw method",
      rus: "Способ вывода",
      ukr: "Спосіб виведення",
      deu: "Auszahlungsmethode"
    },
    withdraw_crypto_to_wallet_translation: {
      eng: "Withdraw crypto currency to your external wallet",
      rus: "Вывести криптовалюту на ваш внешний кошелек",
      ukr: "Вивести криптовалюту на ваш зовнішній гаманець",
      deu: "Kryptowährung auf Ihr externes Wallet abheben",
    },
    withdraw_fiat_to_wallet_translation: {
      eng: "Withdraw fiat currency to your external wallet",
      rus: "Вывести фиатную валюту на ваш внешний кошелек",
      ukr: "Вивести фіатну валюту на ваш зовнішній гаманець",
      deu: "Fiat-Währung auf Ihr externes Wallet abheben",
    },
    convert_translation: {
      eng: "Convert",
      rus: "Обмен",
      ukr: "Обмiн",
      deu: "Konvertieren",
    },
    messenger_translation: {
      eng: "Messenger",
      rus: "Мессенджер",
      ukr: "Месенджер",
      deu: "Messenger",
    },

    deposit_types_translation: {
      eng: "Deposit types",
      rus: "Типы депозитов",
      ukr: "Типи депозитів",
      deu: "Einlagearten",
    },
    deposit_fiat_translation: {
      eng: "Deposit fiat currency via bank transfer, credit card, or other payment methods.",
      rus: "Внесите фиатную валюту через банковский перевод, кредитную карту или другие способы оплаты.",
      ukr: "Внесіть фіатну валюту через банківський переказ, кредитну картку або інші способи оплати.",
      deu: "Hinterlegen Sie Fiat-Währung per Banküberweisung, Kreditkarte oder andere Zahlungsmethoden.",
    },
    crypto_deposit_address_translation: {
      eng: "Get the deposit address for BTC, ETH, or any other crypto and deposit via the blockchain.",
      rus: "Получите адрес для депозита BTC, ETH или любой другой криптовалюты и внесите депозит через блокчейн.",
      ukr: "Отримайте адресу для депозиту BTC, ETH або будь-якої іншої криптовалюти та внесіть депозит через блокчейн.",
      deu: "Holen Sie sich die Einzahlungsadresse für BTC, ETH oder eine andere Krypto und zahlen Sie über die Blockchain ein.",
    },
    deposits_fiat_translation: {
      eng: "Deposit Fiat",
      rus: "Пополнить Фиат",
      ukr: "Поповнити Фіат",
      deu: "Fiat Einzahlen",
    },
    layout_cabinet_drawer_menu_requests: {
      eng: "Smart requests",
      rus: "Смарт-заявки",
      ukr: "Смарт-заявки",
      deu: "Smart Tickets",
    },
    layout_cabinet_drawer_menu_news: {
      eng: "Blog",
      rus: "Блог",
      ukr: "Блог",
      deu: "Blog",
    },
    layout_cabinet_drawer_menu_support: {
      eng: "Support",
      rus: "Поддержка",
      ukr: "Підтримка",
      deu: "Unterstützung",
    },
    new_ticket: {
      eng: "New Ticket",
      rus: "Новый Тикет",
      ukr: "Новий Тiкет",
      deu: "Neues Ticket",
    },
    add_a_comment_translation: {
      eng: "Add a comment:",
      rus: "Добавить комментарий:",
      ukr: "Додати коментар:",
      deu: "Einen Kommentar hinzufügen:",
    },
    close_ticket_translation: {
      eng: "Close Ticket",
      rus: "Закрыть тикет",
      ukr: "Закрити тiкет",
      deu: "Ticket schließen",
    },
    comment_translation: {
      eng: "Comment",
      rus: "Комментарий",
      ukr: "Коментар",
      deu: "Kommentar",
    },
    no_comments_yet_translation: {
      eng: "No comments yet",
      rus: "Пока нет комментариев",
      ukr: "Ще немає коментарів",
      deu: "Noch keine Kommentare",
    },
    created_at_translation: {
      eng: "created at",
      rus: "создано в",
      ukr: "створено о",
      deu: "erstellt am",
    },
    no_tickets_found_translation: {
      eng: "No tickets found",
      rus: "Тикеты не найдены",
      ukr: "Тiкетiв не знайдено",
      deu: "Keine Tickets gefunden",
    },
    updated_at_translation: {
      eng: "Updated at",
      rus: "Обновлено в",
      ukr: "Оновлено о",
      deu: "Aktualisiert am",
    },

    auth_login_social: {
      eng: "Sign in with social media networks",
      rus: "Вход с помощью соц. сетей",
      ukr: "Вхід за допомогою соц. мереж",
      deu: "Mit Social-Media-Netzwerken anmelden",
    },
    auth_forgot_title: {
      eng: "Restore access",
      rus: "Восстановление доступа",
      ukr: "Відновлення доступу",
      deu: "Zugriff wiederherstellen",
    },
    auth_forgot_remember: {
      eng: "I remembered my password!",
      rus: "Я вспомнил свой пароль!",
      ukr: "Я згадав свій пароль!",
      deu: "Ich habe mich an mein Passwort erinnert!",
    },
    auth_forgot_send: {
      eng: "Submit",
      rus: "Отправить",
      ukr: "Надіслати",
      deu: "Senden",
    },
    auth_forgot_new_title: {
      eng: "Change password",
      rus: "Изменение пароля",
      ukr: "Зміна пароля",
      deu: "Passwort ändern",
    },
    auth_forgot_new_pass: {
      eng: "Enter new password",
      rus: "Введите новый пароль",
      ukr: "Введіть новий пароль",
      deu: "Neues Passwort eingeben",
    },
    auth_forgot_new_pass_repeat: {
      eng: "Confirm new password",
      rus: "Подтвердите новый пароль",
      ukr: "Підтвердіть новий пароль",
      deu: "Neues Passwort bestätigen",
    },
    auth_forgot_new_pass_help_min_len: {
      eng: "Minimum 8 characters",
      rus: "Минимум 8 символов",
      ukr: "Мінімум 8 символів",
      deu: "Mindestens 8 Zeichen",
    },
    auth_forgot_new_remember: {
      eng: "I remembered my password!",
      rus: "Я вспомнил свой пароль!",
      ukr: "Я згадав свій пароль!",
      deu: "Ich habe mich an mein Passwort erinnert!",
    },
    auth_forgot_new_send: {
      eng: "Submit",
      rus: "Отправить",
      ukr: "Надіслати",
      deu: "Senden",
    },
    auth_forgot_message_title: {
      eng: "Check your email!",
      rus: "Проверьте email!",
      ukr: "Перевірте email!",
      deu: "Prüfen Sie Ihre E-Mail!",
    },
    auth_forgot_message_text_1: {
      eng: "A link to reset your password has been sent to your email.",
      rus: "Ссылка на восстановление пароля была отправлена Вам на почту.",
      ukr: "Посилання на відновлення пароля було надіслано Вам на пошту.",
      deu: "Ein Link zum Zurücksetzen Ihres Passworts wurde an Ihre E-Mail gesendet.",
    },
    auth_forgot_message_text_2: {
      eng: "Go to it and set a new password.",
      rus: "Перейдите по ней и задайте новый пароль.",
      ukr: "Перейдіть по ній і введіть новий пароль.",
      deu: "Gehen Sie dorthin und legen Sie ein neues Passwort fest.",
    },
    auth_forgot_message_text_3: {
      eng: "Don't forget to check your spam folder",
      rus: 'Не забудьте проверить папку "Спам"',
      ukr: 'Не забудьте перевірити папку "Спам"',
      deu: "Vergessen Sie nicht, Ihren Spam-Ordner zu überprüfen",
    },
    auth_forgot_message_text_4: {
      eng: "Click here to return to the platform",
      rus: "Нажмите сюда, чтобы вернуться на платформу",
      ukr: "Натисніть сюди, щоб повернутися на платформу",
      deu: "Klicken Sie hier, um zur Plattform zurückzukehren",
    },
    auth_forgot_message_grem_logo: {
      eng: "REM logo",
      rus: "REM лого",
      ukr: "REM лого",
      deu: "REM-Logo",
    },
    auth_login_title: {
      eng: "Account Login",
      rus: "Вход в кабинет",
      ukr: "Вхід до кабінету",
      deu: "Kontoanmeldung",
    },
    auth_login_resend_email: {
      eng: "Resend email",
      rus: "Отправить письмо повторно",
      ukr: "Надіслати листа повторно",
      deu: "E-Mail erneut senden",
    },
    auth_login_password: {
      eng: "Password",
      rus: "Пароль",
      ukr: "Пароль",
      deu: "Passwort",
    },
    auth_login_forget_password: {
      eng: "Forgot your password?",
      rus: "Забыли пароль?",
      ukr: "Забули пароль?",
      deu: "Passwort vergessen?",
    },
    auth_login_remember_me: {
      eng: "Remember me",
      rus: "Запомнить меня",
      ukr: "Запам'ятати мене",
      deu: "An mich erinnern",
    },
    auth_login_send: {
      eng: "Login",
      rus: "Войти",
      ukr: "Увійти",
      deu: "Anmelden",
    },
    auth_logout_title: {
      eng: "You have successfully logged out of your account",
      rus: "Вы успешно вышли из Вашей учётной записи",
      ukr: "Ви успішно вийшли з Вашого облікового запису",
      deu: "Sie haben sich erfolgreich von Ihrem Konto abgemeldet",
    },
    auth_logout_text_1: {
      eng: "Thank you for using our platform.",
      rus: "Благодарим Вас за использование нашей платформы.",
      ukr: "Дякую Вам за використання нашої платформи.",
      deu: "Vielen Dank, dass Sie unsere Plattform nutzen.",
    },
    auth_logout_text_2: {
      eng: "We hope to see you again.",
      rus: "Надеемся увидеть Вас снова.",
      ukr: "Сподіваємося побачити Вас знов.",
      deu: "Wir hoffen, Sie wiederzusehen.",
    },
    auth_logout_text_3: {
      eng: "Click here to return to the platform",
      rus: "Нажмите сюда, чтобы вернуться на платформу",
      ukr: "Натисніть сюди, щоб повернутися на платформу",
      deu: "Klicken Sie hier, um zur Plattform zurückzukehren",
    },
    auth_logout_grem_logo: {
      eng: "REM logo",
      rus: "REM лого",
      ukr: "REM лого",
      deu: "REM-Logo",
    },
    auth_register_success_title: {
      eng: "Thank you for being with us",
      rus: "Спасибо за то, что вы с нами",
      ukr: "Дякую за те, що ви з нами",
      deu: "Danke, dass Sie bei uns sind",
    },
    auth_register_success_grem_logo: {
      eng: "REM logo",
      rus: "REM лого",
      ukr: "REM лого",
      deu: "REM-Logo",
    },
    auth_register_success_text_1: {
      eng: "The first step of your registration was successful!",
      rus: "Первый этап вашей регистрации прошел успешно!",
      ukr: "Перший етап вашої реєстрації пройшов успішно!",
      deu: "Der erste Schritt Ihrer Registrierung war erfolgreich!",
    },
    auth_register_success_text_2: {
      eng: "To activate your account, follow the link from the email received after registering on the platform.",
      rus: "Для активации аккаунта перейдите по ссылке из электронного письма, полученного после регистрации на платформе.",
      ukr: "Для активації облікового запису перейдіть за посиланням з електронного листа, отриманого після реєстрації на платформі.",
      deu: "Um Ihr Konto zu aktivieren, folgen Sie dem Link in der E-Mail, die Sie nach der Registrierung auf der Plattform erhalten haben.",
    },
    auth_register_success_text_3: {
      eng: "If you can't find the email in your inbox, please check your spam folder",
      rus: 'Если Вы не можете найти письмо в папке "Входящие", проверьте папку "Спам"',
      ukr: 'Якщо Ви не можете знайти лист у папці "Вхідні", перевірте папку "Спам"',
      deu: "Wenn Sie die E-Mail nicht in Ihrem Posteingang finden können, überprüfen Sie bitte Ihren Spam-Ordner",
    },
    auth_register_success_text_4: {
      eng: "Click here to return to the platform",
      rus: "Нажмите сюда, чтобы вернуться на платформу",
      ukr: "Натисніть сюди, щоб повернутися на платформу",
      deu: "Klicken Sie hier, um zur Plattform zurückzukehren",
    },
    auth_register_confirm_title: {
      eng: "Congratulations!",
      rus: "Поздравляем!",
      ukr: "Вітаємо!",
      deu: "Herzlichen Glückwunsch!",
    },
    auth_register_confirm_grem_logo: {
      eng: "REM logo",
      rus: "REM лого",
      ukr: "REM лого",
      deu: "REM-Logo",
    },
    auth_register_confirm_text_1: {
      eng: "Registration confirmation successful!",
      rus: "Подтверждение регистрации прошло успешно!",
      ukr: "Підтвердження реєстрації пройшло успішно!",
      deu: "Registrierungsbestätigung erfolgreich!",
    },
    auth_register_confirm_text_2: {
      eng: "You can now login to the platform using your credentials",
      rus: "Теперь вы можете войти на платформу, используя свои данные",
      ukr: "Тепер ви можете увійти на платформу, використовуючи свої дані",
      deu: "Sie können sich jetzt mit Ihren Zugangsdaten bei der Plattform anmelden",
    },
    auth_register_confirm_button: {
      eng: "Click here to return to the platform",
      rus: "Нажмите сюда, чтобы вернуться на платформу",
      ukr: "Натисніть сюди, щоб повернутися на платформу",
      deu: "Klicken Sie hier, um zur Plattform zurückzukehren",
    },
    auth_register_helptext_1: {
      eng: "Enter data, confirm e-mail",
      rus: "Ввод данных, подтверждение e-mail",
      ukr: "Введення даних, підтвердження електронної пошти",
      deu: "Daten eingeben, E-Mail bestätigen",
    },
    auth_register_helptext_2: {
      eng: "After choosing your role and filling in your personal data and objects, a quick moderation will take place",
      rus: "После выбора вашей роли и заполнения ваших личных данных и объектов - пройдет быстрая модерация",
      ukr: "Після вибору вашої ролі та заповнення ваших особистих даних та об'єктів - пройде швидка модерація",
      deu: "Nachdem Sie Ihre Rolle ausgewählt und Ihre persönlichen Daten und Objekte eingegeben haben, findet eine kurze Moderation statt",
    },
    auth_register_helptext_3: {
      eng: "When opening a wallet or entering into a digital contract, we will ask you to complete the KYS procedure, as we give access to our financial instruments. We will go through this stage later.",
      rus: "При открытии кошелька или заkeyении цифрового контракта мы попросим вас пройти процедуру KYS, поскольку мы даем доступ к нашим финансовым инструментам. Этот этап мы с вами пройдем позже",
      ukr: "При відкритті гаманця або укладанні цифрового контракту ми попросимо вас пройти процедуру KYS, оскільки ми надаємо доступ до наших фінансових інструментів. Цей етап ми з вами пройдемо пізніше.",
      deu: "Beim Öffnen einer Brieftasche oder beim Abschluss eines digitalen Vertrags werden wir Sie bitten, das KYS-Verfahren abzuschließen, da wir Zugang zu unseren Finanzinstrumenten gewähren. Wir werden diese Phase später durchlaufen.",
    },
    auth_register_helptext_4: {
      eng: "Any questions? Contact us using the feedback form",
      rus: "Возникли вопросы? Свяжитесь с нами с помощью формы обратной связи",
      ukr: "Постали питання? Зв'яжіться з нами за допомогою форми зворотнього зв'язку",
      deu: "Noch Fragen? Kontaktieren Sie uns über das Feedback-Formular",
    },
    auth_register_helptext_4_link: {
      eng: "Go",
      rus: "Перейти",
      ukr: "Перейти",
      deu: "Los",
    },
    auth_register_title: {
      eng: "Account registration",
      rus: "Регистрация аккаунта",
      ukr: "Реєстрація облікового запису",
      deu: "Kontoregistrierung",
    },
    auth_register_form_input_email: {
      eng: "Enter email",
      rus: "Введите email",
      ukr: "Введіть email",
      deu: "E-Mail eingeben",
    },
    auth_register_form_password: {
      eng: "Password",
      rus: "Пароль",
      ukr: "Пароль",
      deu: "Passwort",
    },
    auth_register_form_input_password: {
      eng: "Enter password",
      rus: "Введите пароль",
      ukr: "Введіть пароль",
      deu: "Passwort eingeben",
    },
    auth_register_form_password_help_limit_min: {
      eng: "Minimum 8 characters",
      rus: "Минимум 8 символов",
      ukr: "Мінімум 8 символів",
      deu: "Mindestens 8 Zeichen",
    },
    auth_register_form_repeat_password: {
      eng: "Confirm password",
      rus: "Подтвердите пароль",
      ukr: "Підтвердіть пароль",
      deu: "Passwort bestätigen",
    },
    auth_register_form_repeat_password_help_limit_min: {
      eng: "Minimum 8 characters",
      rus: "Минимум 8 символов",
      ukr: "Мінімум 8 символів",
      deu: "Mindestens 8 Zeichen",
    },
    auth_register_form_fname: {
      eng: "Name",
      rus: "Имя",
      ukr: "Ім'я",
      deu: "Name",
    },
    auth_register_form_input_fname: {
      eng: "Enter name",
      rus: "Введите имя",
      ukr: "Введіть ім'я",
      deu: "Namen eingeben",
    },
    auth_register_form_lname: {
      eng: "Last name",
      rus: "Фамилия",
      ukr: "Прізвище",
      deu: "Nachname",
    },
    auth_register_form_input_lname: {
      eng: "Enter last name",
      rus: "Введите фамилию",
      ukr: "Введіть прізвище",
      deu: "Nachnamen eingeben",
    },
    auth_register_form_partner: {
      eng: "Affiliate code (if any)",
      rus: "Партнерский код (при наличии)",
      ukr: "Партнерський код (за наявності)",
      deu: "Affiliate-Code (falls vorhanden)",
    },
    auth_register_form_input_partner: {
      eng: "Enter affiliate code",
      rus: "Введите партнерский код",
      ukr: "Введіть код партнерства",
      deu: "Affiliate-Code eingeben",
    },
    auth_register_form_your_partner: {
      eng: "You were invited by:",
      rus: "Вас пригласил(а):",
      ukr: "Вас запросив(а):",
      deu: "Sie wurden eingeladen von:",
    },
    auth_register_form_accept: {
      eng: "I agree with",
      rus: "Я согласен (-на) с",
      ukr: "Я згоден(-на) з",
      deu: "Ich stimme zu",
    },
    auth_register_form_accept_terms: {
      eng: "Read the Terms and Conditions",
      rus: "Ознакомьтесь с Положениями и условиями",
      ukr: "Ознайомтеся з Положеннями та умовами",
      deu: "Allgemeine Geschäftsbedingungen lesen",
    },
    auth_register_form_terms: {
      eng: "Terms and Conditions",
      rus: "Положениями и условиями",
      ukr: "Положення та умови",
      deu: "Allgemeine Geschäftsbedingungen",
    },
    auth_register_form_privacy_policy: {
      eng: "Privacy Policy",
      rus: "Политикой конфиденциальности",
      ukr: "Політикою конфіденційності",
      deu: "Datenschutzerklärung",
    },
    auth_register_form_accept_privacy_policy: {
      eng: "Read the Privacy Policy",
      rus: "Ознакомьтесь с Политикой конфиденциальности",
      ukr: "Ознайомтеся з Політикою конфіденційності",
      deu: "Lesen Sie die Datenschutzrichtlinie",
    },
    auth_register_form_send: {
      eng: "Register",
      rus: "Зарегистрироваться",
      ukr: "Зареєструватися",
      deu: "Registrieren",
    },
    auth_register_social: {
      eng: "Sign up with social media networks",
      rus: "Регистрация с помощью соц. сетей",
      ukr: "Реєстрація за допомогою соц. мереж",
      deu: "Bei sozialen Netzwerken anmelden",
    },
    support_form_success: {
      eng: "Form submitted successfully",
      rus: "Форма успешно отправлена",
      ukr: "Форму успішно відправлено",
      deu: "Formular erfolgreich übermittelt",
    },
    support_title: {
      eng: "Support",
      rus: "Поддержка",
      ukr: "Підтримка",
      deu: "Unterstützung",
    },
    support_form_title: {
      eng: "Feedback form",
      rus: "Форма обратной связи",
      ukr: "Форма зворотного зв'язку",
      deu: "Feedback-Formular",
    },
    support_tg_eng_title: {
      eng: "REM support ENG",
      rus: "REM поддержка ENG",
      ukr: "REM підтримка ENG",
      deu: "REM ENG-Unterstützung",
    },
    support_tg_rus_title: {
      eng: "REM support RUS ",
      rus: "REM поддержка RUS",
      ukr: "REM підтримка RUS",
      deu: "REM RUS-Unterstützung",
    },
    support_form_name: {
      eng: "Name",
      rus: "ФИО",
      ukr: "ПІБ",
      deu: "Name",
    },
    support_form_input_name: {
      eng: "Enter full name",
      rus: "Введите ФИО",
      ukr: "Введіть ПІБ",
      deu: "Vollständigen Namen eingeben",
    },
    support_form_input_email: {
      eng: "Enter email",
      rus: "Введите email",
      ukr: "Введіть email",
      deu: "E-Mail eingeben",
    },
    support_form_message: {
      eng: "Message",
      rus: "Сообщение",
      ukr: "Повідомлення",
      deu: "Nachricht",
    },
    support_form_input_message: {
      eng: "Enter a message",
      rus: "Введите сообщение",
      ukr: "Введіть повідомлення",
      deu: "Geben Sie eine Nachricht ein",
    },
    support_form_send: {
      eng: "Submit",
      rus: "Отправить",
      ukr: "Надіслати",
      deu: "Senden",
    },
    refresh: {
      eng: "Refresh",
      rus: "Обновить",
      ukr: "Оновити",
      deu: "Aktualisierung",
    },
    error_title: {
      eng: "Page not found",
      rus: "Страница не найдена",
      ukr: "Сторінку не знайдено",
      deu: "Seite nicht gefunden",
    },
    error_main: {
      eng: "Error",
      rus: "Ошибка",
      ukr: "Помилка",
      deu: "Fehler",
    },
    error_link_back: {
      eng: "Back",
      rus: "Назад",
      ukr: "Назад",
      deu: "Zurück",
    },
    error_link_main: {
      eng: "Home",
      rus: "Главная",
      ukr: "Головна",
      deu: "Zuhause",
    },
    error_link_signin: {
      eng: "Login",
      rus: "Вход",
      ukr: "Вхід",
      deu: "Anmelden",
    },
    error_link_signup: {
      eng: "Registration",
      rus: "Регистрация",
      ukr: "Реєстрація",
      deu: "Registrierung",
    },
    coming_soon_title: {
      eng: "Soon",
      rus: "Вскоре",
      ukr: "Незабаром",
      deu: "Bald",
    },
    coming_soon_link_back: {
      eng: "Back",
      rus: "Назад",
      ukr: "Назад",
      deu: "Zurück",
    },
    coming_soon_link_main: {
      eng: "Home",
      rus: "Главная",
      ukr: "Головна",
      deu: "Zuhause",
    },
    coming_soon_link_signin: {
      eng: "Login",
      rus: "Вход",
      ukr: "Вхід",
      deu: "Anmelden",
    },
    coming_soon_link_signup: {
      eng: "Registration",
      rus: "Регистрация",
      ukr: "Реєстрація",
      deu: "Registrierung",
    },
    profile_title: {
      eng: "My profile",
      rus: "Мой профиль",
      ukr: "Мій профіль",
      deu: "Mein Profil",
    },
    profile_tabs_profile: {
      eng: "Profile",
      rus: "Профиль",
      ukr: "Профіль",
      deu: "Profil",
    },
    profile_tabs_properties: {
      eng: "Property",
      rus: "Недвижимость",
      ukr: "Нерухомість",
      deu: "Eigenschaft",
    },
    profile_tabs_projects: {
      eng: "Projects",
      rus: "Проекты",
      ukr: "Проекти",
      deu: "Projekte",
    },
    profile_tabs_requests: {
      eng: "Requests",
      rus: "Заявки",
      ukr: "Заявки",
      deu: "Anwendungen",
    },
    profile_tabs_partners: {
      eng: "Partner system",
      rus: "Партнерская система",
      ukr: "Партнерська система",
      deu: "Partnersystem",
    },
    profile_tabs_rating: {
      eng: "Rating",
      rus: "Рейтинг",
      ukr: "Рейтинг",
      deu: "Bewertung",
    },
    profile_tabs_wallet: {
      eng: "Wallet",
      rus: "Кошелек",
      ukr: "Гаманець",
      deu: "Brieftasche",
    },
    profile_tabs_settings: {
      eng: "Settings",
      rus: "Настройки",
      ukr: "Налаштування",
      deu: "Einstellungen",
    },maximum_amount: {
      eng: "Maximum Amount",
      rus: "Максимальная Сумма",
      ukr: "Максимальна Сума",
      deu: "Maximalbetrag",
    },
    return_to_tickets: {
      eng: "Return to Tickets",
      rus: "Вернуться к Тикетам",
      ukr: "Повернутися до Тікетiв",
      deu: "Zurück zu Tickets",
    },

    profile_tabs_adv: {
      eng: "Advertising cabinet",
      rus: "Рекламный кабинет",
      ukr: "Рекламний кабінет",
      deu: "Werbeschrank",
    },
    profile_kyc_warning_text: {
      eng: "You need to pass KYC to interact with finance on the platform",
      rus: "Для взаимодействия с финансами на платформе вам необходимо пройти KYC",
      ukr: "Для взаємодії з фінансами на платформі вам необхідно пройти KYC",
      deu: "Sie müssen KYC bestehen, um mit Finanzen auf der Plattform zu interagieren",
    },
    profile_kyc_warning_button: {
      eng: "Pass KYC",
      rus: "Пройти KYC",
      ukr: "Пройти KYC",
      deu: "KYC bestehen",
    },
    profile_verification_wait_text: {
      eng: "Your account is already being moderated and all the main functions of the platform will be available to you soon",
      rus: "Ваш аккаунт уже проходит модерацию и все основные функции платформы вскоре будут доступны для вас",
      ukr: "Ваш обліковий запис вже проходить модерацію і всі основні функції платформи незабаром будуть доступні для вас",
      deu: "Ihr Konto wird bereits moderiert und alle Hauptfunktionen der Plattform werden Ihnen bald zur Verfügung stehen",
    },
    profile_verification_wait_button: {
      eng: "Contact technical support",
      rus: "Написать в техническую поддержку",
      ukr: "Написати на технічну підтримку",
      deu: "Technischen Support kontaktieren",
    },
    profile_verification_text: {
      eng: "We invite you to fill in the data in your profile about yourself, after which all the main functions of the site will be available to you, except for financial ones",
      rus: "Приглашаем вас заполнить данные в профиле о себе, после чего вам будут доступны все основные функции сайта, кроме финансовых",
      ukr: "Запрошуємо вас заповнити дані у профілі про себе, після чого вам будуть доступні всі основні функції сайту, крім фінансових",
      deu: "Wir laden Sie ein, die Daten in Ihrem Profil über sich selbst einzugeben, danach stehen Ihnen alle Hauptfunktionen der Website zur Verfügung, mit Ausnahme der finanziellen",
    },
    profile_verification_button: {
      eng: "Pass moderation",
      rus: "Пройти модерацию",
      ukr: "Пройти модерацію",
      deu: "Moderation bestehen",
    },
    profile_main_title: {
      eng: "Basic info",
      rus: "Основная информация",
      ukr: "Основна інформація",
      deu: "Basisinfo",
    },
    profile_main_views: {
      eng: "The number of times your profile has been viewed",
      rus: "Количество просмотров вашего профиля",
      ukr: "Кількість переглядів вашого профілю",
      deu: "Die Anzahl der Aufrufe Ihres Profils",
    },
    profile_main_community_clicks: {
      eng: "Number of clicks on your profile from the community page",
      rus: "Количество кликов по вашему профилю с страницы сообщества",
      ukr: "Кількість кліків за вашим профілем зі сторінки спільноти",
      deu: "Anzahl der Klicks auf Ihr Profil von der Community-Seite",
    },
    profile_main_edit_button: {
      eng: "Edit profile",
      rus: "Редактировать профиль",
      ukr: "Редагувати профіль",
      deu: "Profil bearbeiten",
    },
    request_specialist_edit: {
      eng: "Edit",
      rus: "Редактировать",
      ukr: "Редагувати",
      deu: "Bearbeiten",
    },
    request_specialist_delete: {
      eng: "Delete",
      rus: "Удалить",
      ukr: "Видалити",
      deu: "Löschen",
    },
    profile_main_verification_button: {
      eng: "Pass moderation",
      rus: "Пройти модерацию",
      ukr: "Пройти модерацію",
      deu: "Moderation bestehen",
    },
    profile_main_location: {
      eng: "Location",
      rus: "Расположение",
      ukr: "Розташування",
      deu: "Standort",
    },
    profile_main_business_country: {
      eng: "Countries of doing business",
      rus: "Страны ведения бизнеса",
      ukr: "Країни ведення бізнесу",
      deu: "Länder der Geschäftstätigkeit",
    },
    profile_main_specializations: {
      eng: "Specializations",
      rus: "Специализации",
      ukr: "Спеціалізації",
      deu: "Spezialisierungen",
    },
    profile_main_completed_projects: {
      eng: "Number of completed projects",
      rus: "Количество завершенных проектов",
      ukr: "Кількість завершених проектів",
      deu: "Anzahl abgeschlossener Projekte",
    },
    profile_main_experience: {
      eng: "Years of experience",
      rus: "Годы опыта",
      ukr: "Роки досвіду",
      deu: "Jahrelange Erfahrung",
    },
    profile_main_max_transaction_amount: {
      eng: "Max trade amount",
      rus: "Максимальная сумма сделки",
      ukr: "Максимальна сума угоди",
      deu: "Maximaler Handelsbetrag",
    },
    profile_main_total_constructed_square_meters: {
      eng: "Total built area, square meters",
      rus: "Общая площадь построенного, квадратные метры",
      ukr: "Загальна площа збудованого, квадратні метри",
      deu: "Gesamt bebaute Fläche, Quadratmeter",
    },
    profile_main_associations: {
      eng: "Associations",
      rus: "Ассоциации",
      ukr: "Асоціація",
      deu: "Assoziationen",
    },
    profile_main_company_name: {
      eng: "Company name",
      rus: "Название компании",
      ukr: "Назва компанії",
      deu: "Firmenname",
    },
    profile_main_company_number: {
      eng: "Company Number",
      rus: "Номер компании",
      ukr: "Номер компанії",
      deu: "Firmennummer",
    },
    profile_main_foundation_year: {
      eng: "Year of founding",
      rus: "Год основания",
      ukr: "Рік заснування",
      deu: "Gründungsjahr",
    },
    profile_main_members_number: {
      eng: "Number of employees",
      rus: "Количество сотрудников",
      ukr: "Кількість співробітників",
      deu: "Anzahl der Mitarbeiter",
    },
    profile_main_branches_number: {
      eng: "Number of branches",
      rus: "Количество филиалов",
      ukr: "Кількість філій",
      deu: "Anzahl der Filialen",
    },
    profile_main_services: {
      eng: "Services",
      rus: "Сервисы",
      ukr: "Сервіси",
      deu: "Dienste",
    },
    profile_main_clients: {
      eng: "Clients",
      rus: "Клиенты",
      ukr: "Клієнти",
      deu: "Kunden",
    },
    profile_main_subject_law: {
      eng: "Subject of the right",
      rus: "Субъект права",
      ukr: "Суб'єкт права",
      deu: "Gegenstand des Rechts",
    },
    profile_main_tax_resident_USA: {
      eng: "US tax resident",
      rus: "Налоговый резидент США",
      ukr: "Податковий резидент США",
      deu: "US-Steuerinländer",
    },
    profile_main_qualified_specialist: {
      eng: "Qualified Person",
      rus: "Квалифицированный специалист",
      ukr: "Кваліфікований спеціаліст",
      deu: "Qualifizierte Person",
    },
    profile_main_desired_investment_period: {
      eng: "Desired investment period (years)",
      rus: "Желаемый период инвестиций (лет)",
      ukr: "Бажаний період інвестицій (років)",
      deu: "Gewünschter Investitionszeitraum (Jahre)",
    },
    profile_main_desired_profitability: {
      eng: "Desired return (%)",
      rus: "Желаемая доходность (%)",
      ukr: "Бажана доходність (%)",
      deu: "Gewünschte Rendite (%)",
    },
    profile_main_description: {
      eng: "Description",
      rus: "Описание",
      ukr: "Опис",
      deu: "Beschreibung",
    },
    profile_main_contact_title: {
      eng: "Contact person",
      rus: "Контактное лицо",
      ukr: "Контактна особа",
      deu: "Ansprechpartner",
    },
    profile_main_contact_position: {
      eng: "Position",
      rus: "Должность",
      ukr: "Посада",
      deu: "Position",
    },
    profile_main_educational_title: {
      eng: "Education",
      rus: "Образование",
      ukr: "Освіта",
      deu: "Bildung",
    },
    profile_main_educational_faculty: {
      eng: "Faculty",
      rus: "Факультет",
      ukr: "Факультет",
      deu: "Fakultät",
    },
    profile_main_educational_period: {
      eng: "Training period",
      rus: "Период обучения",
      ukr: "Період навчання",
      deu: "Trainingszeitraum",
    },
    profile_main_educational_specialty: {
      eng: "Specialty",
      rus: "Специальность",
      ukr: "Спеціальність",
      deu: "Spezialität",
    },
    profile_main_educational_document_number: {
      eng: "Document number",
      rus: "Номер документа",
      ukr: "Номер документа",
      deu: "Dokumentnummer",
    },
    profile_main_license_title: {
      eng: "Licenses",
      rus: "Лицензии",
      ukr: "Ліцензії",
      deu: "Lizenzen",
    },
    profile_main_license_number: {
      eng: "License Name",
      rus: "Название лицензии",
      ukr: "Назва ліцензії",
      deu: "Lizenzname",
    },
    profile_stepper_item_email: {
      eng: "Mail confirmation",
      rus: "Подтверждение почты",
      ukr: "Підтвердження пошти",
      deu: "E-Mail-Bestätigung",
    },
    profile_stepper_item_verification: {
      eng: "Profile moderation",
      rus: "Модерация профиля",
      ukr: "Модерація профілю",
      deu: "Profilmoderation",
    },
    profile_stepper_item_kyc: {
      eng: "KYC pass",
      rus: "Прохождение KYC",
      ukr: "Проходження KYC",
      deu: "KYC-Pass",
    },
    profile_project_item_type: {
      eng: "Type",
      rus: "Тип",
      ukr: "Тип",
      deu: "Typ",
    },
    profile_project_item_payback_period: {
      eng: "Payback period",
      rus: "Срок окупаемости",
      ukr: "Термін окупності",
      deu: "Amortisationszeit",
    },
    profile_project_item_total_investment: {
      eng: "Total invested",
      rus: "Всего вложено",
      ukr: "Усього вкладено",
      deu: "Gesamtinvestition",
    },
    profile_project_item_more: {
      eng: "Details",
      rus: "Подробнее",
      ukr: "Докладніше",
      deu: "Details",
    },
    profile_property_item_selled: {
      eng: "Sold out",
      rus: "Продано",
      ukr: "Продано",
      deu: "Ausverkauft",
    },
    profile_property_item_type: {
      eng: "Type",
      rus: "Тип",
      ukr: "Тип",
      deu: "Typ",
    },
    profile_property_item_floor: {
      eng: "Floor",
      rus: "Этаж",
      ukr: "Поверх",
      deu: "Boden",
    },
    profile_property_item_sq: {
      eng: "sq. m",
      rus: "кв. м",
      ukr: "кв. м",
      deu: "sq. m",
    },
    profile_property_item_more: {
      eng: "Details",
      rus: "Подробнее",
      ukr: "Докладніше",
      deu: "Details",
    },
    profile_property_item_edit: {
      eng: "Edit",
      rus: "Редактировать",
      ukr: "Редагувати",
      deu: "Bearbeiten",
    },
    profile_wallet_table_method: {
      eng: "Method",
      rus: "Метод",
      ukr: "Метод",
      deu: "Methode",
    },
    profile_wallet_table_time: {
      eng: "Time",
      rus: "Время",
      ukr: "Час",
      deu: "Zeit",
    },
    profile_wallet_table_state: {
      eng: "Status",
      rus: "Состояние",
      ukr: "Стан",
      deu: "Status",
    },
    profile_wallet_balance: {
      eng: "Your total M2C balance",
      rus: "Ваш общий баланс M2C",
      ukr: "Ваш загальний баланс M2C",
      deu: "Ihr Gesamtsaldo M2C",
    },
    profile_wallet_history: {
      eng: "Wallet history",
      rus: "История кошелька",
      ukr: "Історія гаманця",
      deu: "Wallet-Verlauf",
    },
    profile_wallet_partners: {
      eng: "Partners",
      rus: "Партнеры",
      ukr: "Партнери",
      deu: "Partner",
    },
    profile_partners_share_text: {
      eng: "I invite you to register in the real estate community",
      rus: "Приглашаю вас зарегистрироваться в сообществе специалистов рынка недвижимости",
      ukr: "Запрошую вас зареєструватися у співтоваристві фахівців ринку нерухомості",
      deu: "Ich lade Sie ein, sich in der Immobilien-Community zu registrieren",
    },
    profile_partners_table_status: {
      eng: "Status",
      rus: "Статус",
      ukr: "Статус",
      deu: "Status",
    },
    profile_partners_table_member: {
      eng: "Participant",
      rus: "Участник",
      ukr: "Учасник",
      deu: "Teilnehmer",
    },
    profile_partners_table_bonuses: {
      eng: "Bonuses",
      rus: "Бонусы",
      ukr: "Бонуси",
      deu: "Boni",
    },
    profile_partners_table_date: {
      eng: "Date of invitation",
      rus: "Дата приглашения",
      ukr: "Дата запрошення",
      deu: "Datum der Einladung",
    },
    profile_partners_table_level: {
      eng: "Level",
      rus: "Уровень",
      ukr: "Рівень",
      deu: "Stufe",
    },
    profile_partners_copy_success: {
      eng: "Copied successfully",
      rus: "Скопировано успешно",
      ukr: "Скопійовано успішно",
      deu: "Erfolgreich kopiert",
    },
    profile_partners_email_error: {
      eng: "Enter a friend's email",
      rus: "Укажите email друга",
      ukr: "Вкажіть email друга",
      deu: "Geben Sie die E-Mail-Adresse eines Freundes ein",
    },
    profile_partners_email_success: {
      eng: "Invitation sent successfully",
      rus: "Приглашение успешно отправлено",
      ukr: "Запрошення успішно надіслано",
      deu: "Einladung erfolgreich versendet",
    },
    profile_partners_title: {
      eng: "Invite a friend",
      rus: "Пригласите друга",
      ukr: "Запросіть друга",
      deu: "Freunde einladen",
    },
    profile_partners_variant_email: {
      eng: "Via email",
      rus: "По email",
      ukr: "По email",
      deu: "Per E-Mail",
    },
    profile_partners_variant_email_friend: {
      eng: "Your friend's email",
      rus: "Email вашего друга",
      ukr: "Email вашого друга",
      deu: "E-Mail deines Freundes",
    },
    profile_partners_variant_messenger: {
      eng: "Via messengers",
      rus: "С помощью мессенджеров",
      ukr: "За допомогою месенджерів",
      deu: "Über Messenger",
    },
    profile_partners_variant_link: {
      eng: "Affiliate link",
      rus: "По партнерской ссылке",
      ukr: "За партнерським посиланням",
      deu: "Affiliate-Link",
    },
    profile_partners_variant_link_your_link: {
      eng: "Your affiliate link",
      rus: "Ваша партнерская ссылка",
      ukr: "Ваше партнерське посилання",
      deu: "Ihr Affiliate-Link",
    },
    profile_partners_variant_qr: {
      eng: "With QR code",
      rus: "С помощью QR кода",
      ukr: "За допомогою QR-коду",
      deu: "Mit QR-Code",
    },
    profile_partners_variant_qr_download: {
      eng: "Download",
      rus: "Скачать",
      ukr: "Завантажити",
      deu: "Herunterladen",
    },
    profile_partners_your_level: {
      eng: "Your Level:",
      rus: "Ваш уровень:",
      ukr: "Ваш рівень:",
      deu: "Ihr Level:",
    },
    profile_partners_level_1: {
      eng: "Your first level partners are:",
      rus: "Ваши партнеры первого уровня:",
      ukr: "Ваші партнери першого рівня:",
      deu: "Ihre Partner der ersten Ebene sind:",
    },
    profile_partners_level_2: {
      eng: "Your second level partners are:",
      rus: "Ваши партнеры второго уровня:",
      ukr: "Ваші партнери другого рівня:",
      deu: "Ihre Partner der zweiten Ebene sind:",
    },
    profile_partners_level_3: {
      eng: "Your third level partners are:",
      rus: "Ваши партнеры третьего уровня:",
      ukr: "Ваші партнери третього рівня:",
      deu: "Ihre Partner der dritten Ebene sind:",
    },
    profile_partners_your_bonuses: {
      eng: "Your bonuses:",
      rus: "Ваши бонусы:",
      ukr: "Ваші бонуси:",
      deu: "Ihre Boni:",
    },
    profile_partners_your_bonuses_available: {
      eng: "Available:",
      rus: "Доступно:",
      ukr: "Доступно:",
      deu: "Verfügbar:",
    },
    profile_partners_your_partners: {
      eng: "Your partners",
      rus: "Ваши партнеры",
      ukr: "Ваші партнери",
      deu: "Ihre Partner",
    },
    profile_partners_partners: {
      eng: "Partners",
      rus: "Партнеры",
      ukr: "Партнери",
      deu: "Partner",
    },
    profile_rating_table_date: {
      eng: "Date",
      rus: "Дата",
      ukr: "Дата",
      deu: "Datum",
    },
    profile_rating_table_rating: {
      eng: "Rating change",
      rus: "Изменение рейтинга",
      ukr: "Зміна рейтингу",
      deu: "Bewertungsänderung",
    },
    profile_rating_table_description: {
      eng: "Description",
      rus: "Описание",
      ukr: "Опис",
      deu: "Beschreibung",
    },
    profile_rating_dates_error: {
      eng: "Please enter a valid date range",
      rus: "Укажите правильный диапазон дат",
      ukr: "Вкажіть правильний діапазон дат",
      deu: "Bitte geben Sie einen gültigen Datumsbereich ein",
    },
    profile_rating_dates_success: {
      eng: "Absent period set",
      rus: "Период отсутствия установлен",
      ukr: "Період відсутності встановлено",
      deu: "Abwesenheitszeit eingestellt",
    },
    profile_rating_your_rating: {
      eng: "Your rating",
      rus: "Ваш рейтинг",
      ukr: "Ваш рейтинг",
      deu: "Ihre Bewertung",
    },
    profile_rating_free_title: {
      eng: "Here you can pause actions with your rating",
      rus: "Здесь вы можете приостановить действия с вашим рейтингом",
      ukr: "Тут ви можете зупинити дії з вашим рейтингом",
      deu: "Hier können Sie Aktionen mit Ihrer Bewertung pausieren",
    },
    profile_rating_free_from: {
      eng: "From",
      rus: "От",
      ukr: "Від",
      deu: "Von",
    },
    profile_rating_free_to: {
      eng: "Before",
      rus: "До",
      ukr: "До",
      deu: "Vorher",
    },
    profile_rating_free_send: {
      eng: "Submit",
      rus: "Отправить",
      ukr: "Надіслати",
      deu: "Senden",
    },
    profile_rating_free_dates: {
      eng: "Absent Period:",
      rus: "Период отсутствия:",
      ukr: "Період відсутності:",
      deu: "Abwesenheitszeitraum:",
    },
    profile_rating_free_status_available: {
      eng: "Absent Period:",
      rus: "Период отсутствия:",
      ukr: "Період відсутності:",
      deu: "Abwesenheitszeitraum:",
    },
    profile_rating_history: {
      eng: "History of accruals",
      rus: "История начислений",
      ukr: "Історія нарахувань",
      deu: "Rückstellungshistorie",
    },
    profile_projects_filter_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    profile_projects_filter_name: {
      eng: "Project Name",
      rus: "Название проекта",
      ukr: "Назва проекту",
      deu: "Projektname",
    },
    profile_projects_filter_input_name: {
      eng: "Enter project name",
      rus: "Введите назване проекта",
      ukr: "Введіть назву проекту",
      deu: "Projektname eingeben",
    },
    profile_projects_filter_button: {
      eng: "Search",
      rus: "Поиск",
      ukr: "Пошук",
      deu: "Suche",
    },
    profile_projects_not_found: {
      eng: "Projects not found",
      rus: "Проекты не найдены",
      ukr: "Проекти не знайдено",
      deu: "Projekte nicht gefunden",
    },
    profile_properties_filter_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    profile_properties_filter_name: {
      eng: "Property name",
      rus: "Название объекта",
      ukr: "Назва об'єкта",
      deu: "Eigenschaftsname",
    },
    profile_properties_filter_input_name: {
      eng: "Enter property name",
      rus: "Введите назване объекта",
      ukr: "Введіть назву об'єкта",
      deu: "Eigenschaftsnamen eingeben",
    },
    profile_properties_filter_input_price: {
      eng: "Enter price",
      rus: "Введите цену",
      ukr: "Введіть ціну",
      deu: "Preis eingeben",
    },
    profile_properties_filter_price_from: {
      eng: "Price from",
      rus: "Цена от",
      ukr: "Ціна від",
      deu: "Preis ab",
    },
    profile_properties_filter_price_to: {
      eng: "Price to",
      rus: "Цена до",
      ukr: "Ціна до",
      deu: "Preis bis",
    },
    profile_properties_filter_input_area: {
      eng: "Enter area",
      rus: "Введите площадь",
      ukr: "Введіть площу",
      deu: "Gebiet eingeben",
    },
    profile_properties_filter_area_from: {
      eng: "Area from",
      rus: "Площадь от",
      ukr: "Площа від",
      deu: "Gebiet von",
    },
    profile_properties_filter_area_to: {
      eng: "Area to",
      rus: "Площадь до",
      ukr: "Площа до",
      deu: "Bereich bis",
    },
    profile_properties_filter_type: {
      eng: "Property Type",
      rus: "Тип недвижимости",
      ukr: "Тип нерухомості",
      deu: "Eigenschaftstyp",
    },
    profile_properties_filter_button: {
      eng: "Search",
      rus: "Поиск",
      ukr: "Пошук",
      deu: "Suche",
    },
    profile_properties_not_found: {
      eng: "No objects found",
      rus: "Объекты не найдены",
      ukr: "Об'єкти не знайдено",
      deu: "Keine Objekte gefunden",
    },
    profile_roles_title: {
      eng: "Select role",
      rus: "Выберите роль",
      ukr: "Виберіть роль",
      deu: "Rolle auswählen",
    },
    profile_roles_specialist: {
      eng: "Specialist",
      rus: "Специалист",
      ukr: "Спеціаліст",
      deu: "Spezialist",
    },
    profile_roles_specialist_text: {
      eng: "To proceed with registration, select one or more roles on the platform that best suits your professional activity. Each role has its own unique opportunities, meeting the modern requirements and criteria of the global real estate market",
      rus: "Для продолжения регистрации выберите одну или несколько ролей на платформе, которые максимально соответствуют вашей профессиональной деятельности. Каждая роль имеет свои уникальные возможности, отвечая современным требованиям и критериям мирового рынка недвижимости",
      ukr: "Для продовження реєстрації виберіть одну або кілька ролей на платформі, які максимально відповідають вашій професійній діяльності. Кожна роль має свої унікальні можливості, відповідаючи сучасним вимогам та критеріям світового ринку нерухомості",
      deu: "Um mit der Registrierung fortzufahren, wählen Sie eine oder mehrere Rollen auf der Plattform aus, die am besten zu Ihrer beruflichen Tätigkeit passt. Jede Rolle hat ihre eigenen einzigartigen Möglichkeiten und erfüllt die modernen Anforderungen und Kriterien des globalen Immobilienmarktes.",
    },
    profile_roles_select: {
      eng: "Select",
      rus: "Выбрать",
      ukr: "Вибрати",
      deu: "Auswählen",
    },
    profile_roles_company: {
      eng: "Company",
      rus: "Компания",
      ukr: "Компанія",
      deu: "Firma",
    },
    profile_roles_company_text: {
      eng: "Depending on the direction of your activity, as well as on the format of doing business, select the type of company that you are most suited to",
      rus: "В зависимости от направления вашей деятельности, а также от формата ведения бизнеса выберите тип компании, которому Вы соответствуете больше всего",
      ukr: "Залежно від напряму вашої діяльності, а також від формату ведення бізнесу виберіть тип компанії, якій Ви найбільше відповідаєте",
      deu: "Wählen Sie je nach Ausrichtung Ihrer Tätigkeit sowie je nach Geschäftsform die Unternehmensform aus, für die Sie am besten geeignet sind",
    },
    profile_roles_organization: {
      eng: "Public organization",
      rus: "Общественная организация",
      ukr: "Громадська організація",
      deu: "Öffentliche Organisation",
    },
    profile_roles_organization_text: {
      eng: "Own passive income network",
      rus: "Собственный нетворк, приносящий пассивный доход",
      ukr: "Власний нетворк, що приносить пасивний дохід",
      deu: "Eigenes passives Einkommensnetzwerk",
    },
    profile_role_company_title: {
      eng: "Select role",
      rus: "Выберите роль",
      ukr: "Виберіть роль",
      deu: "Rolle auswählen",
    },
    profile_role_company_text_1: {
      eng: "We follow the latest trends in the real estate market and try to provide maximum choice for our users.",
      rus: "Мы следуем новейшим тенденциям рынка недвижимости и стараемся предоставлять максимум выбора для наших пользователей.",
      ukr: "Ми дотримуємося найновіших тенденцій ринку нерухомості та намагаємось надавати максимум вибору для наших користувачів.",
      deu: "Wir folgen den neuesten Trends auf dem Immobilienmarkt und versuchen, unseren Nutzern die größtmögliche Auswahl zu bieten.",
    },
    profile_role_company_text_2: {
      eng: "Depending on the direction of your activity, as well as the format of doing business, select the type of company that you are most suited to.",
      rus: "В зависимости от направления вашей деятельности, а также от формата ведения бизнеса выберите тип компании, которому Вы соответствуете больше всего.",
      ukr: "Залежно від напряму вашої діяльності, а також від формату ведення бізнесу виберіть тип компанії, якій Ви найбільше відповідаєте.",
      deu: "Wählen Sie je nach Ausrichtung Ihrer Tätigkeit sowie Geschäftsform die Unternehmensform, die am besten zu Ihnen passt.",
    },
    profile_role_company_text_3: {
      eng: "There are 3 options to choose from: development company, management company and commercial company",
      rus: "Доступно 3 варианта на выбор: девелоперская компания, управляющая компания и коммерческая компания",
      ukr: "Доступно 3 варіанти на вибір: девелоперська компанія, керуюча компанія та комерційна компанія",
      deu: "Es stehen 3 Optionen zur Auswahl: Entwicklungsgesellschaft, Verwaltungsgesellschaft und Handelsgesellschaft",
    },
    profile_role_company_dev_title: {
      eng: "Development company",
      rus: "Девелоперская компания",
      ukr: "Девелоперська компанія",
      deu: "Entwicklungsunternehmen",
    },
    profile_role_company_dev_text: {
      eng: "Security of transactions thanks to the digital contract. Personalized landing page with personalized links. Targeted advertising to a relevant audience. Access to information about potential customers. Automatic fixation of transactions in the blockchain registry. Access to investors from all over the world. Quick launch of campaigns to attract investments. The rating system of the project, which increases the confidence of the investor. Project promo page for promotion. Opportunity to introduce yourself to clients from all over the world. Savings on advertising and customer search",
      rus: "Безопасность сделок благодаря цифровому контракту. Персонализированный лендинг-пейдж с именной ссылокй. Таргетированная реклама на релевантную аудиторию. Доступ к информации о потенциальных клиентах. Автоматическая фиксация сделок в блокчейн реестре. Доступ к инвесторам со всего мира. Быстрый запуск кампаний по привлечению инвестиций. Рейтинговая система проекта, увеличивающая доверие инвестора. Промо-страница проекта для продвижения. Возможность представить себя клиентам со всего мира. Экономия на рекламе и поиска клиентов",
      ukr: "Безпека угод завдяки цифровому контракту. Персоналізований лендінг-пейдж із іменним посиланням. Таргетована реклама на релевантну аудиторію. Доступ до інформації про потенційних клієнтів. Автоматична фіксація угод у блокчейн реєстрі. Доступ до інвесторів з усього світу. Швидкий запуск кампаній із залучення інвестицій. Рейтингова система проекту збільшує довіру інвестора. Промо-сторінка проекту для просування. Можливість уявити себе клієнтам з усього світу. Економія на рекламі та пошуку клієнтів",
      deu: "Transaktionssicherheit dank digitalem Vertrag. Personalisierte Landingpage mit personalisierten Links. Gezielte Werbung für eine relevante Zielgruppe. Zugriff auf Informationen über potenzielle Kunden. Automatische Fixierung von Transaktionen in der Blockchain-Registrierung. Zugang zu Investoren aus der ganzen Welt. Schneller Start von Kampagnen, um Investitionen anzuziehen. Das Bewertungssystem des Projekts, das das Vertrauen des Investors erhöht. Projekt-Werbeseite für Werbung. Gelegenheit, sich Kunden aus der ganzen Welt vorzustellen. Einsparungen bei Werbung und Kundensuche",
    },
    profile_role_company_select: {
      eng: "Select",
      rus: "Выбрать",
      ukr: "Вибрати",
      deu: "Auswählen",
    },
    profile_role_company_mn_title: {
      eng: "Management company",
      rus: "Управляющая компания",
      ukr: "Керуюча компанія",
      deu: "Verwaltungsgesellschaft",
    },
    profile_role_company_mn_text: {
      eng: "Security of transactions thanks to the digital contract. Personalized landing page with personalized links. Targeted advertising to a relevant audience. Access to information about potential customers. Automatic fixation of transactions in the blockchain registry. Opportunity to introduce yourself to clients from all over the world. Savings on advertising and customer search",
      rus: "Безопасность сделок благодаря цифровому контракту. Персонализированный лендинг-пейдж с именной ссылокй. Таргетированная реклама на релевантную аудиторию. Доступ к информации о потенциальных клиентах. Автоматическая фиксация сделок в блокчейн реестре. Возможность представить себя клиентам со всего мира. Экономия на рекламе и поиска клиентов",
      ukr: "Безпека угод завдяки цифровому контракту. Персоналізований лендінг-пейдж із іменним посиланням. Таргетована реклама на релевантну аудиторію. Доступ до інформації про потенційних клієнтів. Автоматична фіксація угод у блокчейн реєстрі. Можливість уявити себе клієнтам з усього світу. Економія на рекламі та пошуку клієнтів",
      deu: "Transaktionssicherheit dank digitalem Vertrag. Personalisierte Landingpage mit personalisierten Links. Gezielte Werbung für eine relevante Zielgruppe. Zugriff auf Informationen über potenzielle Kunden. Automatische Fixierung von Transaktionen in der Blockchain-Registrierung. Gelegenheit, sich Kunden aus der ganzen Welt vorzustellen. Einsparungen bei Werbung und Kundensuche",
    },
    profile_role_company_cn_title: {
      eng: "Commercial company",
      rus: "Коммерческая компания",
      ukr: "Комерційна компанія",
      deu: "Handelsgesellschaft",
    },
    profile_role_company_cn_text: {
      eng: "Security of transactions thanks to the digital contract. Personalized landing page with personalized links. Targeted advertising to a relevant audience. Access to information about potential customers. Automatic fixation of transactions in the blockchain registry. Opportunity to introduce yourself to clients from all over the world. Savings on advertising and customer search",
      rus: "Безопасность сделок благодаря цифровому контракту. Персонализированный лендинг-пейдж с именной ссылокй. Таргетированная реклама на релевантную аудиторию. Доступ к информации о потенциальных клиентах. Автоматическая фиксация сделок в блокчейн реестре. Возможность представить себя клиентам со всего мира. Экономия на рекламе и поиска клиентов",
      ukr: "Безпека угод завдяки цифровому контракту. Персоналізований лендінг-пейдж із іменним посиланням. Таргетована реклама на релевантну аудиторію. Доступ до інформації про потенційних клієнтів. Автоматична фіксація угод у блокчейн реєстрі. Можливість уявити себе клієнтам з усього світу. Економія на рекламі та пошуку клієнтів",
      deu: "Transaktionssicherheit dank digitalem Vertrag. Personalisierte Landingpage mit personalisierten Links. Gezielte Werbung für eine relevante Zielgruppe. Zugriff auf Informationen über potenzielle Kunden. Automatische Fixierung von Transaktionen in der Blockchain-Registrierung. Gelegenheit, sich Kunden aus der ganzen Welt vorzustellen. Einsparungen bei Werbung und Kundensuche",
    },
    profile_role_second_progress_title: {
      eng: "Profile verification in progress",
      rus: "Проверка профиля в процессе",
      ukr: "Перевірка профілю в процесі",
      deu: "Profilüberprüfung läuft",
    },
    profile_role_second_progress_text_1: {
      eng: "Thank you for filling in the additional information about yourself. Soon, after checking your profile, you will be able to access all the main features of the site.",
      rus: "Спасибо за заполнение дополнительных данных о себе. Вскоре, после проверки вашего профиля, вам будут доступны все основные функции сайта.",
      ukr: "Дякую за заповнення додаткових даних про себе. Незабаром після перевірки вашого профілю вам будуть доступні всі основні функції сайту.",
      deu: "Vielen Dank, dass Sie die zusätzlichen Informationen über sich selbst ausgefüllt haben. Nachdem Sie Ihr Profil überprüft haben, können Sie bald auf alle Hauptfunktionen der Website zugreifen.",
    },
    profile_role_second_progress_text_2: {
      eng: "Profile verification takes 24 hours on average",
      rus: "В среднем проверка профиля проходит в течении 24-х часов",
      ukr: "У середньому перевірка профілю проходить протягом 24-х годин",
      deu: "Profilüberprüfung dauert durchschnittlich 24 Stunden",
    },
    profile_role_second_progress_button_help: {
      eng: "Contact technical support",
      rus: "Написать в техническую поддержку",
      ukr: "Написати на технічну підтримку",
      deu: "Technischen Support kontaktieren",
    },
    role_appraiser: {
      eng: "Appraiser",
      rus: "Оценщик",
      ukr: "Оцінювач",
      deu: "Bewerter",
    },
    role_architect: {
      eng: "Architect",
      rus: "Архитектор",
      ukr: "Архітектор",
      deu: "Architekt",
    },
    role_broker: {
      eng: "Broker",
      rus: "Брокер",
      ukr: "Брокер",
      deu: "Makler",
    },
    role_buyer: {
      eng: "Buyer",
      rus: "Покупатель",
      ukr: "Покупець",
      deu: "Kunde",
    },
    role_developer: {
      eng: "Builder",
      rus: "Застройщик",
      ukr: "Забудовник",
      deu: "Erbauer",
    },
    role_investor: {
      eng: "Investor",
      rus: "Инвестор",
      ukr: "Інвестор",
      deu: "Anleger",
    },
    role_notary: {
      eng: "Notary",
      rus: "Нотариус",
      ukr: "Нотаріус",
      deu: "Notar",
    },
    role_seller: {
      eng: "Owner",
      rus: "Cобственник",
      ukr: "Власник",
      deu: "Verkäufer",
    },
    profile_role_specialist_title: {
      eng: "Select one or more roles",
      rus: "Выберите одну или несколько ролей",
      ukr: "Виберіть одну або кілька ролей",
      deu: "Wählen Sie eine oder mehrere Rollen aus",
    },
    profile_role_specialist_next: {
      eng: "Next",
      rus: "Далее",
      ukr: "Далі",
      deu: "Weiter",
    },
    profile_role_specialist_text_1: {
      eng: "REM - the world of fast, transparent and secure real estate transactions in any country in the world. We offer all the necessary tools for convenient work and maximum results.",
      rus: "REM - мир быстрых, прозрачных и безопасных сделок с недвижимостью в любой стране мира. Мы предлагаем все необходимые инструменты для удобной работы и максимальных результатов.",
      ukr: "REM - світ швидких, прозорих та безпечних операцій з нерухомістю у будь-якій країні світу. Ми пропонуємо всі необхідні інструменти для зручної роботи та максимальних результатів.",
      deu: "REM - die Welt der schnellen, transparenten und sicheren Immobilientransaktionen in jedem Land der Welt. Wir bieten alle notwendigen Werkzeuge für komfortables Arbeiten und maximale Ergebnisse.",
    },
    profile_role_specialist_text_2: {
      eng: "To proceed with registration, select one or more roles on the platform that best suits your professional activity. Each role has its own unique opportunities, meeting the modern requirements and criteria of the global real estate market.",
      rus: "Для продолжения регистрации выберите одну или несколько ролей на платформе, которые максимально соответствуют вашей профессиональной деятельности. Каждая роль имеет свои уникальные возможности, отвечая современным требованиям и критериям мирового рынка недвижимости.",
      ukr: "Для продовження реєстрації виберіть одну або кілька ролей на платформі, які максимально відповідають вашій професійній діяльності. Кожна роль має свої унікальні можливості, відповідаючи сучасним вимогам та критеріям світового ринку нерухомості.",
      deu: "Um mit der Registrierung fortzufahren, wählen Sie eine oder mehrere Rollen auf der Plattform aus, die am besten zu Ihrer beruflichen Tätigkeit passt. Jede Rolle hat ihre eigenen einzigartigen Möglichkeiten und erfüllt die modernen Anforderungen und Kriterien des globalen Immobilienmarktes.",
    },
    profile_role_specialist_text_3: {
      eng: "Combining several roles, you expand the circle of your professional activities, clients and partners, developing several lines of business at the same time. Thus, the functionality of your profile increases significantly. You can buy and sell objects, implement development projects, invest funds or engage in appraisal activities. At the same time, if the task at hand requires the simplest and fastest solution, you can always stop at one thing.",
      rus: "Комбинируя несколько ролей, вы расширяете круг своей профессиональной деятельности, клиентов и партнеров, развивая несколько направлений ведения бизнеса одновременно. Таким образом функционал Вашего профиля существенно возрастает. Вы можете покупать и продавать объекты, реализовывать девелоперские проекты, инвестировать средства или заниматься оценочной деятельностью. В то же время, если поставленная задача требует простого и наиболее быстрого решения – всегда можно остановиться на чем-то одном.",
      ukr: "Комбінуючи кілька ролей, ви розширюєте коло своєї професійної діяльності, клієнтів та партнерів, розвиваючи кілька напрямків ведення бізнесу одночасно. Таким чином функціонал Вашого профілю суттєво зростає. Ви можете купувати та продавати об'єкти, реалізовувати девелоперські проекти, інвестувати кошти чи займатися оціночною діяльністю. У той же час, якщо поставлене завдання вимагає простого і найшвидшого вирішення – завжди можна зупинитися на чомусь одному.",
      deu: "Durch die Kombination mehrerer Rollen erweitern Sie den Kreis Ihrer beruflichen Aktivitäten, Kunden und Partner und entwickeln gleichzeitig mehrere Geschäftsfelder. Dadurch erhöht sich die Funktionalität Ihres Profils deutlich. Sie können Objekte kaufen und verkaufen, Entwicklungsprojekte umsetzen, Gelder anlegen oder Gutachtertätigkeiten ausüben. Gleichzeitig kann man, wenn die anstehende Aufgabe die einfachste und schnellste Lösung erfordert, immer bei einer Sache stehen bleiben.",
    },
    profile_role_specialist_text_4: {
      eng: "Get out of the box, unleash your full potential!",
      rus: "Избавьтесь от рамок, раскройте свой потенциал на полную!",
      ukr: "Позбудьтеся рамок, розкрийте свій потенціал на повну!",
      deu: "Raus aus der Box, entfesseln Sie Ihr volles Potenzial!",
    },
    profile_role_specialist_appraiser_text_1: {
      eng: "Security of transactions thanks to the digital contract",
      rus: "Безопасность сделок благодаря цифровому контракту",
      ukr: "Безпека угод завдяки цифровому контракту",
      deu: "Transaktionssicherheit dank digitalem Vertrag",
    },
    profile_role_specialist_appraiser_text_2: {
      eng: "Own passive income network",
      rus: "Собственный нетворк, приносящий пассивный доход",
      ukr: "Власний нетворк, що приносить пасивний дохід",
      deu: "Eigenes passives Einkommensnetzwerk",
    },
    profile_role_specialist_appraiser_text_3: {
      eng: "Personalized landing page with named link",
      rus: "Персонализированный лендинг-пейдж с именной ссылкой",
      ukr: "Персоналізований лендинг-пейдж із іменним посиланням",
      deu: "Personalisierte Zielseite mit benanntem Link",
    },
    profile_role_specialist_appraiser_text_4: {
      eng: "Access lead information",
      rus: "Доступ к информации о потенциальных клиентах",
      ukr: "Доступ до інформації про потенційних клієнтів",
      deu: "Auf Lead-Informationen zugreifen",
    },
    profile_role_specialist_appraiser_text_5: {
      eng: "Comfortable work without being tied to the office",
      rus: "Удобная работа без привязки к офису",
      ukr: "Зручна робота без прив'язки до офісу",
      deu: "Komfortables Arbeiten ohne Bürobindung",
    },
    profile_role_specialist_appraiser_text_6: {
      eng: "Ability to introduce yourself to clients from all over the world",
      rus: "Возможность представить себя клиентам со всего мира",
      ukr: "Можливість уявити себе клієнтам з усього світу",
      deu: "Fähigkeit, sich Kunden aus der ganzen Welt vorzustellen",
    },
    profile_role_specialist_appraiser_text_7: {
      eng: "Improved status and prestige in global and local markets",
      rus: "Улучшение статуса и престижа на мировом и местном рынках",
      ukr: "Поліпшення статусу та престижу на світовому та місцевому ринках",
      deu: "Verbesserter Status und Prestige auf globalen und lokalen Märkten",
    },
    profile_role_specialist_appraiser_text_8: {
      eng: "Ability to receive international orders",
      rus: "Возможность получать международные заказы",
      ukr: "Можливість отримувати міжнародні замовлення",
      deu: "Fähigkeit, internationale Bestellungen zu empfangen",
    },
    profile_role_specialist_architect_text_1: {
      eng: "Ability to introduce yourself to clients from all over the world",
      rus: "Возможность представить себя клиентам со всего мира",
      ukr: "Можливість уявити себе клієнтам з усього світу",
      deu: "Fähigkeit, sich Kunden aus der ganzen Welt vorzustellen",
    },
    profile_role_specialist_architect_text_2: {
      eng: "Ability to receive international orders",
      rus: "Возможность получать международные заказы",
      ukr: "Можливість отримувати міжнародні замовлення",
      deu: "Fähigkeit, internationale Bestellungen zu empfangen",
    },
    profile_role_specialist_architect_text_3: {
      eng: "Access lead information",
      rus: "Доступ к информации о потенциальных клиентах",
      ukr: "Доступ до інформації про потенційних клієнтів",
      deu: "Auf Lead-Informationen zugreifen",
    },
    profile_role_specialist_architect_text_4: {
      eng: "Improved status and prestige in global and local markets",
      rus: "Улучшение статуса и престижа на мировом и местном рынках",
      ukr: "Поліпшення статусу та престижу на світовому та місцевому ринках",
      deu: "Verbesserter Status und Prestige auf globalen und lokalen Märkten",
    },
    profile_role_specialist_broker_text_1: {
      eng: "Access clients and property from all over the world",
      rus: "Доступ к клиентам и собственности со всего мира",
      ukr: "Доступ до клієнтів та власності з усього світу",
      deu: "Zugriff auf Kunden und Eigentum aus der ganzen Welt",
    },
    profile_role_specialist_broker_text_2: {
      eng: "Security of transactions thanks to the digital contract",
      rus: "Безопасность сделок благодаря цифровому контракту",
      ukr: "Безпека угод завдяки цифровому контракту",
      deu: "Transaktionssicherheit dank digitalem Vertrag",
    },
    profile_role_specialist_broker_text_3: {
      eng: "Own passive income network",
      rus: "Собственный нетворк, приносящий пассивный доход",
      ukr: "Власний нетворк, що приносить пасивний дохід",
      deu: "Eigenes passives Einkommensnetzwerk",
    },
    profile_role_specialist_broker_text_4: {
      eng: "Personalized landing page with named link",
      rus: "Персонализированный лендинг-пейдж с именной ссылкой",
      ukr: "Персоналізований лендинг-пейдж із іменним посиланням",
      deu: "Personalisierte Zielseite mit benanntem Link",
    },
    profile_role_specialist_broker_text_5: {
      eng: "Targeted ads to a relevant audience",
      rus: "Таргетированная реклама на релевантную аудиторию",
      ukr: "Таргетована реклама на релевантну аудиторію",
      deu: "Gezielte Anzeigen an eine relevante Zielgruppe",
    },
    profile_role_specialist_broker_text_6: {
      eng: "Access lead information",
      rus: "Доступ к информации о потенциальных клиентах",
      ukr: "Доступ до інформації про потенційних клієнтів",
      deu: "Auf Lead-Informationen zugreifen",
    },
    profile_role_specialist_broker_text_7: {
      eng: "Regular data update 24/7",
      rus: "Регулярное обновление данных 24/7",
      ukr: "Регулярне оновлення даних 24/7",
      deu: "Regelmäßige Datenaktualisierung rund um die Uhr",
    },
    profile_role_specialist_broker_text_8: {
      eng: "Comfortable work without being tied to the office",
      rus: "Удобная работа без привязки к офису",
      ukr: "Зручна робота без прив'язки до офісу",
      deu: "Komfortables Arbeiten ohne Bürobindung",
    },
    profile_role_specialist_buyer_text_1: {
      eng: "Access real estate from all over the world",
      rus: "Доступ к недвижимости со всего мира",
      ukr: "Доступ до нерухомості з усього світу",
      deu: "Zugriff auf Immobilien aus der ganzen Welt",
    },
    profile_role_specialist_buyer_text_2: {
      eng: "Preparation of all types of transactions online",
      rus: "Подготовка всех видов сделок онлайн",
      ukr: "Підготовка всіх видів операцій онлайн",
      deu: "Vorbereitung aller Arten von Transaktionen online",
    },
    profile_role_specialist_buyer_text_3: {
      eng: "Online Dispute Resolution in International Arbitration",
      rus: "Разрешение споров онлайн в международном арбитраже",
      ukr: "Вирішення спорів онлайн у міжнародному арбітражі",
      deu: "Online-Streitbeilegung in internationalen Schiedsverfahren",
    },
    profile_role_specialist_buyer_text_4: {
      eng: "Transaction security and transparency",
      rus: "Безопасность и прозрачность сделки",
      ukr: "Безпека та прозорість угоди",
      deu: "Transaktionssicherheit und -transparenz",
    },
    profile_role_specialist_buyer_text_5: {
      eng: "Expert advice available",
      rus: "Возможность получения консультации экспертов",
      ukr: "Можливість отримання консультації експертів",
      deu: "Expertenberatung verfügbar",
    },
    profile_role_specialist_developer_text_1: {
      eng: "Access to investors from all over the world",
      rus: "Доступ к инвесторам со всего мира",
      ukr: "Доступ до інвесторів з усього світу",
      deu: "Zugang zu Investoren aus aller Welt",
    },
    profile_role_specialist_developer_text_2: {
      eng: "Quick start fundraising campaigns",
      rus: "Быстрый запуск кампаний по привлечению инвестиций",
      ukr: "Швидкий запуск кампаній із залучення інвестицій",
      deu: "Schnellstart-Spendenkampagnen",
    },
    profile_role_specialist_developer_text_3: {
      eng: "Project rating system that increases investor confidence",
      rus: "Рейтинговая система проекта, увеличивающая доверие инвестора",
      ukr: "Рейтингова система проекту, яка збільшує довіру інвестора",
      deu: "Projektbewertungssystem, das das Vertrauen der Investoren stärkt",
    },
    profile_role_specialist_developer_text_4: {
      eng: "Security of transactions thanks to the digital contract",
      rus: "Безопасность сделок благодаря цифровому контракту",
      ukr: "Безпека угод завдяки цифровому контракту",
      deu: "Transaktionssicherheit dank digitalem Vertrag",
    },
    profile_role_specialist_developer_text_5: {
      eng: "Possibility of attracting a strategic investor",
      rus: "Возможность привлечения стратегического инвестора",
      ukr: "Можливість залучення стратегічного інвестора",
      deu: "Möglichkeit, einen strategischen Investor zu gewinnen",
    },
    profile_role_specialist_developer_text_6: {
      eng: "Promo - project page for promotion",
      rus: "Промо - страница проекта для продвижения",
      ukr: "Промо - сторінка проекту для просування",
      deu: "Promo - Projektseite zur Promotion",
    },
    profile_role_specialist_developer_text_7: {
      eng: "Involvement of global experts and consultants in project development",
      rus: "Вовлечение мировых экспертов и консультантов в разработку проектов",
      ukr: "Залучення світових експертів та консультантів до розробки проектів",
      deu: "Einbindung globaler Experten und Berater in die Projektentwicklung",
    },
    profile_role_specialist_investor_text_1: {
      eng: "The ability to invest in real estate in any country in the world",
      rus: "Возможность инвестировать в недвижимость в любой стране мира",
      ukr: "Можливість інвестувати в нерухомість у будь-якій країні світу",
      deu: "Die Fähigkeit, in Immobilien in jedem Land der Welt zu investieren",
    },
    profile_role_specialist_investor_text_2: {
      eng: "Low investment risks (projects pass expert council)",
      rus: "Низкие инвестиционные риски (проекты проходят экспертный совет)",
      ukr: "Низькі інвестиційні ризики (проекти проходять експертну раду)",
      deu: "Geringe Investitionsrisiken (Projekte bestehen Expertenrat)",
    },
    profile_role_specialist_investor_text_3: {
      eng: "Option to select investment type",
      rus: "Возможность выбрать тип инвестиций",
      ukr: "Можливість вибрати тип інвестицій",
      deu: "Option zur Auswahl des Anlagetyps",
    },
    profile_role_specialist_investor_text_4: {
      eng: "Minimum Entry Threshold",
      rus: "Минимальный порог входа",
      ukr: "Мінімальний поріг входу",
      deu: "Mindesteintrittsschwelle",
    },
    profile_role_specialist_investor_text_5: {
      eng: "Online Dispute Resolution in International Arbitration",
      rus: "Разрешение споров онлайн в международном арбитраже",
      ukr: "Вирішення спорів онлайн у міжнародному арбітражі",
      deu: "Online-Streitbeilegung in internationalen Schiedsverfahren",
    },
    profile_role_specialist_investor_text_6: {
      eng: "Ability to get expert advice",
      rus: "Возможность получения консультации специалиста",
      ukr: "Можливість отримання консультації спеціаліста",
      deu: "Fähigkeit, Expertenrat einzuholen",
    },
    profile_role_specialist_investor_text_7: {
      eng: "Automatically fix transactions in the blockchain registry",
      rus: "Автоматическая фиксация сделок в блокчейн реестре",
      ukr: "Автоматична фіксація операцій у блокчейн реєстрі",
      deu: "Transaktionen automatisch in der Blockchain-Registrierung korrigieren",
    },
    profile_role_specialist_notary_text_1: {
      eng: "Ability to introduce yourself to clients from all over the world",
      rus: "Возможность представить себя клиентам со всего мира",
      ukr: "Можливість уявити себе клієнтам з усього світу",
      deu: "Fähigkeit, sich Kunden aus der ganzen Welt vorzustellen",
    },
    profile_role_specialist_notary_text_2: {
      eng: "Comfortable work without being tied to the office",
      rus: "Удобная работа без привязки к офису",
      ukr: "Зручна робота без прив'язки до офісу",
      deu: "Komfortables Arbeiten ohne Bürobindung",
    },
    profile_role_specialist_notary_text_3: {
      eng: "Fulfillment of foreign orders in the local market",
      rus: "Выполнение зарубежных заказов на местном рынке",
      ukr: "Виконання закордонних замовлень на місцевому ринку",
      deu: "Erfüllung von Auslandsaufträgen auf dem lokalen Markt",
    },
    profile_role_specialist_notary_text_4: {
      eng: "Savings on advertising and customer search",
      rus: "Экономия на рекламе и поиске клиентов",
      ukr: "Економія на рекламі та пошуку клієнтів",
      deu: "Einsparungen bei Werbung und Kundensuche",
    },
    profile_role_specialist_notary_text_5: {
      eng: "Access lead information",
      rus: "Доступ к информации о потенциальных клиентах",
      ukr: "Доступ до інформації про потенційних клієнтів",
      deu: "Auf Lead-Informationen zugreifen",
    },
    profile_role_specialist_notary_text_6: {
      eng: "Improved status and prestige in global and local markets",
      rus: "Улучшение статуса и престижа на мировом и местном рынках",
      ukr: "Поліпшення статусу та престижу на світовому та місцевому ринках",
      deu: "Verbesserter Status und Prestige auf globalen und lokalen Märkten",
    },
    profile_role_specialist_seller_text_1: {
      eng: "Access to customers worldwide",
      rus: "Доступ к покупателям по всему миру",
      ukr: "Доступ до покупців по всьому світу",
      deu: "Zugang zu Kunden weltweit",
    },
    profile_role_specialist_seller_text_2: {
      eng: "Access to real estate specialists",
      rus: "Доступ к специалистам рынка недвижимости",
      ukr: "Доступ до фахівців ринку нерухомості",
      deu: "Zugang zu Immobilienspezialisten",
    },
    profile_role_specialist_seller_text_3: {
      eng: "Security of transactions thanks to the digital contract",
      rus: "Безопасность сделок благодаря цифровому контракту",
      ukr: "Безпека угод завдяки цифровому контракту",
      deu: "Transaktionssicherheit dank digitalem Vertrag",
    },
    profile_role_specialist_seller_text_4: {
      eng: "Speed up the process of selling an object",
      rus: "Ускорение процесса продажи объекта",
      ukr: "Прискорення процесу продажу об'єкта",
      deu: "Beschleunigen Sie den Verkaufsprozess eines Objekts",
    },
    profile_role_specialist_seller_text_5: {
      eng: "Property Promo Page",
      rus: "Промо-страница объекта недвижимости",
      ukr: "Промо-сторінка об'єкту нерухомості",
      deu: "Immobilien-Werbeseite",
    },
    profile_secondary_step_professional: {
      eng: "Professional",
      rus: "Профессиональные",
      ukr: "Професійні",
      deu: "Profi",
    },
    profile_secondary_step_contact: {
      eng: "Contact person",
      rus: "Контактное лицо",
      ukr: "Контактна особа",
      deu: "Ansprechpartner",
    },
    profile_secondary_step_finance: {
      eng: "Financials",
      rus: "Финансовые показатели",
      ukr: "Фінансові показники",
      deu: "Finanzen",
    },
    profile_secondary_step_licence: {
      eng: "License and Education",
      rus: "Лицензия и образование",
      ukr: "Ліцензія та освіта",
      deu: "Lizenz und Ausbildung",
    },
    profile_secondary_title: {
      eng: "Enter your information",
      rus: "Укажите информацию о себе",
      ukr: "Вкажіть інформацію про себе",
      deu: "Geben Sie Ihre Informationen ein",
    },
    profile_secondary_form_back: {
      eng: "Back",
      rus: "Назад",
      ukr: "Назад",
      deu: "Zurück",
    },
    profile_secondary_form_next: {
      eng: "Next",
      rus: "Далее",
      ukr: "Далі",
      deu: "Weiter",
    },
    profile_secondary_form_send: {
      eng: "Submit",
      rus: "Отправить",
      ukr: "Надіслати",
      deu: "Senden",
    },
    submit: {
      eng: "Submit",
      rus: "Отправить",
      ukr: "Надіслати",
      deu: "Senden",
    },
    profile_secondary_form_prof_title: {
      eng: "1. Professional Options",
      rus: "1. Профессиональные параметры",
      ukr: "1. Професійні параметри",
      deu: "1. Berufliche Möglichkeiten",
    },
    profile_secondary_form_prof_img_load: {
      eng: "Upload profile photo",
      rus: "Загрузить фотографию для профиля",
      ukr: "Завантажити фотографію для профілю",
      deu: "Profilfoto hochladen",
    },
    profile_secondary_form_prof_role: {
      eng: "Roles:",
      rus: "Роли:",
      ukr: "Ролі:",
      deu: "Rollen:",
    },
    profile_secondary_form_prof_specializations: {
      eng: "Specializations",
      rus: "Специализации",
      ukr: "Спеціалізації",
      deu: "Spezialisierungen",
    },
    profile_secondary_form_prof_help_specializations: {
      eng: "Specify at least one specialization",
      rus: "Укажите хотя бы одну специализацию",
      ukr: "Вкажіть хоча б одну спеціалізацію",
      deu: "Mindestens eine Spezialisierung angeben",
    },
    profile_secondary_form_prof_country: {
      eng: "Country of residence",
      rus: "Страна проживания",
      ukr: "Країна проживання",
      deu: "Land des Wohnsitzes",
    },
    profile_secondary_form_prof_help_country: {
      eng: "Enter your country of residence",
      rus: "Укажите вашу страну проживания",
      ukr: "Вкажіть вашу країну проживання",
      deu: "Geben Sie Ihr Wohnsitzland ein",
    },
    profile_secondary_form_prof_business_country: {
      eng: "Countries of doing business",
      rus: "Страны ведения бизнеса",
      ukr: "Країни ведення бізнесу",
      deu: "Länder der Geschäftstätigkeit",
    },
    profile_secondary_form_prof_help_business_country: {
      eng: "Please select at least one country",
      rus: "Уажите хотя бы одну страну",
      ukr: "Зважте хоча б одну країну",
      deu: "Bitte mindestens ein Land auswählen",
    },
    profile_secondary_form_prof_max_transaction_amount: {
      eng: "Maximum trade amount (₼ equivalent)",
      rus: "Максимальная сумма сделки (₼ эквивалент)",
      ukr: "Максимальна сума угоди (₼ еквівалент)",
      deu: "Maximaler Handelsbetrag (₼-Gegenwert)",
    },
    profile_secondary_form_prof_input_max_transaction_amount: {
      eng: "Enter the maximum trade amount (₼ equivalent)",
      rus: "Введите максимальную сумму сделки (₼ эквивалент)",
      ukr: "Введіть максимальну суму угоди (₼ еквівалент)",
      deu: "Geben Sie den maximalen Handelsbetrag ein (₼-Gegenwert)",
    },
    profile_secondary_form_prof_input_help_max_transaction_amount: {
      eng: "Enter the maximum trade amount (₼ equivalent)",
      rus: "Введите максимальную сумму сделки (₼ эквивалент)",
      ukr: "Введіть максимальну суму угоди (₼ еквівалент)",
      deu: "Geben Sie den maximalen Handelsbetrag ein (₼-Gegenwert)",
    },
    profile_secondary_form_prof_biggest_projects: {
      eng: "Name of the largest completed project",
      rus: "Название крупнейшего завершенного проекта",
      ukr: "Назва найбільшого завершеного проекту",
      deu: "Name des größten abgeschlossenen Projekts",
    },
    profile_secondary_form_prof_input_biggest_projects: {
      eng: "Enter the name of the largest completed project",
      rus: "Введите название крупнейшего завершенного проекта",
      ukr: "Введіть назву найбільшого завершеного проекту",
      deu: "Geben Sie den Namen des größten abgeschlossenen Projekts ein",
    },
    profile_secondary_form_prof_input_help_biggest_projects: {
      eng: "Enter the name of the largest completed project",
      rus: "Введите название крупнейшего завершенного проекта",
      ukr: "Введіть назву найбільшого завершеного проекту",
      deu: "Geben Sie den Namen des größten abgeschlossenen Projekts ein",
    },
    profile_secondary_form_prof_total_constructed: {
      eng: "Total built area, square meters",
      rus: "Общая площадь построенного, квадратные метры",
      ukr: "Загальна площа збудованого, квадратні метри",
      deu: "Gesamt bebaute Fläche, Quadratmeter",
    },
    profile_secondary_form_prof_input_total_constructed: {
      eng: "Enter the total built area, square meters",
      rus: "Введите общую площадь построенного, квадратные метры",
      ukr: "Введіть загальну площу збудованого, квадратні метри",
      deu: "Geben Sie die bebaute Gesamtfläche ein, Quadratmeter",
    },
    profile_secondary_form_prof_input_help_total_constructed: {
      eng: "Enter the total built area, square meters",
      rus: "Введите общую площадь построенного, квадратные метры",
      ukr: "Введіть загальну площу збудованого, квадратні метри",
      deu: "Geben Sie die bebaute Gesamtfläche ein, Quadratmeter",
    },
    profile_secondary_form_prof_completed_projects: {
      eng: "Number of completed projects",
      rus: "Количество завершенных проектов",
      ukr: "Кількість завершених проектів",
      deu: "Anzahl abgeschlossener Projekte",
    },
    profile_secondary_form_prof_input_completed_projects: {
      eng: "Enter the number of completed projects",
      rus: "Введите количество завершенных проектов",
      ukr: "Введіть кількість завершених проектів",
      deu: "Geben Sie die Anzahl abgeschlossener Projekte ein",
    },
    profile_secondary_form_prof_input_help_completed_projects: {
      eng: "Enter the number of completed projects",
      rus: "Введите количество завершенных проектов",
      ukr: "Введіть кількість завершених проектів",
      deu: "Geben Sie die Anzahl abgeschlossener Projekte ein",
    },
    profile_secondary_form_prof_investment_period: {
      eng: "Desired investment period (years)",
      rus: "Желаемый период инвестиций (лет)",
      ukr: "Бажаний період інвестицій (років)",
      deu: "Gewünschter Investitionszeitraum (Jahre)",
    },
    profile_secondary_form_prof_input_investment_period: {
      eng: "Enter desired investment period (years)",
      rus: "Введите желаемый период инвестиций (лет)",
      ukr: "Введіть бажаний період інвестицій (років)",
      deu: "Gewünschten Anlagezeitraum (Jahre) eingeben",
    },
    profile_secondary_form_prof_input_help_investment_period: {
      eng: "Enter desired investment period (years)",
      rus: "Введите желаемый период инвестиций (лет)",
      ukr: "Введіть бажаний період інвестицій (років)",
      deu: "Gewünschten Anlagezeitraum (Jahre) eingeben",
    },
    profile_secondary_form_prof_desired_profitability: {
      eng: "Desired return (%)",
      rus: "Желаемая доходность (%)",
      ukr: "Бажана доходність (%)",
      deu: "Gewünschte Rendite (%)",
    },
    profile_secondary_form_prof_input_desired_profitability: {
      eng: "Enter desired yield (%)",
      rus: "Введите желаемую доходность (%)",
      ukr: "Введіть бажану прибутковість (%)",
      deu: "Gewünschten Ertrag (%) eingeben",
    },
    profile_secondary_form_prof_input_help_desired_profitability: {
      eng: "Enter desired yield (%)",
      rus: "Введите желаемую доходность (%)",
      ukr: "Введіть бажану прибутковість (%)",
      deu: "Gewünschten Ertrag (%) eingeben",
    },
    profile_secondary_form_prof_company_name: {
      eng: "Company name",
      rus: "Название компании",
      ukr: "Назва компанії",
      deu: "Firmenname",
    },
    profile_secondary_form_prof_input_company_name: {
      eng: "Enter company name",
      rus: "Введите название компании",
      ukr: "Введіть назву компанії",
      deu: "Firmenname eingeben",
    },
    profile_secondary_form_prof_input_help_company_name: {
      eng: "Enter company name",
      rus: "Введите название компании",
      ukr: "Введіть назву компанії",
      deu: "Firmenname eingeben",
    },
    profile_secondary_form_prof_foundation_year: {
      eng: "Year of foundation of the company",
      rus: "Год основания компании",
      ukr: "Рік заснування компанії",
      deu: "Gründungsjahr des Unternehmens",
    },
    profile_secondary_form_prof_input_foundation_year: {
      eng: "Enter the year the company was founded",
      rus: "Введите год основания компании",
      ukr: "Введіть рік заснування компанії",
      deu: "Geben Sie das Gründungsjahr des Unternehmens ein",
    },
    profile_secondary_form_prof_input_help_foundation_year: {
      eng: "Enter the year the company was founded",
      rus: "Укажите год основания компании",
      ukr: "Вкажіть рік заснування компанії",
      deu: "Geben Sie das Gründungsjahr des Unternehmens ein",
    },
    profile_secondary_form_prof_experience: {
      eng: "Years of experience",
      rus: "Годы опыта",
      ukr: "Роки досвіду",
      deu: "Jahrelange Erfahrung",
    },
    profile_secondary_form_prof_input_experience: {
      eng: "Enter years of experience",
      rus: "Укажите годы опыта",
      ukr: "Вкажіть роки досвіду",
      deu: "Erfahrungsjahre eintragen",
    },
    profile_secondary_form_prof_input_help_experience: {
      eng: "Enter the number of years of experience in your field",
      rus: "Укажите количество лет опыта работы в вашей сфере",
      ukr: "Вкажіть кількість років досвіду роботи у вашій сфері",
      deu: "Geben Sie die Anzahl der Jahre Erfahrung in Ihrem Bereich ein",
    },
    profile_secondary_form_prof_company_number: {
      eng: "Company number",
      rus: "Номер компании",
      ukr: "Номер компанії",
      deu: "Firmennummer",
    },
    profile_secondary_form_prof_input_company_number: {
      eng: "Enter company number",
      rus: "Укажите номер компании",
      ukr: "Вкажіть номер компанії",
      deu: "Unternehmensnummer eingeben",
    },
    profile_secondary_form_prof_input_help_company_number: {
      eng: "Enter company number",
      rus: "Укажите номер компании",
      ukr: "Вкажіть номер компанії",
      deu: "Unternehmensnummer eingeben",
    },
    profile_secondary_form_prof_registration_number: {
      eng: "Registration number",
      rus: "Регистрационный номер",
      ukr: "Реєстраційний номер",
      deu: "Registrierungsnummer",
    },
    profile_secondary_form_prof_input_registration_number: {
      eng: "Enter registration number",
      rus: "Укажите регистрационный номер",
      ukr: "Вкажіть реєстраційний номер",
      deu: "Registriernummer eingeben",
    },
    profile_secondary_form_prof_input_help_registration_number: {
      eng: "Enter registration number",
      rus: "Укажите регистрационный номер",
      ukr: "Вкажіть реєстраційний номер",
      deu: "Registriernummer eingeben",
    },
    profile_secondary_form_prof_members_number: {
      eng: "Number of employees",
      rus: "Количество сотрудников",
      ukr: "Кількість співробітників",
      deu: "Anzahl der Mitarbeiter",
    },
    profile_secondary_form_prof_input_members_number: {
      eng: "Enter the number of employees",
      rus: "Укажите количество сотрудников",
      ukr: "Вкажіть кількість співробітників",
      deu: "Geben Sie die Anzahl der Mitarbeiter ein",
    },
    profile_secondary_form_prof_input_help_members_number: {
      eng: "Enter the number of employees",
      rus: "Укажите количество сотрудников",
      ukr: "Вкажіть кількість співробітників",
      deu: "Geben Sie die Anzahl der Mitarbeiter ein",
    },
    profile_secondary_form_prof_branches_number: {
      eng: "Number of branches",
      rus: "Количество филиалов",
      ukr: "Кількість філій",
      deu: "Anzahl der Filialen",
    },
    profile_secondary_form_prof_input_branches_number: {
      eng: "Enter the number of branches",
      rus: "Укажите количество филиалов",
      ukr: "Вкажіть кількість філій",
      deu: "Geben Sie die Anzahl der Zweige ein",
    },
    profile_secondary_form_prof_input_help_branches_number: {
      eng: "Enter the number of branches",
      rus: "Укажите количество филиалов",
      ukr: "Вкажіть кількість філій",
      deu: "Geben Sie die Anzahl der Zweige ein",
    },
    profile_secondary_form_prof_services: {
      eng: "Your services",
      rus: "Ваши сервисы",
      ukr: "Ваші сервіси",
      deu: "Ihre Dienste",
    },
    profile_secondary_form_prof_input_services: {
      eng: "Specify your services",
      rus: "Укажите ваши сервисы",
      ukr: "Вкажіть ваші сервіси",
      deu: "Spezifizieren Sie Ihre Dienste",
    },
    profile_secondary_form_prof_input_help_services: {
      eng: "Specify the services your company provides",
      rus: "Укажите услуги, которые предоставляет ваша компания",
      ukr: "Вкажіть послуги, які надає ваша компанія",
      deu: "Geben Sie die Dienstleistungen an, die Ihr Unternehmen anbietet",
    },
    profile_secondary_form_prof_clients: {
      eng: "Number of clients",
      rus: "Количество клиентов",
      ukr: "Кількість клієнтів",
      deu: "Anzahl der Kunden",
    },
    profile_secondary_form_prof_input_clients: {
      eng: "Enter the number of clients",
      rus: "Укажите количество клиентов",
      ukr: "Вкажіть кількість клієнтів",
      deu: "Geben Sie die Anzahl der Kunden ein",
    },
    profile_secondary_form_prof_input_help_clients: {
      eng: "Enter the number of clients",
      rus: "Укажите количество клиентов",
      ukr: "Вкажіть кількість клієнтів",
      deu: "Geben Sie die Anzahl der Kunden ein",
    },
    profile_secondary_form_prof_promocode: {
      eng: "Promo code",
      rus: "Промокод",
      ukr: "Промокод",
      deu: "Gutscheincode",
    },
    profile_secondary_form_prof_input_promocode: {
      eng: "Enter promo code",
      rus: "Укажите промокод",
      ukr: "Вкажіть промокод",
      deu: "Gutscheincode eingeben",
    },
    profile_secondary_form_prof_input_help_promocode: {
      eng: "If you have a registration promo code, enter it",
      rus: "Если у вас есть промокод на регистрацию, укажите его",
      ukr: "Якщо у вас є промокод на реєстрацію, вкажіть його",
      deu: "Wenn Sie einen Registrierungs-Promo-Code haben, geben Sie ihn ein",
    },
    profile_secondary_form_prof_check_spec: {
      eng: "Qualified Person",
      rus: "Квалифицированный специалист",
      ukr: "Кваліфікований спеціаліст",
      deu: "Qualifizierte Person",
    },
    profile_secondary_form_prof_check_spec_yes: {
      eng: "Yes",
      rus: "Да",
      ukr: "Так",
      deu: "Ja",
    },
    profile_secondary_form_prof_check_spec_no: {
      eng: "No",
      rus: "Нет",
      ukr: "Ні",
      deu: "Nein",
    },
    profile_secondary_form_prof_check_resident: {
      eng: "US tax resident",
      rus: "Налоговый резидент США",
      ukr: "Податковий резидент США",
      deu: "US-Steuerinländer",
    },
    profile_secondary_form_prof_check_resident_yes: {
      eng: "Yes",
      rus: "Да",
      ukr: "Так",
      deu: "Ja",
    },
    profile_secondary_form_prof_check_resident_no: {
      eng: "No",
      rus: "Нет",
      ukr: "Ні",
      deu: "Nein",
    },
    profile_secondary_form_prof_check_subject_law: {
      eng: "Subject of the right",
      rus: "Субъект права",
      ukr: "Суб'єкт права",
      deu: "Gegenstand des Rechts",
    },
    profile_secondary_form_prof_check_subject_law_person: {
      eng: "Individual",
      rus: "Физическое лицо",
      ukr: "Фізична особа",
      deu: "Individuell",
    },
    profile_secondary_form_prof_check_subject_law_company: {
      eng: "Company",
      rus: "Компания",
      ukr: "Компанія",
      deu: "Firma",
    },
    profile_secondary_form_prof_description: {
      eng: "Describe your intentions",
      rus: "Опишите ваши намерения",
      ukr: "Опишіть ваші наміри",
      deu: "Beschreiben Sie Ihre Absichten",
    },
    profile_secondary_form_prof_input_description: {
      eng: "Describe your intentions",
      rus: "Опишите ваши намерения",
      ukr: "Опишіть ваші наміри",
      deu: "Beschreiben Sie Ihre Absichten",
    },
    profile_secondary_form_prof_input_help_description: {
      eng: "Describe your intentions",
      rus: "Опишите ваши намерения",
      ukr: "Опишіть ваші наміри",
      deu: "Beschreiben Sie Ihre Absichten",
    },
    profile_secondary_form_prof_license_title: {
      eng: "2. Licenses",
      rus: "2. Лицензии",
      ukr: "2. Ліцензії",
      deu: "2. Lizenzen",
    },
    profile_secondary_form_prof_license_number: {
      eng: "License serial number",
      rus: "Серийный номер лицензии",
      ukr: "Серійний номер ліцензії",
      deu: "Lizenzseriennummer",
    },
    profile_secondary_form_prof_input_license_number: {
      eng: "Enter license serial number",
      rus: "Укажите серийный номер лицензии",
      ukr: "Вкажіть серійний номер ліцензії",
      deu: "Lizenzseriennummer eingeben",
    },
    profile_secondary_form_prof_input_help_license_number: {
      eng: "Enter license serial number",
      rus: "Укажите серийный номер лицензии",
      ukr: "Вкажіть серійний номер ліцензії",
      deu: "Lizenzseriennummer eingeben",
    },
    profile_secondary_form_prof_license_load: {
      eng: "Upload license scan",
      rus: "Загрузить скан лицензии",
      ukr: "Завантажити скан ліцензії",
      deu: "Lizenzscan hochladen",
    },
    profile_secondary_form_prof_license_add: {
      eng: "Add",
      rus: "Добавить",
      ukr: "Додати",
      deu: "Hinzufügen",
    },
    profile_secondary_form_prof_license_remove: {
      eng: "Delete",
      rus: "Удалить",
      ukr: "Видалити",
      deu: "Löschen",
    },
    profile_secondary_form_prof_control_title: {
      eng: "3. Control",
      rus: "3. Управление",
      ukr: "3. Управління",
      deu: "3. Kontrolle",
    },
    profile_secondary_form_prof_management_objects_quantity: {
      eng: "Number of control objects",
      rus: "Количество объектов управления",
      ukr: "Кількість об'єктів керування",
      deu: "Anzahl der Kontrollobjekte",
    },
    profile_secondary_form_prof_input_management_objects_quantity: {
      eng: "Specify the number of control objects",
      rus: "Укажите количество объектов управления",
      ukr: "Вкажіть кількість об'єктів керування",
      deu: "Geben Sie die Anzahl der Kontrollobjekte an",
    },
    profile_secondary_form_prof_input_help_management_objects_quantity: {
      eng: "Specify the number of control objects",
      rus: "Укажите количество объектов управления",
      ukr: "Вкажіть кількість об'єктів керування",
      deu: "Geben Sie die Anzahl der Kontrollobjekte an",
    },
    profile_secondary_form_prof_management_objects_value: {
      eng: "Value of objects in management (₼ equivalent)",
      rus: "Стоимость объектов в управлении (₼ эквивалент)",
      ukr: "Вартість об'єктів управління(₼ еквівалент)",
      deu: "Wert der verwalteten Objekte (₼-Gegenwert)",
    },
    profile_secondary_form_prof_input_management_objects_value: {
      eng: "Specify the value of objects in management (₼ equivalent)",
      rus: "Укажите стоимость объектов в управлении (₼ эквивалент)",
      ukr: "Вкажіть вартість об'єктів управління(₼ еквівалент)",
      deu: "Geben Sie den Wert der verwalteten Objekte an (₼-Gegenwert)",
    },
    profile_secondary_form_prof_input_help_management_objects_value: {
      eng: "Enter the value of the objects in management",
      rus: "Укажите стоимость объектов в управлении",
      ukr: "Вкажіть вартість об'єктів в управлінні",
      deu: "Geben Sie den Wert der verwalteten Objekte ein",
    },
    profile_secondary_form_prof_portfolio: {
      eng: "Object Portfolio",
      rus: "Портфолио объектов",
      ukr: "Портфоліо об'єктів",
      deu: "Objektportfolio",
    },
    profile_secondary_form_prof_input_portfolio: {
      eng: "Enter Portfolio of Objects",
      rus: "Укажите портфолио объектов",
      ukr: "Вкажіть портфоліо об'єктів",
      deu: "Portfolio von Objekten eingeben",
    },
    profile_secondary_form_prof_input_help_portfolio: {
      eng: "Enter Portfolio of Objects",
      rus: "Укажите портфолио объектов",
      ukr: "Вкажіть портфоліо об'єктів",
      deu: "Portfolio von Objekten eingeben",
    },
    profile_secondary_form_prof_own_tenant_base: {
      eng: "Having your own base of tenants",
      rus: "Наличие собственной базы арендаторов",
      ukr: "Наявність власної бази орендарів",
      deu: "Einen eigenen Mieterstamm haben",
    },
    profile_secondary_form_prof_input_own_tenant_base: {
      eng: "Having your own base of tenants",
      rus: "Наличие собственной базы арендаторов",
      ukr: "Наявність власної бази орендарів",
      deu: "Einen eigenen Mieterstamm haben",
    },
    profile_secondary_form_prof_input_help_own_tenant_base: {
      eng: "Having your own base of tenants",
      rus: "Наличие собственной базы арендаторов",
      ukr: "Наявність власної бази орендарів",
      deu: "Einen eigenen Mieterstamm haben",
    },
    profile_secondary_form_prof_contact_title: {
      eng: "Contact person",
      rus: "Контактное лицо",
      ukr: "Контактна особа",
      deu: "Ansprechpartner",
    },
    profile_secondary_form_prof_contact_fname: {
      eng: "Name",
      rus: "Имя",
      ukr: "Ім'я",
      deu: "Name",
    },
    profile_secondary_form_prof_input_contact_fname: {
      eng: "Enter name",
      rus: "Введите имя",
      ukr: "Введіть ім'я",
      deu: "Namen eingeben",
    },
    profile_secondary_form_prof_input_help_contact_fname: {
      eng: "Enter name",
      rus: "Введите имя",
      ukr: "Введіть ім'я",
      deu: "Namen eingeben",
    },
    profile_secondary_form_prof_contact_lname: {
      eng: "Last name",
      rus: "Фамилия",
      ukr: "Прізвище",
      deu: "Nachname",
    },
    profile_secondary_form_prof_input_contact_lname: {
      eng: "Enter last name",
      rus: "Введите фамилию",
      ukr: "Введіть прізвище",
      deu: "Nachnamen eingeben",
    },
    profile_secondary_form_prof_input_help_contact_lname: {
      eng: "Enter last name",
      rus: "Введите фамилию",
      ukr: "Введіть прізвище",
      deu: "Nachnamen eingeben",
    },
    profile_secondary_form_prof_contact_position: {
      eng: "Position",
      rus: "Должность",
      ukr: "Посада",
      deu: "Position",
    },
    profile_secondary_form_prof_input_contact_position: {
      eng: "Specify position",
      rus: "Укажите должность",
      ukr: "Вкажіть посаду",
      deu: "Position angeben",
    },
    profile_secondary_form_prof_input_help_contact_position: {
      eng: "Specify position",
      rus: "Укажите должность",
      ukr: "Вкажіть посаду",
      deu: "Position angeben",
    },
    profile_secondary_form_prof_contact_phone: {
      eng: "Phone number",
      rus: "Номер телефона",
      ukr: "Номер телефону",
      deu: "Telefonnummer",
    },
    profile_secondary_form_prof_input_contact_phone: {
      eng: "Enter phone number",
      rus: "Введите номер телефона",
      ukr: "Введіть номер телефону",
      deu: "Telefonnummer eingeben",
    },
    profile_secondary_form_prof_input_help_contact_phone: {
      eng: "Enter phone number",
      rus: "Введите номер телефона",
      ukr: "Введіть номер телефону",
      deu: "Telefonnummer eingeben",
    },
    profile_secondary_form_prof_contact_email: {
      eng: "Email",
      rus: "Email",
      ukr: "Email",
      deu: "E-Mail",
    },
    profile_secondary_form_prof_input_contact_email: {
      eng: "Enter email",
      rus: "Введите email",
      ukr: "Введіть email",
      deu: "E-Mail eingeben",
    },
    profile_secondary_form_prof_input_help_contact_email: {
      eng: "Enter email",
      rus: "Введите email",
      ukr: "Введіть email",
      deu: "E-Mail eingeben",
    },
    profile_secondary_form_finance: {
      eng: "Financials",
      rus: "Финансовые показатели",
      ukr: "Фінансові показники",
      deu: "Finanzen",
    },
    profile_secondary_form_finance_completed_projects: {
      eng: "Number of completed projects",
      rus: "Количество завершенных проектов",
      ukr: "Кількість завершених проектів",
      deu: "Anzahl abgeschlossener Projekte",
    },
    profile_secondary_form_finance_input_completed_projects: {
      eng: "Enter the number of completed projects",
      rus: "Укажите количество завершенных проектов",
      ukr: "Вкажіть кількість завершених проектів",
      deu: "Geben Sie die Anzahl abgeschlossener Projekte ein",
    },
    profile_secondary_form_finance_input_help_completed_projects: {
      eng: "Enter the number of completed projects",
      rus: "Укажите количество завершенных проектов",
      ukr: "Вкажіть кількість завершених проектів",
      deu: "Geben Sie die Anzahl abgeschlossener Projekte ein",
    },
    profile_secondary_form_finance_biggest_projects: {
      eng: "Name of the largest completed project",
      rus: "Название крупнейшего завершенного проекта",
      ukr: "Назва найбільшого завершеного проекту",
      deu: "Name des größten abgeschlossenen Projekts",
    },
    profile_secondary_form_finance_input_biggest_projects: {
      eng: "Enter the name of the largest completed project",
      rus: "Укажите название крупнейшего завершенного проекта",
      ukr: "Вкажіть назву найбільшого завершеного проекту",
      deu: "Geben Sie den Namen des größten abgeschlossenen Projekts ein",
    },
    profile_secondary_form_finance_input_help_biggest_projects: {
      eng: "Name of the largest completed project",
      rus: "Название крупнейшего завершенного проекта",
      ukr: "Назва найбільшого завершеного проекту",
      deu: "Name des größten abgeschlossenen Projekts",
    },
    profile_secondary_form_finance_total_constructed: {
      eng: "Total built area, square meters",
      rus: "Общая площадь построенного, квадратные метры",
      ukr: "Загальна площа збудованого, квадратні метри",
      deu: "Gesamt bebaute Fläche, Quadratmeter",
    },
    profile_secondary_form_finance_input_total_constructed: {
      eng: "Enter the total built area, square meters",
      rus: "Укажите общую площадь построенного, квадратные метры",
      ukr: "Вкажіть загальну площу збудованого, квадратні метри",
      deu: "Geben Sie die bebaute Gesamtfläche ein, Quadratmeter",
    },
    profile_secondary_form_finance_input_help_total_constructed: {
      eng: "Enter the total built area, square meters",
      rus: "Укажите общую площадь построенного, квадратные метры",
      ukr: "Вкажіть загальну площу збудованого, квадратні метри",
      deu: "Geben Sie die bebaute Gesamtfläche ein, Quadratmeter",
    },
    profile_secondary_form_finance_own_working_capital: {
      eng: "Private working capital (₼ equivalent)",
      rus: "Частный оборотный капитал (₼ эквивалент)",
      ukr: "Приватний оборотний капітал (₼ еквівалент)",
      deu: "Privates Betriebskapital (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_own_working_capital: {
      eng: "Enter private working capital (₼ equivalent)",
      rus: "Укажите частный оборотный капитал (₼ эквивалент)",
      ukr: "Вкажіть приватний оборотний капітал (₼ еквівалент)",
      deu: "Privates Betriebskapital eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_own_working_capital: {
      eng: "Enter private working capital (₼ equivalent)",
      rus: "Укажите частный оборотный капитал (₼ эквивалент)",
      ukr: "Вкажіть приватний оборотний капітал (₼ еквівалент)",
      deu: "Privates Betriebskapital eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_total_assets: {
      eng: "Total assets (₼ equivalent)",
      rus: "Общая сумма активов (₼ эквивалент)",
      ukr: "Загальна сума активів (₼ еквівалент)",
      deu: "Gesamtvermögen (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_total_assets: {
      eng: "Enter the total amount of assets (₼ equivalent)",
      rus: "Укажите общую сумму активов (₼ эквивалент)",
      ukr: "Вкажіть загальну суму активів (₼ еквівалент)",
      deu: "Geben Sie den Gesamtbetrag des Vermögens ein (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_total_assets: {
      eng: "Enter the total amount of assets (₼ equivalent)",
      rus: "Укажите общую сумму активов (₼ эквивалент)",
      ukr: "Вкажіть загальну суму активів (₼ еквівалент)",
      deu: "Geben Sie den Gesamtbetrag des Vermögens ein (₼-Gegenwert)",
    },
    profile_secondary_form_finance_retained_earnings: {
      eng: "Retained earnings (₼ equivalent)",
      rus: "Нераспределенная прибыль (₼ эквивалент)",
      ukr: "Нерозподілений прибуток (₼ еквівалент)",
      deu: "Gewinnrücklagen (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_retained_earnings: {
      eng: "Enter retained earnings (₼ equivalent)",
      rus: "Укажите нераспределенную прибыль (₼ эквивалент)",
      ukr: "Вкажіть нерозподілений прибуток (₼ еквівалент)",
      deu: "Einbehaltene Gewinne eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_retained_earnings: {
      eng: "Enter retained earnings (₼ equivalent)",
      rus: "Укажите нераспределенную прибыль (₼ эквивалент)",
      ukr: "Вкажіть нерозподілений прибуток (₼ еквівалент)",
      deu: "Einbehaltene Gewinne eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_earnings_before_tax: {
      eng: "Earnings before interest and taxes (₼ equivalent)",
      rus: "Прибыль до вычета процентов и налогов (₼ эквивалент)",
      ukr: "Прибуток до вирахування відсотків та податків (₼ еквівалент)",
      deu: "Ergebnis vor Zinsen und Steuern (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_earnings_before_tax: {
      eng: "Enter earnings before interest and taxes (₼ equivalent)",
      rus: "Укажите прибыль до вычета процентов и налогов (₼ эквивалент)",
      ukr: "Вкажіть прибуток до вирахування відсотків та податків (₼ еквівалент)",
      deu: "Gewinn vor Zinsen und Steuern eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_earnings_before_tax: {
      eng: "Enter earnings before interest and taxes (₼ equivalent)",
      rus: "Укажите прибыль до вычета процентов и налогов (₼ эквивалент)",
      ukr: "Вкажіть прибуток до вирахування відсотків та податків (₼ еквівалент)",
      deu: "Gewinn vor Zinsen und Steuern eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_market_value_equity: {
      eng: "Market value of capital (₼ equivalent)",
      rus: "Рыночная стоимость капитала (₼ эквивалент)",
      ukr: "Ринкова вартість капіталу (₼ еквівалент)",
      deu: "Marktwert des Kapitals (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_market_value_equity: {
      eng: "Specify the market value of capital (₼ equivalent)",
      rus: "Укажите рыночную стоимость капитала (₼ эквивалент)",
      ukr: "Вкажіть ринкову вартість капіталу (₼ еквівалент)",
      deu: "Geben Sie den Marktwert des Kapitals an (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_market_value_equity: {
      eng: "Specify the market value of capital (₼ equivalent)",
      rus: "Укажите рыночную стоимость капитала (₼ эквивалент)",
      ukr: "Вкажіть ринкову вартість капіталу (₼ еквівалент)",
      deu: "Geben Sie den Marktwert des Kapitals an (₼-Gegenwert)",
    },
    profile_secondary_form_finance_book_value: {
      eng: "Carrying value of total liabilities (₼ equivalent)",
      rus: "Балансовая стоимость совокупных обязательств (₼ эквивалент)",
      ukr: "Балансова вартість сукупних зобов'язань(₼ еквівалент)",
      deu: "Buchwert der gesamten Verbindlichkeiten (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_book_value: {
      eng: "Enter the carrying amount of the total liabilities (₼ equivalent)",
      rus: "Укажите балансовую стоимость совокупных обязательств (₼ эквивалент)",
      ukr: "Вкажіть балансову вартість сукупних зобов'язань(₼ еквівалент)",
      deu: "Geben Sie den Buchwert der gesamten Verbindlichkeiten ein (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_book_value: {
      eng: "Enter the carrying amount of the total liabilities (₼ equivalent)",
      rus: "Укажите балансовую стоимость совокупных обязательств (₼ эквивалент)",
      ukr: "Вкажіть балансову вартість сукупних зобов'язань(₼ еквівалент)",
      deu: "Geben Sie den Buchwert der gesamten Verbindlichkeiten ein (₼-Gegenwert)",
    },
    profile_secondary_form_finance_sales: {
      eng: "Profit before tax (₼ equivalent)",
      rus: "Прибыль до налогообложения (₼ эквивалент)",
      ukr: "Прибуток оподаткування (₼ еквівалент)",
      deu: "Gewinn vor Steuern (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_sales: {
      eng: "Specify profit before tax (₼ equivalent)",
      rus: "Укажите прибыль до налогообложения (₼ эквивалент)",
      ukr: "Вкажіть прибуток до оподаткування (₼ еквівалент)",
      deu: "Gewinn vor Steuern angeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_sales: {
      eng: "Specify profit before tax (₼ equivalent)",
      rus: "Укажите прибыль до налогообложения (₼ эквивалент)",
      ukr: "Вкажіть прибуток до оподаткування (₼ еквівалент)",
      deu: "Gewinn vor Steuern angeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_current_obligations: {
      eng: "Current liabilities (₼ equivalent)",
      rus: "Текущие обязательства (₼ эквивалент)",
      ukr: "Поточні зобов'язання(₼ еквівалент)",
      deu: "Kurzfristige Verbindlichkeiten (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_current_obligations: {
      eng: "Specify current liabilities (₼ equivalent)",
      rus: "Укажите текущие обязательства (₼ эквивалент)",
      ukr: "Вкажіть поточні зобов'язання (₼ еквівалент)",
      deu: "Kurzfristige Verbindlichkeiten angeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_current_obligations: {
      eng: "Specify current liabilities (₼ equivalent)",
      rus: "Укажите текущие обязательства (₼ эквивалент)",
      ukr: "Вкажіть поточні зобов'язання(₼ еквівалент)",
      deu: "Kurzfristige Verbindlichkeiten angeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_amount_obligations: {
      eng: "Amount of obligations (₼ equivalent)",
      rus: "Сумма обязательств (₼ эквивалент)",
      ukr: "Сума зобов'язань(₼ еквівалент)",
      deu: "Betrag der Verpflichtungen (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_amount_obligations: {
      eng: "Enter the amount of the obligation (₼ equivalent)",
      rus: "Укажите сумму обязательств (₼ эквивалент)",
      ukr: "Вкажіть суму зобов' язань(₼ еквівалент)",
      deu: "Geben Sie den Verpflichtungsbetrag ein (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_amount_obligations: {
      eng: "Enter the amount of the obligation (₼ equivalent)",
      rus: "Укажите сумму обязательств (₼ эквивалент)",
      ukr: "Вкажіть суму зобов'язань(₼ еквівалент)",
      deu: "Geben Sie den Verpflichtungsbetrag ein (₼-Gegenwert)",
    },
    profile_secondary_form_finance_total_current_assets: {
      eng: "Total for current assets (₼ equivalent)",
      rus: "Итог по оборотным активам (₼ эквивалент)",
      ukr: "Підсумок оборотних активів (₼ еквівалент)",
      deu: "Summe des Umlaufvermögens (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_total_current_assets: {
      eng: "Indicate the total for current assets (₼ equivalent)",
      rus: "Укажите итог по оборотным активам (₼ эквивалент)",
      ukr: "Вкажіть результат за оборотними активами (₼ еквівалент)",
      deu: "Geben Sie den Gesamtbetrag für das Umlaufvermögen an (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_total_current_assets: {
      eng: "Indicate the total for current assets (₼ equivalent)",
      rus: "Укажите итог по оборотным активам (₼ эквивалент)",
      ukr: "Вкажіть результат за оборотними активами (₼ еквівалент)",
      deu: "Geben Sie den Gesamtbetrag für das Umlaufvermögen an (₼-Gegenwert)",
    },
    profile_secondary_form_finance_total_current_liabilities: {
      eng: "Total current liabilities (₼ equivalent)",
      rus: "Итог по краткосрочным обязательствам (₼ эквивалент)",
      ukr: "Підсумок щодо короткострокових зобов'язань(₼ еквівалент)",
      deu: "Summe der kurzfristigen Verbindlichkeiten (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_total_current_liabilities: {
      eng: "Indicate the total for short-term liabilities (₼ equivalent)",
      rus: "Укажите итог по краткосрочным обязательствам (₼ эквивалент)",
      ukr: "Вкажіть результат за короткостроковими зобов'язаннями(₼ еквівалент)",
      deu: "Geben Sie den Gesamtbetrag für kurzfristige Verbindlichkeiten an (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_total_current_liabilities: {
      eng: "Indicate the total for short-term liabilities (₼ equivalent)",
      rus: "Укажите итог по краткосрочным обязательствам (₼ эквивалент)",
      ukr: "Вкажіть результат за короткостроковими зобов'язаннями(₼ еквівалент)",
      deu: "Geben Sie den Gesamtbetrag für kurzfristige Verbindlichkeiten an (₼-Gegenwert)",
    },
    profile_secondary_form_finance_net_income_before_taxes: {
      eng: "Net profit before tax (₼ equivalent)",
      rus: "Чистая прибыль до налогообложения (₼ эквивалент)",
      ukr: "Чистий прибуток до оподаткування (₼ еквівалент)",
      deu: "Nettogewinn vor Steuern (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_net_income_before_taxes: {
      eng: "Enter net income before tax (₼ equivalent)",
      rus: "Укажите чистую прибыль до налогообложения (₼ эквивалент)",
      ukr: "Вкажіть чистий прибуток до оподаткування (₼ еквівалент)",
      deu: "Nettoeinkommen vor Steuern eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_finance_input_help_net_income_before_taxes: {
      eng: "Enter net income before tax (₼ equivalent)",
      rus: "Укажите чистую прибыль до налогообложения (₼ эквивалент)",
      ukr: "Вкажіть чистий прибуток до оподаткування (₼ еквівалент)",
      deu: "Nettoeinkommen vor Steuern eingeben (₼-Gegenwert)",
    },
    profile_secondary_form_license: {
      eng: "License and education",
      rus: "Лицензия и образование",
      ukr: "Ліцензія та освіта",
      deu: "Lizenz und Ausbildung",
    },
    properties_list_item_selled: {
      eng: "Sold out",
      rus: "Продано",
      ukr: "Продано",
      deu: "Ausverkauft",
    },
    properties_list_item_type: {
      eng: "Type",
      rus: "Тип",
      ukr: "Тип",
      deu: "Typ",
    },
    properties_list_item_sqm: {
      eng: "sq. m",
      rus: "кв. м",
      ukr: "кв. м",
      deu: "sq. m",
    },
    properties_list_item_floor: {
      eng: "Floor",
      rus: "Этаж",
      ukr: "Поверх",
      deu: "Boden",
    },
    properties_list_item_more: {
      eng: "Details",
      rus: "Подробнее",
      ukr: "Докладніше",
      deu: "Details",
    },
    properties_list_item_edit: {
      eng: "Edit",
      rus: "Редактировать",
      ukr: "Редагувати",
      deu: "Bearbeiten",
    },
    projects_list_item_type: {
      eng: "Type",
      rus: "Тип",
      ukr: "Тип",
      deu: "Typ",
    },
    projects_list_item_payback: {
      eng: "Payback period",
      rus: "Срок окупаемости",
      ukr: "Термін окупності",
      deu: "Amortisationszeit",
    },
    projects_list_item_total_investment: {
      eng: "Total invested",
      rus: "Всего вложено",
      ukr: "Усього вкладено",
      deu: "Gesamtinvestition",
    },
    projects_list_item_more: {
      eng: "Details",
      rus: "Подробнее",
      ukr: "Докладніше",
      deu: "Details",
    },
    properties_list_title: {
      eng: "Property Overview",
      rus: "Обзор объектов недвижимости",
      ukr: "Огляд об'єктів нерухомості",
      deu: "Eigenschaftsübersicht",
    },
    properties_list_button_add: {
      eng: "Add property",
      rus: "Добавить объект недвижимости",
      ukr: "Додати об'єкт нерухомості",
      deu: "Eigenschaft hinzufügen",
    },
    properties_list_button_show_all: {
      eng: "All properties",
      rus: "Вся недвижимость",
      ukr: "Вся нерухомість",
      deu: "Alle Eigenschaften",
    },
    properties_list_button_show_my: {
      eng: "My property",
      rus: "Моя недвижимость",
      ukr: "Моя нерухомість",
      deu: "Mein Eigentum",
    },
    properties_list_filter_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    properties_list_filter_name: {
      eng: "Property name",
      rus: "Название объекта",
      ukr: "Назва об'єкта",
      deu: "Eigenschaftsname",
    },
    properties_list_filter_input_name: {
      eng: "Enter property name",
      rus: "Введите назване объекта",
      ukr: "Введіть назву об'єкта",
      deu: "Eigenschaftsnamen eingeben",
    },
    properties_list_filter_price: {
      eng: "Enter price",
      rus: "Введите цену",
      ukr: "Введіть ціну",
      deu: "Preis eingeben",
    },
    properties_list_filter_price_from: {
      eng: "Price from",
      rus: "Цена от",
      ukr: "Ціна від",
      deu: "Preis ab",
    },
    properties_list_filter_price_to: {
      eng: "Price to",
      rus: "Цена до",
      ukr: "Ціна до",
      deu: "Preis bis",
    },
    properties_list_filter_area: {
      eng: "Enter area",
      rus: "Введите площадь",
      ukr: "Введіть площу",
      deu: "Gebiet eingeben",
    },
    properties_list_filter_area_from: {
      eng: "Area from",
      rus: "Площадь от",
      ukr: "Площа від",
      deu: "Gebiet von",
    },
    properties_list_filter_area_to: {
      eng: "Area to",
      rus: "Площадь до",
      ukr: "Площа до",
      deu: "Bereich bis",
    },
    properties_list_filter_type: {
      eng: "Property type",
      rus: "Тип недвижимости",
      ukr: "Тип нерухомості",
      deu: "Eigenschaftstyp",
    },
    properties_list_filter_search: {
      eng: "Search",
      rus: "Поиск",
      ukr: "Пошук",
      deu: "Suche",
    },
    properties_list_not_found: {
      eng: "No objects found",
      rus: "Объекты не найдены",
      ukr: "Об'єкти не знайдено",
      deu: "Keine Objekte gefunden",
    },
    properties_complex_list_not_found: {
      eng: "Nothing was found for your request",
      rus: "По вашему запросу ничего не найдено",
      ukr: "За вашим запитом нічого не знайдено",
      deu: "Für Ihre Anfrage wurde nichts gefunden",
    },
    projects_list_title: {
      eng: "Project Overview",
      rus: "Обзор проектов",
      ukr: "Огляд проектів",
      deu: "Projektübersicht",
    },
    projects_list_button_add: {
      eng: "Add project",
      rus: "Добавить проект",
      ukr: "Додати проект",
      deu: "Projekt hinzufügen",
    },
    projects_list_button_all: {
      eng: "All projects",
      rus: "Все проекты",
      ukr: "Всі проекти",
      deu: "Alle Projekte",
    },
    projects_list_button_my: {
      eng: "My projects",
      rus: "Мои проекты",
      ukr: "Мої проекти",
      deu: "Meine Projekte",
    },
    filter_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    filter_name: {
      eng: "Project name",
      rus: "Название проекта",
      ukr: "Назва проекту",
      deu: "Projektname",
    },
    filter_input_name: {
      eng: "Enter project name",
      rus: "Введите назване проекта",
      ukr: "Введіть назву проекту",
      deu: "Projektname eingeben",
    },
    filter_search: {
      eng: "Search",
      rus: "Поиск",
      ukr: "Пошук",
      deu: "Suche",
    },
    projects_list_not_found: {
      eng: "Nothing was found for your request",
      rus: "По вашему запросу ничего не найдено",
      ukr: "За вашим запитом нічого не знайдено",
      deu: "Für Ihre Anfrage wurde nichts gefunden",
    },
    community_list_item_more: {
      eng: "More",
      rus: "Подробнее",
      ukr: "Докладніше",
      deu: "Mehr",
    },
    community_list_title: {
      eng: "Community",
      rus: "Сообщество",
      ukr: "Спільнота",
      deu: "Gemeinschaft",
    },
    community_list_filter_name: {
      eng: "Name",
      rus: "Имя",
      ukr: "Ім'я",
      deu: "Name",
    },
    community_list_filter_input_name: {
      eng: "Enter name",
      rus: "Введите имя",
      ukr: "Введіть ім'я",
      deu: "Namen eingeben",
    },
    community_list_filter_country: {
      eng: "Country of business",
      rus: "Страна ведения бизнеса",
      ukr: "Країна ведення бізнесу",
      deu: "Geschäftsland",
    },
    community_list_filter_types: {
      eng: "Types",
      rus: "Роль",
      ukr: "Роль",
      deu: "Typen",
    },
    community_list_filter_search: {
      eng: "Search",
      rus: "Поиск",
      ukr: "Пошук",
      deu: "Suche",
    },
    community_list_not_found: {
      eng: "Nothing was found for your request",
      rus: "По вашему запросу ничего не найдено",
      ukr: "За вашим запитом нічого не знайдено",
      deu: "Für Ihre Anfrage wurde nichts gefunden",
    },
    community_item_tab_main: {
      eng: "Main",
      rus: "Главная",
      ukr: "Головна",
      deu: "Haupt",
    },
    community_item_tab_education: {
      eng: "Education & license",
      rus: "Образование и лицензия",
      ukr: "Освіта та ліцензія",
      deu: "Bildung & Lizenz",
    },
    community_item_tab_properties: {
      eng: "Properties",
      rus: "Объекты недвижимости",
      ukr: "Об'єкти нерухомості",
      deu: "Eigenschaften",
    },
    community_item_tab_projects: {
      eng: "Projects",
      rus: "Проекты",
      ukr: "Проекти",
      deu: "Projekte",
    },
    community_item_tab_requests: {
      eng: "Requests",
      rus: "Заявки",
      ukr: "Заявки",
      deu: "Anwendungen",
    },
    community_item_main_last_activity: {
      eng: "Last activity",
      rus: "Последняя активность",
      ukr: "Остання активність",
      deu: "Letzte Aktivität",
    },
    community_item_main_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    community_item_main_send_message: {
      eng: "Send message",
      rus: "Отправить сообщение",
      ukr: "Надіслати повідомлення",
      deu: "Nachricht senden",
    },
    news_list_title: {
      eng: "News",
      rus: "Новости",
      ukr: "Новини",
      deu: "Neuigkeiten",
    },
    news_list_social_title: {
      eng: "Read us in social:",
      rus: "Читайте нас в социальных сетях:",
      ukr: "Читайте нас у соціальних мережах:",
      deu: "Lesen Sie uns in sozialen Netzwerken:",
    },
    news_list_tab_news: {
      eng: "News",
      rus: "Новости",
      ukr: "Новини",
      deu: "Neuigkeiten",
    },
    news_list_tab_blog: {
      eng: "Blog",
      rus: "Блог",
      ukr: "Блог",
      deu: "Blog",
    },
    news_news_filter_title: {
      eng: "News search",
      rus: "Поиск новостей",
      ukr: "Пошук новин",
      deu: "Nachrichtensuche",
    },
    news_news_filter_find: {
      eng: "Find",
      rus: "Найти",
      ukr: "Знайти",
      deu: "Finden",
    },
    news_news_not_found: {
      eng: "Nothing was found for your request",
      rus: "По вашему запросу ничего не найдено",
      ukr: "За вашим запитом нічого не знайдено",
      deu: "Für Ihre Anfrage wurde nichts gefunden",
    },
    news_news_last_title: {
      eng: "Last news",
      rus: "Последние новости",
      ukr: "Останні новини",
      deu: "Letzte Nachricht",
    },
    news_news_all_title: {
      eng: "All news",
      rus: "Все новости",
      ukr: "Всі новини",
      deu: "Alle Nachrichten",
    },
    news_blog_filter_title: {
      eng: "Blog search",
      rus: "Поиск по блогам",
      ukr: "Пошук блогів",
      deu: "Blog-Suche",
    },
    news_blog_filter_find: {
      eng: "Find",
      rus: "Найти",
      ukr: "Знайти",
      deu: "Finden",
    },
    news_blog_not_found: {
      eng: "Nothing was found for your request",
      rus: "По вашему запросу ничего не найдено",
      ukr: "За вашим запитом нічого не знайдено",
      deu: "Für Ihre Anfrage wurde nichts gefunden",
    },
    news_blog_all_title: {
      eng: "All blogs",
      rus: "Все блоги",
      ukr: "Всі блоги",
      deu: "Alle Blogs",
    },
    news_blog_last_title: {
      eng: "Last blogs",
      rus: "Последние блоги",
      ukr: "Останні блоги",
      deu: "Letzte Blogs",
    },
    modal_copy_success: {
      eng: "Copied successfully",
      rus: "Скопировано успешно",
      ukr: "Скопійовано успішно",
      deu: "Erfolgreich kopiert",
    },
    modal_title_wallet: {
      eng: "Your wallet address",
      rus: "Адрес вашего кошелька",
      ukr: "Адреса вашого гаманця",
      deu: "Ihre Brieftaschenadresse",
    },
    modal_title_contract: {
      eng: "Contract address",
      rus: "Адрес контракта",
      ukr: "Адреса контракту",
      deu: "Vertragsadresse",
    },
    my_address_in_contract_translation: {
      eng: "My address in the contract",
      rus: "Мой адрес в контракте",
      ukr: "Моя адреса в контракті",
      deu: "Meine Adresse im Vertrag",
    },
    total_m2c_translation: {
      eng: "Total M2C",
      rus: "Всего M2C",
      ukr: "Загальний M2C",
      deu: "Gesamt M2C",
    },
    modal_title_deposit_address: {
      eng: "Deposit address",
      rus: "Адрес депозита",
      ukr: "Адреса депозиту",
      deu: "Die Einzahlungsadresse",
    },
    modal_title_deposit_memo: {
      eng: "Memo",
      rus: "Memo",
      ukr: "Memo",
      deu: "Memo",
    },
    projects_add_common_landing: {
      eng: "Desired subdomain",
      rus: "Желаемый субдомен",
      ukr: "Бажаний субдомен",
      deu: "Gewünschte Subdomain",
    },
    projects_add_common_landing_placeholder: {
      eng: "Desired subdomain",
      rus: "Желаемый субдомен",
      ukr: "Бажаний субдомен",
      deu: "Gewünschte Subdomain",
    },
    projects_add_common_landing_helper: {
      eng: "For each new investment project REM presents a personal landing page",
      rus: "Для каждого нового инвестиционного проекта REM предостовляет персональную посадочную страницу",
      ukr: "Для кожного нового інвестиційного проекту REM надає персональну посадочну сторінку",
      deu: "Für jedes neue Investitionsprojekt präsentiert REM eine persönliche Landingpage",
    },
    projects_add_max_photo: {
      eng: "You have uploaded the maximum number of photos!",
      rus: "Вы загрузили максимальное количество изображений!",
      ukr: "Ви завантажили максимальну кількість зображень!",
      deu: "Sie haben die maximale Anzahl von Fotos hochgeladen!",
    },
    Key_not_founded: {
      eng: "Error: Text not found",
      rus: "Ошибка: Текст не найден",
      ukr: "Помилка: Текст не знайдено",
      deu: "Fehler: Text nicht gefunden",
    },
    projects_add_common_title: {
      eng: "Common properties",
      rus: "Общие свойства",
      ukr: "Загальні властивості",
      deu: "Allgemeine Eigenschaften",
    },
    projects_add_common_stage: {
      eng: "Construction stage:",
      rus: "Стадия строительства:",
      ukr: "Етап будівництва:",
      deu: "Bauabschnitt:",
    },
    projects_add_common_stage_placeholder: {
      eng: "Enter % construction phase",
      rus: "Введите % стадии строительства",
      ukr: "Введіть % етапу будівництва",
      deu: "Prozentuale Bauphasen",
    },
    projects_add_common_name: {
      eng: "Name of project",
      rus: "Название проекта",
      ukr: "Назва проекту",
      deu: "Projektname",
    },
    projects_add_common_name_placeholder: {
      eng: "Enter an official name of the project",
      rus: "Введите официальное название проекта",
      ukr: "Введіть офіційну назву проекту",
      deu: "Geben Sie einen offiziellen Namen des Projekts ein",
    },

    projects_add_common_company: {
      eng: "Construction company",
      rus: "Строительная компания",
      ukr: "Будівельна компанія",
      deu: "Bauunternehmen",
    },
    projects_add_common_company_placeholder: {
      eng: "Construction company",
      rus: "Строительная компания",
      ukr: "Будівельна компанія",
      deu: "Bauunternehmen",
    },
    projects_add_common_author: {
      eng: "Author of the project",
      rus: "Автор проекта",
      ukr: "Автор проекту",
      deu: "Autor des Projekts",
    },
    projects_add_common_author_placeholder: {
      eng: "Enter the author(s) of the project",
      rus: "Введите автора/ов проекта",
      ukr: "Введіть автора/ів проекту",
      deu: "Geben Sie den/die Autor(en) des Projekts ein",
    },
    projects_add_common_architects: {
      eng: "The main architects of the project",
      rus: "Основные архитекторы проекта",
      ukr: "Основні архітектори проекту",
      deu: "Die Hauptarchitekten des Projekts",
    },
    projects_add_common_architects_placeholder: {
      eng: "Write the name(s) of the main architect(s) of the project",
      rus: "Напишите имя (имена) основных архитекторов проекта",
      ukr: "Напишіть ім'я (імена) головних архітекторів проекту",
      deu: "Schreiben Sie den/die Namen des/der Hauptarchitekten des Projekts auf",
    },
    projects_add_common_concept: {
      eng: "Concept",
      rus: "Концепция",
      ukr: "Концепція",
      deu: "Konzept",
    },
    projects_add_common_advantages: {
      eng: "Advantages of the project",
      rus: "Преимущества проекта",
      ukr: "Переваги проекту",
      deu: "Vorteile des Projekts",
    },
    projects_add_common_efficiency: {
      eng: "Social efficiency",
      rus: "Социальная эффективность",
      ukr: "Соціальна ефективність",
      deu: "Soziale Effizienz",
    },
    projects_add_common_country: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    projects_add_common_map_placeholder: {
      eng: "Auto-select country from map",
      rus: "Автоматический выбор страны с карты",
      ukr: "Автоматичний вибір країни з карти",
      deu: "Automatische Auswahl des Landes aus der Karte",
    },
    projects_add_common_map_street_placeholder: {
      eng: "Auto-select from map",
      rus: "Автоматический выбор с карты",
      ukr: "Автоматичний вибір з карти",
      deu: "Automatische Auswahl aus der Karte",
    },
    projects_add_common_city: {
      eng: "City",
      rus: "Город",
      ukr: "Місто",
      deu: "Stadt",
    },
    projects_add_common_district_placeholder: {
      eng: "District",
      rus: "Район",
      ukr: "Район",
      deu: "Stadtteil",
    },
    projects_add_common_district: {
      eng: "District",
      rus: "Район",
      ukr: "Район",
      deu: "Stadtteil",
    },
    projects_add_common_street: {
      eng: "Street",
      rus: "Улица",
      ukr: "Вулиця",
      deu: "Straße",
    },
    projects_add_common_house: {
      eng: "House number",
      rus: "Номер дома",
      ukr: "Номер будинку",
      deu: "Hausnummer",
    },
    projects_add_common_house_placeholder: {
      eng: "House number",
      rus: "Номер дома",
      ukr: "Номер будинку",
      deu: "Hausnummer",
    },
    projects_add_technical_type: {
      eng: "Type of property",
      rus: "Тип недвижимости",
      ukr: "Тип нерухомості",
      deu: "Art der Immobilie",
    },
    projects_add_technical_storeys: {
      eng: "Number of storeys",
      rus: "Количество этажей",
      ukr: "Кількість поверхів",
      deu: "Anzahl der Stockwerke",
    },
    projects_add_technical_storeys_placeholder: {
      eng: "Total number of storeys in the building",
      rus: "Общее количество этажей в здании",
      ukr: "Загальна кількість поверхів у будівлі",
      deu: "Gesamtzahl der Etagen im Gebäude",
    },
    projects_add_technical_term: {
      eng: "Construction completion term",
      rus: "Срок завершения строительства",
      ukr: "Термін завершення будівництва",
      deu: "Bauabschlussfrist",
    },
    projects_add_technical_term_help: {
      eng: "Specify the completion date of construction",
      rus: "Укажите срок завершения строительства",
      ukr: "Вкажіть термін завершення будівництва",
      deu: "Geben Sie das Fertigstellungsdatum des Baus an",
    },
    projects_add_technical_area_plot: {
      eng: "Land plot area (sq.m.)",
      rus: "Площадь земельного участка (кв. м)",
      ukr: "Площа земельної ділянки (кв. м)",
      deu: "Grundstücksfläche (qm)",
    },
    projects_add_technical_area_plot_placeholder: {
      eng: "The total number of square meters of the land plot that will be at your disposal",
      rus: "Общее количество квадратных метров земельного участка, которым вы сможете распорядиться",
      ukr: "Загальна кількість квадратних метрів земельної ділянки, якою ви матимете в своєму розпорядженні",
      deu: "Die Gesamtzahl der Quadratmeter des Grundstücks, die Ihnen zur Verfügung stehen werden",
    },
    projects_add_technical_area_effective: {
      eng: "Effective area (sq.m.)",
      rus: "Эффективная площадь (кв. м)",
      ukr: "Ефективна площа (кв. м)",
      deu: "Effektive Fläche (qm)",
    },
    projects_add_technical_area_effective_placeholder: {
      eng: "This is area from which you can earn income. Landing of staircases, WCs, etc. are not counted as an effective area",
      rus: "Это площадь, с которой вы можете получать доход. Площади подъездов, туалетов и т. д. не считаются эффективной площадью",
      ukr: "Це площа, з якої ви можете отримувати дохід. Під'їзди, туалети тощо не враховуються як ефективна площа",
      deu: "Dies ist der Bereich, aus dem Sie Einkommen erzielen können. Treppenabsätze, Toiletten usw. zählen nicht als effektiver Bereich.",
    },
    projects_add_technical_area_total: {
      eng: "Total construction area (sq.m.)",
      rus: "Общая площадь строительства (кв. м)",
      ukr: "Загальна площа будівництва (кв. м)",
      deu: "Gesamte Baufläche (qm)",
    },
    projects_add_technical_area_building: {
      eng: "Building area (sq.m.)",
      rus: "Площадь здания (кв. м)",
      ukr: "Площа будівлі (кв. м)",
      deu: "Gebäudefläche (qm)",
    },
    projects_add_technical_area_office: {
      eng: "Office area (sq.m.)",
      rus: "Площадь офиса (кв. м)",
      ukr: "Площа офісу (кв. м)",
      deu: "Bürofläche (qm)",
    },
    projects_add_technical_area_comercial: {
      eng: "Commercial area (sq.m.)",
      rus: "Торговая площадь (кв. м)",
      ukr: "Торгова площа (кв. м)",
      deu: "Gewerbefläche (qm)",
    },
    projects_add_technical_area_hotel: {
      eng: "Hotel area (sq.m.)",
      rus: "Площадь отеля (кв. м)",
      ukr: "Площа готелю (кв. м)",
      deu: "Hotelfläche (qm)",
    },
    projects_add_technical_area_parking: {
      eng: "Parking area (sq.m.)",
      rus: "Площадь парковки (кв. м)",
      ukr: "Площа парковки (кв. м)",
      deu: "Parkfläche (qm)",
    },
    projects_add_technical_number_aparts: {
      eng: "Number of apartments",
      rus: "Количество квартир",
      ukr: "Кількість квартир",
      deu: "Anzahl der Wohnungen",
    },
    projects_add_technical_number_rooms: {
      eng: "Number of rooms",
      rus: "Количество комнат",
      ukr: "Кількість кімнат",
      deu: "Anzahl der Zimmer",
    },
    conditionOfApartment_translate: {
      eng: "Condition of an Apartment",
      rus: "Состояние Квартиры",
      ukr: "Стан Квартири",
      deu: "Zustand der Wohnung",
    },

    projects_add_technical_space: {
      eng: "Living space (sq.m.)",
      rus: "Жилая площадь (кв. м)",
      ukr: "Житлова площа (кв. м)",
      deu: "Wohnfläche (qm)",
    },
    projects_add_technical_bedrooms_one: {
      eng: "One bedroom",
      rus: "Одна спальня",
      ukr: "Одна спальня",
      deu: "Ein Schlafzimmer",
    },
    projects_add_technical_bedrooms_two: {
      eng: "Two bedrooms",
      rus: "Две спальни",
      ukr: "Дві спальні",
      deu: "Zwei Schlafzimmer",
    },
    projects_add_technical_bedrooms_three: {
      eng: "Three bedrooms",
      rus: "Три спальни",
      ukr: "Три спальні",
      deu: "Drei Schlafzimmer",
    },
    projects_add_technical_bedrooms_four: {
      eng: "Four bedrooms",
      rus: "Четыре спальни",
      ukr: "Чотири спальні",
      deu: "Vier Schlafzimmer",
    },
    projects_add_technical_bedrooms_five: {
      eng: "Five bedrooms",
      rus: "Пять спален",
      ukr: "П'ять спалень",
      deu: "Fünf Schlafzimmer",
    },
    projects_add_technical_penthouses: {
      eng: "Penthouses",
      rus: "Пентхаусы",
      ukr: "Пентхауси",
      deu: "Penthäuser",
    },
    projects_add_technical_villas: {
      eng: "Villas",
      rus: "Виллы",
      ukr: "Вілли",
      deu: "Villen",
    },
    projects_add_technical_parking_onground: {
      eng: "Onground parking (sq.m.)",
      rus: "Наземная парковка (кв. м)",
      ukr: "Наземна парковка (кв. м)",
      deu: "Parken überirdisch (qm)",
    },
    projects_add_technical_parking_underground: {
      eng: "Underground parking (sq.m.)",
      rus: "Подземная парковка (кв. м)",
      ukr: "Підземна парковка (кв. м)",
      deu: "Parken unterirdisch (qm)",
    },
    projects_add_technical_parking_total: {
      eng: "Number of parking spaces",
      rus: "Количество парковочных мест",
      ukr: "Кількість парковочних місць",
      deu: "Anzahl der Parkplätze",
    },
    projects_add_financial_sources_own: {
      eng: "Financing sources (own funds)",
      rus: "Источники финансирования (собственные средства)",
      ukr: "Джерела фінансування (власні кошти)",
      deu: "Finanzierungsquellen (eigene Mittel)",
    },
    projects_add_financial_sources_bank: {
      eng: "Financing sources (bank loans)",
      rus: "Источники финансирования (банковские кредиты)",
      ukr: "Джерела фінансування (банківські кредити)",
      deu: "Finanzierungsquellen (Bankkredite)",
    },
    projects_add_financial_sources_platform: {
      eng: "Financing sources (investments attracted on the platform)",
      rus: "Источники финансирования (привлеченные инвестиции на платформе)",
      ukr: "Джерела фінансування (залучені інвестиції на платформі)",
      deu: "Finanzierungsquellen (auf der Plattform gewonnene Investitionen)",
    },
    projects_add_financial_sources_preliminary: {
      eng: "Financing sources (preliminary payments)",
      rus: "Источники финансирования (предварительные платежи)",
      ukr: "Джерела фінансування (попередні платежі)",
      deu: "Finanzierungsquellen (vorläufige Zahlungen)",
    },
    projects_add_financial_sources_total: {
      eng: "Total investment",
      rus: "Общий объем инвестиций",
      ukr: "Загальний обсяг інвестицій",
      deu: "Gesamtinvestitionen",
    },
    projects_add_financial_sources_currency: {
      eng: "Currency",
      rus: "Валюта",
      ukr: "Валюта",
      deu: "Währung",
    },
    projects_add_financial_settlement: {
      eng: "Settlement account",
      rus: "Расчетный счет",
      ukr: "Розрахунковий рахунок",
      deu: "Abwicklungskonto",
    },
    projects_add_financial_bank: {
      eng: "Bank representative",
      rus: "Представитель банка",
      ukr: "Представник банку",
      deu: "Bankvertreter",
    },
    projects_add_square_warning:{
      eng: "To add a token, fill in the field: Total construction area.",
      rus: "Для добавления токена заполните поле: Общая площадь строительства.",
      ukr: "Для додавання токена заповніть поле: Загальна площа будівництва.",
      deu: "Um einen Token hinzuzufügen, füllen Sie das Feld 'Gesamtfläche des Baus' aus.",
    },
    projects_add_financial_votes: {
      eng: "Minimum number of votes of the expert commission",
      rus: "Минимальное количество голосов экспертной комиссии",
      ukr: "Мінімальна кількість голосів експертної комісії",
      deu: "Mindestanzahl der Stimmen der Expertenkommission",
    },
    projects_add_financial_irr: {
      eng: "Internal Rate of Return (IRR), %",
      rus: "Внутренняя норма доходности (IRR), %",
      ukr: "Внутрішня норма доходності (IRR), %",
      deu: "Interne Zinsfuß (IRR), %",
    },
    projects_add_financial_mirr: {
      eng: "Modified Internal Rate of Return (MIRR), %",
      rus: "Измененная внутренняя норма прибыли (MIRR), %",
      ukr: "Змінена внутрішня норма прибутку (MIRR), %",
      deu: "Modifizierte interne Rendite (MIRR), %",
    },
    projects_add_financial_payback: {
      eng: "Payback period, year(s)",
      rus: "Период окупаемости, год(ы)",
      ukr: "Період окупності, рік(и)",
      deu: "Amortisationszeitraum, Jahr(e)",
    },
    projects_add_financial_discountedPayback: {
      eng: "Discounted payback period, year(s)",
      rus: "Период окупаемости с учетом дисконтирования, год(ы)",
      ukr: "Період окупності з урахуванням дисконту, рік(и)",
      deu: "Abgezinster Amortisationszeitraum, Jahr(e)",
    },
    projects_add_financial_npv: {
      eng: "Net present value (NPV), ₼",
      rus: "Чистая приведенная стоимость (ЧПС), ₼",
      ukr: "Чиста приведена вартість (ЧПВ), ₼",
      deu: "Nettogegenwartswert (NGW), ₼",
    },
    projects_add_financial_arr: {
      eng: "Accounting rate of return (ARR), %",
      rus: "Бухгалтерская норма доходности (ARR), %",
      ukr: "Бухгалтерська норма доходності (ARR), %",
      deu: "Buchhalterische Rendite (ARR), %",
    },
    projects_add_financial_profitability: {
      eng: "Profitability index",
      rus: "Индекс доходности",
      ukr: "Індекс доходності",
      deu: "Rentabilitätsindex",
    },
    projects_add_financial_profit: {
      eng: "Project total profit, ₼",
      rus: "Общая прибыль проекта, ₼",
      ukr: "Загальний прибуток проекту, ₼",
      deu: "Gesamtgewinn des Projekts, ₼",
    },
    projects_add_financial_loan_title: {
      eng: "Loan token - This type of token is based on an electronic contract fixing the monetary contribution of an investor (developer token holder) to a specific development project with the obligation to return this deposit and interest on it after a specified period in the future. The agreement is concluded directly between the developer (acts as a borrower) and the investor - the platform user (acts as a lender)",
      rus: "Токен займа - Этот тип токена основан на электронном контракте, который закрепляет денежный вклад инвестора (владельца токена разработчика) в конкретный проект развития с обязательством вернуть этот депозит и проценты по нему после определенного периода в будущем. Соглашение заключается непосредственно между разработчиком (действующим в качестве заемщика) и инвестором - пользователем платформы (действующим в качестве кредитора)",
      ukr: "Позичковий токен - Цей тип токена ґрунтується на електронному контракті, що закріплює грошовий внесок інвестора (власника токена розробника) в конкретний проект розвитку з обов'язком повернути цей депозит та відсотки на нього після визначеного періоду у майбутньому. Угода укладається безпосередньо між розробником (який виступає у ролі позичальника) та інвестором - користувачем платформи (який виступає у ролі кредитора)",
      deu: "Kredit-Token - Diese Art von Token basiert auf einem elektronischen Vertrag, der den Geldbeitrag eines Investors (Entwickler-Token-Inhabers) zu einem bestimmten Entwicklungsprojekt festlegt. Nach Ablauf eines festgelegten Zeitraums in der Zukunft besteht die Verpflichtung, diese Einzahlung und die darauf anfallenden Zinsen zurückzugeben. Die Vereinbarung wird direkt zwischen dem Entwickler (als Kreditnehmer) und dem Investor - dem Plattformbenutzer (als Kreditgeber) - abgeschlossen",
    },
    projects_add_financial_loan_total: {
      eng: "Total number of tokens",
      rus: "Общее количество токенов",
      ukr: "Загальна кількість токенів",
      deu: "Gesamtzahl der Token",
    },
    projects_add_financial_loan_rate: {
      eng: "Rate of return, %",
      rus: "Ставка доходности, %",
      ukr: "Ставка доходності, %",
      deu: "Rendite, %",
    },
    projects_add_financial_loan_amount: {
      eng: "Minimum amount of investments for start, ₼",
      rus: "Минимальная сумма инвестиций для старта, ₼",
      ukr: "Мінімальна сума інвестицій для старту, ₼",
      deu: "Mindestinvestitionssumme zum Start, ₼",
    },
    projects_add_financial_loan_start: {
      eng: "Start of token sale",
      rus: "Начало продажи токенов",
      ukr: "Початок продажу токенів",
      deu: "Start des Tokenverkaufs",
    },
    projects_add_financial_loan_end: {
      eng: "End of token sale",
      rus: "Окончание продажи токенов",
      ukr: "Закінчення продажу токенів",
      deu: "Ende des Tokenverkaufs",
    },
    projects_add_financial_loan_period: {
      eng: "Payment period",
      rus: "Период платежей",
      ukr: "Період платежів",
      deu: "Zahlungsperiode",
    },
    projects_add_financial_loan_quantity: {
      eng: "Payments quantity",
      rus: "Количество платежей",
      ukr: "Кількість платежів",
      deu: "Anzahl der Zahlungen",
    },
    projects_add_financial_square_title: {
      eng: "Square meter token - which is based on an electronic contract that allows you to fix the investor's contribution to the project from 1 square meter (centimeter). They are cumulative and further can be exchanged for newly built real estate",
      rus: "Токен за квадратный метр - основан на электронном контракте, который позволяет зафиксировать вклад инвестора в проект от 1 квадратного метра (сантиметра). Они накапливаются и в дальнейшем могут быть обменены на недвижимость, только что построенную",
      ukr: "Токен за квадратний метр - що базується на електронному контракті, що дозволяє зафіксувати внесок інвестора до проекту від 1 квадратного метра (сантиметра). Вони накопичуються і подальше можуть бути обмінені на новозбудовану нерухомість",
      deu: "Quadratmeter-Token - basierend auf einem elektronischen Vertrag, der es ermöglicht, den Beitrag des Investors zum Projekt ab 1 Quadratmeter (Zentimeter) festzulegen. Sie werden kumuliert und können später gegen neu gebaute Immobilien eingetauscht werden",
    },
    projects_add_financial_square_total: {
      eng: "Number of square meters for investment",
      rus: "Количество квадратных метров для инвестиций",
      ukr: "Кількість квадратних метрів для інвестицій",
      deu: "Anzahl der Quadratmeter für Investitionen",
    },
    projects_add_financial_square_price: {
      eng: "Base price per sq.m., ₼",
      rus: "Базовая цена за кв.м., ₼",
      ukr: "Базова ціна за кв.м., ₼",
      deu: "Grundpreis pro qm, ₼",
    },
    projects_add_financial_square_start: {
      eng: "Start of token sale",
      rus: "Начало продажи токенов",
      ukr: "Початок продажу токенів",
      deu: "Start des Tokenverkaufs",
    },
    projects_add_financial_square_end: {
      eng: "End of token sale",
      rus: "Окончание продажи токенов",
      ukr: "Закінчення продажу токенів",
      deu: "Ende des Tokenverkaufs",
    },
    projects_add_financial_unit_title: {
      eng: "Unit token - based on an electronic contract according to which the buyer (holder of the unit token) has the primary right to obtain ownership rights for a certain number of square meters (finished unit) in a specific project",
      rus: "Токен единицы - основан на электронном контракте, согласно которому покупатель (владелец токена единицы) имеет первоочередное право на получение прав собственности на определенное количество квадратных метров (готовой единицы) в конкретном проекте",
      ukr: "Токен одиниці - заснований на електронному контракті, згідно з яким покупець (власник токена одиниці) має пріоритетне право на отримання прав власності на певну кількість квадратних метрів (готової одиниці) в конкретному проекті",
      deu: "Einheitstoken - basierend auf einem elektronischen Vertrag, gemäß dem der Käufer (Inhaber des Einheitstokens) das vorrangige Recht hat, Eigentumsrechte für eine bestimmte Anzahl von Quadratmetern (fertige Einheit) in einem bestimmten Projekt zu erlangen",
    },
    projects_add_financial_unit_start: {
      eng: "Start of token sale",
      rus: "Начало продажи токенов",
      ukr: "Початок продажу токенів",
      deu: "Start des Tokenverkaufs",
    },
    projects_add_financial_unit_end: {
      eng: "End of token sale",
      rus: "Окончание продажи токенов",
      ukr: "Закінчення продажу токенів",
      deu: "Ende des Tokenverkaufs",
    },
    projects_add_financial_unit_aparts_total: {
      eng: "Number of the property",
      rus: "Количество объектов недвижимости",
      ukr: "Кількість об'єктів нерухомості",
      deu: "Anzahl der Immobilien",
    },
    projects_add_financial_unit_aparts_total_placeholder: {
      eng: "Enter the number of real estate properties",
      rus: "Введите количество объектов недвижимости",
      ukr: "Введіть кількість об'єктів нерухомості",
      deu: "Geben Sie die Anzahl der Immobilien ein",
    },
    projects_add_financial_unit_aparts_floor: {
      eng: "Floor",
      rus: "Этаж",
      ukr: "Поверх",
      deu: "Stockwerk",
    },
    projects_add_financial_unit_aparts_price: {
      eng: "Initial price per square meter",
      rus: "Начальная цена за квадратный метр",
      ukr: "Початкова ціна за квадратний метр",
      deu: "Anfangspreis pro Quadratmeter",
    },
    projects_add_financial_unit_aparts_currency: {
      eng: "Currency",
      rus: "Валюта",
      ukr: "Валюта",
      deu: "Währung",
    },
    projects_add_financial_unit_aparts_type: {
      eng: "Type of property",
      rus: "Тип недвижимости",
      ukr: "Тип нерухомості",
      deu: "Art der Immobilie",
    },
    projects_add_financial_unit_aparts_type_description: {
      eng: "Description",
      rus: "Описание",
      ukr: "Опис",
      deu: "Beschreibung",
    },
    projects_add_financial_unit_aparts_type_total: {
      eng: "Total area (sq.m.)",
      rus: "Общая площадь (кв.м.)",
      ukr: "Загальна площа (кв.м.)",
      deu: "Gesamtfläche (qm)",
    },
    projects_add_financial_unit_aparts_type_spaceLiving: {
      eng: "Living space (sq.m.)",
      rus: "Жилая площадь (кв.м.)",
      ukr: "Житлова площа (кв.м.)",
      deu: "Wohnfläche (qm)",
    },
    projects_add_financial_unit_aparts_type_kitchen: {
      eng: "Kitchen size:",
      rus: "Площадь кухни:",
      ukr: "Площа кухні:",
      deu: "Küchengröße:",
    },
    projects_add_financial_unit_aparts_type_ceiling: {
      eng: "Ceiling height (m)",
      rus: "Высота потолка (м)",
      ukr: "Висота стелі (м)",
      deu: "Deckenhöhe (m)",
    },
    projects_add_financial_unit_aparts_type_rooms: {
      eng: "Number of rooms",
      rus: "Количество комнат",
      ukr: "Кількість кімнат",
      deu: "Anzahl der Zimmer",
    },
    projects_add_financial_unit_aparts_type_roomsArea: {
      eng: "Area of rooms (sq.m.)",
      rus: "Площадь комнат (кв.м.)",
      ukr: "Площа кімнат (кв.м.)",
      deu: "Fläche der Zimmer (qm)",
    },
    projects_add_financial_unit_aparts_type_balcony: {
      eng: "Balcony",
      rus: "Балкон",
      ukr: "Балкон",
      deu: "Balkon",
    },
    projects_add_financial_unit_aparts_type_numberWindows: {
      eng: "Number of windows",
      rus: "Количество окон",
      ukr: "Кількість вікон",
      deu: "Anzahl der Fenster",
    },
    projects_add_financial_unit_aparts_type_windowView: {
      eng: "Window view",
      rus: "Вид из окна",
      ukr: "Вид з вікна",
      deu: "Fensteransicht",
    },
    projects_add_financial_unit_aparts_type_utilitySystem: {
      eng: "Utility system",
      rus: "Коммунальные системы",
      ukr: "Комунальні системи",
      deu: "Versorgungssystem",
    },
    projects_add_show: {
      eng: "Show",
      rus: "Показать",
      ukr: "Показати",
      deu: "Anzeigen",
    },
    projects_add_hide: {
      eng: "Hide",
      rus: "Скрыть",
      ukr: "Приховати",
      deu: "Ausblenden",
    },
    projects_add_unit_add: {
      eng: "Add Unit*",
      rus: "Добавить Unit*",
      ukr: "Додати Unit*",
      deu: "Hinzufügen Unit*",
    },
    projects_add_unit_save: {
      eng: "Save",
      rus: "Сохранить",
      ukr: "Зберегти",
      deu: "Speichern",
    },
    projects_add_unit_close: {
      eng: "Close",
      rus: "Закрыть",
      ukr: "Закрити",
      deu: "Schließen",
    },
    projects_add_technical_area_total_placeholder: {
      eng: "Total number of square meters of the whole building",
      rus: "Общая площадь всего здания",
      ukr: "Загальна площа всього будинку",
      deu: "Gesamte Grundfläche des gesamten Gebäudes",
    },
    projects_add_technical_area_building_placeholder: {
      eng: "The number of square meters on a land plot which will be occupied by the building",
      rus: "Количество квадратных метров на земельном участке, которые будет занимать здание",
      ukr: "Кількість квадратних метрів на земельній ділянці, які буде займати будівля",
      deu: "Die Anzahl der Quadratmeter auf einem Grundstück, die vom Gebäude eingenommen werden",
    },
    projects_add_technical_number_aparts_placeholder: {
      eng: "Enter total number of apartments in the building",
      rus: "Введите общее количество квартир в здании",
      ukr: "Введіть загальну кількість квартир у будинку",
      deu: "Geben Sie die Gesamtzahl der Wohnungen im Gebäude ein",
    },
    projects_add_technical_space_placeholder: {
      eng: "Total living space of all apartments in the building",
      rus: "Общая жилая площадь всех квартир в здании",
      ukr: "Загальна житлова площа всіх квартир у будинку",
      deu: "Gesamte Wohnfläche aller Wohnungen im Gebäude",
    },
    projects_add_technical_bedrooms_one_placeholder: {
      eng: "The number of one bedroom apartments in the project",
      rus: "Количество квартир с одной спальней в проекте",
      ukr: "Кількість квартир з однією спальнею у проекті",
      deu: "Anzahl der Einzimmerwohnungen im Projekt",
    },
    projects_add_technical_bedrooms_two_placeholder: {
      eng: "The number of two-bedroom apartments in the project",
      rus: "Количество двухспальных квартир в проекте",
      ukr: "Кількість двоспальних квартир у проекті",
      deu: "Anzahl der Zwei-Zimmer-Wohnungen im Projekt",
    },
    projects_add_technical_bedrooms_three_placeholder: {
      eng: "The number of three-bedroom apartments in the project",
      rus: "Количество трехспальных квартир в проекте",
      ukr: "Кількість трьоспальних квартир у проекті",
      deu: "Anzahl der Drei-Zimmer-Wohnungen im Projekt",
    },
    projects_add_technical_bedrooms_four_placeholder: {
      eng: "The number of four-bedroom apartments in the project",
      rus: "Количество четырехспальных квартир в проекте",
      ukr: "Кількість чотирьохспальних квартир у проекті",
      deu: "Anzahl der Vier-Zimmer-Wohnungen im Projekt",
    },
    projects_add_technical_bedrooms_five_placeholder: {
      eng: "The number of five-bedroom apartments in the project",
      rus: "Количество пятиспальных квартир в проекте",
      ukr: "Кількість п'ятиспальних квартир у проекті",
      deu: "Anzahl der Fünf-Zimmer-Wohnungen im Projekt",
    },
    projects_add_technical_penthouses_placeholder: {
      eng: "The number of penthouses in the project",
      rus: "Количество пентхаусов в проекте",
      ukr: "Кількість пентхаусів у проекті",
      deu: "Anzahl der Penthouse-Wohnungen im Projekt",
    },
    projects_add_technical_villas_placeholder: {
      eng: "The number of villas in the project",
      rus: "Количество вилл в проекте",
      ukr: "Кількість вілл у проекті",
      deu: "Anzahl der Villen im Projekt",
    },
    projects_add_technical_area_office_placeholder: {
      eng: "Total area, which will be occupied by offices",
      rus: "Общая площадь, занимаемая офисами",
      ukr: "Загальна площа, яку займатимуть офіси",
      deu: "Gesamtfläche, die von Büros belegt wird",
    },
    projects_add_technical_area_comercial_placeholder: {
      eng: "An area occupied by businesses which sell, rent, trade, store goods, or provide services",
      rus: "Площадь, занимаемая бизнесами, которые продают, сдают в аренду, торгуют, хранят товары или предоставляют услуги",
      ukr: "Площа, яку займають підприємства, які продають, здають в оренду, торгують, зберігають товари або надають послуги",
      deu: "Fläche, die von Unternehmen belegt wird, die Waren verkaufen, vermieten, handeln, lagern oder Dienstleistungen erbringen",
    },
    projects_add_technical_area_hotel_placeholder: {
      eng: "An area, occupied by hotel real estate",
      rus: "Площадь, занимаемая гостиничной недвижимостью",
      ukr: "Площа, яку займає готельна нерухомість",
      deu: "Fläche, die von Hotelimmobilien belegt wird",
    },
    projects_add_technical_number_rooms_placeholder: {
      eng: "Total number of hotel suites",
      rus: "Общее количество номеров в отеле",
      ukr: "Загальна кількість номерів у готелі",
      deu: "Gesamtanzahl der Hotelzimmer",
    },
    projects_add_technical_area_parking_placeholder: {
      eng: "An area, occupied by parking spaces",
      rus: "Площадь, занимаемая парковочными местами",
      ukr: "Площа, яку займають парковочні місця",
      deu: "Fläche, die von Parkplätzen belegt wird",
    },
    projects_add_technical_parking_onground_placeholder: {
      eng: "An area, occupied by onground parking spaces",
      rus: "Площадь, занимаемая наземными парковочными местами",
      ukr: "Площа, яку займають наземні парковочні місця",
      deu: "Fläche, die von oberirdischen Parkplätzen belegt wird",
    },
    projects_add_technical_parking_underground_placeholder: {
      eng: "An area, occupied by underground parking spaces",
      rus: "Площадь, занимаемая подземными парковочными местами",
      ukr: "Площа, яку займають підземні парковочні місця",
      deu: "Fläche, die von Tiefgaragenstellplätzen belegt wird",
    },
    projects_add_technical_parking_total_placeholder: {
      eng: "Enter total number of parking spaces",
      rus: "Введите общее количество парковочных мест",
      ukr: "Введіть загальну кількість парковочних місць",
      deu: "Geben Sie die Gesamtanzahl der Parkplätze ein",
    },
    projects_add_financial_sources_own_placeholder: {
      eng: "Enter the amount of your own funds, that you use to finance the project",
      rus: "Введите сумму собственных средств, которые вы используете для финансирования проекта",
      ukr: "Введіть суму власних коштів, які ви використовуєте для фінансування проекту",
      deu: "Geben Sie den Betrag Ihrer eigenen Mittel ein, den Sie zur Finanzierung des Projekts verwenden",
    },
    projects_add_financial_sources_bank_placeholder: {
      eng: "Enter the amount of bank loans, that are used to finance the project",
      rus: "Введите сумму банковских кредитов, которые используются для финансирования проекта",
      ukr: "Введіть суму банківських кредитів, які використовуються для фінансування проекту",
      deu: "Geben Sie den Betrag der Bankkredite ein, die zur Finanzierung des Projekts verwendet werden",
    },
    projects_add_financial_sources_platform_placeholder: {
      eng: "Enter the amount of investments, attracted on the platform for financing the project, min value: 10 000.",
      rus: "Введите сумму привлеченных инвестиций на платформе для финансирования проекта, минимальное значение: 10 000.",
      ukr: "Введіть суму привернутих інвестицій на платформі для фінансування проекту, мінімальне значення: 10 000.",
      deu: "Geben Sie den Betrag der auf der Plattform für die Finanzierung des Projekts eingeworbenen Investitionen ein, Mindestwert: 10.000.",
    },
    projects_add_financial_sources_preliminary_placeholder: {
      eng: "Enter the amount of preliminary payments, that were used to finance the project",
      rus: "Введите сумму предварительных платежей, которые были использованы для финансирования проекта",
      ukr: "Введіть суму попередніх платежів, які були використані для фінансування проекту",
      deu: "Geben Sie den Betrag der Vorabzahlungen ein, die zur Finanzierung des Projekts verwendet wurden",
    },
    projects_add_financial_sources_total_placeholder: {
      eng: "Total investment",
      rus: "Общий объем инвестиций",
      ukr: "Загальний обсяг інвестицій",
      deu: "Gesamtinvestition",
    },
    projects_add_financial_sources_currency_placeholder: {
      eng: "Select currency",
      rus: "Выберите валюту",
      ukr: "Виберіть валюту",
      deu: "Währung auswählen",
    },
    projects_add_financial_settlement_placeholder: {
      eng: "Settlement account",
      rus: "Расчетный счет",
      ukr: "Розрахунковий рахунок",
      deu: "Abwicklungskonto",
    },
    projects_add_financial_bank_placeholder: {
      eng: "Name, contacts of the bank representative",
      rus: "Имя, контакты представителя банка",
      ukr: "Ім'я, контакти представника банку",
      deu: "Name, Kontakte des Bankvertreters",
    },
    projects_add_financial_votes_placeholder: {
      eng: "Here you determine a minimum number of expert votes for your project",
      rus: "Здесь вы определяете минимальное количество экспертных голосов для вашего проекта",
      ukr: "Тут ви визначаєте мінімальну кількість експертних голосів для вашого проекту",
      deu: "Hier legen Sie eine Mindestanzahl von Expertenstimmen für Ihr Projekt fest",
    },
    projects_add_financial_irr_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_mirr_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_payback_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_discountedPayback_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_npv_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_arr_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_profitability_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_profit_placeholder: {
      eng: "To fill in this field see the business plan of the project",
      rus: "Для заполнения этого поля ознакомьтесь с бизнес-планом проекта",
      ukr: "Для заповнення цього поля ознайомтесь з бізнес-планом проекту",
      deu: "Um dieses Feld auszufüllen, sehen Sie sich den Geschäftsplan des Projekts an",
    },
    projects_add_financial_square_total_placeholder: {
      eng: "Determine total number of square meters, that you want to allocate for investment collection",
      rus: "Определите общее количество квадратных метров, которые вы хотите выделить для сбора инвестиций",
      ukr: "Визначте загальну кількість квадратних метрів, які ви хочете виділити для збору інвестицій",
      deu: "Bestimmen Sie die Gesamtzahl der Quadratmeter, die Sie für die Investitionssammlung zuweisen möchten",
    },
    projects_add_financial_square_price_placeholder: {
      eng: "Base price per sq.m.",
      rus: "Базовая цена за кв. м.",
      ukr: "Базова ціна за кв. м.",
      deu: "Grundpreis pro Quadratmeter",
    },
    projects_add_financial_square_start_help: {
      eng: "Set date of start token sale",
      rus: "Установите дату начала продажи токенов",
      ukr: "Встановіть дату початку продажу токенів",
      deu: "Legen Sie das Datum des Beginns des Token-Verkaufs fest",
    },
    projects_add_financial_square_end_help: {
      eng: "Set date of end token sale",
      rus: "Установите дату окончания продажи токенов",
      ukr: "Встановіть дату завершення продажу токенів",
      deu: "Legen Sie das Datum des Endes des Token-Verkaufs fest",
    },
    projects_add_financial_unit_start_help: {
      eng: "Set date of start token sale",
      rus: "Установите дату начала продажи токенов",
      ukr: "Встановіть дату початку продажу токенів",
      deu: "Legen Sie das Datum des Beginns des Token-Verkaufs fest",
    },
    projects_add_financial_unit_end_help: {
      eng: "Set date of end token sale",
      rus: "Установите дату окончания продажи токенов",
      ukr: "Встановіть дату завершення продажу токенів",
      deu: "Legen Sie das Datum des Endes des Token-Verkaufs fest",
    },
    projects_add_financial_unit_aparts_floor_placeholder: {
      eng: "Floor number",
      rus: "Номер этажа",
      ukr: "Номер поверху",
      deu: "Stockwerksnummer",
    },
    projects_add_financial_unit_aparts_price_placeholder: {
      eng: "Price per apartment",
      rus: "Цена за квартиру",
      ukr: "Ціна за квартиру",
      deu: "Preis pro Wohnung",
    },
    projects_add_financial_unit_aparts_type_help: {
      eng: "Select type of property",
      rus: "Выберите тип недвижимости",
      ukr: "Виберіть тип нерухомості",
      deu: "Wählen Sie den Immobilientyp aus",
    },
    projects_add_financial_unit_aparts_type_total_placeholder: {
      eng: "Total area",
      rus: "Общая площадь",
      ukr: "Загальна площа",
      deu: "Gesamtfläche",
    },
    projects_add_financial_unit_aparts_type_spaceLiving_placeholder: {
      eng: "Living space",
      rus: "Жилая площадь",
      ukr: "Житлова площа",
      deu: "Wohnfläche",
    },
    projects_add_financial_unit_aparts_type_kitchen_placeholder: {
      eng: "Kitchen size",
      rus: "Площадь кухни",
      ukr: "Площа кухні",
      deu: "Küchengröße",
    },
    projects_add_financial_unit_aparts_type_effectiveArea: {
      eng: "Effective area",
      rus: "Площадь помещений",
      ukr: "Площа приміщень",
      deu: "Effektive Fläche",
    },
    projects_add_financial_unit_aparts_type_auxiliaryArea: {
      eng: "Auxiliary area",
      rus: "Вспомогательная площадь",
      ukr: "Допоміжна площа",
      deu: "Nebenfläche",
    },
    projects_add_financial_unit_aparts_type_effectiveArea_placeholder: {
      eng: "Effective area",
      rus: "Площадь помещений",
      ukr: "Площа приміщень",
      deu: "Effektive Fläche",
    },
    projects_add_financial_unit_aparts_type_auxiliaryArea_placeholder: {
      eng: "Auxiliary area",
      rus: "Вспомогательная площадь",
      ukr: "Допоміжна площа",
      deu: "Nebenfläche",
    },
    projects_add_financial_unit_aparts_type_ceiling_placeholder: {
      eng: "Ceiling height",
      rus: "Высота потолка",
      ukr: "Висота стелі",
      deu: "Deckenhöhe",
    },
    projects_add_financial_unit_aparts_type_rooms_placeholder: {
      eng: "Rooms",
      rus: "Количество комнат",
      ukr: "Кількість кімнат",
      deu: "Zimmeranzahl",
    },
    projects_add_financial_unit_aparts_type_roomsArea_placeholder: {
      eng: "Area of rooms",
      rus: "Площадь комнат",
      ukr: "Площа кімнат",
      deu: "Zimmerfläche",
    },
    projects_add_financial_unit_aparts_type_balcony_placeholder: {
      eng: "Balcony",
      rus: "Балкон",
      ukr: "Балкон",
      deu: "Balkon",
    },
    projects_add_financial_unit_aparts_type_numberWindows_placeholder: {
      eng: "Number of windows",
      rus: "Количество окон",
      ukr: "Кількість вікон",
      deu: "Anzahl der Fenster",
    },
    projects_add_financial_unit_aparts_type_numberOfBathrooms: {
      eng: "Number of bathrooms",
      rus: "Количество ванных комнат",
      ukr: "Кількість ванних кімнат",
      deu: "Anzahl der Badezimmer",
    },
    projects_add_financial_unit_aparts_type_numberOfBathrooms_placeholder: {
      eng: "Number of bathrooms",
      rus: "Количество ванных комнат",
      ukr: "Кількість ванних кімнат",
      deu: "Anzahl der Badezimmer",
    },
    projects_add_financial_unit_aparts_type_windowView_placeholder: {
      eng: "Window view",
      rus: "Вид из окна",
      ukr: "Вид з вікна",
      deu: "Fensterblick",
    },
    projects_add_documents_agreement: {
      eng: "Investment agreement",
      rus: "Инвестиционное соглашение",
      ukr: "Інвестиційна угода",
      deu: "Investitionsvereinbarung",
    },
    projects_add_step_common: {
      eng: "Common",
      rus: "Общее",
      ukr: "Загальне",
      deu: "Allgemein",
    },
    projects_add_step_technical: {
      eng: "Technical",
      rus: "Техническое",
      ukr: "Технічне",
      deu: "Technisch",
    },
    projects_add_step_financial: {
      eng: "Financial",
      rus: "Финансовое",
      ukr: "Фінансове",
      deu: "Finanziell",
    },
    projects_add_step_images: {
      eng: "Photos",
      rus: "Фото",
      ukr: "Фотографії",
      deu: "Fotos",
    },
    projects_add_step_documents: {
      eng: "Documents",
      rus: "Документы",
      ukr: "Документи",
      deu: "Dokumente",
    },
    projects_add_technical_title: {
      eng: "Technical specifications",
      rus: "Технические характеристики",
      ukr: "Технічні характеристики",
      deu: "Technische Spezifikationen",
    },
    projects_add_main_photo_title: {
      eng: "Main photo*",
      rus: "Главное фото*",
      ukr: "Головне фото*",
      deu: "Hauptfoto*",
    },
    projects_add_images_title: {
      eng: "Photos",
      rus: "Фото",
      ukr: "Фотографії",
      deu: "Fotos",
    },
    projects_add_documents_title: {
      eng: "Investment agreeement",
      rus: "Инвестиционный договор",
      ukr: "Інвестиційний договір",
      deu: "Investitionsvereinbarung",
    },
    projects_add_common_location_title: {
      eng: "In order to choose an address of the object, please fill in the address in the map field below",
      rus: "Чтобы выбрать адрес объекта, пожалуйста, заполните адрес в поле карты ниже",
      ukr: "Щоб вибрати адресу об'єкта, будь ласка, заповніть адресу в полі карти нижче",
      deu: "Um eine Adresse des Objekts auszuwählen, geben Sie bitte die Adresse in das Kartenfeld unten ein",
    },
    projects_add_financial_title: {
      eng: "Financial indicators",
      rus: "Финансовые показатели",
      ukr: "Фінансові показники",
      deu: "Finanzkennzahlen",
    },
    projects_add_common_type: {
      eng: "Type of property",
      rus: "Тип объекта",
      ukr: "Тип об'єкту",
      deu: "Art der Eigenschaft",
    },
    projects_add_common_type_help: {
      eng: "Select property type",
      rus: "Выберите тип объекта",
      ukr: "Виберіть тип об'єкту",
      deu: "Eigenschaftstyp auswählen",
    },
    projects_add_loader_drop: {
      eng: "Drop image files to upload (or click)",
      rus: "Перетащите файлы изображений для загрузки (или нажмите)",
      ukr: "Перетягніть файли зображень для завантаження (або натисніть)",
      deu: "Bilddateien zum Hochladen ablegen (oder klicken)",
    },
    projects_add_loader_formats: {
      eng: "(jpg, jpeg, png, gif)",
      rus: "(jpg, jpeg, png, gif)",
      ukr: "(jpg, jpeg, png, gif)",
      deu: "(jpg, jpeg, png, gif)",
    },
    projects_add_loader_file_count: {
      eng: "Maximum number of files",
      rus: "Максимальное количество файлов",
      ukr: "Максимальна кількість файлів",
      deu: "Maximale Anzahl an Dateien",
    },
    projects_add_loader_file_size: {
      eng: "Maximum size of each image",
      rus: "Максимальный размер каждого изображения",
      ukr: "Максимальний розмір кожного зображення",
      deu: "Maximale Größe jedes Bildes",
    },
    projects_add_loader_clear: {
      eng: "Clear all",
      rus: "Очистить все",
      ukr: "Очистити все",
      deu: "Alle löschen",
    },
    projects_add_documents_subtitle: {
      eng: "Images of documents",
      rus: "Изображения документов",
      ukr: "Зображення документів",
      deu: "Bilder von Dokumenten",
    },
    projects_add_documents_ownership: {
      eng: "Ownership documents",
      rus: "Документы о собственности",
      ukr: "Документи власності",
      deu: "Eigentumsdokumente",
    },
    projects_add_documents_project: {
      eng: "Project documentation",
      rus: "Документация проекта",
      ukr: "Документація проекту",
      deu: "Projektdokumentation",
    },
    projects_add_documents_restrictions: {
      eng: "Restrictions",
      rus: "Ограничения",
      ukr: "Обмеження",
      deu: "Einschränkungen",
    },
    projects_add_documents_building: {
      eng: "Building permit",
      rus: "Разрешение на строительство",
      ukr: "Дозвіл на будівництво",
      deu: "Baugenehmigung",
    },
    projects_add_documents_business: {
      eng: "Business plan",
      rus: "Бизнес-план",
      ukr: "Бізнес-план",
      deu: "Geschäftsplan",
    },
    projects_add_documents_diligence: {
      eng: "Due diligence",
      rus: "Проверка юридической чистоты",
      ukr: "Перевірка юридичної чистоти",
      deu: "Due Diligence",
    },
    projects_add_documents_warning_text: {
      eng: "All documents must be represented in English.",
      rus: "Все документы должны быть представлены на английском языке.",
      ukr: "Всі документи повинні бути представлені англійською мовою.",
      deu: "Alle Dokumente müssen in englischer Sprache vorgelegt werden.",
    },
    projects_add_documents_warning_text_apost: {
      eng: "All enabling documentation must be apostilled.",
      rus: "Все разрешительные документы должны быть апостилированы.",
      ukr: "Всі дозвільні документи повинні бути апостильовані.",
      deu: "Alle beglaubigten Unterlagen müssen apostilliert sein.",
    },
    projects_add_documents_warning_title: {
      eng: "Warning!",
      rus: "Внимание!",
      ukr: "Увага!",
      deu: "Warnung!",
    },
    projects_add_financial_unit_edit: {
      eng: "Edit",
      rus: "Редактировать",
      ukr: "Редагувати",
      deu: "Bearbeiten",
    },
    projects_add_financial_unit_delete: {
      eng: "Delete",
      rus: "Удалить",
      ukr: "Видалити",
      deu: "Löschen",
    },
    projects_add_financial_units_helper: {
      eng: "At least 1 token must be added",
      rus: "Необходимо добавить минимум 1 токен",
      ukr: "Необхідно додати принаймні 1 токен",
      deu: "Mindestens 1 Token muss hinzugefügt werden",
    },
    projects_add_images_helper: {
      eng: "Please upload at least one photo",
      rus: "Пожалуйста, загрузите хотя бы одну фотографию",
      ukr: "Будь ласка, завантажте хоча б одне фото",
      deu: "Bitte laden Sie mindestens ein Foto hoch",
    },
    marketplace_title: {
      eng: "Marketplace",
      rus: "Маркетплейс",
      ukr: "Маркетплейс",
      deu: "Marktplatz",
    },
    marketplace_total_active: {
      eng: "Total active lots",
      rus: "Всего активных лотов",
      ukr: "Всього активних лотів",
      deu: "Gesamtzahl der aktiven Angebote",
    },
    marketplace_total_lots: {
      eng: "Total lots",
      rus: "Всего лотов",
      ukr: "Всього лотів",
      deu: "Gesamtzahl der Angebote",
    },
    marketplace_total_cost: {
      eng: "Total cost of lots",
      rus: "Общая стоимость лотов",
      ukr: "Загальна вартість лотів",
      deu: "Gesamtkosten der Angebote",
    },
    marketplace_search_title: {
      eng: "Search by",
      rus: "Поиск по",
      ukr: "Пошук за",
      deu: "Suche nach",
    },
    marketplace_search_name: {
      eng: "Name",
      rus: "Название",
      ukr: "Назва",
      deu: "Name",
    },
    marketplace_search_type: {
      eng: "Choose type",
      rus: "Выберите тип",
      ukr: "Виберіть тип",
      deu: "Wählen Sie den Typ",
    },
    marketplace_search_fromPrice: {
      eng: "Price from",
      rus: "Цена от",
      ukr: "Ціна від",
      deu: "Preis ab",
    },
    marketplace_search_toPrice: {
      eng: "Price to",
      rus: "Цена до",
      ukr: "Ціна до",
      deu: "Preis bis",
    },
    marketplace_search_find: {
      eng: "Find",
      rus: "Найти",
      ukr: "Знайти",
      deu: "Suchen",
    },
    marketplace_list_empty: {
      eng: "List is empty",
      rus: "Список пуст",
      ukr: "Список порожній",
      deu: "Die Liste ist leer",
    },
    marketplace_history_title: {
      eng: "Market trade history",
      rus: "История торгов на рынке",
      ukr: "Історія торгів на ринку",
      deu: "Handelsgeschichte des Marktes",
    },
    marketplace_history_date: {
      eng: "Date",
      rus: "Дата",
      ukr: "Дата",
      deu: "Datum",
    },
    marketplace_history_type: {
      eng: "Type",
      rus: "Тип",
      ukr: "Тип",
      deu: "Typ",
    },
    marketplace_history_price: {
      eng: "Price",
      rus: "Цена",
      ukr: "Ціна",
      deu: "Preis",
    },
    marketplace_history_quantity: {
      eng: "Quantity",
      rus: "Количество",
      ukr: "Кількість",
      deu: "Menge",
    },
    projects_list_item_startExpertise: {
      eng: "Start expertise",
      rus: "Начать экспертизу",
      ukr: "Почати експертизу",
      deu: "Expertise starten",
    },
    projects_add_financial_square_ticker: {
      eng: "Token ticker",
      rus: "Тикер токена",
      ukr: "Тікер токена",
      deu: "Token-Symbol",
    },
    projects_add_point: {
      eng: "Add",
      rus: "Добавить",
      ukr: "Додати",
      deu: "Hinzufügen",
    },
    projects_add_point_clear: {
      eng: "Clear",
      rus: "Очистить",
      ukr: "Очистити",
      deu: "Löschen",
    },
    projects_add_chart_label: {
      eng: "Price per square meter (₼)",
      rus: "Цена за квадратный метр (ЕВРО)",
      ukr: "Ціна за квадратний метр (ЄВРО)",
      deu: "Preis pro Quadratmeter (₼)",
    },
    projects_add_point_time: {
      eng: "Date of change",
      rus: "Дата изменения",
      ukr: "Дата зміни",
      deu: "Änderungsdatum",
    },

    projects_add_point_cost: {
      eng: "Price per square meter (₼)",
      rus: "Цена за квадратный метр (ЕВРО)",
      ukr: "Ціна за квадратний метр (ЄВРО)",
      deu: "Preis pro Quadratmeter (₼)",
    },
    projects_add_point_title: {
      eng: "Base price increase",
      rus: "Увеличение базовой цены",
      ukr: "Збільшення базової ціни",
      deu: "Preiserhöhung",
    },
    projects_add_financial_square_ticker_placeholder: {
      eng: "Think of a unique ticker for your token",
      rus: "Придумайте уникальный тикер для вашего токена",
      ukr: "Придумайте унікальний тікер для вашого токена",
      deu: "Denken Sie sich ein eindeutiges Symbol für Ihren Token aus",
    },
    projects_list_item_start_expertise_success: {
      eng: "Expertise started successfully",
      rus: "Экспертиза успешно начата",
      ukr: "Експертиза успішно розпочата",
      deu: "Die Expertise wurde erfolgreich gestartet",
    },
    projects_add_financial_loan_total_placeholder: {
      eng: "Determine the total number of loan tokens for the project",
      rus: "Определите общее количество заемных токенов для проекта",
      ukr: "Визначте загальну кількість позичкових токенів для проекту",
      deu: "Bestimmen Sie die Gesamtzahl der Kredit-Token für das Projekt",
    },
    projects_add_financial_loan_rate_placeholder: {
      eng: "Specify what rate of return on an investment you want to achieve over a specified time period",
      rus: "Укажите желаемую ставку доходности инвестиций за определенный период времени",
      ukr: "Вкажіть бажану ставку доходності інвестицій за визначений період часу",
      deu: "Geben Sie an, welche Rendite Sie über einen bestimmten Zeitraum erzielen möchten",
    },
    projects_add_financial_loan_amount_placeholder: {
      eng: "Enter a minimum amount of investments that can be provided for the project",
      rus: "Введите минимальную сумму инвестиций, которую можно предоставить для проекта",
      ukr: "Введіть мінімальну суму інвестицій, яку можна надати для проекту",
      deu: "Geben Sie einen Mindestbetrag an Investitionen ein, der für das Projekt bereitgestellt werden kann",
    },
    projects_add_financial_loan_start_help: {
      eng: "Set date of start token sale",
      rus: "Установите дату начала продажи токенов",
      ukr: "Встановіть дату початку продажу токенів",
      deu: "Legen Sie das Datum des Beginns des Token-Verkaufs fest",
    },
    projects_add_financial_loan_end_help: {
      eng: "Set date of end token sale",
      rus: "Установите дату окончания продажи токенов",
      ukr: "Встановіть дату завершення продажу токенів",
      deu: "Legen Sie das Datum des Endes des Token-Verkaufs fest",
    },
    projects_add_financial_loan_period_help: {
      eng: "Select payment period",
      rus: "Выберите период выплат",
      ukr: "Виберіть період виплат",
      deu: "Wählen Sie den Zahlungszeitraum aus",
    },
    projects_add_financial_loan_quantity_placeholder: {
      eng: "Enter total number of payments, which will be paid to the investor till the end of payment period",
      rus: "Введите общее количество платежей, которые будут выплачены инвестору до конца периода выплат",
      ukr: "Введіть загальну кількість платежів, які будуть виплачені інвестору до кінця періоду виплат",
      deu: "Geben Sie die Gesamtzahl der Zahlungen ein, die dem Investor bis zum Ende des Zahlungszeitraums gezahlt werden",
    },
    token_coin: {
      eng: "Token/Coin",
      rus: "Токен/Монета",
      ukr: "Токен/Монета",
      deu: "Token/Münze",
    },
    my_wallet_deposit_type: {
      eng: "Type",
      rus: "Тип",
      ukr: "Тип",
      deu: "Typ",
    },

    my_wallet: {
      eng: "My wallet",
      rus: "Мой кошелек",
      ukr: "Мій гаманець",
      deu: "Mein Portemonnaie",
    },
    crypto: {
      eng: "Crypto",
      rus: "Крипто",
      ukr: "Крипто",
      deu: "Krypto",
    },
    network: {
      eng: "Network",
      rus: "Сеть",
      ukr: "Мережа",
      deu: "Netzwerk",
    },
    fiat: {
      eng: "Fiat",
      rus: "Фиат",
      ukr: "Фіат",
      deu: "Fiat",
    },
    withdraw: {
      eng: "Withdraw",
      rus: "Вывод",
      ukr: "Виведення",
      deu: " Abhebung",
    },
    bank_account_translation: {
      eng: "Bank account",
      rus: "Банковский счет",
      ukr: "Банківський рахунок",
      deu: "Bankkonto"
    }
,
    deposit: {
      eng: "Deposit",
      rus: "Депозит",
      ukr: "Депозит",
      deu: "die Kaution",
    },
    transaction_details: {
      eng: "Transaction details",
      rus: "Детали транзакции",
      ukr: "Деталі транзакції",
      deu: "Transaktionsdetails",
    },
    active: {
      eng: "Active",
      rus: "Активный",
      ukr: "Активний",
      deu: "Aktiv",
    },
    inactive_translation: {
      eng: "Inactive",
      rus: "Неактивный",
      ukr: "Неактивний",
      deu: "Inaktiv",
    },
    action: {
      eng: "Action",
      rus: "Действие",
      ukr: "Дія",
      deu: "Aktion",
    },
    new: {
      eng: "New",
      rus: "Новый",
      ukr: "Новий",
      deu: "Neu",
    },
    wait: {
      eng: "Wait",
      rus: "Ожидание",
      ukr: "Очікування",
      deu: "Warten",
    },
    fail: {
      eng: "Fail",
      rus: "Ошибка",
      ukr: "Помилка",
      deu: "Fehler",
    },
    cancel: {
      eng: "Cancel",
      rus: "Отмена",
      ukr: "Скасувати",
      deu: "Abbrechen",
    },
    done: {
      eng: "Done",
      rus: "Готово",
      ukr: "Виконано",
      deu: "Erledigt",
    },
    processing: {
      eng: "Processing",
      rus: "Обработка",
      ukr: "Обробка",
      deu: "Verarbeitung",
    },
    transaction_hash: {
      eng: "Transaction hash",
      rus: "Хэш транзакции",
      ukr: "Хеш транзакції",
      deu: "Transaktions-Hash",
    },
    view_project: {
      eng: "View project",
      rus: "Просмотр проекта",
      ukr: "Переглянути проект",
      deu: "Projekt anzeigen",
    },
    total_m2c_pledged: {
      eng: "Total M2C pledged",
      rus: "Общее количество M2C взносов",
      ukr: "Загальна кількість M2C внесків",
      deu: "Insgesamt zugesagte M2C",
    },
    closed_date_translation: {
      eng: "Closed date",
      rus: "Дата закрытия",
      ukr: "Дата закриття",
      deu: "Abschlussdatum",
    },
    created_date_translation: {
      eng: "Created date",
      rus: "Дата создания",
      ukr: "Дата створення",
      deu: "Erstellungsdatum",
    },
    collateral_asset: {
      eng: "Collateral Asset",
      rus: "Залоговый актив",
      ukr: "Актив-забезпечення",
      deu: "Sicherheitsasset",
    },
    primary_portfolio_price: {
      eng: "Primary portfolio price",
      rus: "Цена основного портфеля",
      ukr: "Ціна основного портфеля",
      deu: "Preis des Hauptportfolios",
    },
    actual_portfolio_price: {
      eng: "Actual portfolio price",
      rus: "Фактическая цена портфеля",
      ukr: "Фактична ціна портфеля",
      deu: "Aktueller Portfolio-Preis",
    },
    square_meter_price: {
      eng: "Square meter price",
      rus: "Цена за квадратный метр",
      ukr: "Ціна за квадратний метр",
      deu: "Quadratmeterpreis",
    },
    total_square_meters: {
      eng: "Total square meters",
      rus: "Общая площадь в квадратных метрах",
      ukr: "Загальна площа у квадратних метрах",
      deu: "Gesamtfläche in Quadratmetern",
    },
    total_square_meters_price: {
      eng: "Total square meters price",
      rus: "Общая стоимость квадратных метров",
      ukr: "Загальна вартість квадратних метрів",
      deu: "Gesamtpreis für Quadratmeter",
    },
    sell_contract: {
      eng: "Sell this contract",
      rus: "Продать этот контракт",
      ukr: "Продати цей контракт",
      deu: "Diesen Vertrag verkaufen",
    },
    add_m2c_to_contract: {
      eng: "Add M2C to contract",
      rus: "Добавить M2C в контракт",
      ukr: "Додати M2C до контракту",
      deu: "M2C zum Vertrag hinzufügen",
    },
    exchange_contract_for_unit: {
      eng: "Exchange contract for a unit",
      rus: "Обменять контракт на единицу",
      ukr: "Обміняти контракт на одиницю",
      deu: "Vertrag gegen eine Einheit eintauschen",
    },
    contract_address: {
      eng: "Contract address",
      rus: "Адрес контракта",
      ukr: "Адреса контракту",
      deu: "Vertragsadresse",
    },
    show_on_stellar_explorer_translation: {
      eng: "Show on Stellar explorer",
      rus: "Показать в Stellar Explorer",
      ukr: "Показати в Stellar Explorer",
      deu: "Im Stellar Explorer anzeigen",
    },
    qr_code_value: {
      eng: "QR code value",
      rus: "Значение QR-кода",
      ukr: "Значення QR-коду",
      deu: "Wert des QR-Codes",
    },
    contract_end_date: {
      eng: "Contract end date",
      rus: "Дата окончания контракта",
      ukr: "Дата закінчення контракту",
      deu: "Vertragsenddatum",
    },
    nft_name: {
      eng: "NFT name",
      rus: "Название NFT",
      ukr: "Назва NFT",
      deu: "NFT-Name",
    },
    export_contracts_history: {
      eng: "Export contracts history",
      rus: "Экспорт истории контрактов",
      ukr: "Експорт історії контрактів",
      deu: "Vertragsverlauf exportieren",
    },
    contact_support: {
      eng: "If you have any troubles, you can contact our",
      rus: "Если у вас возникли какие-либо проблемы, вы можете связаться с нашей",
      ukr: "Якщо у вас виникли які-небудь проблеми, ви можете зв'язатися з нашою",
      deu: "Wenn Sie Probleme haben, können Sie uns kontaktieren",
    },
    customer_support: {
      eng: "customer support",
      rus: "службой поддержки",
      ukr: "службою підтримки",
      deu: "Kundensupport",
    },
    support_service: {
      eng: "support service",
      rus: "службой поддержки",
      ukr: "службою підтримки",
      deu: "Support-Service",
    },
    earn_m2c: {
      eng: "Earn M2C at 10%",
      rus: "Зарабатывайте M2C на 10%",
      ukr: "Заробляйте M2C на 10%",
      deu: "Verdienen Sie M2C mit 10%",
    },

    stake_to_earn_translation: {
      eng: "Stake to earn M2C at 10% APR",
      rus: "Стейк для заработка M2C под 10% годовых",
      ukr: "Стейк для заробітку M2C під 10% річних",
      deu: "Stake, um M2C mit 10% Jahreszins zu verdienen",
    },
    available_limit_translation: {
      eng: "Available limit:",
      rus: "Доступный лимит:",
      ukr: "Доступний ліміт:",
      deu: "Verfügbarer Limit:",
    },
    select_asset: {
      eng: "Select Asset",
      rus: "Выберите актив",
      ukr: "Виберіть актив",
      deu: "Aktiv auswählen",
    },
    my_wallet_deposit_next: {
      eng: "Next",
      rus: "Далее",
      ukr: "Далі",
      deu: "Weiter",
    },
    my_wallet_deposit_select_network: {
      eng: "Select Network",
      rus: "Выберите сеть",
      ukr: "Виберіть мережу",
      deu: "Netzwerk auswählen",
    },
    deposit_address: {
      eng: "Deposit Address",
      rus: "Адрес для депозита",
      ukr: "Адреса для депозиту",
      deu: "Einzahlungsadresse",
    },
    my_wallet_deposit_select_network_back: {
      eng: "Back",
      rus: "Назад",
      ukr: "Назад",
      deu: "Zurück",
    },
    network_fee: {
      eng: "Network Fee",
      rus: "Комиссия сети",
      ukr: "Комісія мережі",
      deu: "Netzwerkgebühr",
    },
    get_address: {
      eng: "Get address",
      rus: "Получить адрес",
      ukr: "Отримати адресу",
      deu: "Adresse erhalten",
    },
    address_updated: {
      eng: "Address Updated!",
      rus: "Адрес обновлен!",
      ukr: "Адреса оновлена!",
      deu: "Adresse aktualisiert!",
    },
    deposits_to_old_address: {
      eng: "Deposits to your old address",
      rus: "Депозиты на ваш старый адрес",
      ukr: "Депозити на ваш старий адрес",
      deu: "Einzahlungen an Ihre alte Adresse",
    },
    new_address_obtained: {
      eng: "You have successfully obtained a new",
      rus: "Вы успешно получили новый",
      ukr: "Ви успішно отримали нову",
      deu: "Sie haben erfolgreich eine neue",
    },
    no_address_created: {
      eng: "No",
      rus: "Нет",
      ukr: "Немае",
      deu: "Nein",
    },
    my_wallet_deposit_withdrawal: {
      eng: "Withdrawal",
      rus: "Вывод",
      ukr: "Виведення",
      deu: "Auszahlung",
    },
    address: {
      eng: "Address",
      rus: "Адрес",
      ukr: "Адреса",
      deu: "Adresse",
    },
    enter_withdraw_address: {
      eng: "Please enter a withdraw address",
      rus: "Пожалуйста, введите адрес для вывода",
      ukr: "Будь ласка, введіть адресу для виведення",
      deu: "Bitte geben Sie eine Auszahlungsadresse ein",
    },
    amount_m2c: {
      eng: "Amount",
      rus: "Сумма",
      ukr: "Сума",
      deu: "Betrag",
    },
    available_balance_translation: {
      eng: "Available balance:",
      rus: "Доступный баланс:",
      ukr: "Доступний баланс:",
      deu: "Verfügbarer Saldo:"
    }
,    minimum_withdrawal_amount_translation: {
  eng: "Minimum withdrawal amount",
  rus: "Минимальная сумма для вывода",
  ukr: "Мінімальна сума для виведення",
  deu: "Mindestabhebungsbetrag"
}
,
    enter_amount_no_higher: {
      eng: "Please enter an amount no higher than your available balance",
      rus: "Пожалуйста, введите сумму не превышающую ваш доступный баланс",
      ukr: "Будь ласка, введіть суму, що не перевищує ваш доступний баланс",
      deu: "Bitte geben Sie einen Betrag ein, der nicht höher ist als Ihr verfügbarer Kontostand",
    },
    deposit_crypto: {
      eng: "Deposit crypto",
      rus: "Пополнить криптовалюту",
      ukr: "Поповнити криптовалюту",
      deu: "Krypto einzahlen",
    },
    withdraw_crypto: {
      eng: "Withdraw crypto",
      rus: "Вывести криптовалюту",
      ukr: "Вивести криптовалюту",
      deu: "Krypto abheben",
    },
    deposit_addressed: {
      eng: "deposit address",
      rus: "адреса для депозита",
      ukr: "адреси для депозиту",
      deu: "Einzahlungsadresse",
    },
    not_automatic_credit: {
      eng: "will not be automatically credited",
      rus: "не будут автоматически зачислены",
      ukr: "не будуть автоматично зараховані",
      deu: "werden nicht automatisch gutgeschrieben",
    },
    use_new_address: {
      eng: "Please be sure to use the new address for all future deposits.",
      rus: "Пожалуйста, обязательно используйте новый адрес для всех последующих депозитов.",
      ukr: "Будьте впевнені, що використовуєте нову адресу для всіх майбутніх депозитів.",
      deu: "Bitte stellen Sie sicher, dass Sie die neue Adresse für alle zukünftigen Einzahlungen verwenden.",
    },
    no_deposit_address_created: {
      eng: "No deposit address has been previously created. Please first create a deposit address.",
      rus: "Ранее не был создан адрес для депозита. Пожалуйста, сначала создайте адрес для депозита.",
      ukr: "Раніше не створювався адресу для депозиту. Будь ласка, спочатку створіть адресу для депозиту.",
      deu: "Es wurde zuvor keine Einzahlungsadresse erstellt. Bitte erstellen Sie zuerst eine Einzahlungsadresse.",
    },

    copied_to_clipboard: {
      eng: "Copied to the clipboard",
      rus: "Скопировано в буфер обмена",
      ukr: "Скопійовано до буферу обміну",
      deu: "In die Zwischenablage kopiert",
    },
    my_wallet_deposit_select_network_next: {
      eng: "Next",
      rus: "Далее",
      ukr: "Далі",
      deu: "Weiter",
    },
    aml_verification: {
      eng: "AML verification is taking place. Based on the results, amounts may be blocked. In this case please contact",
      rus: "Идет проверка по AML. В зависимости от результатов, суммы могут быть заблокированы. В этом случае, пожалуйста, свяжитесь с",
      ukr: "Йде перевірка згідно AML. Залежно від результатів, суми можуть бути заблоковані. У цьому випадку, будь ласка, зв'яжіться з",
      deu: "AML-Verifizierung findet statt. Basierend auf den Ergebnissen können Beträge blockiert werden. In diesem Fall kontaktieren Sie bitte",
    },
    send: {
      eng: "Send",
      rus: "Отправить",
      ukr: "Відправити",
      deu: "Senden",
    },
    projects_add_title: {
      eng: "Add project",
      rus: "Добавить проект",
      ukr: "Додати проект",
      deu: "Projekt hinzufügen",
    },
    projects_item_expertise_started: {
      eng: "Expertise started successfully",
      rus: "Экспертиза успешно начата",
      ukr: "Експертиза успішно розпочата",
      deu: "Expertise erfolgreich gestartet",
    },
    exchange_pair: {
      eng: "Quantity",
      rus: "Количество",
      ukr: "Кількість",
      deu: "Menge",
    },
    exchange_coins_table_pair: {
      eng: "Pair",
      rus: "Пара",
      ukr: "Пара",
      deu: "Paar",
    },
    exchange_coins_table_price: {
      eng: "Price",
      rus: "Цена",
      ukr: "Ціна",
      deu: "Preis",
    },
    exchange_coins_table_change: {
      eng: "Change",
      rus: "Изменение",
      ukr: "Зміна",
      deu: "Änderung",
    },
    exchange_coins_table_amount: {
      eng: "Amount",
      rus: "Количество",
      ukr: "Кількість",
      deu: "Menge",
    },
    exchange_coins_table_time: {
      eng: "Time",
      rus: "Время",
      ukr: "Час",
      deu: "Zeit",
    },
    exchange_coins_table_total: {
      eng: "Total",
      rus: "Всего",
      ukr: "Всього",
      deu: "Gesamt",
    },
    exchange_user_orders_tab_open: {
      eng: "Open orders",
      rus: "Открытые ордера",
      ukr: "Відкриті замовлення",
      deu: "Offene Bestellungen",
    },
    exchange_user_orders_tab_history: {
      eng: "History",
      rus: "История",
      ukr: "Історія",
      deu: "Verlauf",
    },
    exchange_user_orders_tab_open_nothing: {
      eng: "Now you haven't open orders",
      rus: "Сейчас у вас нет открытых ордеров",
      ukr: "Зараз у вас немає відкритих замовлень",
      deu: "Sie haben derzeit keine offenen Bestellungen",
    },
    exchange_user_orders_tab_history_nothing: {
      eng: "History is empty",
      rus: "История пуста",
      ukr: "Історія порожня",
      deu: "Der Verlauf ist leer",
    },
    exchange_spot_price: {
      eng: "Price",
      rus: "Цена",
      ukr: "Ціна",
      deu: "Preis",
    },
    exchange_spot_count: {
      eng: "Count",
      rus: "Количество",
      ukr: "Кількість",
      deu: "Anzahl",
    },
    exchange_spot_total: {
      eng: "Total",
      rus: "Итого",
      ukr: "Разом",
      deu: "Gesamt",
    },
    contract_launch_new: {
      eng: "Launch new contract",
      rus: "Запустить новый контракт",
      ukr: "Запустити новий контракт",
      deu: "Neuen Vertrag starten",
    },
    contract_accept_asset: {
      eng: "Accepted asset",
      rus: "Принимаемый актив",
      ukr: "Принімаючий актив",
      deu: "Akzeptiertes Gut",
    },
    contract_pledged: {
      eng: "Pledged asset",
      rus: "Заложенный актив",
      ukr: "Заложений актив",
      deu: "Verpfändetes Gut",
    },
    contract_payment_asset: {
      eng: "Payment asset",
      rus: "Актив для выплат",
      ukr: "Актив для виплат",
      deu: "Zahlungsgut",
    },
    contract_limit: {
      eng: "Collateral limit",
      rus: "Залоговый лимит",
      ukr: "Ліміт застави",
      deu: "Kollateralgrenze",
    },
    contract_term: {
      eng: "Contract term",
      rus: "Срок контракта",
      ukr: "Термін контракту",
      deu: "Vertragslaufzeit",
    },
    contract_create_btn: {
      eng: "Create",
      rus: "Создать",
      ukr: "Створити",
      deu: "Erstellen",
    },
    kyc_add_last_name: {
      eng: "Last name",
      rus: "Фамилия",
      ukr: "Прізвище",
      deu: "Nachname",
    },
    kyc_add_first_name: {
      eng: "First name",
      rus: "Имя",
      ukr: "Ім'я",
      deu: "Vorname",
    },
    kyc_add_additional_name: {
      eng: "Middle name or other additional name",
      rus: "Отчество или другое дополнительное имя",
      ukr: "По батькові або інше додаткове ім'я",
      deu: "Zweiter Vorname oder zusätzlicher Name",
    },
    kyc_add_sex: {
      eng: "Sex",
      rus: "Пол",
      ukr: "Стать",
      deu: "Geschlecht",
    },
    kyc_add_mobile_number: {
      eng: "Mobile phone number",
      rus: "Мобильный телефон",
      ukr: "Мобільний телефон",
      deu: "Handynummer",
    },
    kyc_add_email_address: {
      eng: "Email address",
      rus: "Email адрес",
      ukr: "Email адреса",
      deu: "E-Mail-Adresse",
    },
    kyc_add_state_or_province: {
      eng: "Name of state/province/region/prefecture",
      rus: "Название штата/провинции/региона/префектуры",
      ukr: "Назва штату/області/регіону/префектури",
      deu: "Name des Bundesstaates/der Provinz/der Region/der Präfektur",
    },
    kyc_add_city: {
      eng: "Name of city/town",
      rus: "Название города/населенного пункта",
      ukr: "Назва міста/населеного пункту",
      deu: "Name der Stadt/Gemeinde",
    },
    kyc_add_postal_code: {
      eng: "Postal code",
      rus: "Почтовый индекс",
      ukr: "Поштовий індекс",
      deu: "Postleitzahl",
    },
    kyc_add_address_country_code: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    kyc_add_address: {
      eng: "Address",
      rus: "Адрес",
      ukr: "Адреса",
      deu: "Adresse",
    },
    kyc_add_birth_date: {
      eng: "Date of birth",
      rus: "Дата рождения",
      ukr: "Дата народження",
      deu: "Geburtsdatum",
    },
    kyc_add_birth_place: {
      eng: "Place of birth (city, state, country as on passport)",
      rus: "Место рождения (город, штат, страна в паспорте)",
      ukr: "Місце народження (місто, область, країна, як у паспорті)",
      deu: "Geburtsort (Stadt, Bundesland, Land wie im Reisepass)",
    },
    kyc_add_birth_country_code: {
      eng: "Country of birth",
      rus: "Страна рождения",
      ukr: "Країна народження",
      deu: "Geburtsland",
    },
    kyc_add_bank_account_number: {
      eng: "Number identifying bank account",
      rus: "Номер, идентифицирующий банковский счет",
      ukr: "Номер, що ідентифікує банківський рахунок",
      deu: "Nummer zur Identifizierung des Bankkontos",
    },
    kyc_add_bank_account_type: {
      eng: "Account type",
      rus: "Тип счета",
      ukr: "Тип рахунку",
      deu: "Kontotyp",
    },
    kyc_add_bank_number: {
      eng: "Number identifying bank in national banking system",
      rus: "Номер, идентифицирующий банк в национальной банковской системе",
      ukr: "Номер, що ідентифікує банк в національній банківській системі",
      deu: "Nummer zur Identifizierung der Bank im nationalen Bankensystem",
    },
    kyc_add_bank_phone_number: {
      eng: "Phone number with country code for bank",
      rus: "Телефонный номер с кодом страны для банка",
      ukr: "Телефонний номер з кодом країни для банку",
      deu: "Telefonnummer mit Ländervorwahl für die Bank",
    },
    kyc_add_bank_branch_number: {
      eng: "Number identifying bank branch",
      rus: "Номер, идентифицирующий филиал банка",
      ukr: "Номер, що ідентифікує відділення банку",
      deu: "Nummer zur Identifizierung der Bankfiliale",
    },
    kyc_add_tax_id: {
      eng: "Tax identifier of user in their country",
      rus: "Идентификатор налогоплательщика пользователя в их стране",
      ukr: "Ідентифікатор податкового платника користувача у їхній країні",
      deu: "Steueridentifikationsnummer des Benutzers in ihrem Land",
    },
    kyc_add_tax_id_name: {
      eng: "Name of the tax ID",
      rus: "Название налогового идентификатора",
      ukr: "Назва податкового ідентифікатора",
      deu: "Name der Steueridentifikationsnummer",
    },
    kyc_add_occupation: {
      eng: "Occupation ISCO code",
      rus: "Код профессии по классификации ISCO",
      ukr: "Код професії за класифікацією ISCO",
      deu: "Berufs-ISCO-Code",
    },
    kyc_add_employer_name: {
      eng: "Name of employer",
      rus: "Название работодателя",
      ukr: "Назва роботодавця",
      deu: "Name des Arbeitgebers",
    },
    kyc_add_employer_address: {
      eng: "Address of employer",
      rus: "Адрес работодателя",
      ukr: "Адреса роботодавця",
      deu: "Adresse des Arbeitgebers",
    },
    kyc_add_id_type: {
      eng: "ID Type",
      rus: "Тип удостоверения личности",
      ukr: "Тип підтвердження особи",
      deu: "Ausweistyp",
    },
    kyc_add_id_country_code: {
      eng: "Country",
      rus: "Страна",
      ukr: "Країна",
      deu: "Land",
    },
    kyc_add_id_issue_date: {
      eng: "ID issue date",
      rus: "Дата выдачи удостоверения личности",
      ukr: "Дата видачі підтвердження особи",
      deu: "Ausstellungsdatum des Ausweises",
    },
    kyc_add_id_expiration_date: {
      eng: "ID expiration date",
      rus: "Срок действия удостоверения личности",
      ukr: "Термін дії підтвердження особи",
      deu: "Ablaufdatum des Ausweises",
    },
    kyc_add_id_number: {
      eng: "Passport or ID number",
      rus: "Номер паспорта или удостоверения личности",
      ukr: "Номер паспорта або підтвердження особи",
      deu: "Pass- oder Ausweisnummer",
    },
    kyc_add_photo_id_front: {
      eng: "Image of front of photo ID or passport",
      rus: "Изображение передней части фотоудостоверения личности или паспорта",
      ukr: "Зображення передньої частини фотоідентифікаційного документа або паспорта",
      deu: "Bild der Vorderseite des Lichtbildausweises oder Reisepasses",
    },
    kyc_add_photo_id_back: {
      eng: "Image of back of photo ID or passport",
      rus: "Изображение задней части фотоудостоверения личности или паспорта",
      ukr: "Зображення задньої частини фотоідентифікаційного документа або паспорта",
      deu: "Bild der Rückseite des Lichtbildausweises oder Reisepasses",
    },
    kyc_add_notary_approval_of_photo_id: {
      eng: "Image of notary's approval of photo ID or passport",
      rus: "Изображение подтверждения нотариуса на фотоудостоверении личности или паспорте",
      ukr: "Зображення підтвердження нотаріуса на фотоідентифікаційному документі або паспорті",
      deu: "Bild der Notarbestätigung des Lichtbildausweises oder Reisepasses",
    },
    kyc_add_photo_proof_residence: {
      eng: "Image of a utility bill, bank statement or similar with the name and address",
      rus: "Изображение коммунального счета, выписки из банка или аналогичного документа с указанием имени и адреса",
      ukr: "Зображення комунального рахунку, виписки з банку або аналогічного документа з ім'ям та адресою",
      deu: "Bild einer Rechnung für Versorgungsleistungen, Kontoauszug oder ähnlichen Dokuments mit Namen und Adresse",
    },
    kyc_add_proof_of_income: {
      eng: "Image of user's proof of income document",
      rus: "Изображение документа, подтверждающего доход пользователя",
      ukr: "Зображення документа, що підтверджує дохід користувача",
      deu: "Bild des Einkommensnachweisdokuments des Benutzers",
    },
    kyc_add_proof_of_liveness: {
      eng: "Image file of user as a liveness proof",
      rus: "Изображение пользователя в качестве доказательства живости",
      ukr: "Зображення користувача як підтвердження життєздатності",
      deu: "Bilddatei des Benutzers als Nachweis der Lebendigkeit",
    },
    kyc_add_organization_name: {
      eng: "Full organization name as on the incorporation",
      rus: "Полное название организации, как указано в учредительных документах",
      ukr: "Повна назва організації, як зазначено в установчих документах",
      deu: "Vollständiger Name der Organisation wie in der Gründungsurkunde",
    },
    kyc_add_organization_VAT_number: {
      eng: "Organization VAT number",
      rus: "Номер НДС организации",
      ukr: "Номер ПДВ організації",
      deu: "Umsatzsteuer-Identifikationsnummer der Organisation",
    },
    kyc_add_organization_registration_number: {
      eng: "Organization registration number",
      rus: "Регистрационный номер организации",
      ukr: "Реєстраційний номер організації",
      deu: "Registrierungsnummer der Organisation",
    },
    kyc_add_organization_registration_date: {
      eng: "Date the organization was registered",
      rus: "Дата регистрации организации",
      ukr: "Дата реєстрації організації",
      deu: "Datum der Organisationseintragung",
    },
    kyc_add_organization_registered_address: {
      eng: "Organization registered address",
      rus: "Зарегистрированный адрес организации",
      ukr: "Зареєстрована адреса організації",
      deu: "Registrierte Adresse der Organisation",
    },
    kyc_add_organization_number_of_shareholders: {
      eng: "Organization shareholder number",
      rus: "Количество акционеров организации",
      ukr: "Кількість акціонерів організації",
      deu: "Anzahl der Aktionäre der Organisation",
    },
    kyc_add_organization_shareholder_name: {
      eng: "Can be an organization or a person",
      rus: "Может быть организацией или физическим лицом",
      ukr: "Може бути організацією або фізичною особою",
      deu: "Kann eine Organisation oder eine Person sein",
    },
    kyc_add_organization_photo_incorporation_doc: {
      eng: "Image of incorporation documents",
      rus: "Изображение учредительных документов",
      ukr: "Зображення установчих документів",
      deu: "Bild der Gründungsdokumente",
    },
    kyc_add_organization_photo_proof_address: {
      eng: "Image of a utility bill, bank statement with the organization's name and address",
      rus: "Изображение коммунального счета, выписки из банка с указанием названия и адреса организации",
      ukr: "Зображення комунального рахунку, виписки з банку з вказівкою назви та адреси організації",
      deu: "Bild einer Versorgungsrechnung, Kontoauszug mit dem Namen und der Adresse der Organisation",
    },
    kyc_add_organization_address_country_code: {
      eng: "Country code for current address",
      rus: "Код страны текущего адреса",
      ukr: "Код країни поточної адреси",
      deu: "Ländercode für aktuelle Adresse",
    },
    kyc_add_organization_state_or_province: {
      eng: "Name of state/province/region/prefecture",
      rus: "Название штата/провинции/региона/префектуры",
      ukr: "Назва штату/області/регіону/префектури",
      deu: "Name des Bundesstaates/der Provinz/Region/Präfektur",
    },
    kyc_add_organization_city: {
      eng: "Name of city/town",
      rus: "Название города/населенного пункта",
      ukr: "Назва міста/населеного пункту",
      deu: "Name der Stadt/Gemeinde",
    },
    kyc_add_organization_postal_code: {
      eng: "Postal or other code identifying organization's locale",
      rus: "Почтовый или другой код, идентифицирующий местоположение организации",
      ukr: "Поштовий або інший код, що ідентифікує розташування організації",
      deu: "Postleitzahl oder anderer Code zur Identifizierung des Standorts der Organisation",
    },
    kyc_add_organization_director_name: {
      eng: "Organization registered managing director",
      rus: "Имя зарегистрированного исполнительного директора организации",
      ukr: "Ім'я зареєстрованого керівника організації",
      deu: "Name des registrierten Geschäftsführers der Organisation",
    },
    kyc_add_organization_website: {
      eng: "Organization website",
      rus: "Веб-сайт организации",
      ukr: "Веб-сайт організації",
      deu: "Website der Organisation",
    },
    kyc_add_organization_email: {
      eng: "Organization contact email",
      rus: "Контактный email организации",
      ukr: "Контактний email організації",
      deu: "Kontakt-E-Mail der Organisation",
    },
    kyc_add_organization_phone: {
      eng: "Organization contact phone",
      rus: "Контактный телефон организации",
      ukr: "Контактний телефон організації",
      deu: "Kontakttelefon der Organisation",
    },
    kyc_add_title_person: {
      eng: "Information about significant parties",
      rus: "Информация о значимых сторонах",
      ukr: "Інформація про значущі сторони",
      deu: "Informationen zu bedeutenden Parteien",
    },
    kyc_add_title_entity: {
      eng: "LEGAL ENTITY INFORMATION",
      rus: "ИНФОРМАЦИЯ О ПРАВОВОМ ЛИЦЕ",
      ukr: "ІНФОРМАЦІЯ ПРО ПРАВОВУ ОСОБУ",
      deu: "INFORMATIONEN ZUR RECHTSPERSON",
    },
    kyc_add_step_general_info: {
      eng: "General",
      rus: "Общая информация",
      ukr: "Загальна інформація",
      deu: "Allgemeines",
    },
    kyc_add_step_id_info: {
      eng: "ID/Passport",
      rus: "ID/Паспорт",
      ukr: "ID/Паспорт",
      deu: "Ausweis/Reisepass",
    },
    kyc_add_step_address_info: {
      eng: "Address",
      rus: "Адрес",
      ukr: "Адреса",
      deu: "Adresse",
    },
    kyc_add_step_documents_info: {
      eng: "Sources of income",
      rus: "Источники дохода",
      ukr: "Джерела доходу",
      deu: "Einkommensquellen",
    },
    convert_title: {
      eng: "Convert",
      rus: "Обмен",
      ukr: "Обмiн",
      deu: "Umwandeln",
    },
    convert_alert: {
      eng: "If you have any questions about the wallet, you can contact our support service",
      rus: "Если у вас есть вопросы по обменнику, вы можете связаться с нашей службой поддержки",
      ukr: "Якщо у вас є питання щодо обмінника, ви можете зв’язатися з нашою службою підтримки",
      deu: "Wenn Sie Fragen zum Austauschdienst haben, können Sie sich an unser Support-Team wenden",
    },
    convert_from: {
      eng: "From",
      rus: "С",
      ukr: "З",
      deu: "Von",
    },
    convert_to: {
      eng: "To",
      rus: "На",
      ukr: "До",
      deu: "Zu",
    },
    fee: {
      eng: "Fee",
      rus: "Комиссия",
      ukr: "Комісія",
      deu: "Provision",
    },
    no_transactions: {
      eng: "No transactions",
      rus: "Нет транзакций",
      ukr: "Немає транзакцій",
      deu: "Keine Transaktionen",
    },

    convert_pre_submit: {
      eng: "Enter an amount",
      rus: "Введите количество",
      ukr: "Введіть кількість",
      deu: "Geben Sie die Anzahl ein",
    },
    convert_submit: {
      eng: "Convert",
      rus: "Конвертировать",
      ukr: "Конвертувати",
      deu: "Umwandeln",
    },
    convert_balance: {
      eng: "Balance:",
      rus: "Баланс:",
      ukr: "Баланс:",
      deu: "Guthaben:",
    },
    convert_amount: {
      eng: "Amount must be in range:",
      rus: "Сумма должна быть в диапазоне:",
      ukr: "Сума повинна бути в діапазоні:",
      deu: "Betrag muss im Bereich liegen:",
    },
    convert_amount_min: {
      eng: "Amount must be greater than:",
      rus: "Сумма должна быть больше:",
      ukr: "Сума повинна бути більше:",
      deu: "Betrag muss größer sein als:",
    },
    convert_amount_max: {
      eng: "Amount must be less than:",
      rus: "Сумма должна быть меньше:",
      ukr: "Сума повинна бути менше:",
      deu: "Betrag muss kleiner sein als:",
    },
    convert_modal_title: {
      eng: "Confirm",
      rus: "Подтвердить",
      ukr: "Підтвердити",
      deu: "Bestätigen",
    },
    convert_modal_rate: {
      eng: "Rate",
      rus: "Курс",
      ukr: "Курс",
      deu: "Kurs",
    },
    convert_modal_inverse_rate: {
      eng: "Inverse rate",
      rus: "Обратный курс",
      ukr: "Зворотній курс",
      deu: "Umgekehrter Kurs",
    },
    convert_modal_fees: {
      eng: "Transaction Fees",
      rus: "Комиссия за транзакцию",
      ukr: "Комісія за транзакцію",
      deu: "Transaktionsgebühren",
    },
    convert_modal_no_fees: {
      eng: "No Fees",
      rus: "Без комиссии",
      ukr: "Без комісії",
      deu: "Keine Gebühren",
    },
    convert_modal_recive: {
      eng: "You will receive",
      rus: "Вы получите",
      ukr: "Ви отримаєте",
      deu: "Sie erhalten",
    },
    convert_modal_recive_success: {
      eng: "You receive",
      rus: "Вы получили",
      ukr: "Ви отримали",
      deu: "Sie haben erhalten",
    },
    convert_modal_confirm_btn: {
      eng: "Confirm",
      rus: "Подтвердить",
      ukr: "Підтвердити",
      deu: "Bestätigen",
    },
    convert_modal_refresh_btn: {
      eng: "Refresh Quota",
      rus: "Обновить квоту",
      ukr: "Оновити квоту",
      deu: "Quota aktualisieren",
    },
    convert_modal_balance: {
      eng: "Your account has insufficient balance. Please fund your account",
      rus: "На вашем счете недостаточно средств. Пожалуйста, пополните счет",
      ukr: "На вашому рахунку недостатньо коштів. Будь ласка, поповніть рахунок",
      deu: "Auf Ihrem Konto ist nicht genügend Guthaben vorhanden. Bitte füllen Sie Ihr Konto auf",
    },
    convert_modal_spent: {
      eng: "You spent",
      rus: "Вы потратили",
      ukr: "Ви витратили",
      deu: "Sie haben ausgegeben",
    },
    convert_modal_title_success: {
      eng: "Successfully",
      rus: "Успешно",
      ukr: "Успішно",
      deu: "Erfolgreich",
    },
    my_complex: {
      eng: "My Complex",
      rus: "Мои комплексы",
      ukr: "Мої комплекси",
      deu: "Mein Komplex",
    },
    all_complex: {
      eng: "All Complex",
      rus: "Все комплексы",
      ukr: "Усі комплекси",
      deu: "Gesamtkomplex",
    },
    common_complex: {
      eng: "Common Complex",
      rus: "Общий комплекс",
      ukr: "Загальний комплекс",
      deu: "Gemeinsamer Komplex",
    },
    name_of_the_complex: {
      eng: "Name of the Complex",
      rus: "Название комплекса",
      ukr: "Назва комплексу",
      deu: "Name des Komplexes",
    },
    enter_name_of_the_complex: {
      eng: "Enter name of the Complex",
      rus: "Введите название комплекса",
      ukr: "Введіть назву комплексу",
      deu: "Geben Sie den Namen des Komplexes ein",
    },
    type_of_the_complex: {
      eng: "Type of the Complex",
      rus: "Тип комплекса",
      ukr: "Тип комплексу",
      deu: "Art des Komplexes",
    },
    available: {
      eng: "Available",
      rus: "Доступно",
      ukr: "Доступно",
      deu: "Verfügbar",
    },
    photo_of_the_complex: {
      eng: "Photo of the Complex*",
      rus: "Фото комплекса*",
      ukr: "Фото комплексу*",
      deu: "Foto des Komplexes*",
    }
    ,main_photo_of_the_complex: {
      eng: "Main photo of the Complex*",
      rus: "Главное фото комплекса",
      ukr: "Головне фото комплексу",
      deu: "Hauptfoto des Komplexes",
    },
    characteristics: {
      eng: "Characteristics",
      rus: "Характеристики",
      ukr: "Характеристики",
      deu: "Merkmale",
    },
    completion_date: {
      eng: "Completion date",
      rus: "Дата завершения",
      ukr: "Дата завершення",
      deu: "Fertigstellungsdatum",
    },
    number_of_blocks: {
      eng: "Number of blocks",
      rus: "Количество блоков",
      ukr: "Кількість блоків",
      deu: "Anzahl der Blöcke",
    },
    number_of_storeys: {
      eng: "Number of storeys",
      rus: "Количество этажей",
      ukr: "Кількість поверхів",
      deu: "Anzahl der Stockwerke",
    },
    number_of_apartments: {
      eng: "Number of apartments",
      rus: "Количество квартир",
      ukr: "Кількість квартир",
      deu: "Anzahl der Wohnungen",
    },
    construction_technology: {
      eng: "Construction technology",
      rus: "Технология строительства",
      ukr: "Технологія будівництва",
      deu: "Bau-Technologie",
    },
    wall_material: {
      eng: "Wall material",
      rus: "Материал стен",
      ukr: "Матеріал стін",
      deu: "Wandmaterial",
    },
    ceiling_height_m: {
      eng: "Ceiling height (m)",
      rus: "Высота потолков (м)",
      ukr: "Висота стель (м)",
      deu: "Deckenhöhe (m)",
    },
    winter_heating_system: {
      eng: "Winter heating system",
      rus: "Система зимнего отопления",
      ukr: "Система зимового опалення",
      deu: "Winterheizsystem",
    },
    number_of_parking_spaces: {
      eng: "Number of parking spaces",
      rus: "Количество парковочных мест",
      ukr: "Кількість паркувальних місць",
      deu: "Anzahl der Parkplätze",
    },
    plans: {
      eng: "Plans",
      rus: "Планы",
      ukr: "Плани",
      deu: "Pläne",
    },
    floor_number: {
      eng: "Floor Number",
      rus: "Номер этажа",
      ukr: "Номер поверху",
      deu: "Stockwerksnummer",
    },
    drop_image_files_to_upload_or_click: {
      eng: "Drop image files to upload (or click)",
      rus: "Перетащите изображения для загрузки (или кликните)",
      ukr: "Перетягніть зображення для завантаження (або клікніть)",
      deu: "Bilddateien zum Hochladen ablegen (oder klicken)",
    },
    floor_plan: {
      eng: "Floor plan",
      rus: "План этажа",
      ukr: "План поверху",
      deu: "Grundriss",
    },
    maximum_number_of_files_10: {
      eng: "Maximum number of files: 10",
      rus: "Максимальное количество файлов: 10",
      ukr: "Максимальна кількість файлів: 10",
      deu: "Maximale Anzahl von Dateien: 10",
    },
    maximum_size_of_each_image_3mb: {
      eng: "Maximum size of each image: 3Mb",
      rus: "Максимальный размер каждого изображения: 3 Мб",
      ukr: "Максимальний розмір кожного зображення: 3 Мб",
      deu: "Maximale Größe jedes Bildes: 3Mb",
    },
    limit_translation: {
      eng: "Limit:",
      rus: "Лимит:",
      ukr: "Ліміт:",
      deu: "Limit:",
    },
    total_staked_translation: {
      eng: "Total staked:",
      rus: "Застейкано:",
      ukr: "Застейкано:",
      deu: "Gesamteinsatz:",
    },
    end_time_translation: {
      eng: "End time",
      rus: "Время окончания",
      ukr: "Час закінчення",
      deu: "Endzeit",
    },
    start_time_translation: {
      eng: "Start time",
      rus: "Время начала",
      ukr: "Час початку",
      deu: "Startzeit",
    },
    stake_translation: {
      eng: "Stake",
      rus: "Стейки",
      ukr: "Стейкi",
      deu: "Einsatz",
    },
    exclusive: {
      eng: "Exclusive",
      rus: "Эксклюзивный",
      ukr: "Ексклюзивний",
      deu: "Exklusiv",
    },
    unlimited: {
      eng: "Unlimited",
      rus: "Неограниченный",
      ukr: "Необмежений",
      deu: "Unbegrenzt",
    },
    earn: {
      eng: "Earn",
      rus: "Зарабатывать",
      ukr: "Заробляти",
      deu: "Verdienen",
    },
    at: {
      eng: "at",
      rus: "под",
      ukr: "под",
      deu: "bei",
    },max: {
      eng: "MAX",
      rus: "МАКС",
      ukr: "МАКС",
      deu: "MAX",
    },


    staking_translation: {
      eng: "Staking",
      rus: "Стейкинг",
      ukr: "Стейкінг",
      deu: "Staking",
    },
    alternative_mining_translation: {
      eng: "Looking for a less resource-intensive alternative to mining? Use your METRUM tokens to earn more tokens, for Free",
      rus: "Ищете менее ресурсоемкую альтернативу майнингу? Используйте ваши токены METRUM, чтобы заработать больше токенов, бесплатно",
      ukr: "Шукаєте менш ресурсомістку альтернативу майнінгу? Використовуйте ваші токени METRUM, щоб заробити більше токенів, безкоштовно",
      deu: "Suchen Sie eine weniger ressourcenintensive Alternative zum Mining? Verwenden Sie Ihre METRUM-Token, um mehr Token kostenlos zu verdienen",
    },
    my_staking_history_translation: {
      eng: "My Staking History",
      rus: "История Моего Стейкинга",
      ukr: "Історія Мого Стейкінгу",
      deu: "Meine Staking-Geschichte",
    },
    add: {
      eng: "Add",
      rus: "Добавить",
      ukr: "Додати",
      deu: "Hinzufügen",
    },
    land_parcel_documents: {
      eng: "Land parcel documents*",
      rus: "Документы на земельный участок*",
      ukr: "Документи на земельну ділянку*",
      deu: "Grundstücksdokumente*",
    },
    construction_documents: {
      eng: "Construction documents*",
      rus: "Документы на строительство*",
      ukr: "Документи на будівництво*",
      deu: "Baudokumente*",
    },
    construction_participants: {
      eng: "Construction participants*",
      rus: "Участники строительства*",
      ukr: "Учасники будівництва*",
      deu: "Bau-Teilnehmer*",
    },
    add_complex: {
      eng: "Add Complex",
      rus: "Добавить комплекс",
      ukr: "Додати комплекс",
      deu: "Komplex hinzufügen",
    },

    convert_modal_wallet_btn: {
      eng: "Back to wallet",
      rus: "Вернуться в кошелек",
      ukr: "Повернутися у гаманець",
      deu: "Zurück zum Portemonnaie",
    },
    convert_modal_ok_btn: {
      eng: "OK",
      rus: "OK",
      ukr: "OK",
      deu: "OK",
    },
    information_not_found: {
      eng: "Information not found",
      rus: "Информация не найдена",
      ukr: "Інформація не знайдена",
      deu: "Information nicht gefunden",
    },
    unit_add_building_number: {
      eng: "Building number",
      rus: "Номер здания",
      ukr: "Номер будівлі",
      deu: "Gebäudenummer",
    },
    unit_add_entrance: {
      eng: "Entrance",
      rus: "Подъезд",
      ukr: "Під'їзд",
      deu: "Eingang",
    },
    unit_add_conditions: {
      eng: "Conditions",
      rus: "Условия",
      ukr: "Умови",
      deu: "Bedingungen",
    },
    unit_add_success: {
      eng: "Unit added successfully",
      rus: "Unit добавлен успешно",
      ukr: "Unit додан успішно",
      deu: "Unit erfolgreich hinzugefügt",
    },
    error_convert_fetch: {
      eng: "Fetch error, try again later.",
      rus: "Ошибка получения. Повторите попытку позже.",
      ukr: "Помилка отримання, спробуйте пізніше.",
      deu: "Fehler beim Abrufen, versuchen Sie es später noch einmal.",
    },
    error_convert_confirm: {
      eng: "The exchange request has not been completed.",
      rus: "Заявка на обмен не завершена.",
      ukr: "Запит на обмін не виконано.",
      deu: "Die Umtauschanfrage wurde nicht abgeschlossen.",
    },
    error_convert_wallet: {
      eng: "Error getting wallet information.",
      rus: "Ошибка получения информации о кошельке.",
      ukr: "Помилка отримання інформації про гаманець.",
      deu: "Fehler beim Abrufen von Wallet-Informationen.",
    },
    error_convert_pairs: {
      eng: "Error getting pairs list.",
      rus: "Ошибка получения списка пар.",
      ukr: "Помилка отримання списку пар.",
      deu: "Fehler beim Abrufen der Paarliste.",
    },
    error_tickets: {
      eng: "No tickets found.",
      rus: "Tickets не найдены.",
      ukr: "Tickets не знайдено.",
      deu: "Keine Tickets gefunden.",
    },
    error_convert_rate: {
      eng: "Error getting exchange rate.",
      rus: "Ошибка получения курса.",
      ukr: "Помилка отримання обмінного курсу.",
      deu: "Fehler beim Abrufen des Wechselkurses.",
    },
    plans_translate: {
      eng: "Plans",
      rus: "Планы",
      ukr: "Плани",
      deu: "Pläne",
    },
    apartments_translate: {
      eng: "Apartments",
      rus: "Квартиры",
      ukr: "Квартири",
      deu: "Wohnungen",
    },
    news_translate: {
      eng: "News",
      rus: "Новости",
      ukr: "Новини",
      deu: "Nachrichten",
    },
    addApartment_translate: {
      eng: "Add Apartment",
      rus: "Добавить Квартиру",
      ukr: "Додати Квартиру",
      deu: "Wohnung Hinzufügen",
    },

    verification: {
      eng: "Security Verification",
      rus: "Верификация",
      ukr: "Верифікація",
      deu: "Verifizierung",
    },
    authenticator: {
      eng: "Authenticator App",
      rus: "Приложение-аутентификатор",
      ukr: "Додаток-аутентифікатор",
      deu: "Authenticator-App",
    },
    paste: {
      eng: "Paste",
      rus: "Вставить",
      ukr: "Вставити",
      deu: "Einfügen",
    },
    select_method: {
      eng: "Select Method",
      rus: "Выберите метод",
      ukr: "Виберіть метод",
      deu: "Methode auswählen",
    },
    enter_code: {
      eng: "Enter the 6-digit code generated by the Authenticator App.",
      rus: "Введите 6-значный код, сгенерированный приложением Authenticator.",
      ukr: "Введіть 6-значний код, згенерований додатком Authenticator.",
      deu: "Geben Sie den 6-stelligen Code ein, der von der Authenticator-App generiert wurde.",
    },
    factoring_next: {
      eng: "Next",
      rus: "Далее",
      ukr: "Далі",
      deu: "Weiter",
    },
    factoring_qr_text: {
      eng: "Download Google Authenticator app and scan this QR code",
      rus: "Загрузите приложение Google Authenticator и отсканируйте этот QR-код.",
      ukr: "Завантажте програму Google Authenticator і відскануйте цей QR-код.",
      deu: "Laden Sie die Google Authenticator-App herunter und scannen Sie diesen QR-Code.",
    },
    start_conversation_in_community: {
      eng: "Start a conversation with someone in the platform community. All chats will appear here",
      rus: "Начните разговор с кем-нибудь в сообществе платформы. Все чаты будут отображаться здесь",
      ukr: "Розпочніть розмову з кимось у спільноті платформи. Всі чати з'являться тут",
      deu: "Beginnen Sie ein Gespräch mit jemandem in der Plattform-Community. Alle Chats werden hier angezeigt",
    },
    in_progress:{
      eng: "In progress",
      rus: "В процессе",
      ukr: "В процесі",
      deu: "In Bearbeitung",
    },
    agreement: {
      eng: "Agreement",
      rus: "Согласование",
      ukr: "Узгодження",
      deu: "Vereinbarung",
    },
    closed:{
      eng: "Closed",
      rus: "Закрыто",
      ukr: "Закрито",
      deu: "Geschlossen",
    },
    not_target:{
      eng: "Not target",
      rus: "Не целевой",
      ukr: "Не цільовий",
      deu: "Nicht Ziel",
    },
    failed:{
      eng: "Failed",
      rus: "Неудачно",
      ukr: "Невдало",
      deu: "Fehlgeschlagen",
    },

    recipient_translation: {
      eng: "Recipient",
      rus: "Получатель",
      ukr: "Одержувач",
      deu: "Empfänger",
    },
    bank_details_translation: {
      eng: "Bank Details",
      rus: "Банковские Реквизиты",
      ukr: "Банківські Реквізити",
      deu: "Bankdaten",
    },
    bank_translation: {
      eng: "Bank",
      rus: "Банк",
      ukr: "Банк",
      deu: "Bank",
    },
    hash_code_reference_translation: {
      eng: "When performing an operation using this method, be sure to specify this hash code in the reference of the payment",
      rus: "При выполнении операции этим методом, убедитесь, что вы указали этот хеш-код в назначении платежа",
      ukr: "При виконанні операції цим методом, переконайтеся, що ви вказали цей хеш-код у призначенні платежу",
      deu: "Wenn Sie eine Operation mit dieser Methode durchführen, stellen Sie sicher, dass Sie diesen Hash-Code in der Zahlungsreferenz angeben",
    },
    your_balance_is_translation: {
      eng: "Your balance is",
      rus: "Ваш баланс составляет",
      ukr: "Ваш баланс становить",
      deu: "Ihr Guthaben beträgt",
    },
  },

  get: function (key, lang) {
    let dictionary = this.dictionary[key]
      ? this.dictionary[key]
      : this.dictionary["Key_not_founded"];
    let dictionaryItem = dictionary[lang]
      ? dictionary[lang]
      : this.dictionary["Key_not_founded"]["eng"];

    return dictionaryItem;
  },
};

export default globalDictionary;
