import {GremService} from '../services/index';
import {setUserInfoToLocalStorage} from '../utils/index';
import {makeAutoObservable} from 'mobx';

class Auth {
    user = null;
    errors = [];
    gremService = new GremService();

    constructor() {
        makeAutoObservable(this);
    }

    async doLogin(loginForm) {
        const result = await this.gremService.doLogin(loginForm);
        const userData = result.data;

        if (userData) {
            setUserInfoToLocalStorage(userData);
            this.user = userData;
            this.errors = [];
        } else {
            this.errors = result.errors;
        }
    }
    async doLogout() {
        if(this.user) {
            const result = await this.gremService.doLogout();
        }
            this.clearAll();
            localStorage.clear();
    }

    getUser(){
        return this.user;
    }

    async setUser(user) {
        this.user = user;
        //setUserInfoToLocalStorage(user);
    }

    clearAll() {
        this.user = null;
        this.errors = [];
    }
}

export default new Auth();
