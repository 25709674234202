import React from "react";
import style from "./Footer.module.css";
import { getActiveLanguageFromLS } from "../../../../utils/index";
import globalDictionary from "../../../../assets/translations/globalDictionary";
import { ReactSVG } from "react-svg";

import bgImage from "../../../../assets/images/bg0.jpg";
import { useNavigate } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();
  const language = getActiveLanguageFromLS();
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    navigate(url);
  };
  return (
    <footer
      className={style.footer}
      style={{
        background: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className={style.footerWrapper}>
        <nav className={style.footerNavigation}>
          <ul>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigate("/community");
                }}
                target={"_blank"}
                rel="noreferrer"
              >
                {globalDictionary.get(
                  "layout_cabinet_footer_services_community",
                  language
                )}
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigate("/properties");
                }}
                target={"_blank"}
                rel="noreferrer"
              >
                {globalDictionary.get(
                  "layout_cabinet_footer_services_properties",
                  language
                )}
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigate("/projects");
                }}
                target={"_blank"}
                rel="noreferrer"
              >
                {globalDictionary.get(
                  "layout_cabinet_footer_services_projects",
                  language
                )}
              </a>
            </li>
          </ul>
          <ul>
          <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigate("/contact");
                }}
                target={"_blank"}
                rel="noreferrer"
              >
                {globalDictionary.get(
                  "layout_cabinet_footer_sub_contacts",
                  language
                )}
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a
                href={"https://remaz.az/terms"}
                target={"_blank"}
                rel="noreferrer"
              >
                {globalDictionary.get(
                  "layout_cabinet_footer_sub_terms",
                  language
                )}
              </a>
            </li>
            <li>
              <a
                href={"https://remaz.az/privacy"}
                target={"_blank"}
                rel="noreferrer"
              >
                {globalDictionary.get(
                  "layout_cabinet_footer_sub_privacy",
                  language
                )}
              </a>
            </li>
            <li>
              <a
                href={"https://remaz.az/anla"}
                target={"_blank"}
                rel="noreferrer"
              >
                {globalDictionary.get(
                  "layout_cabinet_footer_sub_anti_money",
                  language
                )}
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={style.footerCopyright}>
        <a
          href={""}
        >
          <span>
            {`${globalDictionary.get(
              "layout_cabinet_footer_copyright",
              language
            )} ${currentYear}`}
          </span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
