import React, {lazy, Suspense} from 'react';

const LazyLoadComponent = (Component) => (props) => (
    <Suspense fallback={
        <></>
    }>
        <Component {...props} />
    </Suspense>
);

const Login = LazyLoadComponent(lazy(() => import('./Auth/Login/Login')));
const Register = LazyLoadComponent(lazy(() => import('./Auth/Register/Register')));
const RegisterSuccess = LazyLoadComponent(lazy(() => import('./Auth/RegisterSuccess/RegisterSuccess')));
const RegisterConfirm = LazyLoadComponent(lazy(() => import('./Auth/RegisterConfirm/RegisterConfirm')));
const Forgot = LazyLoadComponent(lazy(() => import('./Auth/Forgot/Forgot')));
const ForgotMessage = LazyLoadComponent(lazy(() => import('./Auth/ForgotMessage/ForgotMessage')));
const ForgotChangePassword = LazyLoadComponent(lazy(() => import('./Auth/ForgotChangePassword/ForgotChangePassword')));
const Logout = LazyLoadComponent(lazy(() => import('./Auth/Logout/Logout')));
const AuthSocial = LazyLoadComponent(lazy(() => import('./Auth/AuthSocial/AuthSocial')));

const MyProfile = LazyLoadComponent(lazy(() => import('./Profile/MyProfile/MyProfile')));
const ProfileRoles = LazyLoadComponent(lazy(() => import('./Profile/ProfileRoles/ProfileRoles')));
const ProfileCompanyRoles = LazyLoadComponent(lazy(() => import('./Profile/ProfileRoles/ProfileCompanyRoles')));
const ProfileSpecialistRoles = LazyLoadComponent(lazy(() => import('./Profile/ProfileRoles/ProfileSpecialistRoles')));
const ProfileSecondary = LazyLoadComponent(lazy(() => import('./Profile/ProfileRoles/ProfileSecondary')));
const ProfileSecondaryProgress = LazyLoadComponent(lazy(() => import('./Profile/ProfileRoles/ProfileSecondaryProgress')));
const ProfileEdit = LazyLoadComponent(lazy(() => import('./Profile/MyProfile/components/ProfileEdit/ProfileEdit')));

const KycAdd = LazyLoadComponent(lazy(() => import('./Profile/MyProfile/Kyc/KycAdd')));

const Properties = LazyLoadComponent(lazy(() => import('./Services/Properties/Properties')));
const PropertyItem = LazyLoadComponent(lazy(() => import('./Services/Properties/components/PropertyItem/PropertyItem')));
const PropertyAdd = LazyLoadComponent(lazy(() => import('./Services/Properties/components/PropertyAdd/PropertyAdd')));
const PropertyEdit = LazyLoadComponent(lazy(() => import('./Services/Properties/components/PropertyEdit')));

const ComplexAdd = LazyLoadComponent(lazy(() => import('./Services/Complex/ComplexAdd/ComplexAdd')));
const ComplexEdit = LazyLoadComponent(lazy(() => import('./Services/Complex/ComplexEdit/ComplexEdit')));
const ComplexList = LazyLoadComponent(lazy(() => import('./Services/Complex/ComplexList')));
const ComplexItem = LazyLoadComponent(lazy(() => import('./Services/Complex/components/ComplexItem')));

const Projects = LazyLoadComponent(lazy(() => import('./Services/Projects/Projects')));
const ProjectsAdd = LazyLoadComponent(lazy(() => import('./Services/Projects/components/ProjectAdd/ProjectsAdd')));
const ProjectItem = LazyLoadComponent(lazy(() => import('./Services/Projects/components/ProjectItem/ProjectItem')));

const Community = LazyLoadComponent(lazy(() => import('./Services/Community/Community')));
const CommunityItem = LazyLoadComponent(lazy(() => import('./Services/Community/components/CommunityItem/CommunityItem')));

const RequestAdd = LazyLoadComponent(lazy(() => import('./Profile/MyProfile/components/ProfileRequests/components/RequestAdd/RequestAdd')));
const RequestEdit = LazyLoadComponent(lazy(() => import('./Profile/MyProfile/components/ProfileRequests/components/RequestEdit/RequestEdit')));

const Wallet = LazyLoadComponent(lazy(() => import('./Services/Wallet/Wallet')));

const WalletDepositTypes = LazyLoadComponent(lazy(() => import('./Services/Wallet/components/WalletDepositTypes/WalletDepositTypes')));
const WalletDepositCrypto = LazyLoadComponent(lazy(() => import('./Services/Wallet/components/WalletDepositCrypto/WalletDepositCrypto')));
const WalletDepositFiat = LazyLoadComponent(lazy(() => import('./Services/Wallet/components/WalletDepositFiat/WalletDepositFiat')));

const WalletWithdrawTypes = LazyLoadComponent(lazy(() => import('./Services/Wallet/components/WalletWithdrawTypes/WalletWithdrawTypes')));
const WalletWithdrawCrypto = LazyLoadComponent(lazy(() => import('./Services/Wallet/components/WalletWithdrawCrypto/WalletWithdrawCrypto')));
const WalletWithdrawFiat = LazyLoadComponent(lazy(() => import('./Services/Wallet/components/WalletWithdrawFiat/WalletWithdrawFiat')));
const WalletWithdrawManual = LazyLoadComponent(lazy(() => import('./Services/Wallet/components/WalletWithdrawManual/WalletWithdrawManual')));

const Marketplace = LazyLoadComponent(lazy(() => import('./Services/Marketplace/Marketplace')));

const Convert = LazyLoadComponent(lazy(() => import('./Services/Convert/Convert')));
const OrdersHistory = LazyLoadComponent(lazy(() => import('./Services/Convert/OrdersHistory')));

const Exchange = LazyLoadComponent(lazy(() => import('./Services/Exchange/Exchange')));

const Staking = LazyLoadComponent(lazy(() => import('./Services/Staking/Staking')));
const StakingContractItem = LazyLoadComponent(lazy(() => import('./Services/Staking/components/StakingContractItem')));
const ContractsHistoryTransaction = LazyLoadComponent(lazy(() => import('./Services/Staking/components/ContractsHistory/components/ContractsHistoryTransaction')));

const Messenger = LazyLoadComponent(lazy(() => import('./Services/Messenger/Messenger')));

const TicketList = LazyLoadComponent(lazy(() => import('./Services/Support/TicketList/TicketList')));
const TicketItem = LazyLoadComponent(lazy(() => import('./Services/Support/TicketItem/TicketItem')));
const TicketAdd = LazyLoadComponent(lazy(() => import('./Services/Support/TicketAdd/TicketAdd')));

const SplitCommissionAdd = LazyLoadComponent(lazy(() => import('./Services/Contracts/SplitCommission/SplitCommissionAdd')));

const News = LazyLoadComponent(lazy(() => import('./News/News')));
const ArticleItem = LazyLoadComponent(lazy(() => import('./News/components/ArticleItem/ArticleItem')));

const Contact = LazyLoadComponent(lazy(() => import('./Contact/Contact')));

const Error = LazyLoadComponent(lazy(() => import('./SecondaryPages/Error/Error')));
const ComingSoon = LazyLoadComponent(lazy(() => import('./SecondaryPages/ComingSoon/ComingSoon')));

export {
    LazyLoadComponent,
    Login,
    Register,
    RegisterSuccess,
    RegisterConfirm,
    Forgot,
    ForgotMessage,
    ForgotChangePassword,
    Logout,
    AuthSocial,
    MyProfile,
    ProfileRoles,
    ProfileCompanyRoles,
    ProfileSpecialistRoles,
    ProfileSecondary,
    ProfileSecondaryProgress,
    ProfileEdit,
    KycAdd,
    Properties,
    PropertyItem,
    PropertyAdd,
    PropertyEdit,
    ComplexAdd,
    ComplexEdit,
    ComplexList,
    ComplexItem,
    Projects,
    ProjectsAdd,
    ProjectItem,
    Community,
    CommunityItem,
    RequestAdd,
    RequestEdit,
    Wallet,
    WalletDepositTypes,
    WalletDepositCrypto,
    WalletDepositFiat,
    WalletWithdrawTypes,
    WalletWithdrawCrypto,
    WalletWithdrawFiat,
    Marketplace,
    Convert,
    OrdersHistory,
    Exchange,
    Staking,
    StakingContractItem,
    ContractsHistoryTransaction,
    Messenger,
    TicketList,
    TicketItem,
    TicketAdd,
    SplitCommissionAdd,
    News,
    ArticleItem,
    Contact,
    Error,
    ComingSoon,
    WalletWithdrawManual,
};
