import React, { createContext, useCallback } from 'react';
import TranslatorFactory from './helpers/translator-factory';
import { TranslationHandler, CacheProvider } from './types';

const defaultHandler = () => {};

export const TranslateContext = createContext(defaultHandler);
export const LanguageContext = createContext('en');

const Translator = ({
                        to,
                        from,
                        cacheProvider,
                        children,
                        googleApiKey,
                    }) => {
    const handleTranslationAsync = useCallback(
        async (value, setTranslation) => {
            const options = {
                to,
                from,
                apiKey: googleApiKey,
            };
            const translator = TranslatorFactory.create(options, cacheProvider);
            const translation = await translator.translate(value);

            if (translation) {
                setTranslation(translation);
            }
        },
        [to, from, googleApiKey, cacheProvider]
    );

    return (
        <TranslateContext.Provider value={handleTranslationAsync}>
            <LanguageContext.Provider value={to}>
                {children}
            </LanguageContext.Provider>
        </TranslateContext.Provider>
    );
};

export default Translator;
