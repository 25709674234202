import io from 'socket.io-client';
import {wsServer} from '../config';

const ChatSocketService = {
    socket: null,

    async init(token) {
        return new Promise((resolve, reject) => {
            if (token) {
                this.socket = io(`${wsServer}/chat`, {
                    transports: ['websocket'],
                    query: {
                        token,
                    },
                });

                this.socket.on('connect', () => {
                    resolve(this);
                });

                this.socket.on('error', (error) => {
                    console.error(error);
                    reject(error);
                });

                this.socket.on('connect_error', (error) => {
                    console.error(error);
                    reject(error);
                });
            }
        });
    },

    async getChats() {
        return new Promise((resolve) => {
            this.socket.emit('chatsData', (value) => {
                resolve(value);
            });
        });
    },

    async getMessageList(chatId) {
        return new Promise((resolve) => {
            this.socket.emit(
                'messagesList',
                {
                    chatId,
                },
                (value) => {
                    resolve(value);
                }
            );
        });
    },

    async sendMessage(data) {
        return new Promise((resolve) => {
            this.socket.emit(
                'sendMessage',
                data,
                (value) => {
                    resolve(value);
                }
            );
        });
    },

    async confirmNewChat(chatId, decision) {
        return new Promise((resolve) => {
            this.socket.emit(
                'confirmChat',
                {
                    chatId,
                    decision,
                },
                (value) => {
                    resolve(value);
                }
            );
        });
    },
};

export default ChatSocketService;
