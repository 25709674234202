import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageChecker from "../ImageChecker/ImageChecker";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const ResponsiveCarousel = ({ slides, alt, text }) => {
  const imageSlider = {
    showArrows: true,
    autoPlay: true,
    interval: 5000,
    transitionTime: 500,
    showThumbs: true,
    swipeable: true,
    swipeScrollTolerance: 10,
  };

  const updateImageSlider = (array) => {
    const updated = { ...imageSlider };

    if (Array.isArray(array)) {
      updated.showThumbs = array.length !== 1;
      if (array.length === 1) {
        updated.showArrows = false;
        updated.swipeable = false;
      }
    }

    return updated;
  };

  const updatedSlider = updateImageSlider(slides);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const goToNext = () => {
    const nextIndex = (currentIndex + 1) % slides.length;
    setSelectedImage(slides[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const goToPrevious = () => {
    const prevIndex = (currentIndex - 1 + slides.length) % slides.length;
    setSelectedImage(slides[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  return (
    <div style={{ width: "100%" }}>
      <Carousel {...updatedSlider}>
        {slides.map((slide, index) => (
          <div
            style={{ width: "100%", height: "350px" }}
            key={index}
            onClick={() => openModal(slide,index)}
          >
            <ImageChecker
              variant="rounded"
              alt={alt}
              text={text}
              sx={{
                width: "100%",
                height: 350,
              }}
              src={slide}
            ></ImageChecker>
          </div>
        ))}
      </Carousel>

      <Modal
        open={modalVisible}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
        <IconButton 
            onClick={goToPrevious} 
            sx={{ position: 'absolute', left: 16, top: '50%', transform: 'translateY(-50%)',color:"white" }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
            outline: "none",
          }}
        >
          <ImageChecker
            src={selectedImage}
            alt={alt}
            text={text}
            variant="rounded"
            sx={{
              width: "100%",
              minHeight: 450,
            }}
          />
        </Box>
        <IconButton 
            onClick={goToNext} 
            sx={{ position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)',color:"white" }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        <IconButton 
            onClick={closeModal} 
            sx={{ position: 'absolute', right: 8, top: 8  , color:"white"}}
          >
            <CloseIcon />
          </IconButton>
          </>
      </Modal>
    </div>
  );
};
export default ResponsiveCarousel;
