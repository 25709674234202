import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import countries from "assets/translations/countries";
import {
  getActiveLanguageFromLS,
  isCommunity,
  navigateToSecondaryAuth,
  numberFormatter,
} from "utils/index";
import { StoreContext } from "Providers";
import globalDictionary from "assets/translations/globalDictionary";
import { Box, Stack } from "@mui/system";
import { snackActions } from "utils/SnackBarUtils";
import GremService from "services/GremService";
import { ImageChecker } from "components";
import { Link } from "react-router-dom";

function ProjectListItem({ project: projectData = {} }) {
  const language = getActiveLanguageFromLS();
  const { auth } = useContext(StoreContext);
  const isCommunityUser = isCommunity(auth.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [project, setProject] = useState(projectData);
  const subdomain = project?.landing?.subdomain;
  const service = new GremService();
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://${subdomain}.remaz.az`);
    snackActions.success(
      globalDictionary.get("projects_list_item_link_clipboard", language)
    );
  };

  const startExpertise = async (projId) => {
    const result = await service.initializeExpertise(projId);
    if (result.code === 200) {
      setProject({ ...project, isExpertiseInitiated: true });
      snackActions.success(
        globalDictionary.get(
          "projects_list_item_start_expertise_success",
          language
        )
      );
    }
  };
  const isMenuOpen = Boolean(anchorEl);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Card
        sx={{
          flex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          "&:hover": {
            boxShadow: "0 0 10px 1px #dedede",
          },
        }}
      >
        <IconButton
          onClick={handleOpenMenu}
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
            zIndex: 100,
          }}
          size="large"
        >
          <MoreVert sx={{ color: "white" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              minWidth: "100px",
              width: "fit-content",
              transform: "translateX(50%)",
            },
          }}
        >
          <MenuItem
            component="a"
            href={`https://${subdomain}.remaz.az`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleCloseMenu}
          >
            <Typography sx={{ color: "#0288d1" }}>
              {globalDictionary.get("projects_list_item_view", language)}
            </Typography>
          </MenuItem>

          <MenuItem onClick={handleCopyLink}>
            <Typography sx={{ color: "#0288d1" }}>
              {globalDictionary.get("projects_list_item_share", language)}
            </Typography>
          </MenuItem>
          {auth?.user?.commId === project?.projectOwner?.commId &&
          project.isAccepted === false &&
          project.isExpertiseInitiated === false ? (
            <MenuItem
              onClick={() => {
                startExpertise(project._id);
              }}
            >
              <Typography sx={{ color: "#0288d1" }}>
                {globalDictionary.get(
                  "projects_list_item_startExpertise",
                  language
                )}
              </Typography>
            </MenuItem>
          ) : (
            ""
          )}
        </Menu>
        <a
          target="_blank"
          href={subdomain ? `https://${subdomain}.remaz.az` : ""}
          onClick={(event) => {
            if (!isCommunityUser) {
              event.preventDefault();
              navigateToSecondaryAuth();
            }
          }}
        >
          <ImageChecker
            src={project.mainPhoto && project.mainPhoto !== "" ? project.mainPhoto : project.photos[0]}
            alt={project.name}
            variant={"rounded"}
            sx={{
              width: "100%",
              height: 200,
            }}
            imgProps={{
              objectfit: "cover",
            }}
            text={project.name}
          />
        </a>
        <CardContent
          sx={{
            paddingTop: "5px",
            paddingBottom: 0,
            flex: 1,
            width: "100%",
          }}
        >
          <Typography gutterBottom fontSize={"1em"} variant="h6" component="h3">
            {project.name}
          </Typography>
          <Typography>
            {`${countries.get(project.country, "eng") || project.country}, ${
              project.city
            }`}
          </Typography>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginTop: 1,
            }}
          >
            <Chip
              label={`${globalDictionary.get(
                "projects_list_item_type",
                language
              )}: ${project.types.map((type) => type)}`}
              variant="outlined"
            />
            {project.financialIndicators && (
              <>
                {project.financialIndicators.IRR && (
                  <Chip
                    label={`IRR: ${parseInt(project.financialIndicators.IRR)}%`}
                    variant="outlined"
                  />
                )}
                {project.financialIndicators.paybackPeriod && (
                  <Chip
                    label={`${globalDictionary.get(
                      "projects_list_item_payback",
                      language
                    )}: ${project.financialIndicators.paybackPeriod} year(s)`}
                    variant="outlined"
                  />
                )}
              </>
            )}
            <Chip
              label={`${globalDictionary.get(
                "projects_list_item_total_investment",
                language
              )}: ${numberFormatter(project.totalInvestment)} ${
                project.investmentCurrency
              }`}
              variant="outlined"
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              gap: 1,
            }}
          >
            {subdomain && (
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  if (isCommunityUser) {
                    window.open(`https://${subdomain}.remaz.az`, "_blank");
                  } else {
                    navigateToSecondaryAuth();
                  }
                }}
              >
                {globalDictionary.get("projects_list_item_more", language)}
              </Button>
            )}
            {auth?.user?.commId === project?.projectOwner?.commId ? (
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                component={Link}
                to={`/projects/${project._id}`}
              >
                {globalDictionary.get('profile_tabs_settings',language)}
              </Button>
            ) : (
              ""
            )}
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default ProjectListItem;
