import React from "react";

import {
    ArticleItem,
    Community,
    CommunityItem,
    ComplexAdd,
    ComplexEdit,
    ComplexItem,
    ComplexList,
    Contact,
    ContractsHistoryTransaction,
    Convert,
    Exchange,
    KycAdd,
    Marketplace,
    Messenger,
    MyProfile,
    News,
    OrdersHistory,
    ProfileCompanyRoles,
    ProfileEdit,
    ProfileRoles,
    ProfileSecondary,
    ProfileSecondaryProgress,
    ProfileSpecialistRoles,
    ProjectItem,
    Projects,
    ProjectsAdd,
    Properties,
    PropertyAdd,
    PropertyEdit,
    PropertyItem,
    RequestAdd,
    RequestEdit,
    SplitCommissionAdd,
    Staking,
    StakingContractItem,
    TicketAdd,
    TicketItem,
    TicketList,
    Wallet,
    WalletDepositCrypto,
    WalletDepositFiat,
    WalletDepositTypes,
    WalletWithdrawCrypto,
    WalletWithdrawFiat,
    WalletWithdrawTypes,
    WalletWithdrawManual,
} from "../pages";

import ApartsComplex from "../pages/Services/Complex/components/components/ApartsComplex";
import ProgressComplexAdd from "../pages/Services/Complex/components/components/ProgressComplexAdd";
import {KanbanView} from "../components/shared/Kanban/view/index";
import {KanbanBoard} from "../components/shared/Kanban/view";

const cabinetRoutes = [
    {
        path: "my/wallet/:anchor?",
        element: <Wallet/>,
        protected: true,
    },
    {
        path: "my/wallet/deposit",
        element: <WalletDepositTypes/>,
        protected: true,
    },
    {
        path: "my/wallet/deposit/crypto/:assetCode?",
        element: <WalletDepositCrypto/>,
        protected: true,
    },
    {
        path: "my/wallet/deposit/fiat/:assetCode?",
        element: <WalletDepositFiat/>,
        protected: true,
    },
    {
        path: "my/wallet/withdraw",
        element: <WalletWithdrawTypes/>,
        protected: true,
    },
    {
        path: "my/wallet/withdraw/crypto/:assetCode?",
        element: <WalletWithdrawCrypto/>,
        protected: true,
    },
    {
        path: "my/wallet/withdraw/fiat/:assetCode?",
        element: <WalletWithdrawFiat/>,
        protected: true,
    },
    {
        path: "my/wallet/withdraw/manual",
        element: <WalletWithdrawManual/>,
        protected: true,
    },
    {
        path: "profile",
        element: <MyProfile/>,
        protected: true,
        index: true,
    },
    {
        path: "my/profile/:anchor?",
        element: <MyProfile/>,
        protected: true,
    },
    {
        path: "my/profile/edit",
        element: <ProfileEdit/>,
        protected: true,
    },
    {
        path: "my/profile/secondary",
        element: <ProfileRoles/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: "my/profile/secondary/companies",
        element: <ProfileCompanyRoles/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: "my/profile/secondary/specialists",
        element: <ProfileSpecialistRoles/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: "my/profile/secondary/add",
        element: <ProfileSecondary/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: "my/profile/secondary/progress",
        element: <ProfileSecondaryProgress/>,
        protected: true,
        communityProtected: true,
    },
    {
        path: "my/profile/kyc/add",
        element: <KycAdd/>,
        protected: true,
    },
    {
        path: "landing/:targetType/:targetId/add",
        element: <SplitCommissionAdd/>,
        protected: true,
    },
    {
        path: "contracts/:targetType/division/:targetId/add",
        element: <SplitCommissionAdd/>,
        protected: true,
    },
    {
        path: "properties",
        element: <Properties/>,
        protected: true,
    },
    {
        path: "properties/:propertyId",
        element: <PropertyItem/>,
        protected: true,
    },
    {
        path: "properties/add",
        element: <PropertyAdd/>,
        protected: true,
    },
    {
        path: "properties/edit/:propertyId",
        element: <PropertyEdit/>,
        protected: true,
    },
    {
        path: "complex",
        element: <ComplexList/>,
        protected: true,
    },
    {
        path: "complex/add",
        element: <ComplexAdd/>,
        protected: true,
    },
    {
        path: "complex/edit/:complexId",
        element: <ComplexEdit/>,
        protected: true,
    },
    {
        path: "complex/:complexId",
        element: <ComplexItem/>,
        protected: true,
    },
    {
        path: "complex/:id/aparts",
        element: <ApartsComplex/>,
        protected: true,
    },
    {
        path: "complex/:complexId/progress",
        element: <ProgressComplexAdd/>,
        protected: true,
    },
    {
        path: "projects",
        element: <Projects/>,
        protected: true,
    },
    {
        path: "projects/add",
        element: <ProjectsAdd/>,
        protected: true,
    },
    {
        path: "projects/:projectId",
        element: <ProjectItem/>,
        protected: true,
    },
    {
        path: "community",
        element: <Community/>,
        protected: true,
    },
    {
        path: "community/:userId",
        element: <CommunityItem/>,
        protected: true,
    },
    {
        path: "contact",
        element: <Contact/>,
        protected: true,
    },
    {
        path: "support/tickets",
        element: <TicketList/>,
        protected: true,
    },
    {
        path: "support/tickets/add",
        element: <TicketAdd/>,
        protected: true,
    },
    {
        path: "support/tickets/:ticketId",
        element: <TicketItem/>,
        protected: true,
    },
    {
        path: "marketplace",
        element: <Marketplace/>,
        protected: true,
    },
    {
        path: "convert/:from?/:to?",
        element: <Convert/>,
        protected: true,
    },
    {
        path: "convert/history",
        element: <OrdersHistory/>,
        protected: true,
    },
    {
        path: "trade",
        element: <Exchange/>,
        protected: true,
    },
    {
        path: "staking/:anchor?",
        element: <Staking/>,
        protected: true,
    },
    {
        path: "staking/contract/:contractId",
        element: <StakingContractItem/>,
        protected: true,
    },
    {
        path: "staking/contract/:contractId/transaction/:transactionId",
        element: <ContractsHistoryTransaction/>,
        protected: true,
    },
    {
        path: "news",
        element: <News/>,
        protected: true,
    },
    {
        path: "news/:articleId",
        element: <ArticleItem/>,
        protected: true,
    },
    {
        path: "requests/add",
        element: <RequestAdd/>,
        protected: true,
    },
    {
        path: "requests/edit/:requestId",
        element: <RequestEdit/>,
        protected: true,
    },
    {
        path: "blog/:articleId",
        element: <ArticleItem/>,
        protected: true,
    },
    {
        path: "chat/:chatId?",
        element: <Messenger/>,
        protected: true,
    },
    {
        path: "crm",
        element: <KanbanBoard/>,
        protected: true,
    },
];

export default cabinetRoutes;
