import PropTypes from 'prop-types';
import {Draggable} from '@hello-pangea/dnd';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {useBoolean} from 'hooks/use-boolean';

import {bgBlur} from 'theme/css';

import Iconify from 'components/v2/iconify';

import KanbanDetails from './kanban-details';

// ----------------------------------------------------------------------

export default function KanbanTaskItem({task, index, isTaskMove, sx, ...other}) {
    if (!task) return;
    const theme = useTheme();

    const openDetails = useBoolean();
    const taskCreatedAt = new Date(task.createdAt).toLocaleDateString();
    const taskUpdatedAt = new Date(task.updatedAt).toLocaleDateString();

    const renderInfo = (
        <Stack direction="row" alignItems="center">
            <Stack
                flexGrow={1}
                direction="column"
                justifyContent="center"
                sx={{
                    typography: 'caption',
                    color: 'text.disabled',
                }}
            >
                <Stack direction={'row'}>
                    <Iconify width={16} icon="eva:calendar-outline" sx={{mr: 0.25}}/>
                    <Box component="span">{taskCreatedAt}</Box>
                </Stack>

                <Stack direction={'row'}>
                    <Iconify width={16} icon="material-symbols:update" sx={{mr: 0.25}}/>
                    <Box component="span">{taskUpdatedAt}</Box>
                </Stack>
            </Stack>
        </Stack>
    );

    return (
        <>
            <Draggable draggableId={task.id} index={index} isDragDisabled={!isTaskMove}>
                {(provided, snapshot) => (
                    <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={openDetails.onTrue}
                        sx={{
                            width: 1,
                            borderRadius: 1.5,
                            overflow: 'hidden',
                            position: 'relative',
                            bgcolor: 'background.default',
                            boxShadow: theme.customShadows.z1,
                            '&:hover': {
                                boxShadow: theme.customShadows.z20,
                            },
                            ...(openDetails.value && {
                                boxShadow: theme.customShadows.z20,
                            }),
                            ...(snapshot.isDragging && {
                                boxShadow: theme.customShadows.z20,
                                ...bgBlur({
                                    opacity: 0.48,
                                    color: theme.palette.background.default,
                                }),
                            }),
                            ...sx,
                        }}
                        {...other}
                    >
                        <Stack spacing={2} sx={{px: 2, py: 2.5, position: 'relative'}}>
                            <Typography variant="subtitle2">{task.customerName}</Typography>
                            {renderInfo}
                        </Stack>
                    </Paper>
                )}
            </Draggable>

            <KanbanDetails
                task={task}
                openDetails={openDetails.value}
                onCloseDetails={openDetails.onFalse}
            />
        </>
    );
}

KanbanTaskItem.propTypes = {
    index: PropTypes.number,
    sx: PropTypes.object,
    task: PropTypes.object,
    isTaskMove: PropTypes.bool,
};
