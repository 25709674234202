import PropTypes from 'prop-types';
import {useCallback, useContext, useState} from 'react';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import {styled} from '@mui/material/styles';
import Scrollbar from 'components/v2/scrollbar';
import {InputLabel,Button, MenuItem, Select, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Iconify from "../../v2/iconify";
import React from "react";
import {FormControl} from "@mui/base";
import {getActiveLanguageFromLS} from "utils/index";
import globalDictionary from "../../../assets/translations/globalDictionary";
import {LeadContext} from "./view/kanbanBoard";
import {BoardContext} from "./view/kanban-view";

// ----------------------------------------------------------------------

const StyledLabel = styled('span')(({theme}) => ({
    ...theme.typography.caption,
    width: 100,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightSemiBold,
}));

// ----------------------------------------------------------------------

export default function KanbanDetails({
                                          task,
                                          openDetails,
                                          onCloseDetails,
                                      }) {
    const [taskDescription, setTaskDescription] = useState(task.description);

    const handleChangeTaskDescription = useCallback((event) => {
        setTaskDescription(event.target.value);
    }, []);
    const language = getActiveLanguageFromLS();
    const [text,setText] = useState({message:'',messages: ['']});
    const [show,setShow] = useState(false);
    const renderDetails = () => {
        const {customerContact: {
            email: customerEmail,
            phone: customerPhone,
        }, customerName, customerComments, brokerComments, proposedTerms} = task;
        const taskCreatedAt = new Date(task.createdAt).toLocaleDateString();
        const taskUpdatedAt = new Date(task.updatedAt).toLocaleDateString();
        const {moveTask,board} = useContext(BoardContext)

        return (
            <Stack direction={'column'} sx={{
                gap: 2,
            }}>
                <Stack direction="row" justifyContent={'left'} alignItems={'center'}>
                    <Stack direction={'row'} alignItems={'left'}>
                        <Iconify width={16} icon="eva:calendar-outline" sx={{mr: 0.25}}/>
                        <Box component="span">{taskCreatedAt}</Box>
                    </Stack>

                    <Stack direction={'row'} alignItems={'left'}>
                        <Iconify width={16} icon="material-symbols:update" sx={{mr: 0.25,ml:"10px"}}/>
                        <Box component="span">{taskUpdatedAt}</Box>
                    </Stack>
                    <Stack direction={'row'} alignItems={'right'} width={"100%"}>
                        <Box sx={{ml:"10px", width:"100%"}}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="select-country-label">
                                    Status
                                </InputLabel>
                            <Select
                                labelId="select-country-label"
                                id="select-country"
                                label={globalDictionary.get(
                                    "status",
                                    language
                                )}
                                fullWidth={true}
                                value={task.status}
                                name={'status'}
                                //destination, source, draggableId
                                onChange={(e)=>{
                                    moveTask(
                                        {destination: {droppableId:e.target.value,index:0},
                                            source: {droppableId: task.status,index:board.columns[task.status].taskIds.indexOf(task.id)},
                                            draggableId:task.id,
                                            board})
                                            task.status = e.target.value;
                                }}
                            >
                                {
                                    ["new", "in progress", "processing", "agreement", "closed", "not target", "failed"].map((item)=>(
                                        <MenuItem key={item} value={item}>
                                            {globalDictionary.get(
                                                item.replace(" ",'_'),
                                                language
                                            )}
                                        </MenuItem>
                                    ))

                                }
                            </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                </Stack>
                <Divider/>
                <Typography variant={'h6'}>
                    Customer info
                </Typography>
                <Stack direction="row" alignItems={'center'}>
                    <StyledLabel>
                        Name:
                    </StyledLabel>

                    <Typography variant="body2">{customerName}</Typography>
                </Stack>
                {
                    customerEmail && (
                        <Stack direction="row" alignItems={'center'}>
                            <StyledLabel>
                                Email:
                            </StyledLabel>

                            <Typography variant="body2">
                                <a href={`mailto:${customerEmail}`}>{customerEmail}</a>
                            </Typography>
                        </Stack>
                    )
                }
                {
                    customerPhone && (
                        <Stack direction="row" alignItems={'center'}>
                            <StyledLabel>
                                Phone:
                            </StyledLabel>

                            <Typography variant="body2">
                                <a href={`tel:${customerPhone}`}>{customerPhone}</a>
                            </Typography>
                        </Stack>
                    )
                }
                {
                    customerComments && (
                        <Stack direction="column" justifyContent={'center'}>
                            <StyledLabel>
                                Comments:
                            </StyledLabel>

                            <Typography variant="body2">
                                {customerComments}
                            </Typography>
                        </Stack>
                    )
                }
                <Divider/>
                <Typography variant={'h6'}>
                    Other
                </Typography>
                <Stack direction="column" justifyContent={'center'}>
                    <StyledLabel>
                        Broker comments:
                    </StyledLabel>

                    <Typography variant="body2">
                        {brokerComments || '-'}
                    </Typography>
                </Stack>
                <Stack direction="column" justifyContent={'center'}>
                    <StyledLabel>
                        Proposed terms:
                    </StyledLabel>

                    <Typography variant="body2">
                        {proposedTerms || '-'}
                    </Typography>
                </Stack>
                <Stack direction="column" justifyContent={'center'}>
                    <StyledLabel>
                        Message to Lead:
                    </StyledLabel>
                    {
                      show&&(text.messages && text.messages.map((item)=>(
                          <Typography variant="body2">
                            {item}
                        </Typography>)))
                    }
                    <TextField
                    value={text.message}
                    onChange={(e)=>{setText({...text,message:e.target.value})}}
                    >

                    </TextField>
                    <Button
                        sx={{mt: 1}}
                        variant="contained"
                        color="secondary"
                    onClick={()=>{
                        setText({message:'',messages: [...text.messages,text.message]})
                        setShow(true)
                    }}
                    >
                        Send Message
                    </Button>
                </Stack>
            </Stack>
        );
    }

    return (
        <Drawer
            open={openDetails}
            onClose={onCloseDetails}
            anchor="right"
            slotProps={{
                backdrop: {invisible: true},
            }}
            PaperProps={{
                sx: {
                    width: {
                        xs: 1,
                        sm: 480,
                    },
                    paddingTop: 10,
                },
            }}
        >
            <Divider/>

            <Scrollbar
                sx={{
                    height: 1,
                    '& .simplebar-content': {
                        height: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <Stack
                    spacing={3}
                    sx={{
                        pt: 3,
                        pb: 5,
                        px: 2.5,
                    }}
                >
                    {renderDetails()}
                </Stack>
            </Scrollbar>
        </Drawer>
    );
}

KanbanDetails.propTypes = {
    onCloseDetails: PropTypes.func,
    openDetails: PropTypes.bool,
    task: PropTypes.object,
};
