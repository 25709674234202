import React,{Fragment,useContext} from 'react'
import {getFactoringList} from "utils";
import {Button, Grid, Typography} from "@mui/material";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import EmailIcon from '@mui/icons-material/Email';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {StoreContext} from "../../Providers";

const selectMethod = ({methods,setMethod}) => {

    const {auth} = useContext(StoreContext);
    const changeMethod = (method) => {
        const isEnabled = auth.user.factoring.google
        isEnabled ? setMethod('googleVerify') : setMethod(method)

    }
    return(
        <Fragment>
            <Grid container spacing={2}>
                {methods.map((method) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={method}>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                color: "white",
                                backgroundColor: "darkgrey",
                                minHeight: "60px",
                                marginTop: "5px",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                textTransform: "none",
                                "&:disabled": {
                                    backgroundColor: "#fa7319",
                                    color: "white",
                                },
                            }}
                            onClick={() => changeMethod(method)}
                        >
                            <Typography marginTop={0.5}>
                                {method === "google" ? (
                                    <AvTimerIcon />
                                ) : (
                                    <EmailIcon />
                                )}
                            </Typography>
                            <Typography
                                sx={{
                                    width: "200px",
                                    display: "flex",
                                    marginLeft: "15px",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                }}
                            >
                                {method === "google" ? "Authenticator App" : "Email"}
                            </Typography>
                            <Typography marginTop={0.5} marginLeft={14}>
                                <ArrowForwardIcon/>
                            </Typography>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    )
}

export default selectMethod;
